import React, { useEffect, useState } from 'react'
import * as SurveyBuilder from 'survey-creator'
import 'survey-knockout/survey.css'
import 'survey-creator/survey-creator.css'
import useCampaignsService from '../../Services/Campaigns'
import { StyledTableHeader } from '../../Theme/Components'
import { Button, Form, Header, Icon, Segment } from 'semantic-ui-react'
import { dashboard } from '../../Theme/Styles'
import { schoolLevels as schoolLevelsOptions } from '../../Config/SelectOptions'
import useGlobal from '../../Store'

SurveyBuilder.StylesManager.applyTheme('darkrose')

const SurveyCreator = (props) => {
  const [, globalActions] = useGlobal()
  const { getSurvey, saveSurvey, updateSurvey } = useCampaignsService()

  const campaignId = props.match.params.campaignId || '0'
  const surveyId = props.match.params.surveyId || '0'

  const [creator, setCreator] = useState(null)
  const [survey, setSurvey] = useState({ id: 0, body: { title: '' }, schoolLevels: [] })

  const createBuilder = body => {
    const creator = new SurveyBuilder.SurveyCreator('creatorElement', {
      showToolbox: 'right',
      showPropertyGrid: 'right',
      showLogicTab: false,
    })
    creator.rightContainerActiveItem('toolbox')
    if (body) creator.JSON = body

    setCreator(creator)
  }

  const _saveSurvey = () => {
    if (!survey.schoolLevels.length) {
      globalActions.notifications.showNotification({
        type: 'warning',
        header: 'School Level missing',
        subheader: 'Please select a school level!'
      })

      return null
    }

    if (!creator.JSON.title) {
      globalActions.notifications.showNotification({
        type: 'warning',
        header: 'Survey Title missing',
        subheader: 'Please enter a title for this survey!'
      })

      return null
    }

    if (surveyId !== '0')
      updateSurvey(campaignId, surveyId, creator.JSON)
        .then(() => {
          globalActions.notifications.showNotification({
            type: 'success',
            header: 'Survey Edited',
          })

          props.history.push(`/campaigns/${campaignId}`)
        })
    else
      saveSurvey(campaignId, creator.JSON, survey.schoolLevels)
        .then(() => {
          globalActions.notifications.showNotification({
            type: 'success',
            header: 'Survey Added',
          })

          props.history.push(`/campaigns/${campaignId}`)
        })
  }

  useEffect(() => {
    if (surveyId !== '0') {
      getSurvey(surveyId)
        .then((survey => {
          if (survey)
            setSurvey({ ...survey, body: JSON.parse(survey.body), schoolLevels: survey.schoolLevels.map(s => s.name) })
        }))
        .catch(createBuilder)
    } else {
      createBuilder()
    }
  }, [])

  useEffect(() => {
    if (survey.id) createBuilder(survey.body)
  }, [survey])

  return (
    <>
      <StyledTableHeader>
        <Header as='h2' style={dashboard.phHeader}><strong>{survey.body.title}</strong></Header>
        <Button icon labelPosition='left' color="green" onClick={_saveSurvey}>
          <Icon name='save'/>
          Save
        </Button>
      </StyledTableHeader>

      <Segment>
        <Form>
          <Form.Select label="School Levels" options={schoolLevelsOptions.filter(s => s.value !== 'PRESCHOOL')} multiple
                       value={survey.schoolLevels}
                       required style={{ marginTop: 20, marginBottom: 20 }} placeholder="Choose ..."
                       disabled={!!survey.id}
                       onChange={(event, data) => setSurvey({ ...survey, schoolLevels: data.value })}/>
        </Form>

        <div id="creatorElement"/>
      </Segment>
    </>
  )
}

export default SurveyCreator
