import React, { useEffect, useState } from 'react'
import { Header, Segment, Divider, Responsive, Table, Item, Modal, Button, Popup } from 'semantic-ui-react'
import useGlobal from '../../Store'
import {
  LineChart, Line, YAxis, CartesianGrid, Tooltip, Legend, XAxis, ResponsiveContainer
} from 'recharts'
import { NavLink } from 'react-router-dom'
import useUrlBuilder from '../../Utils/Links'
import { dashboard } from '../../Theme/Styles'
import {
  RightMenuBar,
  RightMenuBarButton,
  RightMenuBarButtonIcon,
  RightMenuBarOptions,
  StyledHeaderCell, StyledTable
} from '../../Theme/Components'
import _ from 'lodash'

const StudentReport = (props) => {
  const studentId = props.match.params.id || '0'
  const [globalState, globalActions] = useGlobal()
  const { loggedInUser } = globalState
  const [student, setStudent] = useState({ ...globalState.editedStudent })
  const [studentLogs, setOnGoingLogs] = useState([])
  const [makeUrl] = useUrlBuilder(props.match.url)
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if (studentId !== '0') {
      globalActions.students.getStudent(studentId)
    }
  }, [studentId])

  useEffect(() => {
    setStudent({ ...globalState.editedStudent })
  }, [globalState.editedStudent])

  useEffect(() => {
    if (globalState.students.length) {
      const student = _.find(globalState.students, student => student.id.toString() === studentId)
      if (student !== undefined) {
        setOnGoingLogs(student.logs)
      }
    }
  }, [globalState.students])

  const stopStudent = () => {
    globalActions.students.studentSupportEnded(studentId).then(props.history.push(makeUrl('/')))

    setTimeout(() => {
      globalActions.students.getStudents()
    }, 1000)
  }

  const restartSupport = () => {
    globalActions.students.studentSupportEnded(studentId, false).then(props.history.push(makeUrl('/')))

    setTimeout(() => {
      globalActions.students.getStudents()
    }, 1000)
  }

  const calculateSocialSkills = (socialSkills) => {
    let response
    switch (socialSkills) {
      case 'HIGH':
        response = 5
        break
      case 'AVEREGE':
        response = 4
        break
      case 'OCCASIONALLY':
        response = 3
        break
      case 'OFTEN':
        response = 2
        break
      case 'NEVER':
        response = 1
        break
      default:
        response = null
    }

    return response
  }

  const calculateEmotionalState = (emotionalState) => {
    let response
    switch (emotionalState) {
      case 'JOY':
        response = 5
        break
      case 'HOPE':
        response = 4
        break
      case 'NEUTRAL':
        response = 3
        break
      case 'IMPATIENT':
        response = 2
        break
      case 'GUILT':
        response = 1
        break
      default:
        response = null
    }

    return response
  }

  const calculateRiskBehaviour = (riskBehavior) => {
    let response
    switch (riskBehavior) {
      case 'ACTIVELY':
        response = 5
        break
      case 'OCCASIONALLY':
        response = 4
        break
      case 'THOUGHTS':
        response = 3
        break
      case 'EXPOSED':
        response = 2
        break
      case 'NO':
        response = 1
        break
      default:
        response = null
    }

    return response
  }

  let data = []
  data.push({
    name: 'Initial',
    ss: calculateSocialSkills(student.initialAssessment.socialSkills),
    es: calculateEmotionalState(student.initialAssessment.emotionalState),
    hrb: calculateRiskBehaviour(student.initialAssessment.riskBehavior)
  })
  data.push({
    name: 'Midpoint',
    ss: calculateSocialSkills(student.midpointAssessment.socialSkills),
    es: calculateEmotionalState(student.midpointAssessment.emotionalState),
    hrb: calculateRiskBehaviour(student.midpointAssessment.riskBehavior)
  })

  data.push({
    name: 'Final',
    ss: calculateSocialSkills(student.finalAssessment.socialSkills),
    es: calculateEmotionalState(student.finalAssessment.emotionalState),
    hrb: calculateRiskBehaviour(student.finalAssessment.riskBehavior)
  })

  return (
    <Segment>
      <RightMenuBar>
        <Header as='h3'
                style={dashboard.myProgramHeader}> {student.firstName + ' ' + student.lastInitial + '.'}</Header>
        <RightMenuBarOptions>
          {(loggedInUser.role === 'Peer Helper' || loggedInUser.role === 'Super User' || loggedInUser.role === 'Coordinator') && (
            <>
              {student.supportEnded ? (
                <Popup
                  basic
                  content='Re-start Support'
                  position='top right'
                  trigger={
                    <Button onClick={restartSupport} style={{ backgroundColor: 'transparent', padding: 0 }}>
                      <RightMenuBarButtonIcon name='play' style={{ fontSize: 18, fontWeight: 'bold', color: 'green' }}/>
                    </Button>
                  }
                />
              ) : (
                <Popup
                  basic
                  content='Stop Support'
                  position='top right'
                  trigger={
                    <Button onClick={() => setModalOpen(true)} style={{ backgroundColor: 'transparent', padding: 0 }}>
                      <RightMenuBarButtonIcon name='ban' style={{ fontSize: 18, fontWeight: 'bold', color: 'red' }}/>
                    </Button>
                  }
                />
              )}
            </>
          )}
          <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
            <Modal.Header>End Support of this Student</Modal.Header>
            <Modal.Content>
              <p>Are you sure you want to end support of this student ?</p>
            </Modal.Content>
            <Modal.Actions>
              <Button negative onClick={() => setModalOpen(false)}>No</Button>
              <Button color='green' onClick={stopStudent}>Yes</Button>
            </Modal.Actions>
          </Modal>
          <RightMenuBarButton as={NavLink} to={makeUrl('/')}>
            <RightMenuBarButtonIcon name='close' style={{ fontSize: 22 }}/>
          </RightMenuBarButton>
        </RightMenuBarOptions>
      </RightMenuBar>

      <Divider/>
      <ResponsiveContainer width='100%' height={200}>
        <LineChart
          data={data}
          margin={{
            top: 5, right: 10, left: -30, bottom: 5
          }}
        >
          <XAxis dataKey='name'/>
          <YAxis domain={[1, 5]}/>
          <CartesianGrid strokeDasharray='3 3'/>
          <Tooltip/>
          <Legend/>
          <Line type='monotone' name='Social Skills' dataKey='ss' stroke='#2076BC' isAnimationActive={false}/>
          <Line type='monotone' name='Emotional State' dataKey='es' stroke='#4D4D4D' isAnimationActive={false}/>
          <Line type='monotone' name='High Risk Behaviors' dataKey='hrb' stroke='#753CBE' isAnimationActive={false}/>
        </LineChart>
      </ResponsiveContainer>

      <Table compact basic='very' size='small'>
        <Table.Header className="stickyTh">
          <Responsive as={Table.Row} minWidth={768}>
            <StyledHeaderCell>
              Assessments
            </StyledHeaderCell>
            <StyledHeaderCell>
              Target Date
            </StyledHeaderCell>
          </Responsive>
        </Table.Header>

        <Table.Body>
          <Table.Row key={'initial'}>
            <Table.Cell>
              {student.initialAssessment.id ? (
                <NavLink to={makeUrl(`/assessments/${studentId}/view/initialAssessment`)}>
                  Initial
                </NavLink>
              ) : 'Initial'}
            </Table.Cell>
            <Table.Cell style={{ color: '#753CBE', paddingLeft: '1rem' }}>
              {student.initialAssessment.dueDate ? new Date(student.initialAssessment.dueDate.split(' ')[0]).toLocaleDateString('en', {
                year: '2-digit',
                month: 'numeric',
                day: 'numeric'
              }) : ''}
            </Table.Cell>
          </Table.Row>
          <Table.Row key={'midpoint'}>
            <Table.Cell>
              {student.midpointAssessment.id ? (
                <NavLink to={makeUrl(`/assessments/${studentId}/view/midpointAssessment`)}>
                  Midpoint
                </NavLink>
              ) : 'Midpoint'}
            </Table.Cell>
            <Table.Cell style={{ color: '#753CBE', paddingLeft: '1rem' }}>
              {student.midpointAssessment.dueDate ? new Date(student.midpointAssessment.dueDate.split(' ')[0]).toLocaleDateString('en', {
                year: '2-digit',
                month: 'numeric',
                day: 'numeric'
              }) : ''}
            </Table.Cell>
          </Table.Row>
          <Table.Row key={'final'}>
            <Table.Cell>
              {student.finalAssessment.id ? (
                <NavLink to={makeUrl(`/assessments/${studentId}/view/finalAssessment`)}>
                  Final
                </NavLink>
              ) : 'Final'}
            </Table.Cell>
            <Table.Cell style={{ color: '#753CBE', paddingLeft: '1rem' }}>
              {student.finalAssessment.dueDate ? new Date(student.finalAssessment.dueDate.split(' ')[0]).toLocaleDateString('en', {
                year: '2-digit',
                month: 'numeric',
                day: 'numeric'
              }) : ''}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Header as='h3' style={{ fontSize: 16 }}>Ongoing Support Logs</Header>

      <Divider/>

      {studentLogs.length === 0 && (
        <p style={{ color: 'rgba(0, 0, 0, 0.38)' }}>No logs completed</p>
      )}

      <Table striped compact basic='very'>
        <Table.Body>
          {studentLogs.map((log, key) => (
            <Table.Row key={key}>
              <Table.Cell width={12}>
                {log.date.split(' ')[0].split('-')[1] + '/' + log.date.split(' ')[0].split('-')[2] + '/' + log.date.split(' ')[0].split('-')[0].substring(2, 4)}
              </Table.Cell>
              <Table.Cell width={4}>
                <Item as={NavLink} to={makeUrl(`/continual-support/${log.id}/view/${studentId}`)}
                      style={{ color: '#2076BC' }}> VIEW </Item>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Segment>
  )
}

export default StudentReport
