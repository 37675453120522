import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Divider, Grid, Header, Icon, Input, List, Responsive, Segment, Table } from 'semantic-ui-react'
import useGlobal from '../../Store'
import ProfileForm from '../../Components/ProfileForm/ProfileForm'
import { useHistory } from 'react-router-dom'

const NotificationsTable = () => {
  const [globalState, globalActions] = useGlobal()
  const { notifications, loggedInUser } = globalState

  const history = useHistory()

  useEffect(() => {
    globalActions.notifications.getUserNotifications()
  }, [])

  const readNotification = async (id, link) => {
    await globalActions.notifications.readNotification(id)
    await globalActions.notifications.getUserNotifications()
    await globalActions.notifications.getNotificationCount()

    history.push(link || '/')
  }

  return (
    <>
      <Header as='h2' style={{ color: 'rgba(0, 0, 0, 0.87)' }}>My Notifications</Header>
      {notifications.length === 0 &&
      (
        <Segment textAlign='center'>
          <Icon name='bell slash outline' size='massive' disabled/> <br/> <br/>
          <p style={{ color: 'rgba(0, 0, 0, 0.38)' }}> No notifications </p>
        </Segment>
      )}
      <Table size='large'>
        <Table.Body>
          {notifications.map((notification, key) => {
            const logType = notification.link.split('type=')[1]

            switch (logType) {
              case 'AS_NEEDED_SUPPORT':
                notification.link = notification.link.split('continual-support').join('support-logs')
                break
              case 'GROUP_ACTIVITY':
                notification.link = notification.link.split('continual-support').join('group-logs')
                break
            }

            if (notification.link && loggedInUser.role === 'Peer Helper') {
              notification.link = notification.link.split('ongoing-overview/').join('')
            }

            return (
              <Table.Row key={key} style={{ backgroundColor: notification.read ? 'white' : '#ccc' }}>
                <Table.Cell width={3} style={{ color: 'rgba(0, 0, 0, 0.75)' }}>
                  <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                    Date
                  </Responsive>
                  {new Date(notification.createdAt.split(' ').join('T')).toLocaleDateString('en', {
                    year: '2-digit',
                    month: 'numeric',
                    day: 'numeric'
                  })}
                </Table.Cell>
                <Table.Cell width={12} style={{ color: 'rgba(0, 0, 0, 0.75)' }}>
                  <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                    Message
                  </Responsive>
                  {notification.message}
                </Table.Cell>

                <Table.Cell width={12}>
                  <a href="javascript: void(0)" style={{ cursor: 'pointer' }}
                     onClick={async () => await readNotification(notification.id, notification.link)}>
                    VIEW
                  </a>
                </Table.Cell>
                <Table.Cell width={3}/>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </>
  )
}

let delayDebounceFn = null

const MySchools = () => {
  const [globalState, globalActions] = useGlobal()
  const { loggedInUser } = globalState
  const [user, setUser] = useState({ ...globalState.editedUser })
  const [schools, setSchools] = useState([...globalState.schools])
  const [myGrades, setMyGrades] = useState()

  const _updateEnrollment = (selectedSchool, enrollments) => {
    clearTimeout(delayDebounceFn)

    delayDebounceFn = setTimeout(() => {
      globalActions.locations.updateSchoolEnrollments(selectedSchool.stateId, selectedSchool.districtId, selectedSchool.id, enrollments)
    }, 1000)

    setSchools(schools.map(school => {
      if (school.id === selectedSchool.id)
        school.enrollment = enrollments

      return school
    }))
  }

  useEffect(() => {
    globalActions.users.getGrades()
    setSchools([...globalState.schools])
  }, [globalState.schools])

  useEffect(() => {
    setMyGrades(globalState.myGrades)
  }, [globalState.myGrades])

  useEffect(() => {
    globalActions.locations.getSchools()
  }, [loggedInUser.id])

  useEffect(() => {
    globalActions.users.getUser(loggedInUser.id)
  }, [loggedInUser.id])

  useEffect(() => {
    setUser({ ...globalState.editedUser })
  }, [globalState.editedUser])

  return (
    <>
      <Header as='h2'>My Schools</Header>
      {schools === undefined || schools.length === 0 ? (
        <Segment textAlign='center'>
          <Icon name='building outline' size='massive' disabled/> <br/> <br/>
          <p style={{ color: 'rgba(0, 0, 0, 0.38)' }}> No schools </p>
        </Segment>
      ) : (
        <Segment>
          <List>
            {schools.map((school, index) => (
              <>
                <List.Item key={index}>
                  <Grid columns={3}>
                    <Grid.Row style={{
                      fontSize: '18px',
                      fontWeight: 'lighter',
                      color: '#753CBE',
                      marginLeft: '1rem',
                      marginBottom: '-1rem',
                      marginTop: '1rem'
                    }}>
                      {school.name}
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px' }}>
                          School Grade Levels
                        </span>

                        {(myGrades !== undefined && school && school.grades) && (
                          <p style={{
                            marginBottom: 0,
                            marginTop: '1rem'
                          }}>{school.grades.split(',').map(grade => myGrades[grade]).join(', ')}</p>
                        )}

                        <Divider inverted fitted/>
                      </Grid.Column>
                      <Grid.Column>
                        <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px' }}>
                          Total School Enrollment
                        </span>
                        <Input
                          style={{
                            width: '100%',
                            borderBottomWidth: 1,
                            borderBottomColor: 'rgba(255,255,255,.1)',
                            marginTop: 3
                          }}
                          value={school.enrollment}
                          onChange={(e) => _updateEnrollment(school, e.target.value)}
                        />
                      </Grid.Column>
                      <Grid.Column style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                        <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px' }}>
                          Peer Helper Registration Code
                        </span>
                        <p style={{ marginBottom: 0, marginTop: '1rem', color: 'purple' }}>{school.peerHelperCode}</p>
                        <Divider inverted fitted/>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </List.Item>
              </>
            ))}
          </List>
        </Segment>
      )}
    </>
  )
}

const ProfilePage = (props) => {
  const [globalState, globalActions] = useGlobal()
  const { loggedInUser } = globalState

  const [width, setWidth] = useState(0)

  useLayoutEffect(() => {
    function updateSize () {
      setWidth(window.innerWidth / (16 / 6))
    }

    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  const editingUser = () => {
    setTimeout(() => {
      globalActions.users.getUser(loggedInUser.id)
    }, 500)
  }

  return (
    <Grid reversed='mobile vertically'>
      <Grid.Column computer={10} table={8} mobile={16} reversed='mobile vertically'>
        <Grid.Row>
          <NotificationsTable/>
        </Grid.Row>
        {loggedInUser.role === 'Coordinator' && (
          <Grid.Row style={{ marginTop: 15 }}>
            <MySchools/>
          </Grid.Row>
        )}
      </Grid.Column>

      <Grid.Column computer={6} table={8} mobile={16}>
        <ProfileForm {...props} userId={loggedInUser.id} refresh={editingUser}/>
      </Grid.Column>
    </Grid>
  )
}

export default ProfilePage
