import React from 'react'
import globalHook from 'use-global-hook'
import * as initialState from '../Config/DefaultState'
import actions from '../Actions'

actions.changeUserType = (store, user, userType) => {
  store.setState({ loggedInUser: { ...user, role: userType } })
}

const useGlobal = globalHook(React, initialState, actions)

export default useGlobal
