import { Header, Icon, Item, Responsive, Table } from 'semantic-ui-react'
import { dashboard } from '../../../../Theme/Styles'
import { StyledTable, StyledTableHeader, StyledTransparentButton } from '../../../../Theme/Components'
import { NavLink } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import useGlobal from '../../../../Store'
import _ from 'lodash'
import { genderOptions, gradeOptions } from '../../../../Config/SelectOptions'
import useStringOperations from '../../../../Utils/StringOperations'

const AssistsTable = (props) => {
  const stringOperations = useStringOperations()

  const [globalState, globalActions] = useGlobal()
  const { currentState, currentDistrict, currentSchool } = globalState
  const [assists, setAssists] = useState([])

  const getAssists = () => {
    setTimeout(() => {
      globalActions.assists.getAssists()
    }, 500)
  }

  useEffect(() => {
    setAssists(globalState.assists)
  }, [globalState.assists])

  useEffect(getAssists, [props.location, currentState, currentDistrict, currentSchool])

  return (
    <>
      <StyledTableHeader>
        <Header as='h2' style={dashboard.phHeader}>
          My Assists Logs
        </Header>
        <StyledTransparentButton as={NavLink} to={`/assists/add`}>
          <Icon name='plus'/>
        </StyledTransparentButton>
      </StyledTableHeader>

      <StyledTable compact striped size='small'>
        <Table.Header className="stickyTh">
          <Responsive as={Table.Row} minWidth={768}>
            <Table.HeaderCell>
              Date
            </Table.HeaderCell>
            <Table.HeaderCell>
              Student Name
            </Table.HeaderCell>
            <Table.HeaderCell>
              Gender
            </Table.HeaderCell>
            <Table.HeaderCell>
              Grade
            </Table.HeaderCell>
            <Table.HeaderCell/>
          </Responsive>
        </Table.Header>

        <Table.Body>
          {_.map(assists, ({ id, date,name, gender, grade }) => (
            <Table.Row key={id}>
              <Table.Cell textAlign='left'>
                <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                  Date:
                </Responsive>
                {new Date(date.split(" ").join("T")).toLocaleDateString()}
              </Table.Cell>

              <Table.Cell textAlign='left'>
                <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                  Student Name:
                </Responsive>
                {name}
              </Table.Cell>

              <Table.Cell textAlign='left'>
                <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                  Gender:
                </Responsive>
                {stringOperations.translate(genderOptions, gender)}
              </Table.Cell>

              <Table.Cell textAlign='left'>
                <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                  Grade:
                </Responsive>
                {stringOperations.translate(gradeOptions, grade)}
              </Table.Cell>

              <Table.Cell textAlign='center'>
                <Item as={NavLink} to={`/assists/view/${id}`} style={{ color: '#2076BC' }}>
                  VIEW LOG
                </Item>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </StyledTable>
    </>
  )
}

export default AssistsTable
