import React, { useEffect, useLayoutEffect, useState } from 'react'
import {
  Button,
  Divider,
  Form,
  Header,
  Segment,
  Modal,
} from 'semantic-ui-react'
import useGlobal from '../../Store'
import useValidation from '../../Utils/Validations'
import {
  RightMenuBar,
  RightMenuBarButton,
  RightMenuBarButtonIcon,
  RightMenuBarOptions,
  StyledTransparentButton,
} from '../../Theme/Components'
import { dashboard } from '../../Theme/Styles'
import { schoolLevels } from '../../Config/SelectOptions'
import { NavLink } from 'react-router-dom'
import useUrlBuilder from '../../Utils/Links'

const SchoolForm = (props) => {
  const districtId = props.match.params.id || '0'
  const isFilter = !props.match.params.id && !props.match.params.action
  const [isEdit, setIsEdit] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [globalState, globalActions] = useGlobal()

  const [district, setDistrict] = useState({ ...globalState.editDistrict, stateId: globalState.currentState })
  const { states } = globalState
  const requiredFields = ['stateId', 'name']
  const [validForm, validationErrors, onFieldChangeValidation] = useValidation(requiredFields, district)

  useEffect(() => {
    globalActions.locations.getStates()

    let districtData = props.location && props.location.state ? props.location.state : null
    globalActions.locations.handleEditDistrict(districtData)
    setIsEdit(false)
    setDistrict({ id: '', name: '', stateId: globalState.currentState })
  }, [districtId, isFilter])

  useLayoutEffect(() => {
    setDistrict({
      ...globalState.editDistrict,
      stateId: districtId !== '0' ? globalState.editDistrict.stateId : globalState.currentState
    })
  }, [globalState.editDistrict])

  useEffect(() => {
    setDistrict({ ...district, stateId: globalState.currentState })

    if (isFilter && districtId === '0')
      globalActions.locations.getDistrictList(globalState.currentState)
  }, [globalState.currentState])

  const handleInputChange = (e) => {
    const { name, value, options } =
      typeof e.target !== 'undefined' ? e.target : e
    setDistrict({ ...district, [name]: value })
    onFieldChangeValidation(name, value)

    if (name === 'stateId' && isFilter) {
      const label = options.filter((i) => {
        if (i.value === value) return true
      })
      globalActions.locations.handleUpdateDistrictLable(
        `Districts within ${label.length > 0 ? `${label[0].text}` : ''}`
      )
      globalActions.locations.getDistrictList(value)
    }
  }

  const handleDistrictOperation = async (from = null) => {
    if (validForm && district && district.name.trim()) {
      let response
      if (from === 'delete') {
        response = await globalActions.locations.handleDistrictAPI(
          district,
          'delete'
        )

        setModalOpen(false)

        response && props.history.push('/heirarchy-management/district')
      } else {
        if (districtId === '0') {
          response = await globalActions.locations.handleDistrictAPI(
            district,
            'add'
          )
        } else {
          response = await globalActions.locations.handleDistrictAPI(
            district,
            'edit'
          )
        }
      }

      props.history.push('/heirarchy-management/district')

      setTimeout(() => {
        globalActions.locations.getDistrictList()
      }, 500)
    } else {
      globalActions.notifications.showNotification({
        type: 'warning',
        header: 'Form validation error',
        subheader: 'Please complete all required fields!',
      })
    }
  }

  const stateOptions = states.map((state, key) => ({
    key,
    text: state.name,
    value: state.id,
  }))

  const handleCloseDistrictModal = () => {
    globalActions.locations.handleEditDistrict()
    props.history.push('/heirarchy-management/district')
  }

  return (
    <Segment>
      <RightMenuBar>
        <Header as="h3" style={dashboard.myProgramHeader}>
          {!isFilter && districtId !== '0'
            ? 'Edit'
            : !isFilter && districtId === '0'
              ? 'Add New'
              : ''}{' '}
          District
        </Header>
        <RightMenuBarOptions>
          {districtId !== '0' && (
            <>
              <RightMenuBarButton onClick={() => setIsEdit(true)}>
                <RightMenuBarButtonIcon
                  name="pencil"
                  style={{ fontSize: 15 }}
                />
              </RightMenuBarButton>

              <RightMenuBarButton onClick={() => setModalOpen(true)}>
                <RightMenuBarButtonIcon name="trash" style={{ fontSize: 15 }}/>
              </RightMenuBarButton>
            </>
          )}

          {!isFilter && (
            <RightMenuBarButton onClick={() => handleCloseDistrictModal()}>
              <RightMenuBarButtonIcon
                name="close"
                style={{ fontSize: districtId !== '0' ? 15 : 20 }}
              />
            </RightMenuBarButton>
          )}
        </RightMenuBarOptions>
      </RightMenuBar>

      <Divider/>
      <Form>
        <Form.Group className="custom-form-group">
          <label className="small-label">State</label>
          <Form.Select
            name="stateId"
            value={district.stateId}
            onChange={(e, data) => handleInputChange(data)}
            error={validationErrors.stateId.error}
            placeholder="Select a State"
            required
            fluid
            options={stateOptions}
            disabled={districtId !== '0' && !isEdit}
          />
        </Form.Group>

        {isFilter && (
          <div style={{ textAlign: 'right' }}>
            <Button onClick={() => {
              setDistrict({ ...district, stateId: '' })
              globalActions.locations.getDistrictList('')
            }}>Clear</Button>
          </div>
        )}

        {!isFilter && (
          <>
            <Form.Group className="custom-form-group">
              <label className="small-label">District</label>
              <Form.Input
                name="name"
                value={district.name}
                onChange={(e, data) => handleInputChange(data)}
                required
                fluid
                error={validationErrors.name.error}
                placeholder="Enter district name"
                disabled={districtId !== '0' && !isEdit ? true : false}
              />
            </Form.Group>

            <div style={{ textAlign: 'right' }}>
              <Button.Group>
                <StyledTransparentButton
                  disabled={districtId !== '0' && !isEdit ? true : false}
                  onClick={handleDistrictOperation}
                >
                  {districtId !== '0' ? 'Edit' : 'Add'} District
                </StyledTransparentButton>
              </Button.Group>
            </div>
          </>
        )}
      </Form>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)} closeIcon>
        <Modal.Header>Delete District</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete this District ?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setModalOpen(false)}>
            No
          </Button>
          <Button
            positive
            icon="checkmark"
            labelPosition="right"
            content="Yes"
            onClick={() => handleDistrictOperation('delete')}
          />
        </Modal.Actions>
      </Modal>
    </Segment>
  )
}

export default SchoolForm
