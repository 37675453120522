import React, { useEffect, useState } from 'react'
import * as SurveyBuilder from 'survey-creator'
import 'survey-knockout/survey.css'
import 'survey-creator/survey-creator.css'
import useCampaignsService from '../../Services/Campaigns'
import { StyledTableHeader } from '../../Theme/Components'
import { Header, Segment } from 'semantic-ui-react'
import { dashboard } from '../../Theme/Styles'

SurveyBuilder.StylesManager.applyTheme('darkrose')

const SurveyView = (props) => {
  const { getSurvey } = useCampaignsService()

  const surveyId = props.match.params.id || '0'
  const [survey, setSurvey] = useState({ id: 0, body: { title: '' }, schoolLevels: [] })

  useEffect(() => {
    if (surveyId !== '0') {
      getSurvey(surveyId)
        .then((survey => {
          if (survey) {
            setSurvey({ ...survey, body: JSON.parse(survey.body), schoolLevels: survey.schoolLevels.map(s => s.name) })
          }
        }))
    }
  }, [])

  const getSurveyTitle = () => {
    if(survey.name === 'Peer Helper Survey') {
      return `${survey.name} - ${survey.schoolLevels.includes("ELEMENTARY") ? 'Elementary':'Middle/High'} School`
    }else{
      return survey.body.title
    }
  }

  if (!survey.body) return null
  return (
    <>
      <StyledTableHeader>
        <Header as='h2' style={dashboard.phHeader}><strong>{getSurveyTitle()}</strong></Header>
      </StyledTableHeader>

      {!!survey.body?.pages?.[0]?.elements && (
        <>
          {survey.body?.pages?.[0]?.elements.map((element, key) => (
            <Segment>
              <Header as='h3'>{key + 1}. {element.title}</Header>
              <h4>Type: {element.type}</h4>

              {element.type !== 'matrix' ? (
                <p>
                  <h4>Options:</h4> {element.choices.map(choice => {
                  return <p>{choice?.text || choice}</p>
                })}
                </p>
              ) : (
                <>
                  <p>
                    <h4>Rows:</h4> {element.rows.map(choice => <p>{choice}</p>)}
                  </p>
                  <p>
                    <h4>Columns:</h4> {element.columns.map(choice => <p>{choice}</p>)}
                  </p>
                </>
              )}
            </Segment>
          ))}
        </>
      )}
    </>
  )
}

export default SurveyView
