import React, { useEffect } from 'react'
import { Grid } from 'semantic-ui-react'
import useGlobal from '../../Store'
import OverviewCard from '../../Components/OverviewCard'
import ContextualRightComponent from '../../Components/ContextualRightComponent/index'
import { withRouter } from 'react-router'
import GroupActivitiesTable from '../../Components/Dashboard/GroupActivitiesTable'
import { NoPrint, Print } from 'react-easy-print'

const GroupActivitiesReport = (props) => {
  const [globalState, globalActions] = useGlobal()
  const { dashboardStatistics, currentState, currentDistrict, currentSchool } = globalState

  useEffect(() => {
    setTimeout(() => {
      globalActions.dashboards.getStatistics()
    }, 500)
  }, [currentState, currentDistrict, currentSchool])

  if (typeof dashboardStatistics.onGoingSupport === 'undefined') {
    return <div/>
  }

  const overviewCardProperties = [
    { property: 'Support Logs', propertyCount: dashboardStatistics.groupActivities.support },
    { property: 'Prevention Logs', propertyCount: dashboardStatistics.groupActivities.prevention },
    { property: 'Total', propertyCount: dashboardStatistics.groupActivities.total },
  ]

  return (
    <Print name='Group Activities Report'>
      <Grid>
        <Grid.Row style={{ paddingTop: 0 }}>
          <Grid.Column mobile={16} tablet={9} computer={11}>
            <Grid padded>
              <Grid.Column computer={16} mobile={16}>
                <OverviewCard title={'Group Activities Report'} columns={overviewCardProperties}/>
              </Grid.Column>
              <Grid.Column computer={16} mobile={16}>
                <GroupActivitiesTable/>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={7} computer={5}>
            <ContextualRightComponent {...props} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Print>
  )
}

export default withRouter(GroupActivitiesReport)
