import React, { useEffect, useState } from 'react'
import { Button, Divider, Header, Segment, Item, Modal } from 'semantic-ui-react'
import useGlobal from '../../Store'
import { NavLink } from 'react-router-dom'
import useUrlBuilder from '../../Utils/Links'
import useStringOperations from '../../Utils/StringOperations'
import { genderOptions, raceOptions, gradeOptions, lifeExperiencesOptions, helpTypeOptions, placeOptions } from '../../Config/SelectOptions'
import { dashboard } from '../../Theme/Styles'
import {
  RightMenuBar,
  RightMenuBarButton,
  RightMenuBarButtonIcon,
  RightMenuBarOptions,
  StyledLogQuestionAnswer,
  StyledLogQuestionHeader
} from '../../Theme/Components'

const AsNeededSupportLog = (props) => {
  const asNeededLogId = props.match.params.id || '0'
  const [globalState, globalActions] = useGlobal()
  const { loggedInUser } = globalState
  const [asNeededSupportLog, setAsNeededSupportLog] = useState({ ...globalState.editedAsNeededLog })
  const [makeUrl] = useUrlBuilder(props.match.url)
  const stringOperations = useStringOperations()
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if (asNeededLogId !== '0') {
      globalActions.asNeededLogs.getAsNeededLog(asNeededLogId)
    }
  }, [asNeededLogId])

  useEffect(() => {
    setAsNeededSupportLog({ ...globalState.editedAsNeededLog })
  }, [globalState.editedAsNeededLog])

  const deleteAsNeededLog = () => {
    globalActions.asNeededLogs.deleteAsNeededLog(asNeededLogId).then(props.history.push(makeUrl('/support-logs')))
  }

  return (
    <Segment>
      <RightMenuBar>
        <Header as='h3' style={dashboard.myProgramHeader}>Support Log</Header>
        <RightMenuBarOptions>
          {props.match.path.split('/')[1] !== 'atrisk-referrals-report' && ['Peer Helper', 'Coordinator', 'Super User'].includes(loggedInUser.role) && (
            <React.Fragment>
              <RightMenuBarButton as={NavLink} to={makeUrl(`/support-logs/${asNeededLogId}/edit`)}>
                <RightMenuBarButtonIcon name='edit' />
              </RightMenuBarButton>
              <RightMenuBarButton onClick={() => setModalOpen(true)}>
                <RightMenuBarButtonIcon name='trash' />
              </RightMenuBarButton>
              <Modal open={modalOpen} onClose={() => setModalOpen(false)} closeIcon>
                <Modal.Header>Delete Your Support Log</Modal.Header>
                <Modal.Content>
                  <p>Are you sure you want to delete this Support Log ?</p>
                </Modal.Content>
                <Modal.Actions>
                  <Button negative onClick={() => setModalOpen(false)}>No</Button>
                  <Button positive icon='checkmark' labelPosition='right' content='Yes' onClick={deleteAsNeededLog} />
                </Modal.Actions>
              </Modal>
            </React.Fragment>
          )}
          <RightMenuBarButton as={NavLink} to={makeUrl('/')}>
            <RightMenuBarButtonIcon name='close' style={{ fontSize: 20 }} />
          </RightMenuBarButton>
        </RightMenuBarOptions>
      </RightMenuBar>

      <Divider />

      <Item.Group>
        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>1. Peer helper</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{asNeededSupportLog.peerHelper.firstName + ' ' + asNeededSupportLog.peerHelper.lastName}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>2. Date of Activity</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{new Date(asNeededSupportLog.date.split(' ').join('T')).toLocaleDateString('en', { year: '2-digit', month: 'numeric', day: 'numeric' })}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>3. Time of Activity</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{new Date(asNeededSupportLog.date.split(' ').join('T')).toLocaleTimeString('en-US', {hour: '2-digit', minute:'2-digit'})}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>4. Gender of Student Helped</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{stringOperations.translate(genderOptions, asNeededSupportLog.gender)}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>5. Race of Student Helped</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{asNeededSupportLog.races.map(race => stringOperations.translate(raceOptions, race)).join(', ')}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>6. Grade of Student Helped</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{stringOperations.translate(gradeOptions, asNeededSupportLog.grade)}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>7. Topics shared during this conversation</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{asNeededSupportLog.experiences.map(experience => stringOperations.translate(lifeExperiencesOptions, experience)).join(', ')}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>8. How did you address this group?</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{stringOperations.translate(helpTypeOptions, asNeededSupportLog.help)}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>9. Where did you help this student</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{stringOperations.translate(placeOptions, asNeededSupportLog.location)}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>
      </Item.Group>
    </Segment>
  )
}

export default AsNeededSupportLog
