import React from 'react'
import { Grid, Header, Icon, List, Popup, Segment } from 'semantic-ui-react'
import { dashboard } from '../../../Theme/Styles'
import { NavLink } from 'react-router-dom'
import useStringOperations from '../../../Utils/StringOperations'
import { lifeExperiencesOptions } from '../../../Config/SelectOptions'

const SupportTopicsStatistics = ({ topics }) => {
  const stringOperations = useStringOperations()
  const getTopThreeTopics = (object) => {
    return Object.keys(object).map((key) => ({
      name: key,
      percent: object[key]
    })).sort((p1, p2) => p2.percent - p1.percent).slice(0, 3)
  }

  return (
    <Segment style={dashboard.segment}>
      <Header as='h2' style={dashboard.header}>Topics</Header>
      <Grid rows={2}>
        <Grid.Row columns={2}>
          <Grid.Column computer={8} mobile={16}>
            <Header as='h4' disabled style={dashboard.table.header}>Support</Header>
            <List divided>
              {getTopThreeTopics(topics.support).map((topic, index) => (
                <List.Item key={index} style={dashboard.list}>
                  {topic.percent ? (index + 1) + '. ' + stringOperations.translate(lifeExperiencesOptions, topic.name) : '-'}
                  <Popup content={stringOperations.translateInfo(lifeExperiencesOptions, topic.name)}
                         position="right center" trigger={<Icon style={{ marginLeft: 10, color: 'lightgray' }} name="info circle"/>}/>
                </List.Item>
              ))}
            </List>
          </Grid.Column>
          <Grid.Column computer={8} mobile={16}>
            <Header as='h4' disabled style={dashboard.table.header}>Prevention</Header>
            <List divided>
              {getTopThreeTopics(topics.prevention).map((topic, index) => (
                <List.Item key={index} style={dashboard.list}>
                  {topic.percent ? (index + 1) + '. ' + stringOperations.translate(lifeExperiencesOptions, topic.name) : '-'}
                  <Popup content={stringOperations.translateInfo(lifeExperiencesOptions, topic.name)}
                         position="right center" trigger={<Icon style={{ marginLeft: 10, color: 'lightgray' }} name="info circle"/>}/>
                </List.Item>
              ))}
            </List>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={dashboard.boxButtonsRow}>
          <Grid.Column floated='right' style={dashboard.boxButtonsColumn}>
            <NavLink to='/topics' style={dashboard.boxButtonsButton}>View</NavLink>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

export default SupportTopicsStatistics
