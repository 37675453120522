import React, { useEffect } from 'react'
import _ from 'lodash'
import useGlobal from '../../../Store'
import { Button, Header, Icon, Responsive, Table } from 'semantic-ui-react'
import { genderOptions, gradeOptions, raceOptions } from '../../../Config/SelectOptions'
import useStringOperations from '../../../Utils/StringOperations'
import { StyledTable, StyledTableHeader } from '../../../Theme/Components'
import { Print } from 'react-easy-print'
import styled from 'styled-components'
import endpoints from '../../../Config/ApiEndpoints'

const UsersReport = () => {
  const [globalState, globalActions] = useGlobal()
  const { usersList, currentSchool, currentDistrict, currentState } = globalState

  const _downloadReport = () => {
    window.open(endpoints.users.root + '/export.xlsx')
  }

  useEffect(() => {
    globalActions.users.getUsers(0, [], 10000, true)
  }, [])

  useEffect(() => {
    globalActions.users.getUsers(0, [], 10000, true)
  }, [currentSchool, currentDistrict, currentState])

  return (
    <React.Fragment>
      <Responsive as='div' minWidth={1000}>
        <StyledTableHeader>
          <Header as='h2' style={{ marginTop: 10, marginBottom: 0 }}>Users Report</Header>
          <div>
            <StyledButton icon='print' floated='right' basic onClick={() => window.print()}/>
            <StyledButton icon='file excel' floated='right' basic onClick={_downloadReport}/>
          </div>
        </StyledTableHeader>
      </Responsive>

      <Print name='Users Report'>
        <UsersTable users={usersList}/>
      </Print>
    </React.Fragment>
  )
}

const UsersTable = ({ users }) => {
  const stringOperations = useStringOperations()

  return (
    <Table compact striped size='small'>
      <Table.Header  className="stickyTh">
        <Responsive as={Table.Row} minWidth={1000}>
          <Table.HeaderCell>
            Last Name
          </Table.HeaderCell>
          <Table.HeaderCell>
            First Name
          </Table.HeaderCell>
          <Table.HeaderCell>
            Grade
          </Table.HeaderCell>
          <Table.HeaderCell>
            Role
          </Table.HeaderCell>
          <Table.HeaderCell>
            School
          </Table.HeaderCell>
          <Table.HeaderCell>
            Race
          </Table.HeaderCell>
          <Table.HeaderCell>
            Sex
          </Table.HeaderCell>
        </Responsive>
      </Table.Header>

      {users.length === 0 ? (
        <Table.Body>
          <Table.Row>
            <Table.Cell/>
            <Table.Cell/>
            <Table.Cell/>
            <Table.Cell>
              <p style={{ textAlign: 'center', marginTop: '1rem' }}><Icon name='user x' size='big' disabled/></p>
              <p style={{ color: 'rgba(0, 0, 0, 0.38)', textAlign: 'center' }}> No users found based on this filter </p>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      ) : (
        <Table.Body>
          {_.map(users, ({ id, lastName, firstName, roles, gender, grades, schools, race }) => (
            <Table.Row key={firstName + lastName + id}>
              <Table.Cell>
                <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                  Last Name:
                </Responsive>
                {lastName}
              </Table.Cell>
              <Table.Cell>
                <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                  First Name:
                </Responsive>
                {firstName}
              </Table.Cell>
              <Table.Cell>
                <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                  Level:
                </Responsive>
                {(grades !== null && grades !== undefined) ? grades.map(grade => stringOperations.translate(gradeOptions, grade)).join(', ') : ''}
              </Table.Cell>
              <Table.Cell>
                <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                  Role:
                </Responsive>
                {(roles !== null && roles !== undefined && roles[0] !== null) ? roles[0].name : ''}
              </Table.Cell>
              <Table.Cell>
                <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                  Schools:
                </Responsive>
                {_.map(schools, school => school.name).join(', ')}
              </Table.Cell>
              <Table.Cell>
                <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                  Race:
                </Responsive>
                {(race !== null && race !== undefined) ? race.map(raceValue => stringOperations.translate(raceOptions, raceValue)).join(', ') : ''}
              </Table.Cell>
              <Table.Cell>
                <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                  Sex:
                </Responsive>
                {stringOperations.translate(genderOptions, gender)}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      )}
    </Table>
  )
}

const StyledButton = styled(Button)`
  box-shadow: none !important;
  float: right;
  i {
    color: black !important;
  }
`

export default UsersReport
