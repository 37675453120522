import React from 'react'
import { Grid, Header, Segment, Table } from 'semantic-ui-react'
import { dashboard } from '../../../Theme/Styles'
import { NavLink } from 'react-router-dom'

const PeerHelpersStatistics = ({ peerHelpers }) => {
  return (
    <Segment style={dashboard.segment}>
      <Header as='h2' style={dashboard.header}>Peer Helpers</Header>
      <Grid rows={2}>
        <Grid.Row columns={2}>
          <Grid.Column computer={7} mobile={16} style={{ textAlign: 'center' }}>
            <Header style={dashboard.count}>
              {peerHelpers.count}
              <Header.Subheader style={dashboard.countSubHeader}>Peer Helpers</Header.Subheader>
            </Header>
          </Grid.Column>

          <Grid.Column computer={9} mobile={16}>
            <Header as='h4' disabled style={dashboard.table.header}>Percentage with</Header>
            <Table basic='very' style={dashboard.table.base}>
              <Table.Body>
                {/* <Table.Row style={dashboard.list}>
                  <Table.Cell style={dashboard.table.cell}>Ongoing Students</Table.Cell>
                  <Table.Cell style={dashboard.table.blueCell}>{peerHelpers.onGoingStudents}%</Table.Cell>
                </Table.Row> */}
                <Table.Row style={dashboard.list}>
                  <Table.Cell style={dashboard.table.cell}>Logs Completed</Table.Cell>
                  <Table.Cell style={dashboard.table.blueCell}>{peerHelpers.logsCompleted}%</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={dashboard.boxButtonsRow}>
          <Grid.Column floated='right' style={dashboard.boxButtonsColumn}>
            <NavLink to='/peerHelpers' style={dashboard.boxButtonsButton}>View</NavLink>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

export default PeerHelpersStatistics
