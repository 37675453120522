import axios from 'axios/index'
import endpoints, { baseUrl } from '../Config/ApiEndpoints'
import { processRequestErrorNotification } from '../Utils/Error'

export const getMyProgram = async (store) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  store.setState({ requestStatus: 'LOADING' })
  try {
    const { currentSchool } = store.state

    if (!currentSchool)
      return

    const url = `${baseUrl}/documents?schoolId=${currentSchool}`

    const response = await axios.get(url, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
    const programs = response.data

    store.setState({ requestStatus: 'SUCCESS', programs })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ programs: [], requestStatus: 'FAILED', notification })
  }
}

export const getProgram = async (store, id) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  store.setState({ requestStatus: 'LOADING' })
  try {
    const response = await axios.get(endpoints.dashboardStatistics.programs + '/' + id, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
    const program = response.data

    store.setState({ requestStatus: 'SUCCESS', program })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ program: {}, requestStatus: 'FAILED', notification })
  }
}

export const saveProgram = async (store, program, file) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  store.setState({ requestStatus: 'LOADING' })
  try {
    if (file !== undefined) {
      const response = await axios.post(endpoints.dashboardStatistics.documents, file, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
      const { content } = response.data

      await axios.post(endpoints.dashboardStatistics.programs, {
        ...program,
        url: content
      }, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
    } else await axios.patch(endpoints.dashboardStatistics.programs + '/' + program.id, program, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })

    setTimeout(() => {
      store.setState({ requestStatus: 'SUCCESS' })
    }, 2000)
    await getMyProgram(store)
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ requestStatus: 'FAILED', notification })
  }
}

export const saveOrder = async (store, programs) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  store.setState({ requestStatus: 'LOADING' })
  try {
    await axios.put(endpoints.dashboardStatistics.programsOrder, { programs }, {
      headers: {
        Authorization: `Bearer ${store.state.loggedInUser.accessToken}`,
        'Content-Type': 'application/json',
        'X-sub': store.state.loggedInUser.id
      }
    })

    store.setState({ requestStatus: 'SUCCESS' })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ program: {}, requestStatus: 'FAILED', notification })
  }
}

export const deleteProgram = async (store, id) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  store.setState({ requestStatus: 'LOADING' })
  try {
    await axios.delete(endpoints.dashboardStatistics.programs + '/' + id, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })

    store.setState({ requestStatus: 'SUCCESS' })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ program: {}, requestStatus: 'FAILED', notification })
  }
}
