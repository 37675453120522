import React, { useEffect, useState } from 'react'
import { Button, Divider, Header, Segment, Item, Modal } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'
import useGlobal from '../../Store'
import useUrlBuilder from '../../Utils/Links'
import useStringOperations from '../../Utils/StringOperations'
import { lifeExperiencesOptions, placeOptions, onGoingSupportActivityTypeOptions, classWorkOptions } from '../../Config/SelectOptions'
import { dashboard } from '../../Theme/Styles'
import {
  RightMenuBar,
  RightMenuBarButton,
  RightMenuBarButtonIcon,
  RightMenuBarOptions,
  StyledLogQuestionAnswer,
  StyledLogQuestionHeader
} from '../../Theme/Components'

const OnGoingSupportLog = (props) => {
  const logId = props.match.params.id || '0'
  const studentId = props.match.params.extraId
  const [globalState, globalActions] = useGlobal()
  const { loggedInUser } = globalState
  const [makeUrl] = useUrlBuilder(props.match.url)
  const stringOperations = useStringOperations()
  const log = globalState.editedOnGoingSupportLog
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if (logId !== '0') {
      globalActions.onGoingSupportLogs.getOnGoingSupportLog(logId)
    }
  }, [logId])

  const deleteOnGoingSupportLog = () => {
    globalActions.onGoingSupportLogs.deleteOnGoingSupportLog(logId).then(props.history.push(makeUrl('/')))
  }

  return (
    <Segment>
      <RightMenuBar>
        <Header as='h3' style={dashboard.myProgramHeader}>Support Log</Header>
        <RightMenuBarOptions>
          {props.match.path.split('/')[1] !== 'atrisk-referrals-report' && (loggedInUser.role === 'Peer Helper' || loggedInUser.role === 'Coordinator' || loggedInUser.role === 'Super User') && (
            <React.Fragment>
              <RightMenuBarButton as={NavLink} to={makeUrl(`/continual-support/${logId}/edit/${studentId}`)}>
                <RightMenuBarButtonIcon name='edit' />
              </RightMenuBarButton>
              <RightMenuBarButton onClick={() => setModalOpen(true)}>
                <RightMenuBarButtonIcon name='trash' />
              </RightMenuBarButton>
              <Modal open={modalOpen} onClose={() => setModalOpen(false)} closeIcon>
                <Modal.Header>Delete Your Support Log</Modal.Header>
                <Modal.Content>
                  <p>Are you sure you want to delete this Support Log ?</p>
                </Modal.Content>
                <Modal.Actions>
                  <Button negative onClick={() => setModalOpen(false)}>No</Button>
                  <Button positive icon='checkmark' labelPosition='right' content='Yes' onClick={deleteOnGoingSupportLog} />
                </Modal.Actions>
              </Modal>
            </React.Fragment>
          )}
          <RightMenuBarButton as={NavLink} to={makeUrl('/')}>
            <RightMenuBarButtonIcon name='close' style={{ fontSize: 20 }} />
          </RightMenuBarButton>
        </RightMenuBarOptions>
      </RightMenuBar>
      <Divider />

      <Item.Group>
        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>1. Date of Activity</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{new Date(log.date.split(' ').join('T')).toLocaleDateString('en', { year: '2-digit', month: 'numeric', day: 'numeric' })}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>2. Time of Activity</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{new Date(log.date.split(' ').join('T')).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>3. Where did you help this student?</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{stringOperations.translate(placeOptions, log.location)}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>4. What did you do together?</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{stringOperations.translate(onGoingSupportActivityTypeOptions.concat(classWorkOptions), log.studentActivity)}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>5. Topics shared during this conversation</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{log.experiences.map(experience => stringOperations.translate(lifeExperiencesOptions, experience)).join(', ')}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>6. Referred to a Program Coordinator / School Counselor:</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{log.referred ? 'Yes' : 'No'}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>
      </Item.Group>
    </Segment>
  )
}

export default OnGoingSupportLog
