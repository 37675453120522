import React from 'react'
import { Grid, Header, Segment, Table } from 'semantic-ui-react'
import { dashboard } from '../../../Theme/Styles'
import { NavLink } from 'react-router-dom'
import useGlobal from '../../../Store'

const OngoingSupportStatistics = ({ data }) => {
  const [globalState] = useGlobal()
  const { loggedInUser } = globalState
  return (
    <Segment style={dashboard.segment}>
      <Header as='h2' style={dashboard.header}>Ongoing Support</Header>
      <Grid rows={2}>
        <Grid.Row columns={2}>
          <Grid.Column computer={7} mobile={16} style={{ textAlign: 'center' }}>
            <Header style={dashboard.count}>
              {data.students}
              <Header.Subheader style={dashboard.countSubHeader}>Assigned Students</Header.Subheader>
            </Header>
          </Grid.Column>

          <Grid.Column computer={9} mobile={16}>
            <Header as='h4' disabled style={dashboard.table.header}>Assessments completed</Header>
            <Table basic='very' style={dashboard.table.base}>
              <Table.Body>
                <Table.Row style={dashboard.list}>
                  <Table.Cell style={dashboard.table.cell}>Initial</Table.Cell>
                  <Table.Cell style={dashboard.table.blueCell}>{data.initial}</Table.Cell>
                </Table.Row>
                <Table.Row style={dashboard.list}>
                  <Table.Cell style={dashboard.table.cell}>Midpoint</Table.Cell>
                  <Table.Cell style={dashboard.table.blueCell}>{data.midpoint}</Table.Cell>
                </Table.Row>
                <Table.Row style={dashboard.list}>
                  <Table.Cell style={dashboard.table.cell}>Final</Table.Cell>
                  <Table.Cell style={dashboard.table.blueCell}>{data.final}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={dashboard.boxButtonsRowLast}>
          <Grid.Column floated='right' style={dashboard.boxButtonsColumn}>
            {!['School Level', 'State Level', 'District Level'].includes(loggedInUser.role) && (
              <NavLink to='/students/0/edit' style={dashboard.boxButtonsButton}>Add Student</NavLink>)}
            <NavLink to='/ongoing-overview' style={dashboard.boxButtonsButton}>View</NavLink>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

export default OngoingSupportStatistics
