import React, { useEffect, useState } from 'react'
import { Button, Grid, Responsive } from 'semantic-ui-react'
import useGlobal from '../../Store'
import BarGraphSegment from '../../Components/BarGraphSegment'
import TopicsSection from '../../Components/Dashboard/TopicsSection'
import useStringOperations from '../../Utils/StringOperations'
import { lifeExperiencesOptions } from '../../Config/SelectOptions'
import { Print } from 'react-easy-print'
import styled from 'styled-components'
import { NoPrint } from 'react-easy-print'

const TopicsOverview = () => {
  const [globalState, globalActions] = useGlobal()
  const { dashboardStatistics, currentState, currentDistrict, currentSchool } = globalState
  const stringOperations = useStringOperations()

  const support = Object.keys(dashboardStatistics.topics.support).map((key) => ({
    name: stringOperations.translate(lifeExperiencesOptions, key),
    value: key,
    total: dashboardStatistics.topics.support[key]
  })).filter(item => item.name !== '' && item.total > 0)

  const prevention = Object.keys(dashboardStatistics.topics.prevention).map((key) => ({
    name: stringOperations.translate(lifeExperiencesOptions, key),
    value: key,
    total: dashboardStatistics.topics.prevention[key]
  }))
    .filter(item => item.name !== '' && item.total > 0)

  support.sort((a, b) => {
    const keyA = a.name
    const keyB = b.name

    if (keyA < keyB) return -1
    if (keyA > keyB) return 1
    return 0
  })

  prevention.sort((a, b) => {
    const keyA = a.name
    const keyB = b.name

    if (keyA < keyB) return -1
    if (keyA > keyB) return 1
    return 0
  })

  useEffect(() => {
    setTimeout(() => {
      globalActions.dashboards.getStatistics()
    }, 500)
  }, [currentState, currentDistrict, currentSchool])

  const [topicsShown, setTopicsShow] = useState(true)

  return (
    <Print name="Support Topics Overview">
      <Grid>
        <Grid.Row style={{ paddingTop: 0 }}>

          <Grid.Column mobile={16} tablet={9} computer={topicsShown ? 11 : 15}>
            <Grid padded>
              <Grid.Column computer={16} mobile={16}>
                <BarGraphSegment
                  title="Support Topics Overview"
                  data={support}
                  dataKey="total"
                  showIcons
                  width={600}
                  height={800}
                />
              </Grid.Column>
              <Grid.Column computer={16} mobile={16}>
                <BarGraphSegment
                  title="Prevention Topics Overview"
                  data={prevention}
                  dataKey="total"
                  width={650}
                  height={700}
                />
              </Grid.Column>
            </Grid>
          </Grid.Column>

          <Grid.Column mobile={16} tablet={7} computer={topicsShown ? 5 : 1}>
            <NoPrint force>
              <Responsive minWidth={960} style={{ textAlign: 'right' }}>
                <Button onClick={() => setTopicsShow(!topicsShown)} color="black">
                  {topicsShown ? 'Hide Topics' : 'Show Topics'}
                </Button>
              </Responsive>
            </NoPrint>

            {!!topicsShown && (
                <div style={{ backgroundColor: 'white', padding: '0 20px 20px 30px' }}>
                  <TopicsSection/>
                </div>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Print>
  )
}

export default TopicsOverview
