import endpoints from '../Config/ApiEndpoints'
import { processRequestErrorNotification } from '../Utils/Error'
import {
  asNeededSupportTransform,
  atRiskReferralTransform,
  groupActivitiesTransform,
  onGoingSupportTransform,
  peerHelpersTransform,
  programsTransform,
  supportTopicsTransform
} from '../Transformers/StatisticsTransformers'
import { dashboardStatistics as defaultDashboardStatistics } from '../Config/DefaultState'
import { getResourceFromArchive } from './Archive'

export const getTopics = async (store) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  const { currentSchool, currentState, currentDistrict } = store.state
  let search = `/search?search=`
  if (currentSchool !== '') {
    search += `schoolId:${currentSchool}`
  } else if (currentDistrict !== '') {
    search += `districtId:${currentDistrict}`
  } else if (currentState !== '') {
    search += `stateId:${currentState}`
  }

  store.setState({ requestStatus: 'LOADING' })
  try {
    const response = await getResourceFromArchive(store,
      endpoints.dashboardStatistics.topics + search,
      { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } }
    )
    let { topics } = response.data

    store.setState({ requestStatus: 'SUCCESS', topics })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ topics: {}, requestStatus: 'FAILED', notification })
  }
}

export const getStatistics = async (store) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  const { currentSchool, currentState, currentDistrict } = store.state
  let search = `/search?search=`
  if (currentSchool !== '') {
    search += `schoolId:${currentSchool}`
  } else if (currentDistrict !== '') {
    search += `districtId:${currentDistrict}`
  } else if (currentState !== '') {
    search += `stateId:${currentState}`
  }

  store.setState({ dashboardRequestStatus: 'LOADING' })
  try {
    const response = await getResourceFromArchive(store,
      endpoints.dashboardStatistics.reports + search,
      { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } }
    )
    let dashboardStatistics = response.data

    dashboardStatistics.peerHelpers = peerHelpersTransform(dashboardStatistics.peerHelpers)
    dashboardStatistics.asNeededSupport = asNeededSupportTransform(dashboardStatistics.asNeededSupport)
    dashboardStatistics.onGoingSupport = onGoingSupportTransform(dashboardStatistics.ongoingSupport)
    dashboardStatistics.referrals = atRiskReferralTransform(dashboardStatistics.atRiskReferral)
    dashboardStatistics.groupActivities = groupActivitiesTransform(dashboardStatistics.groupActivities)
    dashboardStatistics.topics = supportTopicsTransform(dashboardStatistics.supportTopics)
    dashboardStatistics.programs = programsTransform(dashboardStatistics.peerHelpers)

    store.setState({ dashboardRequestStatus: 'SUCCESS', dashboardStatistics })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ dashboardStatistics: defaultDashboardStatistics, dashboardRequestStatus: 'FAILED', notification })
  }
}

export const getAtRiskReferralLogs = async (store, page = 0, size = 20) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }
  const { currentSchool, currentState, currentDistrict } = store.state
  let search = `/search?search=`
  if (currentSchool !== '') {
    search += `schoolId:${currentSchool}`
  } else if (currentDistrict !== '') {
    search += `districtId:${currentDistrict}`
  } else if (currentState !== '') {
    search += `stateId:${currentState}`
  }

  store.setState({ requestStatus: 'LOADING' })
  try {
    const response = await getResourceFromArchive(store,
      endpoints.dashboardStatistics.atRiskReferral + (search !== '' ? search + '&' : '?') + 'page=' + page + '&size=' + size,
      { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } }
    )
    let { logs } = response.data._embedded
    const logsPagination = response.data.page

    store.setState({ requestStatus: 'SUCCESS', logs, logsPagination })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ logs: {}, requestStatus: 'FAILED', notification })
  }
}

export const getUsageReport = async (store, page = 0, size = 20) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  const { currentSchool, currentState, currentDistrict } = store.state
  let search = `/search?search=`
  if (currentSchool !== '') {
    search += `schoolId:${currentSchool}`
  } else if (currentDistrict !== '') {
    search += `districtId:${currentDistrict}`
  } else if (currentState !== '') {
    search += `stateId:${currentState}`
  }
  store.setState({ requestStatus: 'LOADING' })
  try {
    const response = await getResourceFromArchive(store,
      endpoints.dashboardStatistics.usageReport + (search !== '' ? search + '&' : '?') + 'page=' + page + '&size=' + size,
      { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } }
    )
    let { users } = response.data._embedded
    const usageReportPagination = response.data.page

    store.setState({ requestStatus: 'SUCCESS', users, usageReportPagination })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ users: {}, requestStatus: 'FAILED', notification })
  }
}
