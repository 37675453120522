import axios from 'axios'
import endpoints, { keycloackCodeLogin, keycloakToken, redirectUrl } from '../Config/ApiEndpoints'
import { processRequestErrorNotification } from '../Utils/Error'
import { defaultUserFilters } from '../Pages/UsersManagement/Users'
import * as qs from 'qs'
import jwtDecode from 'jwt-decode'
import { editedUser as defaultEditedUser, loggedInUser as defaultLoggedInUser } from '../Config/DefaultState'

export const login = async (store, code, setCookie) => {
  try {
    const response = await axios.post(
      keycloackCodeLogin,
      qs.stringify({
        grant_type: 'authorization_code',
        redirect_uri: redirectUrl,
        code
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Basic ${keycloakToken}`
        }
      }
    )

    const accessToken = response.data.access_token
    const jwtInfo = jwtDecode(accessToken)

    let role = 'Peer Helper'
    if (jwtInfo.realm_access.roles.includes('Super User')) role = 'Super User'
    else if (jwtInfo.realm_access.roles.includes('State Level'))
      role = 'State Level'
    else if (jwtInfo.realm_access.roles.includes('District Level'))
      role = 'District Level'
    else if (jwtInfo.realm_access.roles.includes('School Level'))
      role = 'School Level'
    else if (jwtInfo.realm_access.roles.includes('Coordinator'))
      role = 'Coordinator'

    const profileResponse = await axios.get(endpoints.users.root + '/' + jwtInfo.id, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    let menuText = ''
    if (['Peer Helper', 'School Level', 'Coordinator'].includes(role)) {
      await setCookie('currentSchool', profileResponse.data.schools[0].id)
      store.setState({ currentSchool: profileResponse.data.schools[0].id })
    }else if(role === 'State Level') {
      menuText = profileResponse.data.state.name
    }else if(role === 'District Level') {
      menuText = profileResponse.data.district.name
    }

    const loggedInUser = {
      ...store.state.loggedInUser,
      accessToken,
      id: jwtInfo.id,
      firstName: jwtInfo.given_name,
      lastName: jwtInfo.family_name,
      username: jwtInfo.preferred_username,
      email: jwtInfo.email,
      role: role,
      menuText
    }

    await setCookie('userSession', loggedInUser)
    await store.setState({ requestStatus: 'SUCCESS', loggedInUser })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ requestStatus: 'FAILED', notification })
  }
}

export const logout = async (store) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  try {
    await axios.get(
      endpoints.users.root + '/' + store.state.loggedInUser.id + '/logout',
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${store.state.loggedInUser.accessToken}`
        }
      }
    )
  } catch (e) {
    // console.log('logout issue', e)
  } finally {
    store.setState({ requestStatus: 'SUCCESS', defaultLoggedInUser })
  }
}

export const setLoggedInUser = async (store, loggedInUser) => {
  await store.setState({ loggedInUser })
}

export const getUsers = async (
  store,
  page = 0,
  filters = defaultUserFilters,
  size = 20,
  isUsersReport = false
) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  let query = []
  // const filtersWithSchool = { ...filters, schoolId: store.state.currentSchool }

  let filtersWithSchool = filters
  if (isUsersReport)
    filtersWithSchool = { ...filters, schoolId: store.state.currentSchool }

  Object.keys(filtersWithSchool).forEach((key) => {
    if (
      filtersWithSchool[key] !== undefined &&
      ((typeof filtersWithSchool[key] === 'string' &&
        filtersWithSchool[key] !== '') ||
        filtersWithSchool[key].length > 0 ||
        key === 'enabled')
    ) {
      if (typeof filtersWithSchool[key] !== 'string' && filtersWithSchool[key].length > 0) {
        filtersWithSchool[key].forEach(filter => query.push(`${key}:${filter}`))
      } else {
        query.push(`${key}:${filtersWithSchool[key]}`)
      }
    }
  })     

  const url =
    endpoints.users.root +
    `/search?search=${query.join(',')}&page=${page}&size=${size}`

  store.setState({ usersRequestStatus: 'LOADING' })
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${store.state.loggedInUser.accessToken}`
      }
    })

    let { users } = response.data._embedded

    const usersPagination = response.data.page
    store.setState({
      usersRequestStatus: 'SUCCESS',
      usersList: users,
      usersPagination
    })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({
      usersList: [],
      usersRequestStatus: 'FAILED',
      notification
    })
  }
}

export const getUser = async (store, userId, me = false) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  store.setState({ requestStatus: 'LOADING' })
  try {
    const response = await axios.get(endpoints.users.root + '/' + userId, {
      headers: {
        Authorization: `Bearer ${store.state.loggedInUser.accessToken}`
      }
    })
    const editedUser = response.data

    let stateObject = {
      requestStatus: 'SUCCESS'
    }

    if (me) {
      stateObject.myUser = editedUser
    } else {
      stateObject.editedUser = editedUser
    }

    store.setState(stateObject)
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({
      editedUser: defaultEditedUser,
      requestStatus: 'FAILED',
      notification
    })
  }
}

export const deleteUsers = async (store, users, deleteStudents, deleteLogs, deleteAssignedStudents, deleteAssignedLogs) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  store.setState({ requestStatus: 'LOADING' })
  try {
    axios.post(
      endpoints.users.root + '/list',
      { users, deleteStudents, deleteLogs, deleteAssignedStudents, deleteAssignedLogs },
      {
        headers: {
          Authorization: `Bearer ${store.state.loggedInUser.accessToken}`
        }
      })
      .then(() => {
        const notification = { type: 'success', header: `Users deleted` }
        setTimeout(() => store.setState({ requestStatus: 'SUCCESS', notification }), 500)
      })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ requestStatus: 'FAILED', notification })
  }
}

export const getGrades = async (store) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  store.setState({ requestStatus: 'LOADING' })
  try {
    const response = await axios.get(
      endpoints.locations.myGrades,
      {
        headers: {
          Authorization: `Bearer ${store.state.loggedInUser.accessToken}`
        }
      })

    store.setState({ usersRequestStatus: 'SUCCESS', myGrades: response.data })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ requestStatus: 'FAILED', notification })
  }
}

export const saveUser = async (store, user) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  store.setState({ requestStatus: 'LOADING' })
  try {
    Object.keys(user).forEach((key) => (user[key] === null || user[key] === '' || user[key].length === 0) && delete user[key])
    if (user.id === '' || user.id === undefined || user.id === null) {
      await axios.post(
        endpoints.users.root,
        { ...user },
        {
          headers: {
            Authorization: `Bearer ${store.state.loggedInUser.accessToken}`
          }
        }
      )
    } else {
      await axios.patch(endpoints.users.root + '/' + user.id, user, {
        headers: {
          Authorization: `Bearer ${store.state.loggedInUser.accessToken}`
        }
      })
    }

    setTimeout(() => {
      store.setState({
        requestStatus: 'SUCCESS',
        notification: {
          type: 'success',
          header: `User ${!user.id ? 'added' : 'updated'}`
        }
      })
    }, 500)
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ requestStatus: 'FAILED', notification })
  }
}
