import { useCookies } from 'react-cookie'
import useGlobal from '../../Store'
import React, { useEffect, useState } from 'react'
import { Icon, Image, Label, Menu, Popup } from 'semantic-ui-react'
import logo from '../../Assets/Images/logo2.png'
import _ from 'lodash'
import HierarchySelect from '../AppMenu/Select'

const NavBarDesktop = ({ leftItems, loggedInUserId, history, role, currentSchool, currentDistrict, currentState }) => {
  const [, setCookie] = useCookies()
  const [globalState, globalActions] = useGlobal()
  const [activeIndex, setActiveIndex] = useState('')
  const [user, setUser] = useState({ ...globalState.myUser })
  const [showHierarchyPicker, setShowHierarchyPicker] = useState(false)
  const { loggedInUser, notificationCount, states, districts, schools } = globalState

  useEffect(() => {
    globalActions.locations.getState()

    if (currentState)
      globalActions.locations.getDistricts(currentState)

    if (currentDistrict)
      globalActions.locations.getDistrictsSchools(currentDistrict ? currentDistrict : '')

  }, [currentState, currentDistrict])

  useEffect(() => {
      if (currentSchool) {
        globalActions.locations.getSchools();
      }  
  }, [currentSchool, loggedInUser])

  useEffect(() => {
    if (loggedInUser.id !== '0') {
      globalActions.users.getUser(loggedInUser.id, true)
    }
  }, [loggedInUser.id])

  useEffect(() => {
    setUser({ ...globalState.myUser })
  }, [globalState.myUser])

  useEffect(() => {
    globalActions.notifications.getNotificationCount()
  }, [loggedInUser.id])

  useEffect(() => {
    setActiveIndex(window.location.pathname.substr(1, window.location.pathname.length - 1))
    if (currentSchool !== '') {
      globalActions.locations.getSchool(currentSchool)
    }
  }, [currentSchool, loggedInUser])

  useEffect(() => {
    if (globalState.myUser && globalState.myUser.roles && globalState.myUser.roles[0] && (globalState.myUser.roles[0].name === 'Peer Helper' || globalState.myUser.roles[0].name === 'School Level' || globalState.myUser.roles[0].name === 'Coordinator') && globalState.myUser.schools.length === 1) {
      globalActions.locations.changeSchool(setCookie, globalState.myUser.schools[0].id)
    }
  }, [globalState.myUser])

  const navigateToPage = (page, key) => {
    setActiveIndex(key)
    history.push(page)
  }

  const _getSchoolPickerText = () => {
    if (currentState && !currentDistrict && !currentSchool && states.length)
      return states.find(state => state.id === currentState)?.name || ""

    if (currentDistrict && !currentSchool && districts && districts.length)
      return districts.find(district => district.id === currentDistrict)?.name || ""

    if (currentSchool && schools && schools.length)
      return schools.find(school => school.id === currentSchool)?.name || ""

    return 'No school selected'
  }

  return (
    <>
      {showHierarchyPicker && (
        <div onClick={() => setShowHierarchyPicker(false)}
             style={{
               position: 'absolute',
               top: 0,
               right: 0,
               bottom: 0,
               left: 0,
               backgroundColor: 'rgba(0, 0, 0, 0.5)',
               zIndex: 99
             }}/>
      )}

      <Menu fixed="top" style={{ backgroundColor: '#753CBE' }} inverted>
        <img alt="logo" src={logo} style={{ width: 340, height: 65 }}/>
        {loggedInUserId !== '' && (
          <React.Fragment>
            <Menu.Menu position="left">
              {_.map(leftItems, (item) =>
                (role === 'Peer Helper' && (item.key === 'users' || item.key === 'match' || item.key === 'campaigns')) || (['Peer Helper', 'State Level'].includes(role) && item.key === 'assists') || (role !== 'Coordinator' && role !== 'Super User' && item.key === 'match') || (role !== 'Super User' && item.key === 'archive') ? null : (
                  <Menu.Item as="a" active={activeIndex === item.key} key={item.key}
                             onClick={() => navigateToPage(item.to, item.key)} style={{ fontSize: 24, padding: 10 }}>
                    {item.icon && <Icon name={item.icon}/>}
                    {item.name}
                  </Menu.Item>
                )
              )}
            </Menu.Menu>

            <Menu.Menu position="right">
              {(globalState.myUser && globalState.myUser.roles && globalState.myUser.roles[0] && (globalState.myUser.roles[0].name === 'Peer Helper' || globalState.myUser.roles[0].name === 'School Level' || globalState.myUser.roles[0].name === 'Coordinator') && globalState.myUser.schools.length === 1) ? (
                <Menu.Item as="a" key="pickSchool">
                  <span style={{ borderBottom: '1px solid white', padding: 5, fontSize: 17 }}>
                    {globalState.myUser.schools[0].name}
                  </span>
                </Menu.Item>
              ) : (
                <Popup
                  trigger={
                    <Menu.Item as="a" key="pickSchool" onClick={() => {setShowHierarchyPicker(!showHierarchyPicker)}}>
                      {(!['State Level', 'District Level'].includes(loggedInUser.role) || currentSchool !== '') ? (
                        <span style={{ borderBottom: '1px solid white', padding: 5, fontSize: 17 }}>
                          {_getSchoolPickerText()}
                        </span>
                      ) : (
                        <span style={{ borderBottom: '1px solid white', padding: 5, fontSize: 17 }}>
                          {loggedInUser.menuText}
                        </span>
                      )}
                    </Menu.Item>
                  }
                  content="Pick school"
                  basic
                />
              )}

              {showHierarchyPicker && (
                <div style={{
                  position: 'absolute',
                  top: 65,
                  right: 0,
                  backgroundColor: 'white',
                  padding: 20,
                  borderWidth: 1,
                  borderColor: '#ccc',
                  borderStyle: 'solid',
                  zIndex: 999
                }}>
                  <HierarchySelect
                    role={role}
                    currentSchool={currentSchool}
                    currentDistrict={currentDistrict}
                    currentState={currentState}
                    setCookie={setCookie}
                    changeState={globalActions.locations.changeState}
                    changeDistrict={globalActions.locations.changeDistrict}
                    changeSchool={globalActions.locations.changeSchool}
                  />
                </div>
              )}

              <Popup
                trigger={
                  <Menu.Item as="a" active={activeIndex === 'profile'} key={'profile'}
                             onClick={() => navigateToPage('/profile', 'profile')}>
                    {user.avatar ? (
                      <Image src={user.avatar} size="mini"/>
                    ) : (
                      <Icon name="user" style={{ fontSize: 20, marginRight: 0 }}/>
                    )}

                    {notificationCount !== undefined && notificationCount !== 0 && (
                      <Label color="red" attached="top right" circular>
                        {notificationCount !== undefined ? notificationCount : ''}
                      </Label>
                    )}
                  </Menu.Item>
                }
                content="My Profile"
                basic
              />

              <Popup
                trigger={
                  <Menu.Item as="a" active={activeIndex === 'help'} key="help"
                             onClick={() => navigateToPage('/help', 'help')}>
                    <Icon name="question circle" style={{ fontSize: 30, marginRight: 0 }}/>
                  </Menu.Item>
                }
                content="Guides and Tutorials"
                basic
              />

              <Popup
                trigger={
                  <Menu.Item as="a" active={activeIndex === 'logout'} key="logout"
                             onClick={() => navigateToPage('/logout', 'logout')}>
                    <Icon name="sign-out" style={{ fontSize: 30, marginRight: 0 }}/>
                  </Menu.Item>
                }
                content="Logout"
                basic
              />
            </Menu.Menu>
          </React.Fragment>
        )}
      </Menu>
    </>
  )
}

export default NavBarDesktop
