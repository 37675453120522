import React, { useEffect, useState } from 'react'
import { Button, Divider, Header, Segment, Item, Modal } from 'semantic-ui-react'
import useGlobal from '../../Store'
import { NavLink } from 'react-router-dom'
import useUrlBuilder from '../../Utils/Links'
import useStringOperations from '../../Utils/StringOperations'
import {
  typeOfActivityOptions,
  lifeExperiencesOptions,
  addressingTypeOptions,
  placeOptions
} from '../../Config/SelectOptions'
import { dashboard } from '../../Theme/Styles'
import {
  RightMenuBar,
  RightMenuBarButton,
  RightMenuBarButtonIcon,
  RightMenuBarOptions,
  StyledLogQuestionAnswer,
  StyledLogQuestionHeader
} from '../../Theme/Components'
import * as _ from 'lodash'

const GroupActivityLog = (props) => {
  const groupActivityLogId = props.match.params.id || '0'
  const [globalState, globalActions] = useGlobal()
  const { loggedInUser } = globalState
  const [groupActivityLog, setGroupActivityLog] = useState({ ...globalState.editedGroupActivityLog })
  const [makeUrl] = useUrlBuilder(props.match.url)
  const stringOperations = useStringOperations()
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if (groupActivityLogId !== '0') {
      globalActions.groupActivityLogs.getGroupActivityLog(groupActivityLogId)
    }
  }, [groupActivityLogId])

  useEffect(() => {
    setGroupActivityLog({ ...globalState.editedGroupActivityLog })
  }, [globalState.editedGroupActivityLog])

  const deleteGroupActivityLog = () => {
    globalActions.groupActivityLogs.deleteGroupActivityLog(groupActivityLogId).then(props.history.push(makeUrl('/group-logs')))
  }

  return (
    <Segment>
      <RightMenuBar>
        <Header as='h3' style={dashboard.myProgramHeader}>Group Activity Log</Header>
        <RightMenuBarOptions>
          {props.match.path.split('/')[1] !== 'atrisk-referrals-report' && ['Peer Helper', 'Coordinator', 'Super User'].includes(loggedInUser.role) && (
            <React.Fragment>

              {(loggedInUser.role !== 'Peer Helper' || (loggedInUser.role === 'Peer Helper' && undefined !== groupActivityLog.creator && groupActivityLog.creator.id === loggedInUser.id)) && (
                <>
                  <RightMenuBarButton as={NavLink} to={makeUrl(`/group-logs/${groupActivityLogId}/edit`)}>
                    <RightMenuBarButtonIcon name='edit'/>
                  </RightMenuBarButton>
                  <RightMenuBarButton onClick={() => setModalOpen(true)}>
                    <RightMenuBarButtonIcon name='trash'/>
                  </RightMenuBarButton>
                </>
              )}

              <Modal open={modalOpen} onClose={() => setModalOpen(false)} closeIcon>
                <Modal.Header>Delete Your Group Activity Log</Modal.Header>
                <Modal.Content>
                  <p>Are you sure you want to delete this Group Activity Log ?</p>
                </Modal.Content>
                <Modal.Actions>
                  <Button negative onClick={() => setModalOpen(false)}>No</Button>
                  <Button positive icon='checkmark' labelPosition='right' content='Yes'
                          onClick={deleteGroupActivityLog}/>
                </Modal.Actions>
              </Modal>
            </React.Fragment>
          )}
          <RightMenuBarButton as={NavLink} to={makeUrl('/')}>
            <RightMenuBarButtonIcon name='close' style={{ fontSize: 20 }}/>
          </RightMenuBarButton>
        </RightMenuBarOptions>
      </RightMenuBar>

      <Divider/>

      <Item.Group>
        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>1. Title of Activity</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{groupActivityLog.title}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>2. Date of Activity</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{new Date(groupActivityLog.date.split(' ').join('T')).toLocaleDateString('en', {
              year: '2-digit',
              month: 'numeric',
              day: 'numeric'
            })}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>3. Time of Activity</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{new Date(groupActivityLog.date.split(' ').join('T')).toLocaleTimeString('en-US', {
              hour: '2-digit',
              minute: '2-digit'
            })}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>4. Participating Peer Helpers</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{_.map(groupActivityLog.peerHelpers, peerHelper => peerHelper.firstName + ' ' + peerHelper.lastName).join(', ')}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>5. What type of activity was this?</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{stringOperations.translate(typeOfActivityOptions, groupActivityLog.type)}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>6. How did you address this group?</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{stringOperations.translate(addressingTypeOptions, groupActivityLog.help)}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>7. Topics shared during this conversation</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{groupActivityLog.experiences.map(experience => stringOperations.translate(lifeExperiencesOptions, experience)).join(', ')}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>8. Where did you help this student?</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{stringOperations.translate(placeOptions, groupActivityLog.location)}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>9. Were any students referred to a Program Coordinator / School
              Counselor</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{groupActivityLog.referred ? 'Yes' : 'No'}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        {groupActivityLog.referred && (
          <Item>
            <Item.Content>
              <StyledLogQuestionHeader>10. How many students</StyledLogQuestionHeader>
              <StyledLogQuestionAnswer>{groupActivityLog.sizeReferrals}</StyledLogQuestionAnswer>
            </Item.Content>
          </Item>
        )}
      </Item.Group>
    </Segment>
  )
}

export default GroupActivityLog
