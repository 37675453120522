import React, { useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import useGlobal from '../../Store'
import UsageReportTable from '../../Components/Dashboard/UsageReportTable'
import DemographicCard from '../../Components/Dashboard/DemographicCard'
import OverviewCard from '../../Components/OverviewCard'
import { Print } from 'react-easy-print'

const PeerHelperOverview = () => {
  const [globalState, globalActions] = useGlobal()
  const [users, setUsers] = useState(globalState.users)
  const { dashboardStatistics, currentState, currentDistrict, currentSchool } = globalState

  useEffect(() => {
    setTimeout(() => {
      globalActions.dashboards.getStatistics()
      globalActions.dashboards.getUsageReport(0, 10000)
    }, 500)
  }, [currentState, currentDistrict, currentSchool])

  useEffect(() => {
    setUsers(globalState.users)
  }, [globalState.users])

  const { peerHelpers } = dashboardStatistics

  return (
    <Print name='Peer Helpers Overview'>
      <Grid>
        <Grid.Row style={{ paddingTop: 0 }}>
          <Grid.Column mobile={16} tablet={9} computer={11}>
            <Grid padded>
              <Grid.Column computer={16} mobile={16}>
                {
                  users &&
                  <OverviewCard
                    title='Peer Helpers Overview'
                    columns={[
                      { property: 'Peer Helpers', propertyCount: peerHelpers.peerHelpers },
                      { property: 'Assigned Students', propertyCount: peerHelpers.students },
                      { property: 'As-Needed Logs', propertyCount: peerHelpers.asNeededLogs },
                      { property: 'Group Logs', propertyCount: peerHelpers.groupLogs }
                      // { property: 'Total Logins', propertyCount: peerHelpers.totalLogins }
                    ]}
                  />
                }
              </Grid.Column>
              <Grid.Column computer={16} mobile={16}>
                <UsageReportTable/>
              </Grid.Column>
            </Grid>
          </Grid.Column>

          <Grid.Column mobile={16} tablet={7} computer={5}>
            <DemographicCard data={peerHelpers}/>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Print>
  )
}

export default PeerHelperOverview
