import axios from 'axios'
import endpoints from '../Config/ApiEndpoints'
import { processRequestErrorNotification } from '../Utils/Error'
import { editedAssessment as defaultEditedAssessment } from '../Config/DefaultState'
import { getResourceFromArchive } from './Archive'

const defaultFilters = {
  assessmentType: '',
  dateOfAssessment: '',
  timeOfAssessments: '',
  socialSkills: '',
  emotionalState: '',
  highRiskBehaviors: '',
  referredTo: true
}

export const getAssessments = async (store, filters = defaultFilters) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  let query = []
  const filtersWithSchool = { ...filters, schoolId: store.state.currentSchool }
  Object.keys(filtersWithSchool).forEach(key => {
    if (filtersWithSchool[key] !== undefined && ((typeof filtersWithSchool[key] === 'string' && filtersWithSchool[key] !== '') || filtersWithSchool[key].length > 0)) {
      if (typeof filtersWithSchool[key] !== 'string' && filtersWithSchool[key].length > 0) {
        filtersWithSchool[key].map(filter => {
          query.push(`${key}:${filter}`)
        })
      } else {
        query.push(`${key}:${filtersWithSchool[key]}`)
      }
    }
  })
  const url = endpoints.assessments.root + `/search&search=${query.join(',')}`

  store.setState({ requestStatus: 'LOADING' })
  try {
    const response = await getResourceFromArchive(store, url, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
    const { assessments } = response.data._embedded

    store.setState({ requestStatus: 'SUCCESS', assessments })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ requestStatus: 'FAILED', assessments: [], notification })
  }
}

export const getAssessment = async (store, studentId, assessmentType) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  store.setState({ requestStatus: 'LOADING' })

  try {
    const response = await getResourceFromArchive(store, endpoints.users.students + '/' + studentId, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
    const editedAssessment = assessmentType === 'initial' ? response.data.initialAssessment : (assessmentType === 'midpoint' ? response.data.midpointAssessment : response.data.finalAssessment)

    store.setState({ requestStatus: 'SUCCESS', editedAssessment })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ editedAssessment: defaultEditedAssessment, requestStatus: 'FAILED', notification })
  }
}

export const saveAssessment = async (store, studentId, assessment, assessmentType) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }
  const url = assessmentType === 'initialAssessment' ? endpoints.users.students + '/' + studentId + '/initialAssessment' : (assessmentType === 'midpointAssessment' ? endpoints.users.students + '/' + studentId + '/midpointAssessment' : endpoints.users.students + '/' + studentId + '/finalAssessment')

  store.setState({ requestStatus: 'LOADING' })
  try {
    await axios.post(url, assessment, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
    const notification = { type: 'success', header: `Assessment ${assessment.id === '' ? 'added' : 'updated'}` }
    setTimeout(() => {
      store.setState({ requestStatus: 'SUCCESS', notification })
    }, 500)
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ requestStatus: 'FAILED', notification })
  }
}
