import {  Icon, Dropdown, Responsive, Table, Button, Header } from 'semantic-ui-react'
import React, { useEffect, useState } from 'react'
import useGlobal from '../../Store'
import useCampaignsService from '../../Services/Campaigns'
import { NavLink } from 'react-router-dom'
import { dashboard } from '../../Theme/Styles'
import { StyledTableHeader } from '../../Theme/Components'
import { campaignType } from '../../Config/SelectOptions'
import { baseUrl } from '../../Config/ApiEndpoints'

const CampaignsPage = (props) => {
  const { history } = props

  const [ globalActions] = useGlobal()

  const {  getCampaigns, deleteCampaign } = useCampaignsService()

  const [campaigns, setCampaigns] = useState([])

  const renderCampaignButtons = (campaign, published) => ( 
      <NavLink component={Button} icon labelPosition="left" size="mini" style={{ width: 110 }} color="blue"
               to={`/campaigns/${campaign.id}`}>
        <Icon name="eye"/>
        Surveys
      </NavLink>
  )

  const renderCampaignButtonsMobile = (campaign) => (
    <Dropdown text="Action">
      <Dropdown.Menu>
        <Dropdown.Item icon="eye" text="View Surveys" onClick={() => history.push(`/campaigns/${campaign.id}`)}/>
      </Dropdown.Menu>
    </Dropdown>
  )

  const _getCampaigns = () => {
    getCampaigns()
      .then(campaigns => setCampaigns(campaigns))
      .catch(() => globalActions.notifications.showNotification({
          type: 'warning',
          header: 'Failed to retrieve the campaigns list',
          subheader: 'Unknown error'
        })
      )
  }

  useEffect(_getCampaigns, [])

  const cellStyle = { marginBottom: window.innerWidth < 960 ? 10 : 0, padding: 10 }

  return (
    <>
      <StyledTableHeader>
        <Header as="h2" style={dashboard.phHeader}><strong>Surveys</strong></Header>
      </StyledTableHeader>

      <Table compact striped size="small">
        <Responsive as={Table.Header} minWidth={960}>
          <Table.Row className="stickyTh">
            <Table.HeaderCell style={{ display: 'flex', flexGrow: 1 }}>
              Name
            </Table.HeaderCell>

            <Table.HeaderCell>
              Type
            </Table.HeaderCell>

            <Table.HeaderCell>
              Actions
            </Table.HeaderCell>
          </Table.Row>
        </Responsive>

        <Table.Body>
          {campaigns.map((campaign, key) => {
            const { id, name, type, published } = campaign

            return (
              <Table.Row key={key}>
                <Table.Cell style={cellStyle}>
                  <Responsive as="strong" maxWidth={960} style={{ marginRight: 5 }}>
                    Name:
                  </Responsive>
                  <NavLink to={`/campaigns/${id}`}>{name.replace("Path", "PATH")}</NavLink>
                </Table.Cell>

                <Table.Cell style={cellStyle}>
                  <Responsive as="strong" maxWidth={960} style={{ marginRight: 5 }}>
                    Type:
                  </Responsive>
                  {campaignType.find(t => t.value === type).text.replace("Path", "PATH")}
                </Table.Cell>

                <Table.Cell style={cellStyle} collapsing>
                  <Responsive maxWidth={960} style={{ marginRight: 5 }}>
                    {renderCampaignButtonsMobile(campaign, published)}
                  </Responsive>

                  <Responsive minWidth={960}>
                    {renderCampaignButtons(campaign, published)}
                  </Responsive>
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </>
  )
}

export default CampaignsPage
