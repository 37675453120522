import React, { useEffect, useState } from 'react'
import { Button, Container, Divider, Form, Header, Icon, Image, Segment } from 'semantic-ui-react'
import useGlobal from '../../Store'
import {
  academicStrengthsOptions,
  genderOptions,
  gradeOptions,
  hobbiesOptions,
  lifeExperiencesOptions,
  raceOptions, renderLabel
} from '../../Config/SelectOptions'
import useValidation from '../../Utils/Validations'
import { dashboard } from '../../Theme/Styles'
import ProfilePictureModal from '../ProfilePictureModal/ProfilePictureModal'
import * as _ from 'lodash'

const getUsersRole = (user) => {
  return user.roles !== null && user.roles !== undefined && user.roles[0] !== undefined ? user.roles[0].name : 'Peer Helper'
}

const getRequiredFields = (user) => {
  const role = getUsersRole(user)
  let requiredFields = []
  if (role === 'Peer Helper') {
    requiredFields = ['firstName', 'lastName', 'gender', 'race', 'academic', 'interests', 'experiences', 'grades']
  } else {
    requiredFields = ['firstName', 'lastName', 'email', 'phone', 'gender', 'race']
  }

  return requiredFields
}

const getGradesBasedOnRole = (user) => {
  return user.grades ? (getUsersRole(user) === 'Peer Helper' ? user.grades[0] : user.grades) : ''
}

const ProfileForm = (props) => {
  const userId = props.userId || props.match.params.userId || '0'
  const [globalState, globalActions] = useGlobal()
  const { roles, myGrades } = globalState
  const [user, setUser] = useState({ ...globalState.editedUser, grades: getGradesBasedOnRole(globalState.editedUser) })
  const [requiredFields, setRequiredFields] = useState(getRequiredFields(globalState.editedUser))
  const [validForm, validationErrors, onFieldChangeValidation, requiredFieldsChange] = useValidation(requiredFields, user)
  const [modalOpen, setModalOpen] = useState(false)
  const [gradeOptions, setGradeOptions] = useState([])

  useEffect(() => {
    if (props.match.params.userId !== undefined) {
      globalActions.roles.getRoles()
    }

    if (userId !== '0') {
      globalActions.users.getUser(userId)
    }
  }, [userId])

  useEffect(() => {
    if (getUsersRole(user) === 'Peer Helper') {
      globalActions.users.getGrades()
    }
  }, [user])

  useEffect(() => {
    if (myGrades) {
      let options = []
      Object.keys(myGrades).map(key => {
        options.push(({ key, text: myGrades[key], value: key, ischecked: false }))
      })
      setGradeOptions(options)
    }
  }, [myGrades])

  useEffect(() => {
    requiredFieldsChange(requiredFields, user)
  }, [requiredFields, userId])

  useEffect(() => {
    setRequiredFields(getRequiredFields(globalState.editedUser))
    setUser({ ...globalState.editedUser, grades: getGradesBasedOnRole(globalState.editedUser) })
  }, [globalState.editedUser])

  useEffect(() => {
    setRequiredFields(getRequiredFields(user))
  }, [user.roles])

  const updateUserState = e => {
    const { name, value } = typeof e.target !== 'undefined' ? e.target : e
    setUser({ ...user, [name]: value })
    onFieldChangeValidation(name, value)
  }

  const closeModalAndRefresh = () => {
    setModalOpen(false)
    if (typeof props.refresh !== 'undefined') {
      props.refresh()
    }
  }

  const saveProfilePicture = () => {
    globalActions.users.saveUser({ id: user.id, avatar: user.avatar })
      .then(closeModalAndRefresh)
  }

  const saveUserInfo = () => {
    if (validForm) {
      let excludedFields = ['state', 'district', 'schools', 'username', 'enabled', 'logs', 'students', 'notificationUserId', 'password', 'confirmPassword']
      if (_.includes(['Super User', 'Coordinator', 'State Level', 'District Level', 'School Level'], getUsersRole(user))) {
        excludedFields.push('grades', 'academic', 'interests', 'experiences')
      }

      if (props.match.params.userId === undefined) {
        excludedFields.push('roles')
      }

      const newUser = _.pickBy(user, (field, key) => !_.includes(excludedFields, key))

      if (getUsersRole(user) === 'Peer Helper') {
        newUser.grades = getUsersRole(user) === 'Peer Helper' ? [user.grades] : user.grades
      }

      globalActions.users.saveUser(newUser)
        .then(closeModalAndRefresh)
    } else {
      if (typeof user.password === 'undefined' || user.password.length === 0) {
        const missingFields = []
        Object.keys(validationErrors).map(field => {
          if (validationErrors[field].error)
            missingFields.push(field.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase()))
        })

        globalActions.notifications.showNotification({
          type: 'warning',
          header: 'Form validation error',
          subheader: 'Please complete all required fields! Check "' + missingFields.join('", "') + '"'
        })
      }
    }
  }

  const saveUser = () => {
    if (typeof user.password !== 'undefined' && user.password.length > 0) {
      if (user.password !== user.confirmPassword) {
        globalActions.notifications.showNotification({
          type: 'warning',
          header: 'Password reset',
          subheader: 'Passwords don\'t match!'
        })
      } else if (user.password.length < 8) {
        globalActions.notifications.showNotification({
          type: 'warning',
          header: 'Password change',
          subheader: 'Password is too short!'
        })
      } else {
        globalActions.users.saveUser({
          id: user.id,
          password: user.password
        })
          .then(() => {
            saveUserInfo()
            closeModalAndRefresh()
          })
      }

      return
    }

    saveUserInfo()
  }

  return (
    <Container>
      <Segment>
        <Header as="h3" style={dashboard.myProgramHeader}>My Profile</Header>

        <Divider/>

        <Form>
          <Form.Group>
            <Form.Input name="username" value={user.username} disabled={props.userId !== ''}
                        required={props.userId !== ''} fluid label="Username"
                        onChange={updateUserState}
                        error={props.userId !== '' ? validationErrors.firstName.error : false}
                        placeholder={props.userId !== '' ? 'Username' : ''} width={14}/>
            <Button icon
                    style={{ border: 'none', backgroundColor: 'Transparent', paddingBottom: '1.5rem', float: 'right' }}
                    onClick={() => setModalOpen(true)}>
              {!user.avatar ? <Icon name="user" size="big" color="violet"/> : <Image wrapped src={user.avatar}
                                                                                     size="mini"/>}
            </Button>
          </Form.Group>

          {props.match.params.userId !== undefined && (
            <Form.Dropdown name="roles" value={user.roles ? user.roles[0].id : ''}
                           onChange={(e, data) => setUser({
                             ...user,
                             roles: [{ id: data.value, name: _.find(roles, role => role.value === data.value).text }]
                           })}
                           options={roles}
                           required fluid selection label="Role"
                           error={typeof validationErrors.role !== 'undefined' ? validationErrors.role.error : false}
                           placeholder="Role"/>
          )}

          <Form.Input name="firstName" value={user.firstName} onChange={updateUserState} required fluid
                      label="First name"
                      error={validationErrors.firstName.error}
                      placeholder="First name"/>

          <Form.Input name="lastName" value={user.lastName} onChange={updateUserState} required fluid label="Last name"
                      error={validationErrors.lastName.error}
                      placeholder="Last name"/>

          {getUsersRole(user) !== 'Peer Helper' && (
            <React.Fragment>
              <Form.Input name="email" label="Email" placeholder="Email" value={user.email} onChange={updateUserState}
                          error={typeof validationErrors.email !== 'undefined' ? validationErrors.email.error : false}
                          required fluid/>
              <Form.Input name="phone" label="Cell / Daytime Phone" placeholder="Cell / Daytime Phone"
                          value={user.phone} onChange={updateUserState}
                          error={typeof validationErrors.phone !== 'undefined' ? validationErrors.phone.error : false}
                          required fluid/>
            </React.Fragment>
          )}

          <Form.Select name="gender" value={user.gender} onChange={(e, data) => updateUserState(data)}
                       options={genderOptions} required fluid label="Gender"
                       error={validationErrors.gender.error}
                       placeholder="Gender"/>

          <Form.Dropdown name="race" value={user.race} onChange={(e, data) => updateUserState(data)} required fluid
                         multiple selection label="Race"
                         error={validationErrors.race.error}
                         options={raceOptions} placeholder="Choose one or more"/>

          {['Peer Helper'].includes(getUsersRole(user)) && (
            <Form.Dropdown name="grades" value={user.grades} onChange={(e, data) => updateUserState(data)} required
                           fluid
                           multiple={getUsersRole(user) === 'Coordinator'} selection
                           label={getUsersRole(user) === 'Peer Helper' ? 'Grade' : 'Grades'}
                           error={typeof validationErrors.grades !== 'undefined' ? validationErrors.grades.error : false}
                           options={gradeOptions}
                           placeholder={getUsersRole(user) === 'Peer Helper' ? 'Choose one' : 'Choose one or more'}/>
          )}

          {getUsersRole(user) === 'Peer Helper' && (
            <React.Fragment>
              <Form.Dropdown name="academic" value={user.academic} onChange={(e, data) => updateUserState(data)}
                             options={academicStrengthsOptions}
                             required fluid multiple selection label="Academic Needs"
                             error={typeof validationErrors.academic !== 'undefined' ? validationErrors.academic.error : false}
                             placeholder="Academic Needs"/>
              <Form.Dropdown name="interests" value={user.interests} onChange={(e, data) => updateUserState(data)}
                             options={hobbiesOptions}
                             required fluid multiple selection label="Hobbies/Interests" placeholder="Hobbies/Interests"
                             error={typeof validationErrors.interests !== 'undefined' ? validationErrors.interests.error : false}/>

              <Form.Dropdown name="experiences" value={user.experiences} onChange={(e, data) => updateUserState(data)}
                             options={lifeExperiencesOptions.filter(option => !option.groupOnly)}
                             renderLabel={renderLabel}
                             required fluid multiple selection label="Life Experiences"
                             error={typeof validationErrors.experiences !== 'undefined' ? validationErrors.experiences.error : false}
                             placeholder="Life Experiences"/>

            </React.Fragment>
          )}

          <Form.Group widths="equal">
            <Form.Input value={user.password} onChange={e => setUser({ ...user, password: e.target.value })} fluid
                        label="New Password"
                        name="password"
                        placeholder="New Password" type="password"/>
            <Form.Input value={user.confirmPassword}
                        onChange={e => setUser({ ...user, confirmPassword: e.target.value })} fluid
                        label="Confirm Password"
                        name="confirmPassword"
                        placeholder="Confirm Password" type="password"/>
          </Form.Group>

          <div style={{ textAlign: 'right' }}>
            <Button.Group size="large">
              {typeof props.cancelEdit !== 'undefined' && (
                <React.Fragment>
                  <Button onClick={props.cancelEdit}>Cancel</Button>
                  <Button.Or/>
                </React.Fragment>
              )}
              <Button positive onClick={saveUser}>Save</Button>
            </Button.Group>
          </div>
        </Form>
      </Segment>

      <ProfilePictureModal avatar={user.avatar} modalOpen={modalOpen} setModalOpen={setModalOpen}
                           saveProfilePicture={saveProfilePicture}
                           updateUserState={updateUserState}/>
    </Container>
  )
}

export default ProfileForm
