import React from 'react'
import { Grid, Header, Segment, Table } from 'semantic-ui-react'
import { dashboard } from '../../../Theme/Styles'
import { NavLink } from 'react-router-dom'

const ProgramsStatistics = ({ programs, level }) => {
  return (
    <Segment style={dashboard.segment}>
      <Header as='h2' style={dashboard.header}>Peer Helpers Programs</Header>
      <Grid rows={2}>
        <Grid.Row columns={2}>
          <Grid.Column computer={7} mobile={16} style={{ textAlign: 'center' }}>
            <Header style={dashboard.count}>
              {programs.count}
              <Header.Subheader style={dashboard.countSubHeader}>Peer Helpers</Header.Subheader>
            </Header>
          </Grid.Column>

          <Grid.Column computer={9} mobile={16}>
            <Header as='h4' disabled style={dashboard.table.header}>Totals</Header>
            <Table basic='very' style={dashboard.table.base}>
              <Table.Body>
                {!['State Level', 'District Level'].includes(level) && (
                  <Table.Row style={dashboard.list}>
                    <Table.Cell style={dashboard.table.cell}>States</Table.Cell>
                    <Table.Cell style={dashboard.table.blueCell}>{programs.states}</Table.Cell>
                  </Table.Row>
                )}
                {level !== 'District Level' && (
                  <Table.Row style={dashboard.list}>
                    <Table.Cell style={dashboard.table.cell}>Districts</Table.Cell>
                    <Table.Cell style={dashboard.table.blueCell}>{programs.districts}</Table.Cell>
                  </Table.Row>
                )}
                <Table.Row style={dashboard.list}>
                  <Table.Cell style={dashboard.table.cell}>Schools</Table.Cell>
                  <Table.Cell style={dashboard.table.blueCell}>{programs.schools}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={dashboard.boxButtonsRow}>
          <Grid.Column floated='right' style={dashboard.boxButtonsColumn}>
            {level === 'Super User' && (
              <>
                <NavLink to='/heirarchy-management/schools' style={dashboard.boxButtonsButton}>Schools</NavLink>
              </>
            )}
            <NavLink to='/peerHelpers' style={dashboard.boxButtonsButton}>View</NavLink>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

export default ProgramsStatistics
