import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { Route, Router, Switch } from 'react-router-dom'
import routes from './Routes/index.jsx'
import 'semantic-ui-css/semantic.min.css'
import Notification from './Components/Notification'
import useGlobal from './Store'
import AppMenu from './Components/AppMenu'
import { viewport } from './Theme/Styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { useCookies } from 'react-cookie'
import { keycloack } from './Config/ApiEndpoints'
import './index.css'
import './Assets/CSS/drag.css'
import { Dimmer, Loader } from 'semantic-ui-react'
import PrintProvider, { NoPrint } from 'react-easy-print'

const history = createBrowserHistory()

const App = () => {
  const [cookies] = useCookies()
  const [globalState, globalActions] = useGlobal()
  const [loggedInUser, setLoggedInUser] = useState(globalState.loggedInUser)
  const [notification, setNotification] = useState(globalState.notification)
  const [hasSetSession, setHasSetSession] = useState(false)

  useEffect(() => {
    if (history.location.pathname === '/login-finished') {
      return false
    }

    if (typeof cookies.userSession === 'undefined' && history.location.pathname !== '/login' && history.location.pathname.indexOf('take') === -1) {
      window.location.href = keycloack
    } else if (typeof cookies.userSession !== 'undefined' && typeof loggedInUser.accessToken === 'undefined') {
      globalActions.users.setLoggedInUser(cookies.userSession)
        .then(() => {
          setHasSetSession(true)

          if (typeof cookies.currentSchool !== 'undefined') globalActions.locations.setSchool(cookies.currentSchool)
          if (typeof cookies.currentDistrict !== 'undefined') globalActions.locations.setDistrict(cookies.currentDistrict)
          if (typeof cookies.currentState !== 'undefined') globalActions.locations.setState(cookies.currentState)
          if (typeof cookies.currentArchive !== 'undefined') globalActions.archive.setArchive(cookies.currentArchive)
        })
    } else {
      setHasSetSession(true)
    }
  }, [])

  useEffect(() => {
    const { notification } = globalState
    if (notification.show) {
      setTimeout(() => {
        globalActions.notifications.hideNotification()
      }, 3000)
    }

    setNotification(notification)
  }, [globalState.notification])

  useEffect(() => {
    setLoggedInUser(globalState.loggedInUser)

    if (globalState.loggedInUser.id !== '') {
      let OneSignal = window.OneSignal || []
      // OneSignal.push(function () {
      //   OneSignal.getUserId().then(userId => console.log('OneSignal userId', userId))
      //   OneSignal.getExternalUserId().then(userId => console.log('OneSignal externalUserId', userId))
      // })
      OneSignal.push(function () {
        OneSignal.setExternalUserId(globalState.loggedInUser.id)
      })
    }
  }, [globalState.loggedInUser])

  if (!hasSetSession) {
    return (
      <Dimmer active>
        <Loader>Loading</Loader>
      </Dimmer>
    )
  }

  return (
    <div style={viewport}>
      <PrintProvider>
        <NoPrint>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router history={history}>
              <AppMenu history={history}>
                <Switch>
                  {routes.map((prop, key) => <Route path={prop.path} component={prop.component} key={key} />)}
                </Switch>
              </AppMenu>
            </Router>
            <Notification show={notification.show} header={notification.header} subheader={notification.subheader}
              type={notification.type} hideNotification={() => globalActions.notifications.hideNotification()} />
          </MuiPickersUtilsProvider>
        </NoPrint>
      </PrintProvider>
    </div>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))
