import React, { useEffect } from 'react'
import { Header, Item, Table, Segment, Divider } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'
import useGlobal from '../../Store'
import { dashboard } from '../../Theme/Styles'
import { RightMenuBar, RightMenuBarButton, RightMenuBarButtonIcon, RightMenuBarOptions } from '../../Theme/Components'

const GroupActivityLogsList = () => {
  const [globalState, globalActions] = useGlobal()
  const { groupActivityLogs } = globalState

  useEffect(() => {
    globalActions.groupActivityLogs.getGroupActivityLogs()
  }, [])

  return (
    <Segment>
      <RightMenuBar>
        <Header as='h3' style={dashboard.myProgramHeader}>Group Activity Logs</Header>
        <RightMenuBarOptions>
          <RightMenuBarButton as={NavLink} to={`/`}>
            <RightMenuBarButtonIcon name='close' style={{ fontSize: 20 }}/>
          </RightMenuBarButton>
        </RightMenuBarOptions>
      </RightMenuBar>

      <Divider/>
      <Table basic='very' striped size='small'>
        <Table.Body>
          {groupActivityLogs.map((log, key) => (
            <Table.Row key={key}>
              <Table.Cell
                width={3}>{new Date(log.date.split(" ").join("T")).getMonth() + 1 + '/' + (new Date(log.date.split(" ").join("T")).getDate()) + '/' + new Date(log.date.split(" ").join("T")).getFullYear()}</Table.Cell>
              <Table.Cell width={9}>{log.title}</Table.Cell>
              <Table.Cell width={4}><Item as={NavLink} to={`/group-logs/${log.id}/view`}
                                          style={{ color: '#2076BC' }}> VIEW </Item></Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Segment>
  )
}

export default GroupActivityLogsList
