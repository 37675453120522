import { Button, Divider, Grid, Header, Icon, Item, Responsive, Segment, Table } from 'semantic-ui-react'
import React, { useEffect, useState } from 'react'
import PieChartComponent from '../../Components/PieChart'
import styled from 'styled-components'
import useCampaignsService from '../../Services/Campaigns'
import {
  RightMenuBar, RightMenuBarButton, RightMenuBarButtonIcon, RightMenuBarOptions, StyledLogQuestionAnswer,
  StyledTable,
  StyledTableHeader,
  StyledTransparentButton
} from '../../Theme/Components'
import { dashboard } from '../../Theme/Styles'
import { NavLink } from 'react-router-dom'
import _ from 'lodash'
import { NoPrint } from 'react-easy-print'
import * as Survey from 'survey-react'
import useGlobal from '../../Store'
import { Typography } from '@material-ui/core'
import BarGraphSegment from '../../Components/BarGraphSegment'

const SurveyReportPage = (props) => {
  const [globalState] = useGlobal()
  const { currentState, currentDistrict, currentSchool } = globalState
  const { getSurvey, getSurveyPeerHelpers, getForecast } = useCampaignsService()

  const surveyId = props.match.params.surveyId || '0'
  const version = props.match.params.version || '0'

  const [survey, setSurvey] = useState({ id: 0, name: '', campaign: { id: '' } })
  const [peerHelpers, setPeerHelpers] = useState([])
  const [questions, setQuestions] = useState([])

  const [notComplete, setNotComplete] = useState(0)

  const [showLog, setShowLog] = useState()

  useEffect(() => {
    if (surveyId !== '0') {
      getSurvey(surveyId)
        .then((survey => {
          if (survey)
            setSurvey(survey)
        }))
    }
  }, [])

  useEffect(() => {
    if (survey.id) {
      getSurveyPeerHelpers(survey.id, version).then(data => setPeerHelpers(data))
      getForecast(surveyId, version).then(forecast => setNotComplete(forecast))

      const model = new Survey.Model(JSON.parse(survey.body))
      setQuestions(model.getAllQuestions())
      console.log("UE - " + notComplete);
    }
  }, [survey, currentState, currentDistrict, currentSchool])

  const isOrderedQuestion = question =>
    _.isArray(question.choices) && _.isArray(question.correctAnswer) && question.choices.length === question.correctAnswer.length

  const getAnswerColor = (question, answers, answer = null) => {
    if (!question.correctAnswer) return 'black'

    if (_.isArray(answers)) {
      if (isOrderedQuestion(question))
        return answers.findIndex(a => a === answer) === question.correctAnswer.findIndex(a => a === answer) ? 'green' : 'red'
      else
        return question.correctAnswer.includes(answer) ? 'green' : 'red'
    } else if (_.isObject(answers)) {
      return answers[answer] === question.correctAnswer[answer] ? 'green' : 'red'
    } else {
      return answers === question.correctAnswer ? 'green' : 'red'
    }
  }

  const showAnswers = (question) => {
    console.log('bac')
    try {
      const answers = JSON.parse(showLog.body)[question.name]
      const questionSimpleObject = JSON.parse(JSON.stringify(question))

      if (_.isArray(answers)) {
        if (question.propertyHash.name === 'question5') {
          return (
            <ol style={{ paddingLeft: 0 }}>
              {answers.map(answer => <li
                style={{ color: 'blue' }}>{answer}</li>)}
            </ol>
          )
        } else {
          return (
            <ol style={{ paddingLeft: 0 }}>
              {answers.map(answer => <li
                style={{ color: getAnswerColor(questionSimpleObject, answers, answer) }}>{answer}</li>)}
            </ol>
          )
        }
      } else if (_.isObject(answers)) {
        return (
          <ul style={{ paddingLeft: 0 }}>
            {Object.keys(answers).map((answer, index) => {
              return <li key={index} style={{ color: 'black' }}>{answer}<br/><span
                style={{ color: 'blue' }}>{answers[answer]}</span>
              </li>
            })}
          </ul>
        )
      } else {
        return (
          <p style={{ color: 'blue' }}>
            {question.getDisplayValue(true, answers)}
          </p>
        )
      }
    } catch (e) {
      console.log(e)
      return ''
    }
  }

  const renderPeerHelpersTable = () => (
    <StyledTable compact striped size="small">
      <Table.Header className="stickyTh">
        <Responsive as={Table.Row} minWidth={1000}>
          <Table.HeaderCell>
            Date
          </Table.HeaderCell>
          <Table.HeaderCell>
            Peer Helper
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center">
            Complete
          </Table.HeaderCell>
          <Table.HeaderCell/>
        </Responsive>
      </Table.Header>

      <Table.Body>
        {_.map(peerHelpers, (log) => {
          const { peerHelper: { id, firstName, lastName }, body, updatedAt, createdAt } = log

          return (
            <Table.Row key={id}>
              <Table.Cell>
                <Responsive as="strong" maxWidth={767} style={{ marginRight: 5 }}>
                  Date
                </Responsive>
                {new Date((updatedAt || createdAt)).toLocaleDateString('en', {
                  year: '2-digit',
                  month: 'numeric',
                  day: 'numeric'
                })}
              </Table.Cell>
              <Table.Cell>
                <Responsive as="strong" maxWidth={767} style={{ marginRight: 5 }}>
                  Peer Helper
                </Responsive>
                {(!!firstName && !!lastName) && firstName + ' ' + lastName}
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Responsive as="strong" maxWidth={767} style={{ marginRight: 5 }}>
                  Complete
                </Responsive>
                {!!body && <Icon name="check"/>}
              </Table.Cell>

              <Table.Cell textAlign="right">
                <NoPrint force>
                  {!!body && <Item as={Button} onClick={() => setShowLog(log)}
                                   style={{
                                     color: '#2076BC',
                                     backgroundColor: 'transparent',
                                     borderWidth: 0
                                   }}>
                    VIEW SURVEY
                  </Item>}
                </NoPrint>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </StyledTable>
  )

  const getVersionName = (numberOfCompletions) => {
    if (numberOfCompletions > 2) {
      return `Log ${parseInt(version) + 1}`
    } else {
      return version === '0' ? 'Pre-Survey' : 'Post-Survey'
    }
  }

  const renderQuestionsTable = () => {
    if (!peerHelpers.length) return null

    const newQuestions = _.cloneDeep(questions)
    peerHelpers.forEach(peerHelper => {
      const log = JSON.parse(peerHelper.body)

      Object.keys(log).forEach(questionName => {
        const questionIndex = newQuestions.findIndex(q => q.propertyHash.name === questionName)
        if (newQuestions[questionIndex].propertyHash.choices) {
          if (questionName === 'question2') {
            if (newQuestions[questionIndex].propertyHash.choices.length === 6) {
              newQuestions[questionIndex].propertyHash.choices.push({ propertyHash: { value: 'Multiracial' } })
            }

            if (log.question2.length > 1) {
              log.question2 = ['Multiracial']
            }
          }

          if (['question4', 'question5', 'question6', 'question7'].includes(questionName)) {
            if (!newQuestions[questionIndex].propertyHash.correctAnswers)
              newQuestions[questionIndex].propertyHash.correctAnswers = 0
            if (!newQuestions[questionIndex].propertyHash.wrongAnswers)
              newQuestions[questionIndex].propertyHash.wrongAnswers = 0
            if (!newQuestions[questionIndex].propertyHash.partialAnswers)
              newQuestions[questionIndex].propertyHash.partialAnswers = 0
            if (!newQuestions[questionIndex].propertyHash.totalAnswers)
              newQuestions[questionIndex].propertyHash.totalAnswers = 0
            newQuestions[questionIndex].propertyHash.totalAnswers += 1

            if(newQuestions[questionIndex].propertyHash.correctAnswer[0] === ("other"))
            newQuestions[questionIndex].propertyHash.correctAnswer.shift();

            if (
              log[questionName] === newQuestions[questionIndex].propertyHash.correctAnswer
              || (questionName === 'question4' && log[questionName].includes(newQuestions[questionIndex].propertyHash.correctAnswer))
              || (questionName !== 'question4' && newQuestions[questionIndex].propertyHash.correctAnswer.every(answer => log[questionName].includes(answer) || log[questionName] === answer) && newQuestions[questionIndex].propertyHash.correctAnswer.length === log[questionName].length)
            ) {
              newQuestions[questionIndex].propertyHash.correctAnswers += 1
            } else if (
              questionName !== 'question4' && newQuestions[questionIndex].propertyHash.correctAnswer.some(answer => log[questionName].includes(answer)
              || log[questionName] === answer)
            ) {
              newQuestions[questionIndex].propertyHash.partialAnswers += 1
            } else {
              newQuestions[questionIndex].propertyHash.wrongAnswers += 1
            }
          }

          if (_.isArray(log[questionName])) {
            log[questionName].forEach(answer => {
              const choiceIndex = newQuestions[questionIndex].propertyHash.choices.findIndex(choice => choice.propertyHash.value === answer)

              if (choiceIndex > -1) {
                if (!newQuestions[questionIndex].propertyHash.choices[choiceIndex].propertyHash?.answersCount?.[version]) {
                  const newArray = []
                  newArray[version] = 1
                  newQuestions[questionIndex].propertyHash.choices[choiceIndex].propertyHash.answersCount = newArray
                } else
                  newQuestions[questionIndex].propertyHash.choices[choiceIndex].propertyHash.answersCount[version] += 1
              }
            })
          } else {
            const choiceIndex = newQuestions[questionIndex].propertyHash.choices.findIndex(choice => choice.propertyHash.value === log[questionName])

            if (choiceIndex > -1) {
              if (!newQuestions[questionIndex].propertyHash.choices[choiceIndex].propertyHash?.answersCount?.[version]) {
                const newArray = []
                newArray[version] = 1
                newQuestions[questionIndex].propertyHash.choices[choiceIndex].propertyHash.answersCount = newArray
              } else
                newQuestions[questionIndex].propertyHash.choices[choiceIndex].propertyHash.answersCount[version] += 1
            }
          }
        }

        if (newQuestions[questionIndex].propertyHash.rows && !newQuestions[questionIndex].propertyHash.columns) {
          Object.keys(log[questionName]).forEach((answerKey) => {
            const rowIndex = newQuestions[questionIndex].propertyHash.rows.findIndex(choice => choice.propertyHash.value === answerKey)

            if (rowIndex > -1) {
              if (!newQuestions[questionIndex].propertyHash.rows[rowIndex].propertyHash?.answersCount?.[version]) {
                const newArray = []
                newArray[version] = 1
                newQuestions[questionIndex].propertyHash.rows[rowIndex].propertyHash.answersCount = newArray
              } else
                newQuestions[questionIndex].propertyHash.rows[rowIndex].propertyHash.answersCount[version] += 1
            }
          })
        }

        if (newQuestions[questionIndex].propertyHash.rows && newQuestions[questionIndex].propertyHash.columns) {
          Object.keys(log[questionName]).forEach((answerKey) => {
            const rowIndex = newQuestions[questionIndex].propertyHash.rows.findIndex(choice => choice.propertyHash.value === answerKey)
            const columnIndex = newQuestions[questionIndex].propertyHash.columns.findIndex(column => column.propertyHash.value === log[questionName][answerKey])
            if (rowIndex > -1 && columnIndex > -1) {
              if (!newQuestions[questionIndex].propertyHash.rows[rowIndex].propertyHash.answersCount) {
                newQuestions[questionIndex].propertyHash.rows[rowIndex].propertyHash.answersCount = []
              }
              if (!newQuestions[questionIndex].propertyHash.rows[rowIndex].propertyHash.answersCount[columnIndex]) {
                newQuestions[questionIndex].propertyHash.rows[rowIndex].propertyHash.answersCount[columnIndex] = []
              }

              if (!newQuestions[questionIndex].propertyHash.rows[rowIndex].propertyHash.answersCount?.[columnIndex][version]) {
                newQuestions[questionIndex].propertyHash.rows[rowIndex].propertyHash.answersCount[columnIndex][version] = 1
              } else
                newQuestions[questionIndex].propertyHash.rows[rowIndex].propertyHash.answersCount[columnIndex][version] += 1
            }
          })
        }
      })
    })

    return (
      <Segment key={peerHelpers.length}>
        {newQuestions.map((question, index) => (
          <div key={index}>
            <Typography style={{
              margin: '20px 0',
              fontWeight: 'bold',
              fontSize: 18
            }}>{`${index + 1}. ${question.fullTitle}`}</Typography>

            <StyledTable compact striped size="small">
              <Table.Header className="stickyTh">
                <Responsive as={Table.Row} minWidth={1000}>
                  <Table.HeaderCell style={{ width: '40%' }}>
                    Responses
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {getVersionName(survey.numberOfCompletions)}
                  </Table.HeaderCell>
                </Responsive>
              </Table.Header>

              <Table.Body>
                {question.propertyHash.choices?.map((choice, choiceIndex) => (
                  <Table.Row key={choiceIndex}>
                    <Table.Cell>
                      {!![3, 4, 5, 6].includes(index) ? (
                        <>
                          {!!((question.propertyHash.correctAnswer === choice.propertyHash.value) || (question.propertyHash.correctAnswer.includes(choice.propertyHash.value))) ? (
                            <p style={{ fontSize: 15, fontWeight: '700' }}>
                              {choice.propertyHash.value}
                            </p>
                          ) : (
                            choice.propertyHash.value
                          )}
                        </>
                      ) : (
                        choice.propertyHash.value
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {choice.propertyHash?.answersCount?.[version] || '-'}
                    </Table.Cell>
                  </Table.Row>
                ))}

                {question.propertyHash.rows && !question.propertyHash.columns && question.propertyHash.rows.map((row, rowIndex) => (
                  <Table.Row key={rowIndex}>
                    <Table.Cell>{row.propertyHash.value}</Table.Cell>
                    <Table.Cell>{row.propertyHash?.answersCount?.[version] || '-'}</Table.Cell>
                  </Table.Row>
                ))}

                {question.propertyHash.rows && question.propertyHash.columns && question.propertyHash.rows.map((row, rowIndex) => (
                  <>
                    <Table.Row key={rowIndex}>
                      <Table.Cell>{row.propertyHash.value}</Table.Cell>
                      <Table.Cell>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                          {question.propertyHash.columns.map((column, columnIndex) => (
                            <div key={columnIndex} style={{ textAlign: 'center' }}>
                              {column.propertyHash.value}<br/><br/>{row.propertyHash?.answersCount?.[columnIndex]?.[version] || '-'}
                            </div>
                          ))}
                        </div>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell colSpan={2} style={{ paddingTop: 20 }}>
                        <BarGraphSegment
                          data={question.propertyHash.columns.map((column, columnIndex) => ({
                            name: column.propertyHash.value,
                            total: row.propertyHash?.answersCount?.[columnIndex]?.[version] || 0
                          }))}
                          height={200}
                          dataKey="value"/>
                      </Table.Cell>
                    </Table.Row>
                  </>
                ))}
              </Table.Body>
            </StyledTable>

            {!![3, 4, 5, 6].includes(index) && (
              <PieChartComponent
                title={question.fullTitle}
                label="Correct / Incorrect answers"
                columns={[{
                  name: 'CORRECT',
                  percent: 100 - Math.round((question.propertyHash.partialAnswers + question.propertyHash.wrongAnswers) * 100 / question.propertyHash.totalAnswers) || 0
                }, {
                  name: 'PARTIALLY',
                  percent: Math.round(question.propertyHash.partialAnswers * 100 / question.propertyHash.totalAnswers) || 0
                }, {
                  name: 'INCORRECT',
                  percent: Math.round(question.propertyHash.wrongAnswers * 100 / question.propertyHash.totalAnswers) || 0
                }]}/>
            )}
          </div>
        ))}
      </Segment>
    )
  }

  return (
    <Grid>
      <Grid.Row style={{ paddingTop: 0 }}>
        <Grid.Column mobile={16} tablet={9} computer={survey.campaign.type !== 'PREVENTION' ? 11 : 16}>
          <Grid padded columns={2}>
            <Grid.Column style={{ paddingBottom: 0 }}>
              <StyledHeader as="h2"/>
            </Grid.Column>
          </Grid>

          <Grid padded>
            <Grid.Column computer={16} mobile={16} style={{ paddingTop: 0 }}>
              <StyledCardContent>
                {survey.campaign.type === 'PREVENTION' ? (
                  <PieChartComponent
                    title={(version === '0' ? 'Pre-' : 'Post-') + 'PATH Survey' + (survey.schoolLevels?.[0]?.name === 'ELEMENTARY' ? ' - Elementary School' : ' - Middle/High School')}
                    label="Survey Progress" columns={[{
                    name: 'COMPLETE',
                    percent: 100 - Math.floor(notComplete / (notComplete + peerHelpers.filter(p => !!p.body).length) * 100) || 0
                  }, {
                    name: 'NOT COMPLETE',
                    percent: Math.floor(notComplete / (notComplete + peerHelpers.filter(p => !!p.body).length) * 100) || 0
                  }]}/>
                ) : (
                  <PieChartComponent
                    title={`Peer Helper Survey ${(survey.schoolLevels?.[0]?.name === 'ELEMENTARY' ? ' - Elementary School' : ' - Middle/High School')}`}
                    label="Survey Progress" columns={[{
                    name: 'COMPLETE',
                    percent: Math.floor(peerHelpers.filter(p => !!p.body).length / peerHelpers.length * 100) || 0
                  }, {
                    name: 'NOT COMPLETE',
                    percent: 100 - Math.floor(peerHelpers.filter(p => !!p.body).length / peerHelpers.length * 100) || 0
                  }]}/>
                )}
              </StyledCardContent>
            </Grid.Column>

            <Grid.Column computer={16} mobile={16}>
              <StyledTableHeader>
                <Header as="h2" style={dashboard.phHeader}>
                  {survey.campaign.type === 'PREVENTION' ? (version === '0' ? 'Pre-' : 'Post-') + 'PATH Survey' + (survey.schoolLevels?.[0]?.name === 'ELEMENTARY' ? ' - Elementary School' : ' - Middle/High School')
                    : `Peer Helper Survey ${(survey.schoolLevels?.[0]?.name === 'ELEMENTARY' ? ' - Elementary School' : ' - Middle/High School')}`}
                </Header>
                {survey.campaign.type === 'PREVENTION' ? (
                  <StyledTransparentButton as={NavLink} to={`/surveys/${surveyId}/comparison`}>
                    View Comparison Report
                  </StyledTransparentButton>
                ) : (
                  <StyledTransparentButton as={NavLink} to={`/surveys/${surveyId}/analytics`}>
                    View Report
                  </StyledTransparentButton>
                )}
              </StyledTableHeader>
              {survey.campaign.type !== 'PREVENTION' ? renderPeerHelpersTable() : renderQuestionsTable()}
            </Grid.Column>
          </Grid>
        </Grid.Column>

        <Grid.Column mobile={16} tablet={7} computer={5}>
          {!!showLog && (
            <Segment>
              <RightMenuBar>
                <Header as="h3" style={{ marginTop: 10 }}>
                  {`${showLog.peerHelper.firstName} ${showLog.peerHelper.lastName} Answers`}
                </Header>

                <RightMenuBarOptions>
                  <RightMenuBarButton onClick={() => setShowLog(undefined)}>
                    <RightMenuBarButtonIcon name="close" style={{ fontSize: 20 }}/>
                  </RightMenuBarButton>
                </RightMenuBarOptions>
              </RightMenuBar>

              <Divider/>


              <Item.Group>
                {questions.map((question, key) => (
                  <Item key={key}>
                    <Item.Content>
                      <StyledSurveyQuestionHeader>{key + 1}. {question.fullTitle}</StyledSurveyQuestionHeader>
                      <StyledLogQuestionAnswer>{showAnswers(question)}</StyledLogQuestionAnswer>
                    </Item.Content>
                  </Item>
                ))}
              </Item.Group>
            </Segment>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

const StyledHeader = styled(Header)`
  size: 24px !important;
  font-weight: lighter;
  `

const StyledCardContent = styled(Segment)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 250px !important;
  align-items: stretch;
  `
const StyledSurveyQuestionHeader = styled(Item.Header)`
  font-size: 15px !important;
  color: black !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
`

export default SurveyReportPage
