import MatchTool from '../Pages/MatchTool'
import Guides from '../Pages/Guides'
import Profile from '../Pages/Profile'
import Dashboard from '../Pages/Dashboard'
import OnGoingSupportOverview from '../Pages/Overview/OnGoingSupportOverview'
import AtRiskReferralsReport from '../Pages/Overview/AtRiskReferralsReport'
import PeerHelpersOverview from '../Pages/Overview/PeerHelpersOverview'
import TopicsOverview from '../Pages/Overview/TopicsOverview'
import AsNeededSupportReport from '../Pages/Overview/AsNeededSupportReport'
import GroupActivitiesReport from '../Pages/Overview/GroupActivitiesReport'
import ProgramView from '../Pages/ProgramView'
import Login from '../Pages/Login'
import UsersPage from '../Pages/UsersManagement/Users'
import School from '../Pages/School'
import React, { useEffect } from 'react'
import { keycloack } from '../Config/ApiEndpoints'
import useGlobal from '../Store'
import { Dimmer, Loader } from 'semantic-ui-react'
import { useCookies } from 'react-cookie'
import LoginFinished from '../Pages/LoginFinished/LoginFinished'
import Archive from '../Pages/Archive'
import UsersReport from '../Pages/Reports/UsersReport/UsersReport'
import UsageReport from '../Pages/Reports/UsageReport/UsageReport'
import SurveyView from '../Pages/SurveyView/SurveyView'
import State from '../Pages/State'
import District from '../Pages/District'
import ImportPage from '../Pages/Import/Import'
import ImportSchoolsPage from '../Pages/ImportSchools/ImportSchools'
import ProgramsPage from '../Pages/Programs/Programs'
import SurveyCreator from '../Pages/SurveyCreator'
import Assists from '../Pages/Assists'
import CampaignsPage from '../Pages/Campaigns'
import CampaignSurveysPage from '../Pages/CampaignSurveys'
import SurveyReportPage from '../Pages/SurveyReport'
import SurveyTake from '../Pages/SurveyTake'
import SurveyAnalytics from '../Pages/SurveyAnalytics/SurveyAnalytics'
import SurveyComparationPage from '../Pages/SurveyComparation'
import CampaignReportPage from '../Pages/CampaignReport'
import CampaignAnalyticsPage from '../Pages/CampaignAnalytics'

const Logout = (props) => {
  const [, globalActions] = useGlobal()
  const [, , removeCookie] = useCookies()

  useEffect(() => {
    if (props.history.location.pathname === '/logout') {
      globalActions.users
        .logout()
        .then(() => {
          removeCookie('userSession')
          removeCookie('currentState')
          removeCookie('currentDistrict')
          removeCookie('currentSchool')
          window.location.href = keycloack
        })
        .catch(() => {
          window.location.href = keycloack
        })
    }
  }, [props.history.location.pathname])

  return (
    <Dimmer active>
      <Loader>Loading</Loader>
    </Dimmer>
  )
}

const routes = [
  { path: '/surveys/:id/comparison', name: 'Survey Comparison', component: SurveyComparationPage },
  { path: '/surveys/:id/analytics', name: 'Survey Analytics', component: SurveyAnalytics },
  {
    path: '/surveys/:id/external/take/versions/:version/schools/:schoolId?',
    name: 'Take Survey',
    component: SurveyTake
  },
  { path: '/surveys/:id/take/:logId/:version', name: 'Take Survey', component: SurveyTake },
  { path: '/surveys/:id/take', name: 'Take Survey', component: SurveyTake },
  { path: '/surveys/:id/edit', name: 'Survey Form', component: SurveyCreator },
  { path: '/surveys/:id/view', name: 'Surveys', component: SurveyView },
  {
    path: '/campaigns/:campaignId/surveys/:surveyId/reports/:version?',
    name: 'Survey Report',
    component: SurveyReportPage
  },
  { path: '/campaigns/:campaignId/surveys/:surveyId?', name: 'Survey', component: SurveyCreator },
  { path: '/campaigns/:campaignId/analytics', name: 'Campaign Report', component: CampaignAnalyticsPage },
  { path: '/campaigns/:campaignId/report', name: 'Campaign Report', component: CampaignReportPage },
  { path: '/campaigns/:campaignId/surveys', name: 'Surveys', component: CampaignSurveysPage },
  { path: '/campaigns/:id', name: 'Surveys', component: CampaignSurveysPage },
  { path: '/campaigns', name: 'Campaigns', component: CampaignsPage },
  { path: '/dropbox', name: 'Manage Programs', component: ProgramsPage },
  { path: '/import-hierarchy', name: 'Import', component: ImportSchoolsPage },
  { path: '/import', name: 'Import', component: ImportPage },
  { path: '/usage-report', name: 'Users Report', component: UsageReport },
  { path: '/users-report', name: 'Users Report', component: UsersReport },
  { path: '/users', name: 'Users', component: UsersPage },
  { path: '/Logout', name: 'Logout', component: Logout },
  { path: '/login-finished', name: 'Login', component: LoginFinished },
  { path: '/login', name: 'Login', component: Login },
  { path: '/help/:guideId?', name: 'Guides and Tutorials', component: Guides },
  { path: '/match', name: 'Match Tool', component: MatchTool },
  { path: '/assists/:action?/:id?', name: 'My Assists', component: Assists },
  { path: '/profile', name: 'My Profile', component: Profile },
  { path: '/archive', name: 'Admin folder', component: Archive },
  { path: '/heirarchy-management/schools/:id?/:action?', name: 'Schools', component: School },
  { path: '/heirarchy-management/state/:id?/:action?', name: 'State', component: State },
  { path: '/heirarchy-management/district/:id?/:action?', name: 'District', component: District },
  {
    path: '/peerHelpers', name: 'Peer Helpers', component: PeerHelpersOverview
  },
  { path: '/topics', name: 'Topics', component: TopicsOverview },
  {
    path: '/group-activities-report/:section?/:id?/:action?',
    name: 'Group Activities Report',
    component: GroupActivitiesReport
  },
  {
    path: '/programs/:programId?',
    name: 'Program View',
    component: ProgramView
  },
  {
    path: '/ongoing-overview/:section?/:id?/:action?/:extraId?',
    name: 'Ongoing Support Overview',
    component: OnGoingSupportOverview
  },
  {
    path: '/atrisk-referrals-report/:section?/:id?/:action?',
    name: 'At-Risk Referral Report',
    component: AtRiskReferralsReport
  },
  {
    path: '/as-needed-support-report/:section?/:id?/:action?',
    name: 'As-Needed Support Report',
    component: AsNeededSupportReport
  },
  {
    path: '/:section?/:id?/:action?/:extraId?',
    name: 'Dashboard',
    component: Dashboard
  }
]

export default routes
