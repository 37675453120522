import React, { useEffect, useLayoutEffect, useState } from 'react'
import useGlobal from '../../Store'
import { Button, Grid, Header, Icon, List, Responsive, Segment, Table } from 'semantic-ui-react'
import ContextualRightComponent from '../../Components/ContextualRightComponent'
import { NavLink } from 'react-router-dom'
import { StyledTable, StyledTableHeader, StyledTransparentButton } from '../../Theme/Components'
import { dashboard } from '../../Theme/Styles'
import { Link } from '../Import/Import'

const isMobile = window.innerWidth < 1000

const StatePage = (props) => {
  const [globalState, globalActions] = useGlobal()
  const { states } = globalState

  const [leftWidth, setLeftWidth] = useState(0)

  useLayoutEffect(() => {
    function updateSize () {
      setLeftWidth(window.innerWidth / (16 / 3))
    }

    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  
  useEffect(() => {
    globalActions.locations.getStates()
    if (window.performance) {
      if (performance.navigation.type === 1) {
        if (props.match.params.id) props.history.push('/heirarchy-management/state')
      }
    }
  }, [])
  
  const handleSelectState = async (idx, state) => {
    let stateData = Object.assign({}, state)
    stateData.isSelected = true
    props.history.push({ 
      pathname: `/heirarchy-management/state/${state.id}/edit`,
      state: { ...stateData, idx }
    })
  }
  
  return (
    <Grid>
      <Grid.Column mobile={16} computer={3}>
        <div style={window.innerWidth > 1000 ? {
          position: 'fixed',
          left: 0,
          top: 65,
          width: leftWidth
        } : {}}>
          <Segment>
            <List>
              <Link path="/heirarchy-management/state" currentPath={props.location.pathname}/>
              <Link path="/heirarchy-management/district" currentPath={props.location.pathname}/>
              <Link path="/heirarchy-management/schools" currentPath={props.location.pathname}/>
            </List>
          </Segment>
        </div>
      </Grid.Column>

      <Grid.Column
        mobile={16}
        tablet={9}
        computer={8}
        style={{ paddingTop: 0 }}
      >
        {states && states.length > 0 ? (
          <StyledTable compact striped size='small'>
            <Table.Header className="stickyTh">
              <Responsive as={Table.Row} minWidth={1000}>
                <Table.HeaderCell>
                  State
                </Table.HeaderCell>
                <Table.HeaderCell style={{ textAlign: 'right', paddingRight: 14 }}>
                  <StyledTransparentButton as={NavLink} to={`/heirarchy-management/state/0/add`}>
                    <Icon name="plus"/>
                  </StyledTransparentButton>
                </Table.HeaderCell>
              </Responsive>
            </Table.Header>
            <Table.Body>
              {states.map((state, index) => (
                <Table.Row key={index}>
                  <Table.Cell>
                    <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                      State:
                    </Responsive>
                    <a href="javascript: void(0)" onClick={() => handleSelectState(index, state)}
                       style={{ color: 'black' }}>
                      {state.name}
                    </a>
                  </Table.Cell>

                  <Table.Cell style={{ textAlign: 'right' }}>
                    <Button icon color='blue' circular size='mini' labelPosition={isMobile ? 'right' : undefined}
                            onClick={() => handleSelectState(index, state)}
                            style={isMobile ? { marginTop: '1rem' } : {}}>
                      <Responsive as='span' {...Responsive.onlyMobile}>Edit</Responsive> <Icon name='pencil alternate'/>
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </StyledTable>
        ) : (
          <Segment placeholder style={{ marginTop: 0 }}>
            <StyledTransparentButton as={NavLink} to={`/heirarchy-management/state/0/add`}
                                     style={{ position: 'absolute', top: '10px', right: '10px' }}>
              <Icon name="plus"/>
            </StyledTransparentButton>
            <Header as="h2" icon color="grey">
              State
              <Header.Subheader>No state found</Header.Subheader>
            </Header>
          </Segment>
        )}
      </Grid.Column>

      <Grid.Column mobile={16} tablet={7} computer={5}>
        <ContextualRightComponent {...props} width={16 / 5}/>
      </Grid.Column>
    </Grid>
  )
}

export default React.memo(StatePage)
