import React from 'react'
import { Grid, Header, Icon, Segment } from 'semantic-ui-react'
import { dashboard } from '../../../Theme/Styles'
import { NavLink } from 'react-router-dom'

const AsNeededSupportPeerHelperStatistics = ({ asNeededSupport }) => {
  return (
    <Segment>
      <Header as='h2' style={{ ...dashboard.phHeader, paddingBottom: 15, paddingTop: 0 }}>As-Needed Support</Header>
      <Grid rows={2}>
        <Grid.Row columns={2} style={{ backgroundColor: '#753CBE', padding: '1rem' }}>
          <Grid.Column textAlign='center'>
            <Icon name='users' size='massive' style={{ color: 'white' }} />
          </Grid.Column>
          <Grid.Column textAlign='center'>
            <Header style={dashboard.phCount}>
              {asNeededSupport.length}
              <Header.Subheader style={{ color: 'white' }}>Total Support Logs</Header.Subheader>
            </Header>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={3}>
          <Grid.Column floated='right'>
            <NavLink to={`/support-logs`} style={{ color: '#2076BC', marginRight: '1rem' }}>VIEW</NavLink>
            <NavLink to={`/support-logs/0/edit`} style={{ color: '#2076BC' }}>ADD LOG</NavLink>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

export default AsNeededSupportPeerHelperStatistics
