import axios from 'axios'
import endpoints from '../Config/ApiEndpoints'
import { processRequestErrorNotification } from '../Utils/Error'
import { editedAssist as defaultEditedAssis } from '../Config/DefaultState'
import { getResourceFromArchive } from './Archive'

export const getAssists = async (store) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  const { currentState, currentDistrict, currentSchool } = store.state

  let search = `/search?search=`
  if (currentSchool !== '') {
    search += `schoolId:${currentSchool}`
  } else if (currentDistrict !== '') {
    search += `districtId:${currentDistrict}`
  } else if (currentState !== '') {
    search += `stateId:${currentState}`
  }
  const url = endpoints.logs.assists + search

  store.setState({ requestStatus: 'LOADING' })
  try {
    const response = await getResourceFromArchive(store, url, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
    const { logs } = response.data._embedded

    store.setState({ requestStatus: 'SUCCESS', assists: logs })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ asNeededLogs: [], requestStatus: 'FAILED', notification })
  }
}

export const getAssist = async (store, assistId) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  store.setState({ requestStatus: 'LOADING' })
  try {
    const response = await getResourceFromArchive(store, endpoints.logs.assists + '/' + assistId, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
    const editedAssist = response.data

    store.setState({ requestStatus: 'SUCCESS', editedAssist })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ editedAssist: defaultEditedAssis, requestStatus: 'FAILED', notification })
  }
}

export const saveAssist = async (store, log) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  let url = endpoints.logs.assists
  store.setState({ requestStatus: 'LOADING' })

  try {
    if (log.id === '') {
      await axios.post(url, {
        ...log,
        schoolId: store.state.currentSchool
      }, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
    } else {
      await axios.patch(url + '/' + log.id, log, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
    }
    const notification = { type: 'success', header: `Assist ${log.id === '' ? 'added' : 'updated'}` }
    setTimeout(() => {
      store.setState({ requestStatus: 'SUCCESS', notification })
    }, 500)
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ requestStatus: 'FAILED', notification })
  }
}

export const deleteAssist = async (store, assistId) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  store.setState({ requestStatus: 'LOADING' })

  try {
    await axios.delete(endpoints.logs.assists + '/' + assistId, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })

    store.setState({ requestStatus: 'SUCCESS' })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ requestStatus: 'FAILED', notification })
  }
}
