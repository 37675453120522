import React, { useLayoutEffect, useState } from 'react'
import StudentForm from '../Dashboard/StudentForm'
import StudentView from '../Dashboard/StudentView'
import OnGoingSupportLogForm from '../Dashboard/OnGoingSupportLogForm'
import OnGoingSupportLog from '../Dashboard/OngoingSupportLog'
import StudentReport from '../Dashboard/StudentReport'
import AsNeededSupportLogForm from '../Dashboard/AsNeededSupportLogForm'
import AsNeededSupportLog from '../Dashboard/AsNeededSupportLog'
import AsNeededSupportLogsList from '../Dashboard/AsNeededSupportLogsList'
import GroupActivityLogForm from '../Dashboard/GroupActivityLogForm'
import GroupActivityLog from '../Dashboard/GroupActivityLog'
import GroupActivityLogsList from '../Dashboard/GroupActivityLogsList'
import School from '../Dashboard/School'
import SchoolForm from '../Dashboard/AddForm'
import StateForm from '../Dashboard/StateForm'
import DistrictForm from '../Dashboard/DistrictForm'
import AssessmentForm from '../Dashboard/AssessmentForm'
import AtRiskReferralsLog from '../Dashboard/AtRiskReferralsLog'
import MyProgram from '../Dashboard/MyProgram'
import useGlobal from '../../Store'
import MyProgramSuperUser from '../Dashboard/MyProgramSuperUser'
import AssessmentLog from '../Dashboard/AssessmentLog'
import AssistForm from '../../Pages/Assists/parts/AssistForm'
import AssistView from '../../Pages/Assists/parts/AssistView'

const ContextualRightComponent = (props) => {
  const [globalState] = useGlobal()
  const { role } = globalState.loggedInUser
  const { section, action } = props.match.params

  const [width, setWidth] = useState(0)

  useLayoutEffect(() => {
    function updateSize () {
      setWidth(window.innerWidth / (props.width ? props.width : (16 / 5)))
    }

    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [props.location.pathname])

  const sideComponentName = () => {
    if (props.location.pathname.indexOf('/school') > -1) {
      switch (action) {
        case 'view':
          return {
            component: <School {...props} />,
            fixed: true
          }
        case 'add':
          return {
            component: <SchoolForm {...props} />,
            fixed: true
          }
        case 'edit':
          return {
            component: <SchoolForm {...props} />,
            fixed: true
          }
        default:
          return {
            component: <SchoolForm {...props} />,
            fixed: true
          }
      }
    } else if (props.location.pathname.indexOf('/district') > -1) {
      switch (action) {
        case 'edit':
          return {
            component: <DistrictForm {...props} />,
            fixed: true
          }
        case 'add':
          return {
            component: <DistrictForm {...props} />,
            fixed: true
          }
        default:
          return {
            component: <DistrictForm {...props} />,
            fixed: true
          }
      }
    } else if (props.location.pathname.indexOf('/state') > -1) {
      switch (action) {
        case 'edit':
          return {
            component: <StateForm {...props} />,
            fixed: true
          }
        case 'add':
          return {
            component: <StateForm {...props} />,
            fixed: true
          }
        case 'view':
          return {
            component: <StateForm {...props} />,
            fixed: true
          }

        default:
          return {
            component: <div/>,
            fixed: false
          }
      }
    } else if (props.location.pathname.indexOf('/assists') > -1) {
      switch (action) {
        case 'edit':
        case 'add':
          return {
            component: <AssistForm {...props} />,
            fixed: false
          }
        case 'view':
          return {
            component: <AssistView {...props} />,
            fixed: false
          }
        default:
          return {
            component: <div/>,
            fixed: false
          }
      }
    } else {
      switch (section) {
        case 'students':
          switch (action) {
            case 'edit':
              return {
                component: <StudentForm {...props} />,
                fixed: false
              }
            case 'report':
              return {
                component: <StudentReport {...props} />,
                fixed: false
              }
            case 'view':
              return {
                component: <StudentView {...props} />,
                fixed: false
              }
          }
          break

        case 'continual-support':
          switch (action) {
            case 'edit':
              return {
                component: <OnGoingSupportLogForm {...props} />,
                fixed: false
              }
            case 'view':
              return {
                component: <OnGoingSupportLog {...props} />,
                fixed: false
              }
          }
          break

        case 'support-logs':
          switch (action) {
            case 'edit':
              return {
                component: <AsNeededSupportLogForm {...props} />,
                fixed: false
              }
            case 'view':
              return {
                component: <AsNeededSupportLog {...props} />,
                fixed: false
              }
            default:
              return {
                component: <AsNeededSupportLogsList {...props} />,
                fixed: false
              }
          }

        case 'atrisk-referrals':
          switch (action) {
            case 'view':
              return {
                component: <AtRiskReferralsLog {...props} />,
                fixed: false
              }
          }
          break

        case 'group-logs':
          switch (action) {
            case 'edit':
              return {
                component: <GroupActivityLogForm {...props} />,
                fixed: false
              }
            case 'view':
              return {
                component: <GroupActivityLog {...props} />,
                fixed: false
              }
            default:
              return {
                component: <GroupActivityLogsList {...props} />,
                fixed: false
              }
          }

        case 'assessments':
          switch (action) {
            case 'view':
              return {
                component: <AssessmentLog {...props} />,
                fixed: false
              }
            default:
              return {
                component: <AssessmentForm {...props} />,
                fixed: false
              }
          }

        default:
          if (props.match.url === '/' && typeof props.noProgram === 'undefined') {
            if (role === 'Super User') {
              return {
                component: <MyProgramSuperUser {...props} />,
                fixed: false
              }
            } else if (role !== 'Peer Helper') {
              return {
                component: <MyProgram {...props} />,
                fixed: false
              }
            } else {
              return {
                component: <div/>,
                fixed: false
              }
            }
          } else {
            return {
              component: <div/>,
              fixed: false
            }
          }
      }
    }
  }

  const { component, fixed } = sideComponentName()

  return (
    <div style={(width && window.innerWidth > 1000 && fixed) ? {
      position: 'fixed',
      right: 0,
      top: 65,
      width
    } : {}}>
      {component}
    </div>
  )
}

export default ContextualRightComponent
