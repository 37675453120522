import React, { useEffect, useState } from 'react'
import { Header, Segment, Divider, Item } from 'semantic-ui-react'
import {
  emotionalStateOptions,
  highRiskBehaviorsOptions,
  socialSkillsOptions
} from '../../Config/SelectOptions'
import useGlobal from '../../Store'
import useStringOperations from '../../Utils/StringOperations'

import { NavLink } from 'react-router-dom'
import useUrlBuilder from '../../Utils/Links'
import { editedAssessment } from '../../Config/DefaultState'
import {
  RightMenuBar,
  RightMenuBarButton,
  RightMenuBarButtonIcon,
  RightMenuBarOptions,
  StyledLogQuestionAnswer,
  StyledLogQuestionHeader
} from '../../Theme/Components'
import { dashboard } from '../../Theme/Styles'

const AssessmentLog = (props) => {
  const studentId = props.match.params.id || '0'
  const assessmentType = props.match.params.extraId || 'initialAssessment'
  const [globalState, globalActions] = useGlobal()
  const { loggedInUser } = globalState
  const [student, setStudent] = useState({ ...globalState.editedStudent })
  const [assessment, setAssessment] = useState({ ...editedAssessment })
  const [makeUrl] = useUrlBuilder(props.match.url)
  const stringOperations = useStringOperations()

  useEffect(() => {
    if (studentId !== '0') {
      globalActions.students.getStudent(studentId)
    }
  }, [])

  useEffect(() => {
    setStudent({ ...globalState.editedStudent })
  }, [globalState.editedStudent])

  useEffect(() => {
    if (student !== undefined && student[assessmentType] !== undefined && student[assessmentType].id) {
      const { date } = student[assessmentType]
      const dateObject = date !== '' ? new Date(date.split(' ').join('T')) : new Date()
      setAssessment({ ...student[assessmentType], date: dateObject, time: dateObject })
    }
  }, [student, assessmentType])

  let pageTitle = ''
  switch (assessmentType) {
    case 'initialAssessment':
      pageTitle = 'Initial Assessment'
      break
    case 'midpointAssessment':
      pageTitle = 'Midpoint Assessment'
      break
    default:
      pageTitle = 'Final Assessment'
  }

  return (

    <Segment>
      <RightMenuBar>
        <Header as='h3' style={dashboard.myProgramHeader}>{pageTitle}</Header>
        <RightMenuBarOptions>
          <React.Fragment>
            {['Peer Helper', 'Coordinator'].includes(loggedInUser.role) && (
              <RightMenuBarButton as={NavLink} to={makeUrl(`/assessments/${studentId}/${assessmentType}`)}>
                <RightMenuBarButtonIcon name='edit' />
              </RightMenuBarButton>
            )}
          </React.Fragment>
          <RightMenuBarButton as={NavLink} to={makeUrl('/')}>
            <RightMenuBarButtonIcon name='close' style={{ fontSize: 20 }} />
          </RightMenuBarButton>
        </RightMenuBarOptions>
      </RightMenuBar>

      <Divider />

      <Item.Group>
        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>1. What was the date of {pageTitle.split(' ')[0]} assigned student session?</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{ new Date(assessment.date).toLocaleDateString('en', {
              year: '2-digit',
              month: 'numeric',
              day: 'numeric'
            })}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>2. What was the time of the {pageTitle.split(' ')[0]} assigned student session?</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{new Date(assessment.date).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>3. Thinking about the SOCIAL SKILLS of this student, which group of words BEST describes them?</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{stringOperations.translate(socialSkillsOptions, assessment.socialSkills)}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>4. Thinking about the EMOTIONAL STATE of this student, which group of words BEST describes them?</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{stringOperations.translate(emotionalStateOptions, assessment.emotionalState)}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>5. Thinking about the HIGH RISK BEHAVIORS (consumes alcohol, uses illegal drugs, abuses prescription pills, self-harm, abusive behaviors or
              hears voices), which of the following BEST describes the student?</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{stringOperations.translate(highRiskBehaviorsOptions, assessment.riskBehavior)}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>6. Referred to a Program Coordinator / School Counselor:</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{assessment.referred ? 'Yes' : 'No'}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

      </Item.Group>
    </Segment>
  )
}

export default AssessmentLog
