import React, { useEffect, useState } from 'react'
import {
  Button,
  Divider,
  Form,
  Header,
  Segment,
  Modal,
} from 'semantic-ui-react'
import useGlobal from '../../Store'
import {
  RightMenuBar,
  RightMenuBarButton,
  RightMenuBarButtonIcon,
  RightMenuBarOptions,
  StyledTransparentButton,
} from '../../Theme/Components'
import { dashboard } from '../../Theme/Styles'

const StateForm = (props) => {
  const stateId = props.match.params.id || '0'
  const [isEdit, setIsEdit] = useState(false)

  const [globalState, globalActions] = useGlobal()
  const [state, setState] = useState({ ...globalState.editState })
  const [modalOpen, setModalOpen] = useState(false)

  // const [validForm, validationErrors, onFieldChangeValidation] = useValidation(requiredFields, states)

  useEffect(() => {
    let stateData =
      props.location && props.location.state ? props.location.state : null
    globalActions.locations.handleEditState(stateData)
    setIsEdit(false)
  }, [stateId])

  useEffect(() => {
    setState({ ...globalState.editState })
  }, [globalState.editState])

  const onStateValueChange = (e) => {
    const { name, value } = typeof e.target !== 'undefined' ? e.target : e
    setState({ ...state, [name]: value })
  }

  const saveState = async (from = null) => {
    if (state && state.name && state.name.trim()) {
      let response = false
      if (from) {
        response = await globalActions.locations.handleDeleteStateAPI(state)
      } else {
        if (stateId === '0') {
          response = await globalActions.locations.handleSaveState(state)
        } else if (stateId !== '0') {
          response = await globalActions.locations.handleEditStateAPI(state)
        }
      }
      response && props.history.push('/heirarchy-management/state')

      setTimeout(() => {
        globalActions.locations.getStates()
      }, 1000)
    } else {
      globalActions.notifications.showNotification({
        type: 'warning',
        header: 'Form validation error',
        subheader: 'Please complete all required fields!',
      })
    }
  }

  const handleStateModalClose = () => {
    globalActions.locations.handleEditState()
    props.history.push('/heirarchy-management/state')
  }

  return (
    <Segment>
      <RightMenuBar>
        <Header as="h3" style={dashboard.myProgramHeader}>
          {stateId !== '0' ? 'Edit' : 'Add New'} State
        </Header>
        <RightMenuBarOptions>
          {stateId !== '0' && (
            <>
              <RightMenuBarButton onClick={() => setIsEdit(true)}>
                <RightMenuBarButtonIcon
                  name="pencil"
                  style={{ fontSize: 15 }}
                />
              </RightMenuBarButton>

              <RightMenuBarButton onClick={() => setModalOpen(true)}>
                <RightMenuBarButtonIcon name="trash" style={{ fontSize: 15 }}/>
              </RightMenuBarButton>
            </>
          )}

          <RightMenuBarButton onClick={() => handleStateModalClose()}>
            <RightMenuBarButtonIcon
              name="close"
              style={{ fontSize: stateId !== '0' ? 15 : 20 }}
            />
          </RightMenuBarButton>
        </RightMenuBarOptions>
      </RightMenuBar>

      <Divider/>
      <Form>
        <Form.Group className="custom-form-group">
          <label className="small-label">State</label>
          <Form.Input
            name="name"
            placeholder="Enter state name"
            value={state ? state.name : ''}
            onChange={(e, data) => onStateValueChange(data)}
            fluid
            disabled={stateId !== '0' && !isEdit ? true : false}
          />
        </Form.Group>
        <div style={{ textAlign: 'right' }}>
          <Button.Group>
            <StyledTransparentButton
              disabled={stateId !== '0' && !isEdit ? true : false}
              onClick={() => saveState()}
            >
              {stateId !== '0' ? 'Edit' : 'Add'} State
            </StyledTransparentButton>
          </Button.Group>
        </div>
      </Form>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)} closeIcon>
        <Modal.Header>Delete State</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete this State ?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setModalOpen(false)}>
            No
          </Button>
          <Button
            positive
            icon="checkmark"
            labelPosition="right"
            content="Yes"
            onClick={() => saveState('delete')}
          />
        </Modal.Actions>
      </Modal>
    </Segment>
  )
}

export default React.memo(StateForm)
