import React from 'react'
import {
  PieChart, Pie, Cell, ResponsiveContainer
} from 'recharts'
import styled from 'styled-components'
import { Segment, Header } from 'semantic-ui-react'
import { lifeExperiencesOptions } from '../Config/SelectOptions'

const PieChartComponent = (props) => {
  const COLORS = ['#753CBE', '#0088FE', '#3CBEB9', '#F58127', '#BE3CA5']

  const data = props.columns.map(topic => {
    return {
      name: topic.name,
      value: topic.percent
    }
  })

  const columns = props.columns.map((value, index) => {
    return (
      <StyledProperty key={index}>
        <StyledText>{index + 1}. {lifeExperiencesOptions.find(option => value.name === option.value)?.text || value.name} </StyledText>
        <StyledCount>{value.percent}%</StyledCount>
      </StyledProperty>
    )
  })

  return (
    <StyledDiv>
      <StyledResponsiveContainer width='25%' height={300}>
        <PieChart>
          <Pie data={data} cx='50%' cy='60%' labelLine={false} outerRadius='75%' fill='#753CBE' dataKey='value'>
            {data.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} key={index} />)}
          </Pie>
        </PieChart>
      </StyledResponsiveContainer>

      <StyledSegment>
        <StyledHeader>{props.title}</StyledHeader>
        <StyledParagraph> {props.label || 'Top Topics:'} </StyledParagraph>
        {columns}
      </StyledSegment>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-around;
`

const StyledResponsiveContainer = styled(ResponsiveContainer)`
  .recharts-surface {
      margin-top: -4.5rem !important;
  }
`

const StyledSegment = styled(Segment)`
  display: flex !important;
  flex-direction: column !important;
  width: 50% !important;
  align-items: stretch !important;
  box-shadow: none !important;
  border: none !important;
`
const StyledProperty = styled.div`
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  width: 300px;
  border-bottom: 2px solid lightgray !important;
  height: 22px !important;
`

const StyledCount = styled.p`
color: #753CBE !important;
font-family: Roboto !important;
`

const StyledText = styled.p`
  width: 10rem !important;
`

const StyledHeader = styled(Header)`
 color: #753CBE !important; 
 font-size: 24px !important;
  fontWeight: 0 !important; 
`

const StyledParagraph = styled.p`
color: gray !important;
font-size: 14px !important;
`

export default PieChartComponent
