import React, { useState, useEffect } from 'react'
import { Button, Grid, Header, Icon, Table } from 'semantic-ui-react'
import { StyledTableHeader } from '../../Theme/Components'
import readXlsxFile from 'read-excel-file'
import useGlobal from '../../Store/index'
import { schoolLevels, gradeOptions } from '../../Config/SelectOptions'
import axios from 'axios'
import endpoints from '../../Config/ApiEndpoints'

const ImportSchoolsPage = () => {
  const [globalState, globalActions] = useGlobal()

  const [file, setFile] = useState(undefined)
  const [rows, setRows] = useState([])
  const [status, setStatus] = useState([''])
  const [processing, setProcessing] = useState(false)

  const _readXlsx = (file) => {
    readXlsxFile(file).then((rows) => setRows(rows))
  }

  const _readCsv = (file) => {
    file.text()
      .then(text => {
        const lines = text.split('\r\n')

        let rows = []
        for (let i = 0; i < lines.length; i++) {
          if (lines[i]) {
            rows.push(lines[i].split(','))
          }
        }

        setRows(rows)
      })
  }

  const _readFile = (file) => {
    if (!file)
      return

    const extension = file.name.split('.').pop()

    if (extension.toLowerCase() === 'csv') {
      _readCsv(file)
    } else if (extension.toLowerCase() === 'xlsx') {
      _readXlsx(file)
    } else {
      alert('Invalid file, please upload a valid CSV or XLSX file.')
      setFile(undefined)
    }
  }

  const _startProcessing = async () => {
    setProcessing(true)

    const formData = new FormData()
    formData.append('file', file)

    const response = await axios.post(endpoints.archive.hierarchy, formData, { headers: { Authorization: `Bearer ${globalState.loggedInUser.accessToken}` } })

    const oldStatuses = [...status]
    response.data.map((data, index) => {
      oldStatuses[index + 1] = !data.errorMessage ? '' : data.errorMessage
    })
    setStatus(oldStatuses)

    setProcessing(false)
  }

  const _getIcon = (index) => {
    if (status[index] === undefined && !processing) {
      return <Icon name={'wait'}/>
    } else if (status[index] === undefined && processing) {
      return <Icon loading={processing} name={processing ? 'spinner' : 'wait'}/>
    } else if (status[index] === '') {
      return <Icon name="check"/>
    } else if (status[index] !== undefined && status[index] !== '') {
      return <Icon name="ban"/>
    }
  }

  useEffect(() => {
    _readFile(file)
  }, [file])

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} computer={16}>
            <StyledTableHeader>
              <Header as='h2'>Import Hierarchy</Header>
            </StyledTableHeader>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Button onClick={() => document.getElementById('file-picker').click()} color="purple"
                      style={{ marginLeft: 15 }}>
                Pick File
              </Button>
              <input style={{ display: 'none' }} type="file" id="file-picker"
                     onChange={(input) => setFile(input.currentTarget.files[0])}/>

              <div style={{
                width: 300,
                padding: 10,
                backgroundColor: 'rgba(0,0,0,0.2)',
                borderRadius: 5,
                textAlign: 'center',
                marginRight: 10,
                marginLeft: 10
              }}>
                {file !== undefined ? file.name : 'No file picked'}
              </div>

              {rows.length > 0 && (
                <Button icon={processing} onClick={_startProcessing} color="blue" disabled={processing}>
                  Process File
                </Button>
              )}
            </div>
          </Grid.Column>
        </Grid.Row>

        {rows.length > 0 && (
          <Grid.Row>
            <Grid.Column>
              <Table>
                <Table.Header className="stickyTh">
                  <Table.Row key={0}>
                    {rows[0].map((title, key) => (
                      <Table.HeaderCell style={{ textTransform: 'capitalize' }} key={key + 1}>
                        {title.replace(/([a-z0-9])([A-Z])/g, '$1 $2')}
                      </Table.HeaderCell>
                    ))}
                    <Table.HeaderCell key={0} style={{ textAlign: 'center' }}>Status</Table.HeaderCell>
                    <Table.HeaderCell key={status}/>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {rows.map((row, index) => {
                    if (index === 0) return

                    return (
                      <Table.Row key={index + 1}>
                        {row.map((cell, cellKey) => (
                          <Table.Cell key={cellKey + 'i' + index}>{cell}</Table.Cell>
                        ))}
                        <Table.Cell key={'statusCell' + 'i' + index} style={{ textAlign: 'center' }}>
                          {_getIcon(index)}
                        </Table.Cell>
                        <Table.Cell key={'message' + 'i' + index}>
                          {status[index]}
                        </Table.Cell>
                      </Table.Row>
                    )
                  })}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </>
  )
}

export default ImportSchoolsPage
