import useCampaignsService from '../../Services/Campaigns'
import React, { useEffect, useState } from 'react'
import * as Survey from 'survey-react'
import { VisualizationPanel } from 'survey-analytics'

const CampaignReportPage = ({ match }) => {
  const { getCampaignSurveys, getSurveyAllAnswers } = useCampaignsService()

  const [campaignId] = useState(match.params.campaignId || '0')

  const [data, setData] = useState([])
  const [surveys, setSurveys] = useState([])

  useEffect(() => {
    if (campaignId !== '0') {
      getCampaignSurveys(campaignId).then(surveys => {
        if (surveys.length) {
          const newSurveys = [...surveys.map(s => JSON.parse(s.body))]

          newSurveys.forEach((survey, index) => {
            if (index > 0) {
              newSurveys[0].pages.forEach((page, pageIndex) => {
                newSurveys[0].pages[pageIndex].elements.forEach((element, elementIndex) => {
                  if (newSurveys[0].pages[pageIndex].elements[elementIndex].choices)
                    newSurveys[0].pages[pageIndex].elements[elementIndex].choices = [...newSurveys[0].pages[pageIndex].elements[elementIndex].choices, ...survey.pages[pageIndex].elements[elementIndex].choices].filter((v, i, a) => a.indexOf(v) === i)
                  if (newSurveys[0].pages[pageIndex].elements[elementIndex].columns)
                    newSurveys[0].pages[pageIndex].elements[elementIndex].columns = [...newSurveys[0].pages[pageIndex].elements[elementIndex].columns, ...survey.pages[pageIndex].elements[elementIndex].columns].filter((v, i, a) => a.indexOf(v) === i)
                  if (newSurveys[0].pages[pageIndex].elements[elementIndex].rows)
                    newSurveys[0].pages[pageIndex].elements[elementIndex].rows = [...newSurveys[0].pages[pageIndex].elements[elementIndex].rows, ...survey.pages[pageIndex].elements[elementIndex].rows].filter((v, i, a) => a.indexOf(v) === i)
                })
              })
            }
          })

          setSurveys(surveys.map((s, i) => ({ ...s, body: newSurveys[i] })))
        }
      })
    }
  }, [campaignId])

  useEffect(() => {
    if (surveys.length) {
      (async () => {
        let newData = []

        for (let i = 0; i < surveys.length; i += 1) {
          const dataResponse = await getSurveyAllAnswers(surveys[i].id)

          if (dataResponse) {
            newData = [...newData, ...dataResponse.map(o => JSON.parse(o))]
          }
        }

        setData(newData)
      })()
    }
  }, [surveys])

  useEffect(() => {
    if (surveys.length && surveys[0] && surveys[0].body && data) {
      const model = new Survey.Model(surveys[0].body)

      const visPanel = new VisualizationPanel(model.getAllQuestions(), data)
      visPanel.showHeader = true
      visPanel.render(document.getElementById('summaryContainer'))
    }
  }, [data])

  return <div id="summaryContainer"/>
}

export default CampaignReportPage
