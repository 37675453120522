import axios from 'axios'
import { baseUrl } from '../Config/ApiEndpoints'
import useGlobal from '../Store'

const useCampaignsService = () => {
  const [globalState] = useGlobal()
  const { loggedInUser: { accessToken }, currentState, currentDistrict, currentSchool } = globalState

  const getCampaigns = async () => {
    try {
      const { data: { _embedded: { campaigns } } } = await axios.get(`${baseUrl}/campaigns`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      return campaigns || []
    } catch (e) {
      console.log(e)
      return []
    }
  }

  const saveCampaign = async (campaign) => {
    try {
      campaign.id ? await axios.patch(`${baseUrl}/campaigns/${campaign.id}`, campaign) : await axios.post(`${baseUrl}/campaigns`, campaign, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  const getCampaign = async campaignId => {
    try {
      const { data } = await axios.get(`${baseUrl}/campaigns/${campaignId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      return data
    } catch (e) {
      console.log(e)
    }
  }

  const deleteCampaign = async campaign => {
    try {
      await axios.delete(`${baseUrl}/campaigns/${campaign.id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  const getCampaignSurveys = async (campaignId) => {
    try {
      const { data: { _embedded: { surveys } } } = await axios.get(`${baseUrl}/campaigns/${campaignId}/surveys`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      return surveys || []
    } catch (e) {
      console.log(e)
      return []
    }
  }

  const getSurvey = async (surveyId) => {
    try {
      const { data } = await axios.get(`${baseUrl}/surveys/${surveyId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      return data
    } catch (e) {
      console.log(e)
    }
  }

  const saveSurvey = async (campaignId, body, schoolLevels) => {
    try {
      await axios.post(`${baseUrl}/campaigns/${campaignId}/surveys`, {
        id: null,
        name: body.title,
        schoolLevels,
        body: JSON.stringify(body)
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  const updateSurvey = async (campaignId, surveyId, body) => {
    try {
      await axios.patch(`${baseUrl}/campaigns/${campaignId}/surveys/${surveyId}`, {
        id: surveyId,
        name: body.title,
        body: JSON.stringify(body)
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  const deleteSurvey = async surveyId => {
    try {
      await axios.delete(`${baseUrl}/surveys/${surveyId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  const sendSurvey = async (survey, schools, peerHelpers, version) => {
    try {
      await axios.post(`${baseUrl}/campaigns/${survey.campaign.id}/surveys/${survey.id}/logs/${version}`, schools.length ? { schools } : { peerHelpers }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  const getSurveyAllAnswers = async (surveyId) => {
    try {
      let search = ''
      if (currentSchool) {
        search = `/search?search=schoolId:${currentSchool}`
      } else if (currentDistrict) {
        search = `/search?search=districtId:${currentDistrict}`
      } else if (currentState) {
        search = `/search?search=stateId:${currentState}`
      }

      const { data: { _embedded: { stringList } } } = await axios.get(`${baseUrl}/surveys/${surveyId}/logs/answers${search}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })

      return stringList
    } catch (e) {
      // console.log(e)
      return []
    }
  }
  const getSurveyPeerHelpers = async (surveyId, version = '0') => {
    try {
      let search = `/search?search=version:${version}`
      if (currentSchool) {
        search += `,schoolId:${currentSchool}`
      } else if (currentDistrict) {
        search += `,districtId:${currentDistrict}`
      } else if (currentState) {
        search += `,stateId:${currentState}`
      }

      const { data } = await axios.get(`${baseUrl}/surveys/${surveyId}/logs${search}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })

      return data?._embedded?.surveys || []
    } catch (e) {
      console.log(e)
      return []
    }
  }

  const getCampaignAnalytics = async (campaignId) => {
    try {
      const { data: { _embedded: { stringList } } } = await axios.get(`${baseUrl}/campaigns/${campaignId}/logs/answers`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })

      return stringList
    } catch (e) {
      console.log(e)
      return []
    }
  }

  const getCampaignAnalyticsTable = async (campaignId) => {
    try {
      let search = ``
      if (currentSchool) {
        search = `/search?search=schoolId:${currentSchool}`
      } else if (currentDistrict) {
        search = `/search?search=districtId:${currentDistrict}`
      } else if (currentState) {
        search = `/search?search=stateId:${currentState}`
      }

      const { data: { _embedded: { surveys } } } = await axios.get(`${baseUrl}/campaigns/${campaignId}/logs${search}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })

      console.log({ surveys })

      return surveys
    } catch (e) {
      console.log(e)
      return []
    }
  }

  const saveSurveyAnswers = async (surveyId, body, logId, version, schoolId) => {
    try {
      if (logId === '0' && schoolId === '0') {
        await axios.post(`${baseUrl}/surveys/${surveyId}/logs/${version}`, body, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
      } else if (logId === '0' && schoolId !== '0') {
        await axios.post(`${baseUrl}/surveys/${surveyId}/logs/${version}?schoolId=${schoolId}`, body, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
      } else {
        await axios.patch(`${baseUrl}/surveys/${surveyId}/logs/${logId}/${version}`, body, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getMySchools = async (schoolLevels) => {
    try {
      const { data: { _embedded } } = await axios.get(`${baseUrl}/me/hierarchy?search=schoolLevels:${schoolLevels.join(' ')}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })

      return {
        states: _embedded?.hierarchy || [],
        districts: _embedded?.districts || [],
        schools: _embedded?.schools || []
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getSchoolUsers = async () => {
    try {
      let search = '/search?search=enabled:true,role:Peer Helper'
      if (currentSchool) {
        search += `,schoolId:${currentSchool}`
      } else if (currentDistrict) {
        search += `,districtId:${currentDistrict}`
      } else if (currentState) {
        search += `,stateId:${currentState}`
      }

      const { data: { _embedded: { users } } } = await axios.get(`${baseUrl}/users${search}&page=0&size=10000`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })

      return users || []
    } catch (e) {
      console.log(e)
    }
  }

  const profileIsComplete = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/me/profileCompleted`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
      })

      return data
    } catch (e) {
      console.log(e)
      return false
    }
  }

  const getForecast = async (surveyId, version) => {
    try {
      let search = ''
      if (currentSchool) {
        search = `/search?search=schoolId:${currentSchool}`
      } else if (currentDistrict) {
        search = `/search?search=districtId:${currentDistrict}`
      } else if (currentState) {
        search = `/search?search=stateId:${currentState}`
      }

      const { data: { _embedded: { integerList } } } = await axios.get(`${baseUrl}/surveys/${surveyId}/forecast/${version}${search}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
      })

      return integerList.reduce((a, b) => a + b, 0)
    } catch (e) {
      console.log(e)
      return [0]
    }
  }

  const getCampaignForecast = async (CampaignId) => {
    try {
      let search = ''
      if (currentSchool) {
        search = `/search?search=schoolId:${currentSchool}`
      } else if (currentDistrict) {
        search = `/search?search=districtId:${currentDistrict}`
      } else if (currentState) {
        search = `/search?search=stateId:${currentState}`
      }

      const { data: { _embedded: { integerList } } } = await axios.get(`${baseUrl}/campaigns/${CampaignId}/forecast${search}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
      })

      return integerList.reduce((a, b) => a + b, 0)
    } catch (e) {
      console.log(e)
      return [0]
    }
  }

  const saveForecast = async (surveyId, students, version) => {
    try {
      await axios.post(`${baseUrl}/surveys/${surveyId}/forecast/${version}`, {
        students,
        schoolId: currentSchool
      })
    } catch (e) {
      console.log(e)
    }
  }

  const exportSurvey = async (surveyId, title) => {
    try {
      fetch(`${baseUrl}/exports/surveys/${surveyId}/export.xlsx`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}` },
        })
        .then(response => response.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${title}.xlsx`)
          link.setAttribute('style', 'display:none')
          document.body.appendChild(link)
          link.click()
          link.remove()
        })
    } catch (e) {
      console.log(e)
    }
  }

  return {
    getCampaigns,
    saveCampaign,
    getCampaign,
    deleteCampaign,
    getCampaignSurveys,
    getSurvey,
    saveSurvey,
    updateSurvey,
    deleteSurvey,
    sendSurvey,
    getSurveyAllAnswers,
    saveSurveyAnswers,
    getSurveyPeerHelpers,
    getCampaignAnalytics,
    getCampaignAnalyticsTable,
    getMySchools,
    getSchoolUsers,
    profileIsComplete,
    getForecast,
    getCampaignForecast,
    saveForecast,
    exportSurvey
  }
}

export default useCampaignsService
