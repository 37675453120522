import React, { useEffect, useState } from 'react'
import { Item, Pagination, Responsive, Table } from 'semantic-ui-react'
import _ from 'lodash'
import { NavLink } from 'react-router-dom'
import useGlobal from '../../Store'
import useUrlBuilder from '../../Utils/Links'
import { logTypes } from '../../Config/SelectOptions'
import useStringOperations from '../../Utils/StringOperations'
import { NoPrint } from 'react-easy-print'
import { StyledTable } from '../../Theme/Components'

const AtRiskReferralsTable = (props) => {
  const [globalState, globalActions] = useGlobal()
  const { currentSchool, currentDistrict, currentState } = globalState
  const [logs, setLogs] = useState(globalState.logs)
  const [makeUrl] = useUrlBuilder(props.match.url)
  const stringOperations = useStringOperations()

  const getLogs = () => {
    globalActions.dashboards.getAtRiskReferralLogs(0, 100000)
  }

  useEffect(() => {
    setLogs(globalState.logs)
  }, [globalState.logs])

  useEffect(getLogs, [])

  useEffect(getLogs, [props.location, currentSchool, currentDistrict, currentState])

  return (
    <React.Fragment>
      <Table compact striped size='small'>
        <Table.Header className="stickyTh">
          <Responsive as={Table.Row} minWidth={1000}>
            <Table.HeaderCell>
              Date
            </Table.HeaderCell>
            <Table.HeaderCell>
              Log Type
            </Table.HeaderCell>
            <Table.HeaderCell>
              Referred By
            </Table.HeaderCell>
            <Table.HeaderCell>
              Student
            </Table.HeaderCell>
            <Table.HeaderCell>
            </Table.HeaderCell>
          </Responsive>
        </Table.Header>

        {!!logs && !_.isEmpty(logs) && (
          <Table.Body>
            {_.map(logs.sort((a, b) => new Date(a.content.date).getTime() - new Date(b.content.date).getTime()), ({ content }) => (
              <Table.Row key={content.id}>
                <Table.Cell>
                  <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                    Date
                  </Responsive>
                  {new Date(content.date.split(' ').join('T')).toLocaleDateString('en', {
                    year: '2-digit',
                    month: 'numeric',
                    day: 'numeric'
                  })}
                </Table.Cell>
                <Table.Cell>
                  <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                    Log Type
                  </Responsive>
                  {stringOperations.translate(logTypes, content.logType)}
                </Table.Cell>
                <Table.Cell>
                  <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                    Referred By
                  </Responsive>
                  {(content.logType === 'GROUP_ACTIVITY' || !content.peerHelper)
                    ? content.creator.firstName + ' ' + content.creator.lastName
                    : content.peerHelper.firstName + ' ' + content.peerHelper.lastName}
                </Table.Cell>
                <Table.Cell>
                  <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                    Student
                  </Responsive>
                  {content.studentName ? content.studentName : '-'}
                </Table.Cell>
                <Table.Cell>
                  <NoPrint force>
                    <Item as={NavLink}
                          to={content.logType === 'GROUP_ACTIVITY' ? makeUrl(`/group-logs/${content.id}/view`) : (content.logType === 'AS_NEEDED_SUPPORT' ? makeUrl(`/support-logs/${content.id}/view`) : makeUrl(`/continual-support/${content.id}/view/`))}
                          style={{ color: '#2076BC' }}> VIEW LOG </Item>
                  </NoPrint>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        )}
      </Table>
    </React.Fragment>
  )
}

export default AtRiskReferralsTable
