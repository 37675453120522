import React, { useEffect, useState } from 'react'
import { Button, Grid, Header } from 'semantic-ui-react'
import useGlobal from '../../Store'
import MyProgram from '../../Components/Dashboard/MyProgram'
import styled from 'styled-components'

const ProgramView = (props) => {
  const programId = props.match.params.programId || '0'
  const [globalState, globalActions] = useGlobal()
  const [program, setProgram] = useState(globalState.program)

  useEffect(() => {
    if (programId !== '0') {
      globalActions.myProgram.getProgram(programId)
    }
  }, [programId])

  useEffect(() => {
    setProgram(globalState.program)
  }, [globalState.program])

  const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase()
    if (ua.indexOf('safari') !== -1) {
      return ua.indexOf('chrome') <= -1
    }

    return false
  }

  const downloadPDF = (fileURL) => {
    var save = document.createElement('a')
    save.href = fileURL
    save.target = '_blank'
    save.download = fileURL.substring(fileURL.lastIndexOf('/') + 1)

    var evt = new MouseEvent('click', {
      'view': window,
      'bubbles': true,
      'cancelable': false
    })
    save.dispatchEvent(evt);
    (window.URL || window.webkitURL).revokeObjectURL(save.href)
  }

  return (
    <Grid reversed='mobile vertically'>
      <Grid.Column mobile={16} tablet={9} computer={11}>
        <Grid columns={2}>
          <Grid.Column style={{ paddingBottom: 0 }}>
            <StyledHeader as='h2'>{program.name}</StyledHeader>
          </Grid.Column>
          <Grid.Column style={{ paddingBottom: 0 }}>
            {/*{isSafari() && (*/}
            {/*  <StyledButton icon='download' floated='right' basic onClick={() => downloadPDF('https://' + program.url)} />*/}
            {/*)}*/}
          </Grid.Column>
        </Grid>

        <iframe src={'https://' + program.url} style={{ height: '80vh', width: '100%', overflow: 'auto', marginTop: 20 }} />
      </Grid.Column>
      <Grid.Column mobile={16} tablet={7} computer={5}>
        <MyProgram />
      </Grid.Column>
    </Grid>
  )
}

const StyledHeader = styled(Header)`
  size: 24px !important;
  font-weight: lighter;
`

const StyledButton = styled(Button)`
  box-shadow: none !important;
  float: right;
  i {
    color: black !important;
  }
`

export default ProgramView
