import React, { useEffect, useLayoutEffect, useState } from 'react'
import _ from 'lodash'
import { Button, Grid, Header, Responsive, Table } from 'semantic-ui-react'
import { StyledTable, StyledTableHeader } from '../../../Theme/Components'
import useGlobal from '../../../Store'
import styled from 'styled-components'
import { Print } from 'react-easy-print'
import endpoints from '../../../Config/ApiEndpoints'
import Filters from './Filters'
import { rightForm } from '../../../Theme/Styles'

export const defaultFilters = {
  stateId: '',
  districtId: '',
}

const UsageReport = (props) => {
  const [globalState, globalActions] = useGlobal()
  const [superUserSchools, setSuperUserSchools] = useState([])

  const [filters, setFilters] = useState(defaultFilters)

  const [width, setWidth] = useState(0)

  const _downloadReport = () => {
    window.open(endpoints.locations.superUserSchools + '/schools-export.xlsx')
  }

  const _getReport = () => {
    globalActions.locations.getSuperUserSchools()
  }

  const _resetFilters = () => {
    setFilters(defaultFilters)
    _getReport()
  }

  useLayoutEffect(() => {
    function updateSize () {
      setWidth(window.innerWidth / (16 / 5))
    }

    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [props.location.pathname])

  useEffect(_getReport, [])

  useEffect(() => {
    if (filters.districtId !== '') {
      setSuperUserSchools(globalState.superUserSchools.filter(school => school.districtId === filters.districtId))
    } else if (filters.stateId !== '') {
      setSuperUserSchools(globalState.superUserSchools.filter(school => school.stateId === filters.stateId))
    } else {
      setSuperUserSchools(globalState.superUserSchools)
    }
  }, [globalState.superUserSchools])

  return (
    <Grid reversed='computer tablet'>
      <Grid.Column mobile={16} computer={5} style={rightForm}>
        <Responsive as='div' maxWidth={1000}>
          <StyledTableHeader>
            <Header as='h2'>Usage Report</Header>
          </StyledTableHeader>
        </Responsive>

        <div style={{ position: 'fixed', top: 65, right: 0, width }}>
          <Filters filters={filters} setFilters={setFilters} resetFilters={_resetFilters}
                   filterReport={_getReport}/>
        </div>
      </Grid.Column>


      <Grid.Column mobile={16} computer={11}>
        <Responsive as='div' minWidth={1000}>
          <StyledTableHeader>
            <Header as='h2' style={{ marginTop: 10, marginBottom: 0 }}>Usage Report</Header>
            <div>
              <StyledButton icon='print' floated='right' basic onClick={() => window.print()}/>
              <StyledButton icon='file excel' floated='right' basic onClick={_downloadReport}/>
            </div>
          </StyledTableHeader>

          <Print name='Usage Report'>
            <SchoolsTable schools={superUserSchools}/>
          </Print>
        </Responsive>
      </Grid.Column>
    </Grid>
  )
}

const SchoolsTable = ({ schools }) => {
  return (
    <Table compact striped size='small'>
      <Table.Header className="stickyTh">
        <Responsive as={Table.Row} minWidth={1000}>
          <Table.HeaderCell>
            School
          </Table.HeaderCell>

          <Table.HeaderCell>
            School Level
          </Table.HeaderCell>

          <Table.HeaderCell>
            District
          </Table.HeaderCell>

          <Table.HeaderCell>
            State
          </Table.HeaderCell>

          <Table.HeaderCell>
            Creation Date
          </Table.HeaderCell>
        </Responsive>
      </Table.Header>

      <Table.Body>
        {_.map(schools, ({ name, schoolLevel, stateName, districtName, createdAt }) => (
          <Table.Row key={name + stateName + districtName}>
            <Table.Cell>
              <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                Name:
              </Responsive>
              {name}
            </Table.Cell>

            <Table.Cell>
              <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                School Level:
              </Responsive>
              {schoolLevel}
            </Table.Cell>

            <Table.Cell>
              <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                District:
              </Responsive>
              {districtName}
            </Table.Cell>

            <Table.Cell>
              <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                State:
              </Responsive>
              {stateName}
            </Table.Cell>

            <Table.Cell>
              <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                Creation Date:
              </Responsive>
              {createdAt !== null ? new Date(createdAt.split(' ').join('T')).toLocaleDateString() : ''}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}

const StyledButton = styled(Button)`
  box-shadow: none !important;
  float: right;
  i {
    color: black !important;
  }
`

export default UsageReport
