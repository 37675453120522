import React from 'react'
import { Container, Responsive } from 'semantic-ui-react'
import useGlobal from '../../Store'
import { pageContainer } from '../../Theme/Styles'
import NavBarDesktop from '../NavbarDesktop/NavbarDesktop'
import NavBarMobile from '../NavbarMobile/NavbarMobile'

const leftItems = [
  { name: 'Home', icon: '', key: 'dashboard', to: '/' },
  { name: 'Users', icon: '', key: 'users', to: '/users' },
  { name: 'Match Tool', icon: '', key: 'match', to: '/match' },
  { name: 'My Assists', icon: '', key: 'assists', to: '/assists' },
  { name: 'Admin', icon: '', key: 'archive', to: '/archive' },
  { name: 'Surveys', icon: '', key: 'campaigns', to: '/campaigns' }
]
const rightItems = [
  { name: '', icon: 'user', key: 'profile', to: '/profile' },
  { name: '', icon: 'question circle', key: 'help', to: '/help' },
  { name: 'Logout', icon: '', key: 'logout', to: '/logout' }
]

const AppMenu = ({ children, history }) => {
  const [globalState] = useGlobal()
  const { loggedInUser, currentSchool, currentDistrict, currentState } = globalState

  return (
    <React.Fragment>
      <Responsive {...Responsive.onlyMobile}>
        <NavBarMobile
          role={loggedInUser.role}
          currentSchool={currentSchool}
          currentDistrict={currentDistrict}
          currentState={currentState}
          leftItems={leftItems}
          rightItems={rightItems}
          history={history}
          loggedInUserId={loggedInUser.id}
        >
          <Container fluid style={pageContainer}>
            {children}
          </Container>
        </NavBarMobile>
      </Responsive>

      <Responsive minWidth={Responsive.onlyTablet.minWidth}>
        <NavBarDesktop
          role={loggedInUser.role}
          currentSchool={currentSchool}
          currentDistrict={currentDistrict}
          currentState={currentState}
          leftItems={leftItems}
          rightItems={rightItems}
          history={history}
          loggedInUserId={loggedInUser.id}
        />
        <Container fluid style={pageContainer}>
          {children}
        </Container>
      </Responsive>
    </React.Fragment>
  )
}

export default AppMenu
