import React, { useEffect, useState } from 'react'
import { Checkbox, Header, Icon, Image, Segment, Table } from 'semantic-ui-react'
import useGlobal from '../../Store'
import { dashboard as styles, dashboard } from '../../Theme/Styles'
import { KeyboardDatePicker } from '@material-ui/pickers'
import phHand from '../../Assets/Images/phhand.png'
import phHandWhite from '../../Assets/Images/phhand_w.png'
import styled from 'styled-components'
import _ from 'lodash'
import MyProgramNoSchoolSelected from './MyProgramNoSchoolSelected'
import MyProgramsCategoryPicker from '../MyProgramsCategoryPicker/MyProgramsCategoryPicker'
import { baseUrl } from '../../Config/ApiEndpoints'
import { NavLink } from 'react-router-dom'
import MyProgramNoPrograms from './MyProgramNoPrograms'

let dragIndex = -1
let draggedIndex = -1
let draggedSubcategory = ''
let draggedGroup = ''
let draggedSubgroup = ''

const MyProgram = (props) => {
  const [globalState, globalActions] = useGlobal()
  const { loggedInUser, currentSchool } = globalState

  const [programs, setPrograms] = useState(globalState.programs)
  const [category, setCategory] = useState('')
  const [subcategory, setSubcategory] = useState('')

  const [visibleGroup, setVisibleGroup] = useState()
  const [visibleSubgroup, setVisibleSubgroup] = useState()

  const getMyProgram = () => {
    setPrograms([])
    globalActions.myProgram.getMyProgram()
  }

  useEffect(() =>{
    getMyProgram()
    setSubcategory('')
  }, [currentSchool, props.location.pathname])

  useEffect(() => {
    setPrograms(globalState.programs)

    if (globalState.programs.categories && globalState.programs.categories[0] && subcategory === "") {
      setCategory(globalState.programs.categories[0])
      setSubcategory(globalState.programs.subCategories[globalState.programs.categories[0]][0])
    }
  }, [globalState.programs])

  useEffect(() => {
    if (subcategory) {
      setVisibleGroup(programs?.groups?.[subcategory]?.[0])
    }
  }, [subcategory])

  const onDateChange = (date, subcategory, group, subGroup, document) => {
    if (date === null) {
      globalActions.myProgram.saveProgram({ ...document, schoolId: currentSchool, date: null })
    } else if (date.format('YYYY-MM-DD') !== 'Invalid date') {
      const itemIndex = _.findIndex(programs.documents[subcategory + '/' + group + (subGroup !== '' ? '/' + subGroup : '')], doc => {
        return doc.id === document.id
      })

      let documentsCopy = _.clone(programs.documents[subcategory + '/' + group + (subGroup !== '' ? '/' + subGroup : '')])
      documentsCopy[itemIndex] = { ...document, date: date.format('YYYY-MM-DD') + ' 00:00:00' }
      setPrograms({
        ...programs,
        documents: {
          ...programs.documents,
          [subcategory + '/' + group + (subGroup !== '' ? '/' + subGroup : '')]: documentsCopy
        }
      })

      globalActions.myProgram.saveProgram({ ...document, schoolId: currentSchool, date: date.format('YYYY-MM-DD') + ' 00:00:00' })
    }
  }

  const onComplete = (subcategory, group, subGroup, document) => {
    const itemIndex = _.findIndex(programs.documents[subcategory + '/' + group + (subGroup !== '' ? '/' + subGroup : '')], doc => {
      return doc.id === document.id
    })

    if (itemIndex > -1) {
      let documentsCopy = _.clone(programs.documents[subcategory + '/' + group + (subGroup !== '' ? '/' + subGroup : '')])
      documentsCopy[itemIndex] = { ...document, completed: !document.completed }
      setPrograms({
        ...programs,
        documents: {
          ...programs.documents,
          [subcategory + '/' + group + (subGroup !== '' ? '/' + subGroup : '')]: documentsCopy
        }
      })

      globalActions.myProgram.saveProgram({ ...document, schoolId: currentSchool, completed: !document.completed })
    }
  }

  const closest = (el, selector) => {
    const matchesSelector = el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector

    while (el) {
      const flagRoot = el === document.body
      if (flagRoot || matchesSelector.call(el, selector)) {
        if (flagRoot) {
          el = null
        }
        break
      }
      el = el.parentElement
    }

    return el && el.className === 'document-row' ? el : null
  }

  const changeRowIndex = () => {
    if (dragIndex >= 0 && dragIndex !== draggedIndex) {
      let data = _.cloneDeep(programs)
      const item = data.documents[draggedSubcategory + '/' + draggedGroup + (draggedSubgroup !== '' ? '/' + draggedSubgroup : '')].splice(dragIndex, 1)[0]
      data.documents[draggedSubcategory + '/' + draggedGroup + (draggedSubgroup !== '' ? '/' + draggedSubgroup : '')].splice(draggedIndex, 0, item)

      setPrograms(data)

      globalActions.myProgram.saveOrder(Object.values(data.documents).reduce((acc, val) => [...acc, ...val]).map(doc => doc.id))
    }

    dragIndex = -1
    draggedIndex = -1
  }

  const getTrNode = (target) => {
    return closest(target, 'tr')
  }

  const onDragEnter = (e) => {
    const target = getTrNode(e.target)
    if (target) {
      draggedIndex = target ? target.rowIndex : -1
    }
  }

  const onDragLeave = (e) => {
    const target = getTrNode(e.target)
    if (target) {
      target.style.backgroundColor = 'white'
    }
  }

  const onDragStart = (e) => {
    const target = (e.target)
    if (target) {
      target.parentElement.ondragenter = onDragEnter
      target.parentElement.ondragleave = onDragLeave
      target.parentElement.ondragover = function (ev) {
        ev.preventDefault()
        return true
      }
      dragIndex = target.rowIndex
      draggedIndex = dragIndex
    }

    return true
  }

  const onDragEnd = (e) => {
    const target = (e.target)

    if (target) {
      target.style.cursor = 'default'
      target.style.backgroundColor = 'white'

      target.setAttribute('draggable', false)
      target.ondragstart = null
      target.ondragend = null
      target.parentElement.ondragenter = null
      target.parentElement.ondragleave = null
      target.parentElement.ondragover = null
      changeRowIndex()
    }
  }

  const onMouseDown = (e, subcategory, group, subgroup = '') => {
    if (!['Coordinator'].includes(loggedInUser.role))
      return

    const target = getTrNode(e.target)
    if (target) {
      draggedSubcategory = subcategory
      draggedGroup = group
      draggedSubgroup = subgroup

      target.setAttribute('draggable', true)
      target.ondragstart = onDragStart
      target.ondragend = onDragEnd
    }
  }

  const download = (path) => {
    const newTab = window.open(baseUrl + path)
    newTab.onload = function () {
      newTab.close()
    }
  }

  const renderDocumentRow = (subcategory, group, subGroup = '', document, key) => {
    return (
      <tr key={key} className="document-row" onMouseDown={e => onMouseDown(e, subcategory, group, subGroup)}>
        <StyledCell style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {!['Coordinator'].includes(loggedInUser.role) ? (
            <StyledProgramImage draggable={false} src={document.completed ? phHandWhite : phHand}/>
          ) : (
            <StyledProgramImageMove draggable={false} src={document.completed ? phHandWhite : phHand}/>
          )}
          <NavLink draggable to={'?pdfPath=/documents/' + document.id}
                   style={{ ...styles.myProgramLink, paddingTop: 5, paddingBottom: 5 }}>
            {document.name.substring(0, 25)}
          </NavLink>
        </StyledCell>

        <StyledCell centered>
          <KeyboardDatePicker
            disabled={!['Coordinator', 'State Level'].includes(loggedInUser.role)}
            value={document.date}
            onChange={date => onDateChange(date, subcategory, group, subGroup, document)}
            format='MM/DD/YY'
            InputAdornmentProps={{ style: { padding: 0, margin: 0 } }}
            InputProps={{
              style: { maxWidth: 90, fontSize: 11, borderBottom: '1px solid rgba(0,0,0,.1)' },
              disableUnderline: true,
              placeholder: 'mm/dd/yy'
            }}
            invalidDateMessage='Invalid date'
          />
        </StyledCell>

        <StyledCell centered>
          <Checkbox
            key={'checkbox' + key}
            checked={document.completed || false}
            onChange={() => onComplete(subcategory, group, subGroup, document)}
            style={{ paddingTop: 3 }}
            disabled={!['Coordinator', 'State Level'].includes(loggedInUser.role)}
          />
        </StyledCell>
      </tr>
    )
  }

  const getHandImageForGroup = (subcategory, group) => {
    let completed = true

    if (programs.documents[subcategory + '/' + group]) {
      programs.documents[subcategory + '/' + group].map(doc => {
        if (!doc.completed)
          completed = false
      })
    }

    if (programs.subGroups[subcategory + '/' + group]) {
      programs.subGroups[subcategory + '/' + group].map(subGroup => {
        programs.documents[subcategory + '/' + group + '/' + subGroup].map(doc => {
          if (!doc.completed)
            completed = false
        })
      })
    }

    return completed ? phHandWhite : phHand
  }

  const getHandImageForSubgroup = (subcategory, group, subgroup) => {
    let completed = true

    if (programs.documents[subcategory + '/' + group + '/' + subgroup]) {
      programs.documents[subcategory + '/' + group + '/' + subgroup].map(doc => {
        if (!doc.completed)
          completed = false
      })
    }

    return completed ? phHandWhite : phHand
  }

  const renderGroups = (subcategory, group) => {
    return (
      <tr>
        <StyledCell colSpan={3}>
          <StyledTable>
            <tr>
              <StyledCategoryCell colSpan={3}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1 }}
                     onClick={() => setVisibleGroup(group)}>
                  <StyledProgramImage src={getHandImageForGroup(subcategory, group)} style={{ height: 24 }}/>
                  <span style={{ fontSize: 18 }}>{group}</span>
                </div>

                <a href="#"
                   onClick={() => download('/documents/all?path=' + category + '/' + subcategory + '/' + group + '&schoolId=' + currentSchool)}>
                  <Icon name="cloud download"/>
                </a>
              </StyledCategoryCell>
            </tr>

            {programs.subGroups[subcategory + '/' + group] && programs.subGroups[subcategory + '/' + group].map((subGroup, key) => (
              <tr>
                <StyledCell colSpan={3}>
                  <StyledTable style={visibleGroup === group ? { paddingLeft: 20 } : { display: 'none' }}>
                    <tr>
                      <StyledCategoryCell colSpan={3}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1 }}
                             onClick={() => setVisibleSubgroup(subGroup)}>
                          <StyledProgramImage src={getHandImageForSubgroup(subcategory, group, subGroup)}
                                              style={{ height: 20 }}/>
                          <span style={{ fontSize: 16 }}>{subGroup}</span>
                        </div>

                        <a href="#"
                           onClick={() => download('/documents/all?path=' + category + '/' + subcategory + '/' + group + '/' + subGroup + '&schoolId=' + currentSchool)}>
                          <Icon name="cloud download"/>
                        </a>
                      </StyledCategoryCell>
                    </tr>

                    <tr>
                      <StyledCell colspan={3}>
                        <StyledTable style={visibleSubgroup === subGroup ? {
                          paddingLeft: 20,
                          marginBottom: 5
                        } : { display: 'none' }}
                        >
                          {programs.documents[subcategory + '/' + group + '/' + subGroup] && programs.documents[subcategory + '/' + group + '/' + subGroup].map((document, key3) =>
                            renderDocumentRow(subcategory, group, subGroup, document, key3)
                          )}
                        </StyledTable>
                      </StyledCell>
                    </tr>

                  </StyledTable>
                </StyledCell>
              </tr>
            ))}

            <tr>
              <StyledCell colSpan={3}>
                <StyledTable
                  style={visibleGroup === group ? { paddingLeft: 20, marginBottom: 5 } : { display: 'none' }}>
                  {programs.documents[subcategory + '/' + group] && programs.documents[subcategory + '/' + group].map((document, key3) =>
                    renderDocumentRow(subcategory, group, '', document, key3)
                  )}
                </StyledTable>
              </StyledCell>
            </tr>

          </StyledTable>
        </StyledCell>
      </tr>
    )
  }

  return (
    <Segment>
      <StyledTable>
        <thead>
        <tr>
          <StyledHeaderCell left>
            <Header as='h2' style={dashboard.myProgramHeader}>My Programs</Header>
          </StyledHeaderCell>
          <StyledHeaderCell>DATE</StyledHeaderCell>
          <StyledHeaderCell>COMPLETE</StyledHeaderCell>
        </tr>
        </thead>

        <tbody>
        {currentSchool === '' ? (
          <tr>
            <td colSpan={3}>
              <MyProgramNoSchoolSelected/>
            </td>
          </tr>
        ) : (
          <>
            {programs.length === undefined ? (
              <>
                {Object.keys(programs.documents).length === 0 ? (
                  <MyProgramNoPrograms/>
                ) : (
                  <>
                    <tr>
                      <td colSpan={3} style={{ paddingTop: 10, paddingBottom: 10 }}>
                        <MyProgramsCategoryPicker category={category} setCategory={setCategory}
                                                  subcategory={subcategory}
                                                  setSubcategory={setSubcategory} programs={programs}/>
                      </td>
                    </tr>

                    {(category !== '' && subcategory !== '') && programs.groups[subcategory] && programs.groups[subcategory].map((group) => renderGroups(subcategory, group))}
                  </>
                )}
              </>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 20 }}>
                <Icon loading name="spinner" style={{ alignSelf: 'center', fontSize: 35 }}/>
              </div>
            )}
          </>
        )}
        </tbody>
      </StyledTable>
    </Segment>
  )
}

const StyledProgramImage = styled(Image)`
            margin-right: 8px;
            height: 16px;
            `

const StyledProgramImageMove = styled(StyledProgramImage)`
            cursor: move !important;
            `

const StyledTable = styled.table`
  width: 100%;
`

const StyledCell = styled.td`
            padding: 0 !important;
            border-top: 0 !important;
            border-bottom: 0 !important;
            ${props => props.centered ? 'text-align: center !important;' : ''}
            `
const StyledCategoryCell = styled.td`
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 5px 0 !important;
            border-top: 0 !important;
            border-bottom: 0 !important;
            cursor: pointer;
            ${props => props.centered ? 'text-align: center !important;' : ''}
            `

const StyledHeaderCell = styled.th`
            border-bottom: 2px solid rgba(0, 0, 0, 0.84) !important;
            font-size: 9px !important;
            padding-bottom: 0px !important;
            color: #753CBE !important;
            text-align: ${props => props.left ? 'left' : 'center'} !important;
            `

export default MyProgram
