import axios from 'axios'
import endpoints from '../Config/ApiEndpoints'
import { processRequestErrorNotification } from '../Utils/Error'
import { guide as defaultGuide } from '../Config/DefaultState'
import { getResourceFromArchive } from './Archive'
import * as CoordinatorUserGuide from '../Assets/PDFGuides/School Coordinator User Guide.pdf'

const myTutorials = [
  { id: 0, title: 'What is Peer Helpers?', url: 'https://www.youtube.com/embed/xMVuXIUHOWI', description: '' },
  { id: 1, title: 'Registering and Logging into the Portal', url: 'https://www.youtube.com/embed/yqUR-TtNk8w', description: '' },
  { id: 2, title: 'Setting Up Profile & Home Page Overview', url: 'https://www.youtube.com/embed/oFKqmCq0W84', description: '' },
  { id: 3, title: 'As-Needed Support', url: 'https://www.youtube.com/embed/OLIXCjrE4X8', description: '' },
  { id: 4, title: 'Group Activity Logs', url: ' https://www.youtube.com/embed/NNw7meHQjzo', description: '' },
  { id: 5, title: 'Ongoing Support- Creating a New Assigned Student', url: 'https://www.youtube.com/embed/tjFq640GjJU', description: '' },
  { id: 6, title: 'Ongoing Support- Completing Assessments', url: 'https://www.youtube.com/embed/o6kip0A7DpU', description: '' },
  { id: 7, title: 'Ongoing Support Logs', url: 'https://www.youtube.com/embed/RNd5VRm-2Pc', description: '' },
  { id: 8, title: 'Peer Helper Portal Registration', url: 'https://www.youtube.com/embed/9GRcrA21mT4', description: '' },
  { id: 9, title: 'School Coordinator User Guide', url: CoordinatorUserGuide, description: '' }
]

export const getGuidesAndTutorials = async (store) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  store.setState({ requestStatus: 'LOADING' })
  try {
    // const response = await getResourceFromArchive(store, endpoints.guides.root, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
    // const { tutorials } = response.data._embedded
    const tutorials = myTutorials

    store.setState({ requestStatus: 'SUCCESS', tutorials })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ tutorials: [], requestStatus: 'FAILED', notification })
  }
}

export const getGuide = async (store, guideId) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  store.setState({ requestStatus: 'LOADING' })
  try {
    // const response = await getResourceFromArchive(store, endpoints.guides.root + '/' + guideId, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
    // const guide = response.data
    const guide = myTutorials[guideId]

    store.setState({ requestStatus: 'SUCCESS', guide })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ guide: defaultGuide, requestStatus: 'FAILED', notification })
  }
}
