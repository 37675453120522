import * as users from './Users'
import * as notifications from './Notifications'
import * as matchTool from './MatchTool'
import * as guidesAndTutorials from './Guides'
import * as myProgram from './MyProgram'
import * as dashboards from './Dashboard'
import * as locations from './Locations'
import * as students from './Students'
import * as assists from './Assists'
import * as asNeededLogs from './AsNeededLogs'
import * as assessments from './Assessments'
import * as groupActivityLogs from './GroupActivityLogs'
import * as usage from './Usage'
import * as demographic from './Demographic'
import * as onGoingSupportLogs from './OnGoingSupportLogs'
import * as topics from './Topics'
import * as roles from './Roles'
import * as contact from './Contact'
import * as archive from './Archive'
import * as surveys from './Suveys'

export default {
  users,
  notifications,
  matchTool,
  guidesAndTutorials,
  locations,
  myProgram,
  dashboards,
  students,
  assists,
  asNeededLogs,
  assessments,
  groupActivityLogs,
  onGoingSupportLogs,
  usage,
  topics,
  demographic,
  roles,
  contact,
  archive,
  surveys
}
