import React, { useEffect, useState } from 'react'
import { Button, Grid, Header, Segment } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { dashboard } from '../Theme/Styles'
import { NoPrint } from 'react-easy-print'
import useStringOperations from '../Utils/StringOperations'
import { lifeExperiencesOptions } from '../Config/SelectOptions'
import { isEmpty } from 'lodash'

const StyledCardContent = styled(Segment)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: stretch;
`

const StyledButton = styled(Button)`
  box-shadow: none !important;
  
  i {
    color: black !important;
  }
`
const COLORS = ['#753CBE', '#0088FE', '#3CBEB9', '#F58127', '#BE3CA5']

const CustomTooltip = ({ payload, active }) => {
  const stringOperations = useStringOperations()
  if (active && payload && payload.length && payload[0].payload.value) {
    return (
      <div style={{ backgroundColor: 'white', padding: 5, borderRadius: 3, border: '1px solid #ccc' }}>
        <p>{`${payload[0].payload.name} - ${stringOperations.translateInfo(lifeExperiencesOptions, payload[0].payload.value)}`}</p>
        <p>Total: {payload[0].value}</p>
      </div>
    )
  }

  return null
}

const BarGraphSegment = props => {
  const data = props.data
  const [width, setWidth] = useState(props.width)

  useEffect(() => {
    if (!isEmpty(data))
      setTimeout(() => {
        setWidth(width === props.width ? width - 1 : props.width)
      }, 1000)
  }, [data])

  return (
    <React.Fragment>
      <Grid columns={2}>
        {!!props.title && (
          <Grid.Column>
            <Header as="h2" style={{ ...dashboard.phHeader, marginBottom: 0, paddingBottom: 0 }}>{props.title}</Header>
          </Grid.Column>
        )}

        {props.showIcons && (
          <Grid.Column>
            <NoPrint force>
              <StyledButton icon="print" floated="right" basic onClick={() => window.print()}/>
            </NoPrint>
          </Grid.Column>
        )}
      </Grid>

      <StyledCardContent>
        <div style={{
          width: width || '100%',
          height: props.height || 600,
          paddingLeft: 30
        }}>
          <ResponsiveContainer width="100%">
            <BarChart data={data} layout="vertical">
              <YAxis width={props.yAxisWidth || 100} dataKey="name" type="category" tickLine={false}/>
              <XAxis allowDecimals={false} type="number"/>
              <Tooltip content={<CustomTooltip/>}/>
              <Bar dataKey="total" barSize={20} gap={10} fill="#753CBE" label={{ position: 'right' }}>
                {
                  data.map((entry, index) => (
                    <Cell
                      fill={lifeExperiencesOptions.find(option => entry.value === option.value)?.color || entry.color || COLORS[index]}
                      key={index}/>
                  ))
                }
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </StyledCardContent>
    </React.Fragment>
  )
}

BarGraphSegment.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.string.isRequired,
  showIcons: PropTypes.bool,
  yAxisWidth: PropTypes.number
}

export default BarGraphSegment
