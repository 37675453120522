import React, { useEffect, useState } from 'react'
import { Button, Container, Divider, Form, Header, Segment } from 'semantic-ui-react'
import useGlobal from '../../Store'
import useValidation from '../../Utils/Validations'
import { dashboard } from '../../Theme/Styles'
import {
  academicStrengthsOptions,
  genderOptions, gradeOptions,
  hobbiesOptions,
  lifeExperiencesOptions,
  raceOptions, renderLabel
} from '../../Config/SelectOptions'
import { loggedInUser } from '../../Config/DefaultState'

const UserForm = (props) => {
  const userId = props.userId || props.match.params.userId || '0'
  const [globalState, globalActions] = useGlobal()
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    roles: [],
    state: '',
    district: '',
    schools: []
  })
  const [roles, setRoles] = useState([])
  const [states, setStates] = useState([])
  const [districts, setDistricts] = useState([])
  const [schools, setSchools] = useState([])
  const [requiredFields, setRequiredFields] = useState(userId !== '' && userId !== '0' ? ['firstName', 'lastName', 'username', 'roles'] : ['firstName', 'lastName', 'username', 'roles', 'password', 'confirmPassword'])
  const [validForm, validationErrors, onFieldChangeValidation, requiredFieldsChange] = useValidation(requiredFields, user)
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    requiredFieldsChange(requiredFields)

    globalActions.roles.getRoles()

    switch (loggedInUser.role) {
      case 'Super User':
        globalActions.locations.getStates(true)
        break
      case 'State Level':
        globalActions.locations.getDistricts('', true)
        break
      default:
        globalActions.locations.getDistrictsSchools('', true)
    }

    if (userId !== '0') {
      globalActions.users.getUser(userId)
    } else {
      setUser({
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        roles: [],
        state: '',
        district: '',
        schools: []
      })
    }
  }, [userId])

  useEffect(() => {
    if (user.roles.length > 0) {
      let defaultFields = ['firstName', 'lastName', 'username', 'roles']
      if (userId === '' || userId === '0')
        defaultFields = [...defaultFields, 'password', 'confirmPassword']

      switch (user.roles[0].name) {
        case 'Peer Helper':
          if (userId !== '' && userId !== '0') {
            let fields = [...defaultFields, 'schools', 'gender', 'race', 'academic', 'interests', 'experiences']

            if (loggedInUser.role === 'Super User')
              fields.push('state')
            if (loggedInUser.role === 'State Level')
              fields.push('district')

            setRequiredFields(fields)
          } else {
            let fields = [...defaultFields, 'schools']

            if (loggedInUser.role === 'Super User')
              fields.push('state')
            if (loggedInUser.role === 'State Level')
              fields.push('district')

            setRequiredFields(fields)
          }
          break

        case 'Coordinator':
        case 'School Level':
          if (userId !== '' && userId !== '0') {
            let fields = [...defaultFields, 'schools', 'gender', 'race', 'phone']

            if (loggedInUser.role === 'Super User')
              fields.push('state')
            if (loggedInUser.role === 'State Level')
              fields.push('district')

            setRequiredFields(fields)
          } else {
            let fields = [...defaultFields, 'schools']

            if (loggedInUser.role === 'Super User')
              fields.push('state')
            if (loggedInUser.role === 'State Level')
              fields.push('district')

            setRequiredFields(fields)
          }
          break

        case 'District Level':
          if (userId !== '' && userId !== '0') {
            let fields = [...defaultFields, 'district', 'gender', 'race', 'phone']

            if (loggedInUser.role === 'Super User')
              fields.push('state')

            setRequiredFields(fields)
          } else {
            let fields = [...defaultFields, 'district']

            if (loggedInUser.role === 'Super User')
              fields.push('state')

            setRequiredFields(fields)
          }
          break

        case 'State Level':
          if (userId !== '' && userId !== '0')
            setRequiredFields([...defaultFields, 'state', 'gender', 'race', 'phone'])
          else
            setRequiredFields([...defaultFields, 'state'])
          break

        case 'Super User':
          if (userId !== '' && userId !== '0')
            setRequiredFields([...defaultFields])
          else
            setRequiredFields([...defaultFields])
          break
      }
    }
  }, [user.roles])

  useEffect(() => {
    requiredFieldsChange(requiredFields, user)
  }, [requiredFields])

  useEffect(() => {
    if (user.state && (user.state.id !== '' || user.state !== ''))
      globalActions.locations.getDistricts(user.state.id ? user.state.id : user.state, true)
  }, [user.state])

  useEffect(() => {
    if (user.district && (user.district.id !== '' || user.district !== ''))
      globalActions.locations.getDistrictsSchools(user.district.id ? user.district.id : user.district, true)
  }, [user.district])

  useEffect(() => {
    if (globalState.loggedInUser && globalState.loggedInUser.role) {
      const allRoles = globalState.roles
      let filteredRoles

      switch (globalState.loggedInUser.role) {
        case 'State Level':
          filteredRoles = allRoles.filter(role => !['Super User', 'State Level'].includes(role.text))
          break
        case 'District Level':
          filteredRoles = allRoles.filter(role => !['Super User', 'State Level', 'District Level'].includes(role.text))
          break
        case 'School Level':
        case 'Coordinator':
          filteredRoles = allRoles.filter(role => role.text === 'Peer Helper')
          break
        default:
          filteredRoles = allRoles.filter(role => role.text !== 'Super User')
      }
      setRoles(filteredRoles)
    } else {
      setRoles([])
    }
  }, [globalState.roles])

  useEffect(() => {
    if (globalState.userManagementStates) {
      setStates(globalState.userManagementStates)
    }
  }, [globalState.userManagementStates])

  useEffect(() => {
    if (globalState.userManagementDistricts && globalState.userManagementDistricts.length > 0)
      setDistricts(globalState.userManagementDistricts)
  }, [globalState.userManagementDistricts])

  useEffect(() => {
    if (globalState.userManagementSchools && globalState.userManagementSchools.length > 0)
      setSchools(globalState.userManagementSchools)
  }, [globalState.userManagementSchools])

  useEffect(() => {
    if (globalState.editedUser && userId !== '0') {
      let updatedUser = { ...globalState.editedUser }
      if (globalState.editedUser.state) {
        updatedUser.state = globalState.editedUser.state.id
      }

      if (globalState.editedUser.district) {
        updatedUser.district = globalState.editedUser.district.id
      }

      if (globalState.editedUser.schools) {
        updatedUser.schools = globalState.editedUser.schools.map(school => school.id)
      }

      setUser(updatedUser)
    }
  }, [globalState.editedUser])

  const updateUserState = e => {
    const { name, value } = typeof e.target !== 'undefined' ? e.target : e
    setUser({ ...user, [name]: value })
    onFieldChangeValidation(name, value)
  }

  const saveProfileInfo = () => {
    if (validForm) {
      setDisabled(true)
      const savedUser = { ...user, enabled: true }

      if (!Array.isArray(savedUser.grades))
        savedUser.grades = [savedUser.grades]

      globalActions.users.saveUser(savedUser)
        .catch(e => console.log(e))
        .then(() => setDisabled(false))
      props.refresh()
    } else {
      const missingFields = []
      Object.keys(validationErrors).map(field => {
        if (validationErrors[field].error)
          missingFields.push(field.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase()))
      })

      if ((missingFields.includes('Password') || missingFields.includes('Confirm Password')) && missingFields.length <= 2) {
        globalActions.notifications.showNotification({
          type: 'warning',
          header: 'Password is too short',
          subheader: 'Password must be at least 8 characters long'
        })
      } else {
        globalActions.notifications.showNotification({
          type: 'warning',
          header: 'Invalid form',
          subheader: 'Please complete all required fields! Check "' + missingFields.join('", "') + '"'
        })
      }
    }
  }

  const saveUser = () => {
    if (typeof user.password !== 'undefined' && user.password.length > 0 && user.password !== user.confirmPassword) {
      globalActions.notifications.showNotification({
        type: 'warning',
        header: 'Passwords do not match',
        subheader: 'Please make sure you enter the same password'
      })
    } else if (typeof user.password !== 'undefined' && user.password.length > 0 && user.password === user.confirmPassword) {
      const savedUser = { ...user, enabled: true }

      if (!Array.isArray(savedUser.grades))
        savedUser.grades = [savedUser.grades]
        
      saveProfileInfo()
      props.refresh()

      return
    }

    saveProfileInfo()
  }

  const showStateSelect = () => {
    if (globalState.loggedInUser.role === 'Super User')
      return roles && roles.length > 0 && user.roles && user.roles.length > 0
    else
      return false
  }

  const showDistrictSelect = () => {
    switch (globalState.loggedInUser.role) {
      case 'Super User':
        return roles && roles.length > 0 && user.roles && user.roles.length > 0
          && roles.find(role => role.text === 'State Level')
          && user.roles[0].id !== roles.find(role => role.text === 'State Level').key
      case 'State Level':
        return user.roles && user.roles.length > 0
      default:
        return false
    }
  }

  const showSchoolSelect = () => {
    switch (globalState.loggedInUser.role) {
      case 'Super User':
        return roles && roles.length > 0 && user.roles && user.roles.length > 0
          && roles.find(role => role.text === 'State Level')
          && user.roles[0].id !== roles.find(role => role.text === 'State Level').key
          && roles.find(role => role.text === 'District Level')
          && user.roles[0].id !== roles.find(role => role.text === 'District Level').key
      case 'State Level':
        return roles && roles.length > 0 && user.roles && user.roles.length > 0
          && roles.find(role => role.text === 'District Level')
          && user.roles[0].id !== roles.find(role => role.text === 'District Level').key
      default:
        return user.roles && user.roles.length > 0
    }
  }

  const hasMultipleSchools = () => {
    return roles && roles.length > 0 && roles.length && user.roles.length > 0 && user.roles[0].id !== roles.find(role => role.text === 'Peer Helper').key
  }

  const getUsersRole = (user) => {
    return user.roles !== null && user.roles !== undefined && user.roles[0] !== undefined ? user.roles[0].name : 'Peer Helper'
  }

  return (
    <Container>
      <Segment>
        <Header as='h3'
                style={dashboard.myProgramHeader}>{(userId !== '' && userId !== '0') ? 'Edit' : 'Add New'} User</Header>

        <Divider/>

        <Form>
          <Form.Input name='firstName' value={user.firstName} onChange={updateUserState} required fluid
                      label='First name'
                      error={validationErrors.firstName.error} placeholder='First name'/>

          <Form.Input name='lastName' value={user.lastName} onChange={updateUserState} required fluid label='Last name'
                      error={validationErrors.lastName.error} placeholder='Last name'/>

          <Form.Input name='username' disabled={userId !== '0' && userId !== ''} value={user.username}
                      onChange={updateUserState} required fluid label='Username'
                      error={validationErrors.username.error} placeholder="Username"/>

          <Form.Group widths='equal'>
            <Form.Input name="password" value={user.password}
                        onChange={e => setUser({ ...user, password: e.target.value })} fluid
                        label={'Password' + (userId === '' || userId === '0' ? '*' : '')} placeholder='Password'
                        type='password' error={validationErrors.password ? validationErrors.password.error : false}
            />
            <Form.Input name="confirmPassword" onChange={e => setUser({ ...user, confirmPassword: e.target.value })}
                        fluid label={'Confirm Password' + (userId === '' || userId === '0' ? '*' : '')}
                        value={user.confirmPassword} placeholder='Confirm Password'
                        type='password'
                        error={validationErrors.confirmPassword ? validationErrors.confirmPassword.error : false}
            />
          </Form.Group>

          <Form.Input name='email' value={user.email} onChange={updateUserState} fluid label='Email'
                      placeholder="Email"/>

          {(getUsersRole(user) !== 'Peer Helper' && userId !== '' && userId !== '0') && (
            <React.Fragment>
              <Form.Input name='phone' label='Cell / Daytime Phone' placeholder='Cell / Daytime Phone'
                          value={user.phone} onChange={updateUserState}
                          error={typeof validationErrors.phone !== 'undefined' ? validationErrors.phone.error : false}
                          required
                          fluid/>
            </React.Fragment>
          )}

          {(userId !== '' && userId !== '0') && (
            <>
              <Form.Select name='gender' value={user.gender} onChange={(e, data) => updateUserState(data)}
                           options={genderOptions} required fluid label='Gender'
                           error={validationErrors.gender ? validationErrors.gender.error : false}
                           placeholder='Gender'/>

              <Form.Dropdown name='race' value={user.race} onChange={(e, data) => updateUserState(data)}
                             required fluid multiple selection label='Race'
                             error={validationErrors.race ? validationErrors.race.error : false}
                             options={raceOptions} placeholder='Choose one or more'/>
            </>
          )}

          {(getUsersRole(user) === 'Peer Helper' && userId !== '' && userId !== '0') && (
            <React.Fragment>
              <Form.Dropdown name='grades'
                             value={user.grades ? (Array.isArray(user.grades) ? user.grades[0] : user.grades) : ''}
                             onChange={(e, data) => updateUserState(data)}
                             options={gradeOptions}
                             required fluid selection label='Grade'
                             error={validationErrors.grade ? validationErrors.grade.error : false}
                             placeholder='Grade'/>
              <Form.Dropdown name='academic' value={user.academic} onChange={(e, data) => updateUserState(data)}
                             options={academicStrengthsOptions}
                             required fluid multiple selection label='Academic Needs'
                             error={validationErrors.academic ? validationErrors.academic.error : false}
                             placeholder='Academic Needs'/>
              <Form.Dropdown name='interests' value={user.interests} onChange={(e, data) => updateUserState(data)}
                             options={hobbiesOptions}
                             required fluid multiple selection label='Hobbies/Interests' placeholder='Hobbies/Interests'
                             error={validationErrors.interests ? validationErrors.interests.error : false}/>
              <Form.Dropdown name='experiences' value={user.experiences} onChange={(e, data) => updateUserState(data)}
                             options={lifeExperiencesOptions.filter(option => !option.groupOnly)}
                             renderLabel={renderLabel}
                             required fluid multiple selection label='Life Experiences'
                             error={validationErrors.experiences ? validationErrors.experiences.error : false}
                             placeholder='Life Experiences'/>
            </React.Fragment>
          )}

          <Form.Dropdown name='role' value={(user.roles && user.roles.length) ? user.roles[0].id : ''}
                         onChange={(e, data) => {
                           setUser({
                             ...user,
                             roles: [{ id: data.value, name: roles.find(role => role.value === data.value).text }]
                           })
                         }}
                         options={roles}
                         required fluid selection label='Role'
                         error={typeof validationErrors.role !== 'undefined' ? validationErrors.role.error : false}
                         placeholder='Role'/>

          {showStateSelect() && (
            <Form.Dropdown name='state' value={user.state}
                           onChange={(e, data) => {
                             setUser({ ...user, state: data.value, district: '', schools: [] })
                           }}
                           options={states.map(state => ({ key: state.id, text: state.name, value: state.id }))}
                           required fluid selection label='State'
                           error={typeof validationErrors.state !== 'undefined' ? validationErrors.state.error : false}
                           placeholder='State'/>
          )}

          {showDistrictSelect() && (
            <Form.Dropdown name='district'
                           value={user.district}
                           onChange={(e, data) => {
                             setUser({ ...user, district: data.value, schools: [] })
                           }}
                           options={districts ? districts.map(district => ({
                             key: district.id,
                             text: district.name,
                             value: district.id
                           })) : []}
                           required fluid selection label='District'
                           error={typeof validationErrors.district !== 'undefined' ? validationErrors.district.error : false}
                           placeholder='District'/>
          )}

          {showSchoolSelect() && (
            <Form.Dropdown name='schools'
                           value={hasMultipleSchools() ? user.schools : user.schools[0]}
                           multiple={hasMultipleSchools()}
                           onChange={(e, data) => {
                             setUser({ ...user, schools: hasMultipleSchools() ? data.value : [data.value] })
                           }}
                           options={schools ? schools.map(school => ({
                             key: school.id,
                             text: school.name,
                             value: school.id
                           })) : []}
                           required fluid selection
                           label={(roles && roles.length && user.roles && user.roles[0].id !== roles.find(role => role.text === 'Peer Helper').key) ? 'Schools' : 'School'}
                           error={typeof validationErrors.schools !== 'undefined' ? validationErrors.schools.error : false}
                           placeholder={(roles && roles.length && user.roles && user.roles[0].id !== roles.find(role => role.text === 'Peer Helper').key) ? 'Schools' : 'School'}
            />
          )}

          <div style={{ textAlign: 'right' }}>
            <Button.Group size='large'>
              {typeof props.cancelEdit !== 'undefined' && (
                <React.Fragment>
                  <Button onClick={props.cancelEdit}>Cancel</Button>
                  <Button.Or/>
                </React.Fragment>
              )}
              <Button positive onClick={saveUser} disabled={disabled}>Save</Button>
            </Button.Group>
          </div>
        </Form>
      </Segment>
    </Container>
  )
}

export default UserForm
