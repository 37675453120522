import _ from 'lodash'
import { useEffect, useState } from 'react'

const useValidation = (requiredFields, data) => {
  const isValidEmail = (email) => {
    // eslint-disable-next-line no-useless-escape
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regex.test(email)
  }

  const isValidPhone = (phone) => {
    // eslint-disable-next-line no-useless-escape
    const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
    return regex.test(phone)
  }

  const validatePassword = password => {
    if (password === undefined)
      return true
    return password.length >= 8
  }

  const isValidInitial = (lastInitial) => {
    const regex = /^[a-zA-Z]{1}$/
    return regex.test(lastInitial)
  }

  const isValidField = (name, value) => {
    let error
    switch (name) {
      case 'email':
        error = !isValidEmail(value)
        break
      case 'phone':
        error = !isValidPhone(value)
        break
      case 'lastInitial':
        error = !isValidInitial(value)
        break
      case 'password':
      case 'confirmPassword':
        error = !validatePassword(value)
        break
      case 'peerHelpersId':
      case 'experiences':
      case 'academics':
      case 'races':
      case 'interests':
        error = !value || (value && !value.length)
        break
      default:
        error = typeof value === 'string' ? !value.length : !value
    }

    return error
  }

  const isValidForm = () => {
    let valid = true
    _.forOwn(validationErrors, (formField) => {
      if (formField.error === true) {
        valid = false
      }
    })

    return valid
  }

  const createInitialValidationErrorsObject = (requiredFields, values) => {
    let validationErrors = {}
    for (let i = 0; i < requiredFields.length; i++) {
      validationErrors[requiredFields[i]] = {
        error: values ? isValidField(requiredFields[i], values[requiredFields[i]]) : false
      }
    }

    return validationErrors
  }

  const [validationErrors, setValidationErrors] = useState(createInitialValidationErrorsObject(requiredFields, data))
  const [isValid, setIsValid] = useState(isValidForm(validationErrors))

  const validate = (name, value) => {
    setValidationErrors({ ...validationErrors, [name]: { error: isValidField(name, value) } })
  }

  const requiredFieldsChange = (requiredFields, values) => {
    setValidationErrors(createInitialValidationErrorsObject(requiredFields, values))
  }

  useEffect(() => {
    setIsValid(isValidForm(validationErrors))
  }, [validationErrors])

  useEffect(() => {
    setValidationErrors(createInitialValidationErrorsObject(requiredFields, data))
  }, [data])

  return [isValid, validationErrors, validate, requiredFieldsChange]
}

export default useValidation
