import React, { useEffect, useState } from 'react'

import useGlobal from '../../Store'
import { Header, Icon, Popup, Responsive, Table } from 'semantic-ui-react'
import { dashboard } from '../../Theme/Styles'
import styled from 'styled-components'
import useStringOperations from '../../Utils/StringOperations'
import { lifeExperiencesOptions } from '../../Config/SelectOptions'

const StyledTableHeader = styled(Table.HeaderCell)`
  font-size: 10px;
  padding-top: 2.5rem !important;
  padding-bottom: 0 !important;
`

const TopicsSection = () => {
  const [globalState, globalActions] = useGlobal()
  const { currentState, currentDistrict, currentSchool } = globalState
  const stringOperations = useStringOperations()
  const [topics, setTopics] = useState(globalState.topics)

  useEffect(() => {
    setTimeout(() => {
      globalActions.dashboards.getTopics()
    }, 500)
  }, [currentState, currentDistrict, currentSchool])

  useEffect(() => {
    setTopics(globalState.topics)
  }, [globalState.topics])

  const statistics = typeof topics.AS_NEEDED_SUPPORT !== 'undefined' ? lifeExperiencesOptions.map((option) => ({
    name: stringOperations.translate(lifeExperiencesOptions, option.key),
    onGoing: topics.ONGOING_SUPPORT[option.key] || 0,
    asNeeded: topics.AS_NEEDED_SUPPORT[option.key] || 0,
    group: topics.GROUP_ACTIVITY[option.key] || 0,
    total: topics.ONGOING_SUPPORT[option.key] || 0 + topics.AS_NEEDED_SUPPORT[option.key] || 0 + topics.GROUP_ACTIVITY[option.key] || 0
  })) : []

  statistics.sort((a, b) => {
    const keyA = a.name
    const keyB = b.name

    if (keyA < keyB) return -1
    if (keyA > keyB) return 1
    return 0
  })

  return (
    <React.Fragment>
      <Table basic='very'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ ...dashboard.table.cell, paddingTop: '2rem' }}>
              <Header as='h2' style={dashboard.myProgramHeader}>Topics of Support</Header>
            </Table.HeaderCell>
            <StyledTableHeader>ONGOING</StyledTableHeader>
            <StyledTableHeader>AS-NEEDED</StyledTableHeader>
            <StyledTableHeader>GROUP</StyledTableHeader>
            <StyledTableHeader>TOTAL</StyledTableHeader>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {statistics.map((document, index) => (
            <Table.Row key={index}>
              <StyledColoredCell color={lifeExperiencesOptions.find(option => document.name === option.text).color}>
                <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                  Topic
                </Responsive>
                {document.name.split('_').join(' ') + ' - ' + stringOperations.translateInfo(lifeExperiencesOptions, document.name.split('-').join('_').split(' ').join('_').split('/').join('_').split('___').join('_').toUpperCase())}
              </StyledColoredCell>
              <StyledColoredCell color={lifeExperiencesOptions.find(option => document.name === option.text).color}>
                <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                  Ongoing
                </Responsive>
                {document.onGoing}
              </StyledColoredCell>
              <StyledColoredCell color={lifeExperiencesOptions.find(option => document.name === option.text).color}>
                <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                  As-needed
                </Responsive>
                {document.asNeeded}
              </StyledColoredCell>
              <StyledColoredCell color={lifeExperiencesOptions.find(option => document.name === option.text).color}>
                <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                  Group
                </Responsive>
                {document.group}
              </StyledColoredCell>
              <StyledColoredCell color={lifeExperiencesOptions.find(option => document.name === option.text).color}>
                <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                  Total
                </Responsive>
                {document.onGoing + document.asNeeded + document.group}
              </StyledColoredCell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </React.Fragment>
  )
}
const StyledColoredCell = styled(Table.Cell)`
  border-top: 0 !important;
  border-bottom: 2px solid ${props => typeof props.color !== 'undefined' ? props.color : 'rgba(0, 0, 0, 0.57)'} !important;
`

export default TopicsSection
