import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import * as qs from "qs";
import useGlobal from "../../Store";
import { Dimmer, Loader } from "semantic-ui-react";

const Login = (props) => {
  const [, setCookie] = useCookies();
  const [, globalActions] = useGlobal();
  useEffect(() => {
    const params = qs.parse(props.location.search.split("?").join(""));
    if (typeof params.code !== "undefined" && params.code !== "") {
      globalActions.users
        .login(params.code, setCookie)
        .then(() => props.history.push("/"));
    }
  }, []);

  return (
    <Dimmer active>
      <Loader>Loading</Loader>
    </Dimmer>
  );
};

export default Login;
