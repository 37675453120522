import { Button, Confirm, Dropdown, Header, Icon, Responsive, Table } from 'semantic-ui-react'
import React, { useEffect, useState } from 'react'
import useGlobal from '../../Store'
import useCampaignsService from '../../Services/Campaigns'
import { dashboard } from '../../Theme/Styles'
import { StyledTable, StyledTableHeader } from '../../Theme/Components'
import { NavLink } from 'react-router-dom'
import SendSurvey from './SendSurvey/SendSurvey'
import { schoolLevels as schoolLevelOptions } from '../../Config/SelectOptions'
import SendSurveyUsers from './SendSurveyUsers'
import SendLink from './SendLink/SendLink'
import { isGenericTypeAnnotation } from '@babel/types'

const CampaignSurveysPage = (props) => {
  
  const campaignId = props.match.params.id || '0'

  const { deleteSurvey, getCampaign, getCampaignSurveys, exportSurvey } = useCampaignsService()

  const [globalState, globalActions] = useGlobal()
  const { loggedInUser, currentSchool, schools } = globalState

  const [campaign, setCampaign] = useState({})
  const [surveys, setSurveys] = useState([])

  const [confirmDelete, setConfirmDelete] = useState(0)
  const [sendSurvey, setSendSurvey] = useState(null)
  const [surveyVersion, setSurveyVersion] = useState(0)

  const [linkSurvey, setLinkSurvey] = useState()
  const [linkSurveyVersion, setLinkSurveyVersion] = useState(0)
  
  useEffect(() => {
    if (loggedInUser.role === 'Coordinator')
      globalActions.locations.getSchools()
  }, [])

  const getSendButtonLabel = (index, length) => {
    if (length === 1) {
      return 'Send Survey'
    } else if (length === 2) {
      return index === 0 ? 'Send Pre' : 'Send Post'
    } else {
      return `Send ${index + 1}`
    }
  }
  
  const getLinkButtonLabel = (index, length) => {
    if (length === 1) {
      return 'Link'
    } else if (length === 2) {
      return index === 0 ? 'Pre Link' : 'Post Link'
    } else {
      return `Link ${index + 1}`
    }
  }

  const getReportButtonLabel = (index, length) => {
    if (length === 1) {
      return 'Report'
    } else if (length === 2) {
      return index === 0 ? 'Pre Report' : 'Post Report'
    } else {
      return `Report ${index + 1}`
    }
  }
 
  const _getLink = (survey, index) => {
    if (!currentSchool) {
      globalActions.notifications.showNotification({
        type: 'warning',
        header: 'You must select a school first',
      })

      return null
    }
    
    const currentSchoolLevel = schools.find(s => s.id === currentSchool)?.schoolLevel

    if (currentSchoolLevel !== 'HYBRID' && !survey.schoolLevels.some(sl => sl.name === currentSchoolLevel)) {
      globalActions.notifications.showNotification({
        type: 'warning',
        header: 'Selected school doesn\'t have the required level.',
      })

      return null
    }

    setLinkSurvey(survey)
    setLinkSurveyVersion(index)
  }

  const renderSurveyButtons = (survey, version = '') => {
    const { id, numberOfCompletions } = survey
    //console.log(surveys)
    return (
      <>
        {campaign.type === 'PREVENTION'
          ? (
            <Button key={version === 'pre' ? 0 : 1} icon labelPosition='left' size="mini" style={{ width: 110 }}
                    color="blue"
                    as={NavLink} to={`/campaigns/${campaignId}/surveys/${id}/reports/${version === 'pre' ? 0 : 1}`}>
              <Icon name='user'/>
              Report
            </Button>
          )
          :
          [...Array(numberOfCompletions || 1).keys()].map(index => (
            <Button key={index} icon labelPosition='left' size="mini" style={{ width: 110 }} color="blue"
                    as={NavLink} to={`/campaigns/${campaignId}/surveys/${id}/reports/${index}`}>
              <Icon name='user'/>
              {getReportButtonLabel(index, numberOfCompletions || 1)}
            </Button>
          ))}

        <Button icon labelPosition='left' size="mini" style={{ width: 110 }} color="blue"
                as={NavLink} to={`/surveys/${id}/view`}>
          <Icon name='eye'/>
          View
        </Button>


        {campaign.type === 'PREVENTION'
          ? (
            <Button icon labelPosition='left' size="mini" color="green" style={{ width: 110 }}
                    onClick={() => _getLink(survey, version === 'pre' ? 0 : 1)} key={version === 'pre' ? 0 : 1}>
              <Icon name="linkify"/> Get Link
            </Button>
          )
          : [...Array(numberOfCompletions || 1).keys()].map(index => (
            <Button icon labelPosition='left' size="mini" color="green" style={{ width: 110 }}
                    key={index}
                    onClick={() => {
                      setSendSurvey(survey)
                      setSurveyVersion(index)
                    }}>
              <Icon name='send'/>
              {getSendButtonLabel(index, numberOfCompletions || 1)}
            </Button>
          ))}
      </>
    )
  }

  const renderSurveyButtonsMobile = (survey) => {
    const { id, numberOfCompletions } = survey
    return (
      <Dropdown text='Action'>
        <Dropdown.Menu>
          {!!campaign.published && (
            <>
              {[...Array(numberOfCompletions || 1).keys()].map(index => (
                <Dropdown.Item icon='user' text={getReportButtonLabel(index, numberOfCompletions || 1)} as={NavLink}
                               to={`/campaigns/${campaignId}/surveys/${id}/reports/${index}`}/>
              ))}

              {[...Array(numberOfCompletions || 1).keys()].map(index => (
                <Dropdown.Item key={index} icon='send' text={getSendButtonLabel(index, numberOfCompletions || 1)}
                               onClick={() => {
                                 setSendSurvey(survey)
                                 setSurveyVersion(index)
                               }}/>
              ))}
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  useEffect(() => {
    if (campaignId !== '0') getCampaign(campaignId)
      .then(campaign => {
        if (campaign)
          setCampaign(campaign)
      })
  }, [])

  useEffect(() => {
    if (campaign.id) {
      getCampaignSurveys(campaignId)
        .then(surveys => setSurveys(surveys))
        .catch(() => globalActions.notifications.showNotification({
            type: 'warning',
            header: 'Failed to retrieve the surveys list',
            subheader: 'Unknown error'
          })
        )
    }
  }, [campaign])

  const _deleteSurvey = () => {
    deleteSurvey(confirmDelete)
      .then(() => {
        setSurveys(surveys.filter(c => c.id !== confirmDelete))
        setConfirmDelete(0)

        globalActions.notifications.showNotification({
          type: 'success',
          header: 'Survey Deleted',
        })
      })
  }

  const cellStyle = { marginBottom: window.innerWidth < 960 ? 10 : 0, padding: 10 }

  if (!surveys.length) return null
  var currentSchoolLevel = schools.find(s => s.id === currentSchool)?.schoolLevel;
  var currentSurveys = surveys;
  //setting current surveys to exclude the wrong surveys
  //without this - super users accounts will include all levels of surveys regardless of which current school is selected
  if(currentSchoolLevel!=="HYBRID")
    currentSurveys = surveys.filter(function(value, index, arr){ 
      if(value.schoolLevels.some((element) => { 
          return element.name === currentSchoolLevel
      } ))
        return value
            });

  const elementarySurveyId = currentSurveys.find(survey => survey.schoolLevels[0].name === 'ELEMENTARY')?.id
  const middleHighSurveyId = currentSurveys.find(survey => survey.id !== elementarySurveyId)?.id

  return (
    <>
      <StyledTableHeader>
        <Header as='h2' style={dashboard.phHeader}><strong>{campaign.name.replace("Path","PATH")}</strong></Header>

        {campaign.type === 'PREVENTION' ? (
          <div>
            {!!elementarySurveyId && (
              <Button color="green" labelPosition="left" size="mini" icon
                      onClick={() => exportSurvey(elementarySurveyId, 'PATH Survey - Elementary School Answers')}>
                <Icon name='download'/>
                Export PATH Survey - Elementary School
              </Button>
            )}

            {!!middleHighSurveyId && (
              <Button color="green" labelPosition="left" size="mini" icon
                      onClick={() => exportSurvey(middleHighSurveyId, 'PATH Survey - Middle/High School Answers')}>
                <Icon name='download'/>
                Export PATH Survey - Middle/High School
              </Button>
            )}
          </div>
        ) : (
          <div>
            {!!elementarySurveyId && (
              <Button color="green" labelPosition="left" size="mini" icon
                      onClick={() => exportSurvey(elementarySurveyId, 'Peer Helper Survey - Elementary School Answers')}>
                <Icon name='download'/>
                Export Peer Helper Survey - Elementary School
              </Button>
            )}

            {!!middleHighSurveyId && (
              <Button color="green" labelPosition="left" size="mini" icon
                      onClick={() => exportSurvey(middleHighSurveyId, 'Peer Helper Survey - Middle/High School Answers')}>
                <Icon name='download'/>
                Export Peer Helper Survey - Middle/High School
              </Button>
            )}
          </div>
        )}
      </StyledTableHeader>

      <StyledTable compact striped size='small'>
        <Responsive as={Table.Header} minWidth={960}>
          <Table.Row className="stickyTh">
            <Table.HeaderCell style={{ display: 'flex', flexGrow: 1 }}>
              Name
            </Table.HeaderCell>

            <Table.HeaderCell>
              School Levels
            </Table.HeaderCell>

            <Table.HeaderCell>
              Actions
            </Table.HeaderCell>
          </Table.Row>
        </Responsive>
        <Table.Body>
          {currentSurveys.map((survey, key) => {
            const { name, schoolLevels } = survey
            return survey.campaign.type === 'PREVENTION' ? (
              <>
                <Table.Row key={key}>
                  <Table.Cell style={cellStyle}>
                    <Responsive as='strong' maxWidth={960} style={{ marginRight: 5 }}>
                      Name:
                    </Responsive>
                    {`Pre-${name}`}
                  </Table.Cell>

                  <Table.Cell style={cellStyle}>
                    <Responsive as='strong' maxWidth={960} style={{ marginRight: 5 }}>
                      School Levels:
                    </Responsive>

                    {schoolLevelOptions.filter(s => schoolLevels.map(level => level.name).includes(s.value)).map(s => s.text).join(', ')}
                  </Table.Cell>

                  <Table.Cell style={cellStyle} collapsing>
                    <Responsive maxWidth={960} style={{ marginRight: 5 }}>
                      {renderSurveyButtonsMobile(survey, 'pre')}
                    </Responsive>

                    <Responsive minWidth={960}>
                      {renderSurveyButtons(survey, 'pre')}
                    </Responsive>
                  </Table.Cell>
                </Table.Row>
                <Table.Row key={key}>
                  <Table.Cell style={cellStyle}>
                    <Responsive as='strong' maxWidth={960} style={{ marginRight: 5 }}>
                      Name:
                    </Responsive>
                    {`Post-${name}`}
                  </Table.Cell>

                  <Table.Cell style={cellStyle}>
                    <Responsive as='strong' maxWidth={960} style={{ marginRight: 5 }}>
                      School Levels:
                    </Responsive>

                    {schoolLevelOptions.filter(s => schoolLevels.map(level => level.name).includes(s.value)).map(s => s.text).join(', ')}
                  </Table.Cell>

                  <Table.Cell style={cellStyle} collapsing>
                    <Responsive maxWidth={960} style={{ marginRight: 5 }}>
                      {renderSurveyButtonsMobile(survey, 'pre')}
                    </Responsive>

                    <Responsive minWidth={960}>
                      {renderSurveyButtons(survey, 'post')}
                    </Responsive>
                  </Table.Cell>
                </Table.Row>
              </>
            ) : (
              <Table.Row key={key}>
                <Table.Cell style={cellStyle}>
                  <Responsive as='strong' maxWidth={960} style={{ marginRight: 5 }}>
                    Name:
                  </Responsive>
                  {`${name} - ${schoolLevels.length === 1 ? 'Elementary School' : 'Middle/High School'}`}
                </Table.Cell>

                <Table.Cell style={cellStyle}>
                  <Responsive as='strong' maxWidth={960} style={{ marginRight: 5 }}>
                    School Levels:
                  </Responsive>

                  {schoolLevelOptions.filter(s => schoolLevels.map(level => level.name).includes(s.value)).map(s => s.text).join(', ')}
                </Table.Cell>

                <Table.Cell style={cellStyle} collapsing>
                  <Responsive maxWidth={960} style={{ marginRight: 5 }}>
                    {renderSurveyButtonsMobile(survey)}
                  </Responsive>

                  <Responsive minWidth={960}>
                    {renderSurveyButtons(survey)}
                  </Responsive>
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </StyledTable>

      <Confirm
        open={!!confirmDelete}
        header='Are you sure you want to delete this survey?'
        content="This action is permanent and the data cannot be recovered after that"
        onCancel={() => setConfirmDelete(0)}
        onConfirm={_deleteSurvey}
      />

      {loggedInUser.role.toLowerCase() !== 'coordinator' ? (
        <SendSurvey
          open={!!sendSurvey}
          survey={sendSurvey}
          version={surveyVersion}
          onClose={() => {
            setSendSurvey(null)
            setSurveyVersion(0)
          }}
        />
      ) : (
        <SendSurveyUsers
          open={!!sendSurvey}
          survey={sendSurvey}
          version={surveyVersion}
          onClose={() => {
            setSendSurvey(null)
            setSurveyVersion(0)
          }}
        />
      )}

      {!!linkSurvey && (
        <SendLink open={!!linkSurvey} survey={linkSurvey} version={linkSurveyVersion}
                  onClose={() => setLinkSurvey(undefined)}/>
      )}

    </>
  )
}

export default CampaignSurveysPage
