import React, { useEffect, useState } from 'react'
import { Button, Divider, Form, Header, Segment } from 'semantic-ui-react'
import { dashboard } from '../../../Theme/Styles'
import useGlobal from '../../../Store'

const Filters = ({ filters, setFilters, resetFilters, filterReport }) => {
  const [globalState, globalActions] = useGlobal()
  const { userManagementStates, userManagementDistricts } = globalState

  const [stateOptions, setStateOptions] = useState([])
  const [districtOptions, setDistrictOptions] = useState([])

  const _resetFilters = () => {
    setDistrictOptions([])

    resetFilters()
  }

  useEffect(() => {
    globalActions.locations.getStates(true)
  }, [])

  useEffect(() => {
    if (filters.stateId) {
      globalActions.locations.getDistricts(filters.stateId, true)
    }
  }, [filters.stateId])

  useEffect(() => {
    setStateOptions(userManagementStates ? userManagementStates.map((state, key) => ({
      key,
      text: state.name,
      value: state.id
    })) : [])

    setDistrictOptions(userManagementDistricts ? userManagementDistricts.map((district, key) => ({
      key,
      text: district.name,
      value: district.id
    })) : [])
  }, [userManagementStates, userManagementDistricts])

  return (
    <Segment>
      <Header as='h3' style={dashboard.myProgramHeader}>Report Filters</Header>

      <Divider/>

      <Form size='large'>
        <Form.Group className='custom-form-group'>
          <label className='small-label'>State</label>
          <Form.Select placeholder='Select a State' options={stateOptions} value={filters.stateId}
                       onChange={(e, data) => {
                         setFilters({ ...filters, stateId: data.value })
                         setDistrictOptions([])
                         globalActions.locations.getDistricts(data.value, true)
                       }}/>
        </Form.Group>

        {districtOptions.length > 0 && (
          <Form.Group className='custom-form-group'>
            <label className='small-label'>District</label>
            <Form.Select placeholder='Select a District' options={districtOptions} value={filters.districtId}
                         onChange={(e, data) => {
                           setFilters({ ...filters, districtId: data.value })
                         }}/>
          </Form.Group>
        )}

        <div style={{ textAlign: 'right', marginTop: 10 }}>
          <Button.Group>
            <Button onClick={_resetFilters}>Clear All</Button>
            <Button.Or/>
            <Button color='blue' onClick={filterReport} disabled={filters.stateId === ''}>Search</Button>
          </Button.Group>
        </div>
      </Form>
    </Segment>
  )
}

export default Filters