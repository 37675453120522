import { Button, Form, Input, Modal } from 'semantic-ui-react'
import React, { useState } from 'react'
import useGlobal from '../../../Store'
import useCampaignsService from '../../../Services/Campaigns'

const SendLink = ({ survey, version, open, onClose }) => {
  const [globalState, globalActions] = useGlobal()
  const {saveForecast} = useCampaignsService()
  const { currentSchool } = globalState

  const [students, setStudents] = useState('')
  const [showLink, setShowLink] = useState(false)

  const _save = async () => {
    if (!students || !survey.id || students<1) {
      globalActions.notifications.showNotification({ header: 'Enter the students number', type: 'warning' })
      return null
    }

    await saveForecast(survey.id, students, version)

    setShowLink(true)
  }

  const _onClose = () => {
    setShowLink(false)
    onClose()
  }

  return (
    <Modal open={open} onClose={_onClose}>
      <Modal.Header>Generate survey link</Modal.Header>

      <Modal.Content>
        <Form>
          <Form.Field required style={{ marginBottom: 30 }}>
            <label>Number of students</label>
            <input placeholder='Students' value={students} name="students"
                   disabled={showLink}
                   style={{
                     paddingLeft: 2,
                     borderWidth: 0,
                     borderRadius: 0,
                     borderBottomWidth: 1,
                     borderBottomColor: '#000'
                   }}
                   onChange={e => {
                     if (/[0-9]$/.test(e.target.value)) setStudents(e.target.value)
                   }}/>
          </Form.Field>
        </Form>

        {showLink && (
          <Input
            action={{
              color: 'teal',
              labelPosition: 'right',
              icon: 'copy',
              content: 'Copy',
              onClick: () => {
                navigator.clipboard.writeText(`${window.location.protocol}//${window.location.host}/surveys/${survey.id}/external/take/versions/${version}/schools/${currentSchool}`)
                _onClose()
                globalActions.notifications.showNotification({ header: 'Link copied to clipboard', type: 'success' })
              }
            }}
            fluid
            value={`${window.location.protocol}//${window.location.host}/surveys/${survey.id}/external/take/versions/${version}/schools/${currentSchool}`}
          />
        )}
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={_onClose}>
          Close
        </Button>
        {!showLink && (
          <Button positive onClick={_save}>
            Save
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  )
}

export default SendLink
