import React, { useEffect, useState } from 'react'
import { Loader, Divider, Header, Segment, Table, Icon, Checkbox, } from 'semantic-ui-react'
import { dashboard } from '../../Theme/Styles'
import useGlobal from '../../Store'
import MyProgramNoSchoolSelected from './MyProgramNoSchoolSelected'
import styled from 'styled-components'
import MyProgramsCategoryPicker from '../MyProgramsCategoryPicker/MyProgramsCategoryPicker'
import { NavLink } from 'react-router-dom'
import MyProgramNoPrograms from './MyProgramNoPrograms'
import { KeyboardDatePicker } from '@material-ui/pickers'
import _ from 'lodash'

const MyProgramSuperUser = (props) => {
  const [globalState, globalActions] = useGlobal()
  const { currentSchool } = globalState

  const [programs, setPrograms] = useState(globalState.programs)
  const [category, setCategory] = useState('')
  const [subcategory, setSubcategory] = useState('')

  const onDateChange = (date, subcategory, group, subGroup, document) => {
    if (date === null) {
      globalActions.myProgram.saveProgram({ ...document, date: null, schoolId: currentSchool })
    } else if (date.format('YYYY-MM-DD') !== 'Invalid date') {

      const itemIndex = _.findIndex(programs.documents[subcategory + '/' + group + (subGroup !== '' ? '/' + subGroup : '')], doc => {
        return doc.id === document.id
      })

      let documentsCopy = _.clone(programs.documents[subcategory + '/' + group + (subGroup !== '' ? '/' + subGroup : '')])
      documentsCopy[itemIndex] = { ...document, date: date.format('YYYY-MM-DD') + ' 00:00:00' }
      setPrograms({
        ...programs,
        documents: {
          ...programs.documents,
          [subcategory + '/' + group + (subGroup !== '' ? '/' + subGroup : '')]: documentsCopy
        }
      })

      globalActions.myProgram.saveProgram({
        ...document,
        schoolId: currentSchool,
        date: date.format('YYYY-MM-DD') + ' 00:00:00'
      })
    }
  }

  const onComplete = (subcategory, group, subGroup, document) => {
    const itemIndex = _.findIndex(programs.documents[subcategory + '/' + group + (subGroup !== '' ? '/' + subGroup : '')], doc => {
      return doc.id === document.id
    })

    if (itemIndex > -1) {
      let documentsCopy = _.clone(programs.documents[subcategory + '/' + group + (subGroup !== '' ? '/' + subGroup : '')])
      documentsCopy[itemIndex] = { ...document, completed: !document.completed }
      setPrograms({
        ...programs,
        documents: {
          ...programs.documents,
          [subcategory + '/' + group + (subGroup !== '' ? '/' + subGroup : '')]: documentsCopy
        }
      })

      globalActions.myProgram.saveProgram({ ...document, schoolId: currentSchool, completed: !document.completed })
    }
  }

  useEffect(() => {
    setPrograms([])
    setSubcategory("")
    globalActions.myProgram.getMyProgram()
  }, [currentSchool, props.location.pathname])

  useEffect(() => {
    setPrograms(globalState.programs)
    if (globalState.programs.categories && globalState.programs.categories[0] && subcategory === '') {
      setCategory(globalState.programs.categories[0])
      setSubcategory(globalState.programs.subCategories[globalState.programs.categories[0]][0])
    }
  }, [globalState.programs])

  return (
    <Segment>
      <Header as="h3" style={dashboard.myProgramHeader}>
        My Programs
      </Header>

      <Divider/>

      {currentSchool !== '' ? (
        <>
          {programs.length === undefined ? (
            <>
              {Object.keys(programs.documents).length === 0 ? (
                <MyProgramNoPrograms/>
              ) : (
                <>
                  <MyProgramsCategoryPicker
                    category={category}
                    setCategory={setCategory}
                    subcategory={subcategory}
                    setSubcategory={setSubcategory}
                    programs={programs}
                  />

                  {(category !== '' && subcategory !== '') && (
                    <>
                      {programs.groups[subcategory] && programs.groups[subcategory].map((group, key1) => (
                        <Table style={{ borderWidth: 1, borderColor: 'black', borderStyle: 'solid' }} key={key1}>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell colSpan={3}>
                                {group}
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>

                          <Table.Body>
                            {programs.subGroups[subcategory + '/' + group] && programs.subGroups[subcategory + '/' + group].map(subGroup => (
                              <>
                                <Table.Row>
                                  <Table.Cell style={{ paddingLeft: 20 }} colSpan={3}>
                                    {subGroup}
                                  </Table.Cell>
                                </Table.Row>

                                {programs.documents[subcategory + '/' + group + '/' + subGroup] && programs.documents[subcategory + '/' + group + '/' + subGroup].map((document, key3) =>
                                  <Table.Row key={key3}>
                                    <Table.Cell style={{ paddingLeft: 40 }}>
                                      <NavLink to={'?pdfPath=/documents/' + document.id}>{document.name}</NavLink>
                                    </Table.Cell>
                                    <Table.Cell>
                                      <KeyboardDatePicker
                                        value={document.date}
                                        onChange={date => onDateChange(date, subcategory, group, subGroup, document)}
                                        format='MM/DD/YY'
                                        InputAdornmentProps={{ style: { padding: 0, margin: 0 } }}
                                        InputProps={{
                                          style: {
                                            maxWidth: 90,
                                            fontSize: 11,
                                            borderBottom: '1px solid rgba(0,0,0,.1)'
                                          },
                                          disableUnderline: true,
                                          placeholder: 'mm/dd/yy'
                                        }}
                                        invalidDateMessage='Invalid date'
                                      />
                                    </Table.Cell>

                                    <Table.Cell>
                                      <Checkbox
                                        key={'checkbox' + key3}
                                        checked={document.completed || false}
                                        onChange={() => onComplete(subcategory, group, subGroup, document)}
                                        style={{ paddingTop: 3 }}
                                      />
                                    </Table.Cell>
                                  </Table.Row>
                                )}
                              </>
                            ))}

                            {programs.documents[subcategory + '/' + group] && programs.documents[subcategory + '/' + group].map((document, key2) => (
                              <Table.Row key={key2}>
                                <Table.Cell>
                                  <NavLink to={'?pdfPath=/documents/' + document.id}>{document.name}</NavLink>
                                </Table.Cell>
                                <Table.Cell>
                                  <KeyboardDatePicker
                                    value={document.date}
                                    onChange={date => onDateChange(date, subcategory, group, '', document)}
                                    format='MM/DD/YY'
                                    InputAdornmentProps={{ style: { padding: 0, margin: 0 } }}
                                    InputProps={{
                                      style: { maxWidth: 90, fontSize: 11, borderBottom: '1px solid rgba(0,0,0,.1)' },
                                      disableUnderline: true,
                                      placeholder: 'mm/dd/yy'
                                    }}
                                    invalidDateMessage='Invalid date'
                                  />
                                </Table.Cell>

                                <Table.Cell>
                                  <Checkbox
                                    key={'checkbox' + key2}
                                    checked={document.completed || false}
                                    onChange={() => onComplete(subcategory, group, '', document)}
                                    style={{ paddingTop: 3 }}
                                  />
                                </Table.Cell>
                              </Table.Row>
                            ))}
                          </Table.Body>
                        </Table>
                      ))}
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <Icon loading name="spinner" style={{ alignSelf: 'center', fontSize: 35 }}/>
            </div>
          )}
        </>
      ) : (
        <MyProgramNoSchoolSelected/>
      )}
    </Segment>
  )
}

const StyledProgramLabel = styled.label`
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 550;
`

export default MyProgramSuperUser
