import { Button, Grid, Header } from 'semantic-ui-react'
import React from 'react'
import SchoolSelect from './SchoolSelect'
import DistrictSchoolSelect from './DistrictSchoolSelect'
import DistrictSelect from './DistrictSelect'
import StateSelect from './StateSelect'

const isMobile = window.innerWidth < 1000

const decideSelectBasedOnRole = (role, currentSchool, currentDistrict, currentState, setCookie, changeState, changeDistrict, changeSchool) => {
  switch (role) {
    case 'Super User':
      return (
        <Grid divided columns={3} style={{ minWidth: isMobile ? 'auto' : 1125 }}>
          <Grid.Column textAlign="left" style={{ width: isMobile ? '100%' : 375 }}>
            <Header as="h4" style={{ textAlign: 'left' }}>State</Header>
            <StateSelect/>
            <Button color="red" size="mini" onClick={() => changeState(setCookie, '')}>Clear</Button>
          </Grid.Column>

          <Grid.Column textAlign="left" style={{ width: isMobile ? '100%' : 375 }}>
            <Header as="h4" style={{ textAlign: 'left' }}>District</Header>
            {currentState !== '' ? (
              <React.Fragment>
                <DistrictSelect currentState={currentState}/>
                <Button color="red" size="mini" onClick={() => changeDistrict(setCookie, '')}>Clear</Button>
              </React.Fragment>
            ) : (
              <p style={{ color: 'rgba(0, 0, 0, 0.65)' }}>No state selected</p>
            )}
          </Grid.Column>

          <Grid.Column textAlign="left" style={{ width: isMobile ? '100%' : 375 }}>
            <Header as="h4" style={{ textAlign: 'left' }}>School</Header>
            {currentDistrict !== '' ? (
              <React.Fragment>
                <DistrictSchoolSelect currentDistrict={currentDistrict}/>
                <Button color="red" size="mini" onClick={() => changeSchool(setCookie, '')}>Clear</Button>
              </React.Fragment>
            ) : (
              <p style={{ color: 'rgba(0, 0, 0, 0.65)' }}>No district selected</p>
            )}
          </Grid.Column>
        </Grid>
      )
    case 'State Level':
      return (
        <Grid divided columns={3} style={{ minWidth: isMobile ? 'auto' : 1125 }}>
          <Grid.Column textAlign="left" style={{ width: isMobile ? '100%' : 375 }}>
            <Header as="h4" style={{ textAlign: 'left' }}>District</Header>
            <React.Fragment>
              <DistrictSelect currentState={currentState}/>
              <Button color="red" size="mini" onClick={() => changeDistrict(setCookie, '')}>Clear</Button>
            </React.Fragment>
          </Grid.Column>

          <Grid.Column textAlign="left" style={{ width: isMobile ? '100%' : 375 }}>
            <Header as="h4" style={{ textAlign: 'left' }}>School</Header>
            {currentDistrict !== '' ? (
              <React.Fragment>
                <DistrictSchoolSelect currentDistrict={currentDistrict}/>
                <Button color="red" size="mini" onClick={() => changeSchool(setCookie, '')}>Clear</Button>
              </React.Fragment>
            ) : (
              <p style={{ color: 'rgba(0, 0, 0, 0.65)' }}>No district selected</p>
            )}
          </Grid.Column>
        </Grid>
      )

    case 'District Level':
      return (
        <Grid divided columns={3} style={{ minWidth: isMobile ? '100%' : 750 }}>
          <Grid.Column textAlign="left" style={{ width: isMobile ? '100%' : 375 }}>
            <Header as="h4" style={{ textAlign: 'left' }}>School</Header>
            <React.Fragment>
              <DistrictSchoolSelect role={role} currentDistrict={currentDistrict}/>
              <Button color="red" size="mini" onClick={() => changeSchool(setCookie, '')}>Clear</Button>
            </React.Fragment>
          </Grid.Column>
        </Grid>
      )

    case 'Coordinator':
    case 'School Level':
    case 'Peer Helper':
      return (
        <Grid divided columns={3} style={{ minWidth: isMobile ? '100%' : 375 }}>
          <Grid.Column textAlign="left" style={{ width: isMobile ? '100%' : 375 }}>
            <Header as="h4" style={{ textAlign: 'left' }}>School</Header>
            <SchoolSelect/>
            <Button color="red" size="mini" onClick={() => changeSchool(setCookie, '')}>Clear</Button>
          </Grid.Column>
        </Grid>
      )
    default:
      return <div/>
  }
}

const HierarchySelect = ({ role, currentSchool, currentDistrict, currentState, setCookie, changeState, changeDistrict, changeSchool }) => (
  <>
    {decideSelectBasedOnRole(role, currentSchool, currentDistrict, currentState, setCookie, changeState, changeDistrict, changeSchool)}
  </>
)

export default HierarchySelect
