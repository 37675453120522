import axios from 'axios'
import endpoints, { baseUrl } from '../Config/ApiEndpoints'
import { processRequestErrorNotification } from '../Utils/Error'
import * as _ from 'lodash'
import {
  editedSchool as defaultEditedSchool,
  superUserSchoolsPagination as defaultSuperUserSchoolsPagination
} from '../Config/DefaultState'

export const getSchools = async (store) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }
  store.setState({ requestStatus: 'LOADING' })
  try {
    const response = await axios.get(endpoints.locations.schools, {
      headers: {
        Authorization: `Bearer ${store.state.loggedInUser.accessToken}`
      }
    })

    const schools = []
    _.forEach(response.data._embedded.schools, (school) => {
      schools.push({ ...school, key: school.id })
    })

    // store.actions.locations.setSchool(schools[0].id);
    store.setState({ requestStatus: 'SUCCESS', schools })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ schools: [], requestStatus: 'FAILED', notification })
  }
}

export const getSuperUserSchools = async (store, page = 0, size = 10000) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }
  store.setState({ requestStatus: 'LOADING' })
  try {
    const response = await axios.get(
      endpoints.locations.superUserSchools + `?page=${page}&size=${size}`,
      {
        headers: {
          Authorization: `Bearer ${store.state.loggedInUser.accessToken}`
        }
      }
    )

    const superUserSchools = response.data._embedded.schools
    const superUserSchoolsPagination = response.data.page

    store.setState({
      requestStatus: 'SUCCESS',
      superUserSchools,
      superUserSchoolsPagination
    })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({
      superUserSchools: [],
      requestStatus: 'FAILED',
      notification,
      superUserSchoolsPagination: defaultSuperUserSchoolsPagination
    })
  }
}

export const getSchool = async (store, id) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  store.setState({ requestStatus: 'LOADING' })
  try {
    const response = await axios.get(
      endpoints.locations.superUserSchools + '/' + id,
      {
        headers: {
          Authorization: `Bearer ${store.state.loggedInUser.accessToken}`
        }
      }
    )
    const editedSchool = response.data

    store.setState({ requestStatus: 'SUCCESS', editedSchool })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({
      editedSchool: defaultEditedSchool,
      requestStatus: 'FAILED',
      notification
    })
  }
}

export const updateSchoolEnrollments = async (store, stateId, districtId, schoolId, enrollment) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  const url = baseUrl + '/hierarchy/schools/' + schoolId + '/enrollment'
  store.setState({ requestStatus: 'LOADING' })

  try {
    await axios.patch(url, { stateId, districtId, enrollment }, {
      headers: {
        Authorization: `Bearer ${store.state.loggedInUser.accessToken}`
      }
    })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ requestStatus: 'FAILED', notification })
  }
}

export const saveSchool = async (store, school) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  let url =
    endpoints.locations.districts + '/' + school.districtId + '/schools'
  store.setState({ requestStatus: 'LOADING' })

  try {
    if (school.id === '') {
      await axios.post(url, school, {
        headers: {
          Authorization: `Bearer ${store.state.loggedInUser.accessToken}`
        }
      })
    } else {
      await axios.patch(url + '/' + school.id, school, {
        headers: {
          Authorization: `Bearer ${store.state.loggedInUser.accessToken}`
        }
      })
    }
    const notification = {
      type: 'success',
      header: `School ${school.id === '' ? 'added' : 'updated'}`
    }
    setTimeout(() => {
      store.setState({ requestStatus: 'SUCCESS', notification })
    }, 500)
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ requestStatus: 'FAILED', notification })
  }
}

export const getDistrictsSchools = async (
  store,
  currentDistrict = '',
  userManagement = false
) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  const url =
    currentDistrict !== ''
      ? endpoints.locations.districts + '/' + currentDistrict + '/schools'
      : endpoints.locations.myHierarchy
  store.setState({ requestStatus: 'LOADING' })
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${store.state.loggedInUser.accessToken}`
      }
    })
    let schools = []
    if (response.data._embedded.districts) {
      schools = response.data._embedded.districts[0].schools
    } else {
      schools = response.data._embedded.schools
    }

    if (userManagement) {
      store.setState({
        requestStatus: 'SUCCESS',
        userManagementSchools: schools
      })
    } else {
      store.setState({ requestStatus: 'SUCCESS', schools })
    }
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ schools: [], requestStatus: 'FAILED', notification })
  }
}

export const getDistricts = async (store, currentState = '', userManagement = false) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  const url = currentState !== '' ? endpoints.locations.root + '/' + currentState : endpoints.locations.myHierarchy
  store.setState({ requestStatus: 'LOADING' })
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${store.state.loggedInUser.accessToken}`
      }
    })

    let districts = []
    if (currentState !== '') {
      districts = response.data.districts
    } else {
      if (response.data._embedded.hierarchy && response.data._embedded.hierarchy[0]) {
        districts = response.data._embedded.hierarchy[0].districts
      } else {
        districts = response.data._embedded.hierarchy.districts
      }

    }

    if (userManagement) {
      store.setState({ requestStatus: 'SUCCESS', userManagementDistricts: districts })
    } else {
      store.setState({ requestStatus: 'SUCCESS', districts })
    }
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ districts: [], requestStatus: 'FAILED', notification })
  }
}

export const getStates = async (store, userManagement = false) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  store.setState({ requestStatus: 'LOADING' })
  try {
    const response = await axios.get(endpoints.locations.myHierarchy, {
      headers: {
        Authorization: `Bearer ${store.state.loggedInUser.accessToken}`
      }
    })
    const states = response.data._embedded.hierarchy

    if (userManagement) {
      store.setState({
        requestStatus: 'SUCCESS',
        userManagementStates: states
      })
    } else {
      store.setState({ requestStatus: 'SUCCESS', states })
    }
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ states: [], requestStatus: 'FAILED', notification })
  }
}

export const changeSchool = async (store, setCookie, currentSchool) => {
  await setCookie('currentSchool', currentSchool)
  store.setState({ currentSchool })
}

export const setSchool = async (store, currentSchool) => {
  store.setState({ currentSchool })
}

export const changeDistrict = async (store, setCookie, currentDistrict) => {
  await setCookie('currentSchool', '')
  await setCookie('currentDistrict', currentDistrict)
  store.setState({ currentDistrict, currentSchool: '' })
}

export const setDistrict = async (store, currentDistrict) => {
  store.setState({ currentDistrict })
}

export const changeState = async (store, setCookie, currentState) => {
  await setCookie('currentSchool', '')
  await setCookie('currentDistrict', '')
  await setCookie('currentState', currentState)
  store.setState({ currentState, currentDistrict: '', currentSchool: '' })
}

export const setState = async (store, currentState) => {
  store.setState({ currentState })
}

// -----Chetu code -> API call for save state --------------- //
export const handleSaveState = async (store, state) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }
  store.setState({ requestStatus: 'LOADING' })
  try {
    const response = await axios.post(
      endpoints.locations.states,
      { name: state.name.trim() },
      {
        headers: {
          Authorization: `Bearer ${store.state.loggedInUser.accessToken}`
        }
      }
    )
    if (response && response.data.result && response.status === 202) {
      store.setState({
        states: response.data.details,
        requestStatus: 'SUCCESS',
        notification: { type: 'success', header: 'State Added Successfully' }
      })
      return true
    } else {
      store.setState({
        requestStatus: 'FAILED',
        notification: {
          header: 'Error',
          subheader: response.data.message
        }
      })
    }
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ requestStatus: 'FAILED', notification, editState: state })
  }
}

// -----Chetu code -> API call for get state --------------- //
export const getState = async (store) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }
  store.setState({ requestStatus: 'LOADING' })
  try {
    const response = await axios.get(endpoints.locations.states, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.state.loggedInUser.accessToken}`
      }
    })
    const states = []
    _.forEach(response.data.details, (state) => {
      state.isSelected = false
      states.push({ ...state, key: state.id })
    })
    // store.actions.locations.setState(states[0].id)
    if ((response && response.status === 202) || response.status === 200) {
      await store.setState({ requestStatus: 'SUCCESS', states })
      return true
    }
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ states: [], requestStatus: 'FAILED', notification })
  }
}

// -----Chetu code -> functionality to update global state in edit state --------------- //
export const handleEditState = async (store, editState = null) => {
  if (editState) {
    let idx = editState ? editState.idx : null
    let stateData = await Object.assign([], store.state.states)
    stateData.map((i) => (i.isSelected = false))
    if (stateData && stateData.length > 0) stateData[idx].isSelected = true
    store.setState({ editState, states: stateData })
  } else {
    let states = Object.assign([], store.state.states)
    states.map((i) => (i.isSelected = false))
    store.setState({ editState: { id: '', name: '' }, states })
  }
}

// -----Chetu code -> API call for edit state --------------- //
export const handleEditStateAPI = async (store, data) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }
  store.setState({ requestStatus: 'LOADING' })
  try {
    const response = await axios.patch(
      `${endpoints.locations.states}/${data.id}`,
      { name: data.name.trim() },
      {
        headers: {
          Authorization: `Bearer ${store.state.loggedInUser.accessToken}`
        }
      }
    )
    if (response && response.data.result && response.status === 202) {
      store.setState({
        states: response.data.details,
        requestStatus: 'SUCCESS',
        notification: { type: 'success', header: 'State updated Successfully' }
      })
      return true
    } else {
      store.setState({
        requestStatus: 'FAILED',
        notification: {
          header: 'Error',
          subheader: response.data.message
        }
      })
    }
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ requestStatus: 'FAILED', notification })
  }
}

// -----Chetu code -> API call for delete state --------------- //
export const handleDeleteStateAPI = async (store, data) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }
  store.setState({ requestStatus: 'LOADING' })
  try {
    const response = await axios.delete(
      `${endpoints.locations.states}/${data.id}`,
      {
        headers: {
          Authorization: `Bearer ${store.state.loggedInUser.accessToken}`
        }
      }
    )
    if (
      (response && response.data.result && response.status === 202) ||
      response.status === 200
    ) {
      store.setState({
        // states: response.data.details,
        requestStatus: 'SUCCESS',
        notification: { type: 'success', header: 'State Deleted Successfully' }
      })
      return true
    } else {
      store.setState({
        requestStatus: 'FAILED',
        notification: {
          header: 'Error',
          subheader: response.data.message
        }
      })
    }
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ requestStatus: 'FAILED', notification })
  }
}

// -----Chetu code -> Functionality to update global district label  --------------- //
export const handleUpdateDistrictLable = async (
  store,
  editDistrictLable = 'District'
) => {
  store.setState({ editDistrictLable })
}

// -----Chetu code -> Functionality to update global district in edit district  --------------- //
export const handleEditDistrict = async (store, editDistrict = null) => {
  if (editDistrict) {
    let idx = editDistrict ? editDistrict.idx : null
    let stateData = await Object.assign([], store.state.districts)
    stateData.map((i) => (i.isSelected = false))
    if (stateData && stateData.length > 0) stateData[idx].isSelected = true
    store.setState({ editDistrict, districts: stateData })
  } else {
    let districts = Object.assign([], store.state.districts)
    districts.map((i) => (i.isSelected = false))
    store.setState({
      editDistrict: { id: '', name: '', stateId: '' },
      districts
    })
  }
}

// -----Chetu code -> API call for get List and filter District --------------- //
export const getDistrictList = async (store, stateId = null) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }
  store.setState({ requestStatus: 'LOADING' })
  try {
    let url = stateId
      ? `${endpoints.locations.root}/${stateId}${endpoints.locations.labelDistrict}`
      : `${endpoints.locations.root}${endpoints.locations.labelDistrict}`
    const response = await axios.get(url)
    const districts = []
    // response.data._embedded.districts
    _.forEach(response.data.details, (i) => {
      i.isSelected = false
      districts.push({ ...i, key: i.id })
    })
    store.setState({ requestStatus: 'SUCCESS', districts })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ states: [], requestStatus: 'FAILED', notification })
  }
}

// -----Chetu code -> API call for List, Add, Edit and delete District --------------- //
export const handleDistrictAPI = async (store, data, type) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }
  store.setState({ requestStatus: 'LOADING' })
  try {
    let apiUrl =
      type === 'add' || type === 'edit'
        ? `${endpoints.locations.root}/${data.stateId}${endpoints.locations.labelDistrict}`
        : `${endpoints.locations.districts}/${data.id}`
    let apiType =
      type === 'add' ? 'POST' : type === 'edit' ? 'PATCH' : 'DELETE'
    let msg =
      type === 'add'
        ? 'District added successfully'
        : type === 'edit'
        ? 'District updated successfully'
        : 'District deleted successfully'
    let dataToSend = { name: data.name.trim() }
    if (type === 'edit') {
      dataToSend['districtId'] = data.id
    }
    if (apiUrl) {
      const response = await axios({
        url: apiUrl,
        method: apiType,
        data: type !== 'delete' ? dataToSend : null,
        headers: {
          Authorization: `Bearer ${store.state.loggedInUser.accessToken}`
        }
      })
      if (
        (response && response.data.result && response.status === 202) ||
        response.status === 200
      ) {
        store.setState({
          // districts: response.data.details,
          requestStatus: 'SUCCESS',
          notification: { type: 'success', header: msg }
        })
        return true
      } else {
        store.setState({
          requestStatus: 'FAILED',
          notification: {
            header: 'Error',
            subheader: response.data.message
          }
        })
      }
    }
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ requestStatus: 'FAILED', notification })
  }
}

// -----Chetu code -> API call for List, Add, Edit and delete School --------------- //
export const handleSchoolAPI = async (store, data, type) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }
  store.setState({ requestStatus: 'LOADING' })
  let selGrade = []
  if (data && data.grade && data.grade.length > 0) {
    let grade = data.grade.filter((i) => {
      if (i.ischecked) {
        selGrade.push(i.value)
        return true
      }
    })
  }
  let dataToSend = {
    stateId: data.stateId,
    districtId: data.districtId,
    name: data.name.trim(),
    schoollevel_id: data.level,
    grades: selGrade.toString()
  }

  try {
    let apiUrl =
      type === 'add'
        ? `${endpoints.locations.root}${endpoints.locations.labelSchool}`
        : type === 'edit' || type === 'delete'
        ? `${endpoints.locations.root}${endpoints.locations.labelSchool}/${data.id}`
        : ''
    let apiType =
      type === 'add' ? 'POST' : type === 'edit' ? 'PATCH' : 'DELETE'
    let msg =
      type === 'add'
        ? 'School added successfully'
        : type === 'edit'
        ? 'School updated successfully'
        : 'School deleted successfully'
    if (apiUrl) {
      const response = await axios({
        url: apiUrl,
        method: apiType,
        data: type !== 'delete' ? dataToSend : null,
        headers: {
          Authorization: `Bearer ${store.state.loggedInUser.accessToken}`
        }
      })
      if (
        (response && response.data.result && response.status === 202) ||
        response.status === 200
      ) {
        store.setState({
          // superUserSchools: response.data.details,
          requestStatus: 'SUCCESS',
          notification: { type: 'success', header: msg }
        })
        return true
      } else {
        store.setState({
          requestStatus: 'FAILED',
          notification: {
            header: 'Error',
            subheader: response.data.message
          }
        })
      }
    }
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ requestStatus: 'FAILED', notification })
  }
}

// -----Chetu code -> API call for List School levels--------------- //
export const getSchoolLevel = async (store) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }
  store.setState({ requestStatus: 'LOADING' })
  try {
    const response = await axios.get(
      `${endpoints.locations.root}${endpoints.locations.schoolLevel}`,
      {
        headers: {
          Authorization: `Bearer ${store.state.loggedInUser.accessToken}`
        }
      }
    )
    if ((response && response.status === 202) || response.status === 200) {
      store.setState({
        requestStatus: 'SUCCESS',
        schoolLevel: response.data.details
      })
    }
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ requestStatus: 'FAILED', notification })
  }
}

// -----Chetu code -> API call for School Listing and filter --------------- //
export const getSchoolListing = async (
  store,
  stateId = '',
  districtId = ''
) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }
  store.setState({ requestStatus: 'LOADING' })
  try {
    const response = await axios.get(
      `${endpoints.locations.root}${endpoints.locations.labelSchool}?stateId=${stateId}&districtId=${districtId}`,
      {
        headers: {
          Authorization: `Bearer ${store.state.loggedInUser.accessToken}`
        }
      }
    )

    if ((response && response.status === 202) || response.status === 200) {
      const schoolData = []
      _.forEach(response.data.details, (i) => {
        i.isSelected = false
        schoolData.push({ ...i, key: i.id })
      })

      store.setState({
        requestStatus: 'SUCCESS',
        superUserSchools: schoolData
      })
    }
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ requestStatus: 'FAILED', notification })
  }
}

// -----Chetu code -> function to handle select/deselect and edit school --------------- //
export const handleSelectSchool = async (store, data = null, type = null) => {
  let schoolData = await Object.assign([], store.state.superUserSchools)
  if (!type) {
    if (Object.keys(data).length > 0 && schoolData.length > 0) {
      schoolData.map((i) => (i.isSelected = false))
      schoolData[data.idx].isSelected = true

      let editedSchool = {
        id: data.id,
        name: data.name,
        districtId: data.districtId,
        stateId: data.stateId,
        level: data.schoollevel_id
      }

      store.setState({ superUserSchools: schoolData, editedSchool })
    }
  } else {
    schoolData.map((i) => (i.isSelected = false))
    let editedSchool = {
      id: '',
      name: '',
      districtId: '',
      stateId: '',
      level: ''
    }
    store.setState({ superUserSchools: schoolData, editedSchool })
  }
}
