import endpoints from '../Config/ApiEndpoints'
import { processRequestErrorNotification } from '../Utils/Error'
import * as _ from 'lodash'
import axios from 'axios'

export const getRoles = async (store) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  store.setState({ requestStatus: 'LOADING' })
  try {
    const response = await axios.get(endpoints.locations.roles, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })

    const roles = _.map(response.data._embedded.roles, role => ({
      key: role.id,
      text: role.name,
      value: role.id
    }))
    store.setState({ requestStatus: 'SUCCESS', roles })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ roles: [], requestStatus: 'FAILED', notification })
  }
}
