import React from 'react'
import { Icon, Popup } from 'semantic-ui-react'

export const raceOptions = [
  {
    key: 'AMERICAN',
    text: 'American Indian or Alaska Native',
    value: 'AMERICAN',
  },
  { key: 'ASIAN', text: 'Asian', value: 'ASIAN' },
  { key: 'BLACK', text: 'Black or African American', value: 'BLACK' },
  { key: 'HISPANIC', text: 'Hispanic', value: 'HISPANIC' },
  {
    key: 'NATIVE',
    text: 'Native Hawaiian or Other Pacific Islander',
    value: 'NATIVE',
  },
  { key: 'WHITE', text: 'White', value: 'WHITE' },
]

export const genderOptions = [
  { key: 'MALE', text: 'Male', value: 'MALE' },
  { key: 'FEMALE', text: 'Female', value: 'FEMALE' },
]

export const academicStrengthsOptions = [
  { key: 'SPELLING', text: 'Spelling', value: 'SPELLING' },
  { key: 'MATH', text: 'Math', value: 'MATH' },
  { key: 'SCIENCE', text: 'Science', value: 'SCIENCE' },
  { key: 'ENGLISH', text: 'English/ Language Arts', value: 'ENGLISH' },
  { key: 'SOCIAL', text: 'Social Studies/History', value: 'SOCIAL' },
  { key: 'READING', text: 'Reading/Literature', value: 'READING' },
  { key: 'FOREIGN', text: 'Foreign Language', value: 'FOREIGN' },
  { key: 'BUSINESS', text: 'Business', value: 'BUSINESS' },
  { key: 'ARTS', text: 'Fine Arts', value: 'ARTS' },
  { key: 'ELECTIVES', text: 'Electives', value: 'ELECTIVES' },
]

export const hobbiesOptions = [
  { key: 'ANIMALS', text: 'Animals', value: 'ANIMALS' },
  { key: 'MUSIC', text: 'Music', value: 'MUSIC' },
  { key: 'FITNESS', text: 'Fitness', value: 'FITNESS' },
  { key: 'PROJECTS', text: 'Arts/Projects', value: 'PROJECTS' },
  { key: 'MOVIES', text: 'Movies', value: 'MOVIES' },
  { key: 'WRITING', text: 'Writing', value: 'WRITING' },
  { key: 'DANCING', text: 'Dancing', value: 'DANCING' },
  { key: 'ARTS', text: 'Performing Arts', value: 'ARTS' },
  { key: 'GAMING', text: 'Gaming', value: 'GAMING' },
  { key: 'INSTRUMENT', text: 'Playing an Instrument', value: 'INSTRUMENT' },
  { key: 'SPORTS', text: 'Sports', value: 'SPORTS' },
  { key: 'FRIENDS', text: ' Time with Friends/Family', value: 'FRIENDS' },
  { key: 'SHOPPING', text: 'Shopping', value: 'SHOPPING' },
  { key: 'READING', text: 'Reading', value: 'READING' },
  { key: 'TECHNOLOGY', text: 'Technology', value: 'TECHNOLOGY' },
  { key: 'OUTDOORS', text: 'Outdoors', value: 'OUTDOORS' },
]

export const renderLabel = label => ({
  content: (
    <Popup content={label.subtext} trigger={<span>{label.text}</span>}/>
  ),
})
export const lifeExperiencesOptions = [
  {
    key: 'ABUSE',
    value: 'ABUSE',
    text: 'Abuse',
    subtext: 'Physical, Sexual, Verbal, Emotional',
    color: '#DC143C',
    content: (
      <>
        <span>Abuse</span>
        <Popup content='Physical, Sexual, Verbal, Emotional' position={"top"}
               trigger={<Icon style={{ marginLeft: 10, color: 'lightgray' }} name="info circle"/>}/>
      </>
    ),
  }, {
    key: 'ACADEMICS',
    value: 'ACADEMICS',
    text: 'Academics',
    subtext: 'Grades, Learning Challenges',
    color: '#FFAEB9',
    content: (
      <>
        <span>Academics</span>
        <Popup content='Grades, Learning Challenges' position={"top"}
               trigger={<Icon style={{ marginLeft: 10, color: 'lightgray' }} name="info circle"/>}/>
      </>
    ),
  }, {
    key: 'Attendance'.toUpperCase(),
    value: 'Attendance'.toUpperCase(),
    text: 'Attendance',
    subtext: 'Unexcused Absences, Truancy',
    color: '#FF34B3',
    content: (
      <>
        <span>Attendance</span>
        <Popup content='Unexcused Absences, Truancy' position={"top"}
               trigger={<Icon style={{ marginLeft: 10, color: 'lightgray' }} name="info circle"/>}/>
      </>
    ),
  }, {
    key: 'Behavior'.toUpperCase(),
    value: 'Behavior'.toUpperCase(),
    text: 'Behavior',
    subtext: 'Classroom, Defiance of Authority',
    color: '#B452CD',
    content: (
      <>
        <span>Behavior</span>
        <Popup content='Classroom, Defiance of Authority' position={"top"}
               trigger={<Icon style={{ marginLeft: 10, color: 'lightgray' }} name="info circle"/>}/>
      </>
    ),
  }, {
    key: 'Bullying'.toUpperCase(),
    value: 'Bullying'.toUpperCase(),
    text: 'Bullying',
    subtext: 'Verbal, Emotional, Cyber',
    color: '#7D26CD',
    content: (
      <>
        <span>Bullying</span>
        <Popup content='Verbal, Emotional, Cyber' position={"top"}
               trigger={<Icon style={{ marginLeft: 10, color: 'lightgray' }} name="info circle"/>}/>
      </>
    ),
  }, {
    key: 'Future'.toUpperCase(),
    value: 'Future'.toUpperCase(),
    text: 'Future',
    subtext: 'College, Career',
    color: '#4876FF',
    content: (
      <>
        <span>Future</span>
        <Popup content='College, Career' position={"top"}
               trigger={<Icon style={{ marginLeft: 10, color: 'lightgray' }} name="info circle"/>}/>
      </>
    ),
  }, {
    key: 'Grief'.toUpperCase(),
    value: 'Grief'.toUpperCase(),
    text: 'Grief',
    subtext: 'Loss, Death',
    color: '#5CACEE',
    content: (
      <>
        <span>Grief</span>
        <Popup content='Loss, Death' position={"top"}
               trigger={<Icon style={{ marginLeft: 10, color: 'lightgray' }} name="info circle"/>}/>
      </>
    ),
  }, {
    key: 'Health_Home'.toUpperCase(),
    value: 'Health_Home'.toUpperCase(),
    text: 'Health / Home',
    subtext: 'Family, Divorce, Foster Care, Finances and Resources',
    color: '#00C5CD',
    content: (
      <>
        <span>Health / Home</span>
        <Popup content='Physical Illness, Family, Divorce, Foster Care, Finances and Resources' position={"top"}
               trigger={<Icon style={{ marginLeft: 10, color: 'lightgray' }} name="info circle"/>}/>
      </>
    ),
  },
  {
    key: 'Mental_Health'.toUpperCase(),
    value: 'Mental_Health'.toUpperCase(),
    text: 'Mental Health',
    subtext: 'Stress, Anxiety, Depression',
    color: '#4EEE94',
    content: (
      <>
        <span>Mental Health</span>
        <Popup content='Stress, Anxiety, Depression' position={"top"}
               trigger={<Icon style={{ marginLeft: 10, color: 'lightgray' }} name="info circle"/>}/>
      </>
    ),
  },
  {
    key: 'New_Transition'.toUpperCase(),
    value: 'New_Transition'.toUpperCase(),
    text: 'New / Transition',
    subtext: 'New to School or Transitioning Back to Base School',
    color: '#008000',
    content: (
      <>
        <span>New / Transition</span>
        <Popup content='New to School or Transitioning Back to Base School' position={"top"}
               trigger={<Icon style={{ marginLeft: 10, color: 'lightgray' }} name="info circle"/>}/>
      </>
    ),
  },
  {
    key: 'Quarantine'.toUpperCase(),
    value: 'Quarantine'.toUpperCase(),
    text: 'Quarantine',
    subtext: 'COVID-19 Pandemic',
    color: '#B3EE3A',
    content: (
      <>
        <span>Quarantine</span>
        <Popup content='COVID-19 Pandemic' position={"top"}
               trigger={<Icon style={{ marginLeft: 10, color: 'lightgray' }} name="info circle"/>}/>
      </>
    ),
  },
  {
    key: 'Relationships'.toUpperCase(),
    value: 'Relationships'.toUpperCase(),
    text: 'Relationships',
    subtext: 'Friends, Dating, Acceptance, Pressures',
    color: '#FFD700',
    content: (
      <>
        <span>Relationships</span>
        <Popup content='Friends, Dating, Acceptance, Pressures' position={"top"}
               trigger={<Icon style={{ marginLeft: 10, color: 'lightgray' }} name="info circle"/>}/>
      </>
    ),
  },
  {
    key: 'Self_Harm'.toUpperCase(),
    value: 'Self_Harm'.toUpperCase(),
    text: 'Self-Harm',
    subtext: 'Cutting, Burning, Eating Disorder',
    color: '#FFA500',
   content: (
      <>
        <span>Self-Harm</span>
        <Popup content='Cutting, Burning, Eating Disorder' position={"top"}
               trigger={<Icon style={{ marginLeft: 10, color: 'lightgray' }} name="info circle"/>}/>
      </>
    ),
  },
  {
    key: 'Self_Worth'.toUpperCase(),
    value: 'Self_Worth'.toUpperCase(),
    text: 'Self-Worth',
    subtext: 'Esteem, Identity, Belonging',
    color: '#EE7600',
    content: (
      <>
        <span>Self-Worth</span>
        <Popup content='Esteem, Identity, Belonging' position={"top"}
               trigger={<Icon style={{ marginLeft: 10, color: 'lightgray' }} name="info circle"/>}/>
      </>
    ),
  },
  {
    key: 'Service'.toUpperCase(),
    value: 'Service'.toUpperCase(),
    text: 'Service',
    subtext: 'Community or School Service',
    groupOnly: true,
    color: '#CDCD00',
    content: (
      <>
        <span>Service</span>
        <Popup content='Community or School Service' position={"top"}
               trigger={<Icon style={{ marginLeft: 10, color: 'lightgray' }} name="info circle"/>}/>
      </>
    ),
  },
  {
    key: 'Skill_Development'.toUpperCase(),
    value: 'Skill_Development'.toUpperCase(),
    text: 'Skill Development',
    subtext: 'Peer Helper Trainings and Meetings',
    groupOnly: true,
    color:'#8B2323',
    content: (
      <>
        <span>Skill Development</span>
        <Popup content='Peer Helper Trainings and Meetings' position={"top"}
               trigger={<Icon style={{ marginLeft: 10, color: 'lightgray' }} name="info circle"/>}/>
      </>
    ),
  },
  {
    key: 'Substances'.toUpperCase(),
    value: 'Substances'.toUpperCase(),
    text: 'Substances',
    subtext: 'Alcohol, Drugs & Nicotine Use or Abuse',
    color: '#FA8072',
    content: (
      <>
        <span>Substances</span>
        <Popup content='Alcohol, Drugs & Nicotine Use or Abuse' position={"top"}
               trigger={<Icon style={{ marginLeft: 10, color: 'lightgray' }} name="info circle"/>}/>
      </>
    ),
  },
  {
    key: 'Suicide'.toUpperCase(),
    value: 'Suicide'.toUpperCase(),
    text: 'Suicide',
    subtext: 'Thoughts, Attempts, Survivor',
    color: '#FF0000',
    content: (
      <>
        <span>Suicide</span>
        <Popup content='Thoughts, Attempts, Survivor' position={"top"}
               trigger={<Icon style={{ marginLeft: 10, color: 'lightgray' }} name="info circle"/>}/>
      </>
    ),
  },
]

export const initiationOptions = [
  { key: 'REFERRAL', text: 'Referral from someone', value: 'REFERRAL' },
  { key: 'APPROACHED', text: 'You approached student', value: 'APPROACHED' },
  { key: 'STUDENT', text: 'Student came to you', value: 'STUDENT' },
  { key: 'PATH', text: 'P.A.T.H. lesson follow-up', value: 'PATH' },
]

export const assistHelpOptions = [
  { key: 'CONVERSATION', text: 'Conversation Only', value: 'CONVERSATION' },
  { key: 'PEERHELPER', text: 'Assigned to Peer Helper', value: 'PEERHELPER' },
  { key: 'REFERRED_SCHOOL', text: 'Referred to other school level professional', value: 'REFERRED_SCHOOL' },
  { key: 'REFERRED_NONSCHOOL', text: 'Referred to non-school-based professional', value: 'REFERRED_NONSCHOOL' },
  { key: 'ASSIST', text: 'Assisted student through Crisis Referral Protocol', value: 'ASSIST' },
]

export const gradeOptions = [
  { key: 'K', text: 'K', value: 'K', ischecked: false },
  { key: 'G1', text: '1st', value: 'G1', ischecked: false },
  { key: 'G2', text: '2nd', value: 'G2', ischecked: false },
  { key: 'G3', text: '3rd', value: 'G3', ischecked: false },
  { key: 'G4', text: '4th', value: 'G4', ischecked: false },
  { key: 'G5', text: '5th', value: 'G5', ischecked: false },
  { key: 'G6', text: '6th', value: 'G6', ischecked: false },
  { key: 'G7', text: '7th', value: 'G7', ischecked: false },
  { key: 'G8', text: '8th', value: 'G8', ischecked: false },
  { key: 'G9', text: '9th', value: 'G9', ischecked: false },
  { key: 'G10', text: '10th', value: 'G10', ischecked: false },
  { key: 'G11', text: '11th', value: 'G11', ischecked: false },
  { key: 'G12', text: '12th', value: 'G12', ischecked: false },
]
export const userTypeOptions = [
  'peerHelper',
  'coordinator',
  'school',
  'district',
  'state',
  'superUser',
].map((userType, key) => ({
  key,
  text: userType,
  value: userType,
}))

export const campaignType = [{
  key: 'PEERHELPER',
  text: 'Peer Helper Survey',
  value:'PEERHELPER'
},
  {
    key: 'PREVENTION',
    text: 'Schoolwide Path Prevention Survey',
    value:'PREVENTION'
  }]

export const helpTypeOptions = [
  {
    key: 'CONVERSION',
    text: 'Used Helping Skills/Conversation Only',
    value: 'CONVERSION',
  },
  {
    key: 'CONTINUAL',
    text: 'Assigned Students to PHs for Continual Support',
    value: 'CONTINUAL',
  },
  {
    key: 'REFERRED',
    text: 'Referred to a Program Coordinator/School Counselor',
    value: 'REFERRED',
  },
]

export const placeOptions = [
  { key: 'SCHOOL', text: 'Your School', value: 'SCHOOL' },
  { key: 'ANOTHER_SCHOOL', text: 'Another School', value: 'ANOTHER_SCHOOL' },
  { key: 'OTHER', text: 'Other Location', value: 'OTHER' },
]

export const typeOfActivityOptions = [
  { key: 'SUPPORT', text: 'Support', value: 'SUPPORT' },
  { key: 'PREVENTION', text: 'Prevention and Awareness', value: 'PREVENTION' },
]

export const numberOfStudentsOptions = [
  { key: 'SMALL', text: '2-35', value: 'SMALL' },
  { key: 'MEDIUM', text: '36-125', value: 'MEDIUM' },
  { key: 'BIG', text: '126-500', value: 'BIG' },
  { key: 'WIDE', text: 'School Wide', value: 'WIDE' },
]

export const addressingTypeOptions = [
  { key: 'MEDIATION', text: 'Mediation', value: 'MEDIATION' },
  { key: 'CLASSROOM', text: 'Classroom', value: 'CLASSROOM' },
  { key: 'ASSEMBLY', text: 'School assembly', value: 'ASSEMBLY' },
  { key: 'CAMPAIGN', text: 'School campaign', value: 'CAMPAIGN' },
  { key: 'COMMUNITY', text: 'Community Project', value: 'COMMUNITY' },
]

export const socialSkillsOptions = [
  {
    key: 'HIGH',
    text:
      'Always Smooth, Controlled, Confident, Eye Contact, Potentially a Great Public Speaker',
    value: 'HIGH',
  },
  {
    key: 'AVEREGE',
    text: 'Mostly Controlled and Confident Responses with Good Eye Contact',
    value: 'AVEREGE',
  },
  {
    key: 'OCCASIONALLY',
    text: 'Occasionally Looked Down when Responding, Avoiding the Question',
    value: 'OCCASIONALLY',
  },
  {
    key: 'OFTEN',
    text:
      'Consistently Disruptive, Avoided the Question, Awkward, No Eye Contact',
    value: 'OFTEN',
  },
  {
    key: 'NEVER',
    text: 'Silent - Never Spoke or Interacted Vocally',
    value: 'NEVER',
  },
]

export const emotionalStateOptions = [
  {
    key: 'JOY',
    text: 'Joy, Confidence, Trust, Empowerment, Enthusiastic',
    value: 'JOY',
  },
  { key: 'HOPE', text: 'Hope, Content, Optimistic, Calm', value: 'HOPE' },
  { key: 'NEUTRAL', text: 'Neutral, Relaxed', value: 'NEUTRAL' },
  {
    key: 'IMPATIENT',
    text: 'Impatient, Irritated, Boredom, Blame, Worry',
    value: 'IMPATIENT',
  },
  {
    key: 'GUILT',
    text: 'Guilt Insecure, Depressed, Fear, Grief, Hate',
    value: 'GUILT',
  },
]

export const highRiskBehaviorsOptions = [
  { key: 'NO', text: 'No sign of high risk behaviors', value: 'NO' },
  {
    key: 'EXPOSED',
    text: 'Is exposed to high risk behaviors but does not participate',
    value: 'EXPOSED',
  },
  {
    key: 'THOUGHTS',
    text: 'Thoughts of participating in high risk behaviors',
    value: 'THOUGHTS',
  },
  {
    key: 'OCCASIONALLY',
    text: 'Occasionally participates in high risk behaviors',
    value: 'OCCASIONALLY',
  },
  {
    key: 'ACTIVELY',
    text: 'Actively and Frequently participates in high risk behaviors',
    value: 'ACTIVELY',
  },
]

export const onGoingSupportActivityTypeOptions = [
  { key: 'WORK', text: 'Class Work', value: 'WORK' },
  { key: 'GAME', text: 'Play game', value: 'GAME' },
  { key: 'TALK', text: 'Talk-time', value: 'TALK' },
  { key: 'LUNCH', text: 'Lunch', value: 'LUNCH' },
  { key: 'OTHER', text: 'Other', value: 'OTHER' },
]

export const classWorkOptions = [
  { key: 'MATH', text: 'Math', value: 'MATH' },
  { key: 'SCIENCE', text: 'Science', value: 'SCIENCE' },
  { key: 'ENGLISH', text: 'English/Lit', value: 'ENGLISH' },
  { key: 'HISTORY', text: 'SS/History', value: 'HISTORY' },
  { key: 'READING', text: 'Reading/Spelling', value: 'READING' },
  { key: 'FOREIGN', text: 'Foreign Lang/Business', value: 'FOREIGN' },
  { key: 'OTHER_WORK', text: 'Other', value: 'OTHER_WORK' },
]

export const schoolLevels = [
  { key: 'PRESCHOOL', text: 'Preschool', value: 'PRESCHOOL' },
  { key: 'ELEMENTARY', text: 'Elementary School', value: 'ELEMENTARY' },
  { key: 'MIDDLESCHOOL', text: 'Middle School', value: 'MIDDLESCHOOL' },
  { key: 'HIGHSCHOOL', text: 'High School', value: 'HIGHSCHOOL' },
]

export const logTypes = [
  { key: 'AS_NEEDED_SUPPORT', text: 'As-Needed', value: 'AS_NEEDED_SUPPORT' },
  { key: 'GROUP_ACTIVITY', text: 'Group Activity', value: 'GROUP_ACTIVITY' },
  { key: 'ONGOING_SUPPORT', text: 'Ongoing', value: 'ONGOING_SUPPORT' },
]
