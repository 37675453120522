import noSchoolPlaceholder from '../../Assets/Images/icons8-school-house-96.png'
import React from 'react'

const MyProgramNoSchoolSelected = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20%' }}>
      <img src={noSchoolPlaceholder} alt='No school selected' />
      <p style={{ fontSize: 16, color: 'rgba(0, 0, 0, 0.38)', textAlign: 'center' }}>Select a school to view program</p>
    </div>
  )
}

export default MyProgramNoSchoolSelected
