import React, { useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import useGlobal from '../../Store'
import StudentsProgressionTable from '../../Components/Dashboard/StudentsProgressionTable'
import OverviewCard from '../../Components/OverviewCard'
import ContextualRightComponent from '../../Components/ContextualRightComponent/index'
import DemographicCard from '../../Components/Dashboard/DemographicCard'
import { NoPrint, Print } from 'react-easy-print'

const OnGoingSupportOverview = (props) => {
  const [globalState, globalActions] = useGlobal()
  const [dashboardStatistics, setDashboardStatistics] = useState(globalState.dashboardStatistics)

  const { currentState, currentDistrict, currentSchool } = globalState

  useEffect(() => {
    setTimeout(() => {
      globalActions.dashboards.getStatistics()
    }, 500)
  }, [currentState, currentDistrict, currentSchool])

  useEffect(() => {
    setDashboardStatistics(globalState.dashboardStatistics)
  }, [globalState.dashboardStatistics])

  if (typeof dashboardStatistics.onGoingSupport === 'undefined') {
    return <div/>
  }

  const overviewCardProperties = [
    { property: 'Ongoing Support Logs', propertyCount: dashboardStatistics.onGoingSupport.count },
    { property: 'Initial Assessments Complete', propertyCount: dashboardStatistics.onGoingSupport.initial },
    { property: 'Midpoint Assessments Complete', propertyCount: dashboardStatistics.onGoingSupport.midpoint },
    { property: 'Final Assessments Complete', propertyCount: dashboardStatistics.onGoingSupport.final },
    {
      property: 'Total Assessments Complete',
      propertyCount: dashboardStatistics.onGoingSupport.total,
      isPercentage: true
    }
  ]

  return (
    <Print name='Ongoing Support Overview'>
      <Grid>
        <Grid.Row style={{ paddingTop: 0 }}>
          <Grid.Column mobile={16} tablet={9} computer={11}>
            <Grid padded>
              <Grid.Column computer={16} mobile={16}>
                <OverviewCard title={'Ongoing Support Overview'} columns={overviewCardProperties}/>
              </Grid.Column>
              <Grid.Column computer={16} mobile={16}>
                <StudentsProgressionTable {...props} />
              </Grid.Column>
            </Grid>
          </Grid.Column>

          <Grid.Column mobile={16} tablet={7} computer={5}>

            {props.match.params.section === undefined ? (
              <DemographicCard data={dashboardStatistics.onGoingSupport}/>
            ) : (
              <ContextualRightComponent {...props} />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Print>
  )
}

export default OnGoingSupportOverview
