export const asNeededSupportTransform = (initialObject) => ({
  ...initialObject,
  count: initialObject.total,
  topics: getTopThree(initialObject.experiences)
})

export const onGoingSupportTransform = (initialObject) => ({
  ...initialObject,
  count: initialObject.logs,
  initial: initialObject.initialAssessment,
  midpoint: initialObject.midpointAssessment,
  final: initialObject.finalAssessment
})

const getTopThree = (object) => {
  return Object.keys(object).map((key) => (
    {
      name: key,
      percent: object[key]
    }))
    .sort((p1, p2) => p2.percent - p1.percent)
    .slice(0, 3)
}

export const atRiskReferralTransform = (initialObject) => ({
  ...initialObject,
  count: initialObject.total,
  onGoing: initialObject.ongoingSupport,
  group: initialObject.groupActivity,
  supportPercentage: initialObject.support,
  preventionPercentage: initialObject.prevention
})

export const groupActivitiesTransform = (initialObject) => ({
  ...initialObject,
  count: initialObject.total
})

export const supportTopicsTransform = (initialObject) => ({
  ...initialObject
})

export const peerHelpersTransform = (initialObject) => ({
  ...initialObject,
  count: initialObject.peerHelpers,
  onGoingStudents: initialObject.ongoingStudents,
  assignedStudents: initialObject.students,
  totalLogins: initialObject.login

})

export const programsTransform = (initialObject) => ({
  ...initialObject,
  count: initialObject.peerHelpers,
  onGoingStudents: initialObject.ongoingStudents,
  assignedStudents: initialObject.students,
  totalLogins: initialObject.login,
  states: initialObject.hierarchyCount.states,
  districts: initialObject.hierarchyCount.districts,
  schools: initialObject.hierarchyCount.schools
})
