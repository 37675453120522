import React, { useEffect, useState } from 'react'
import { Segment, Divider, Header, Item, Icon } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'
import { RightMenuBar, RightMenuBarButton, RightMenuBarOptions, StyledLogQuestionAnswer, StyledLogQuestionHeader } from '../../Theme/Components'
import { dashboard } from '../../Theme/Styles'
import useUrlBuilder from '../../Utils/Links'
import useGlobal from '../../Store'
import useStringOperations from '../../Utils/StringOperations'
import { genderOptions, gradeOptions, helpTypeOptions, lifeExperiencesOptions, placeOptions, raceOptions } from '../../Config/SelectOptions'

const AtRiskReferralsLog = (props) => {
  const asNeededLogId = props.match.params.id || '0'
  const [globalState, globalActions] = useGlobal()
  const [asNeededSupportLog, setAsNeededSupportLog] = useState({ ...globalState.editedAsNeededLog })
  const [makeUrl] = useUrlBuilder(props.match.url)
  const stringOperations = useStringOperations()

  useEffect(() => {
    if (asNeededLogId !== '0') {
      globalActions.asNeededLogs.getAsNeededLog(asNeededLogId)
    }
  }, [asNeededLogId])

  useEffect(() => {
    setAsNeededSupportLog({ ...globalState.editedAsNeededLog })
  }, [globalState.editedAsNeededLog])

  return (
    <Segment>
      <RightMenuBar>
        <Header as='h3' style={dashboard.myProgramHeader}> Support Log</Header>
        <RightMenuBarOptions>
          <RightMenuBarButton as={NavLink} to={makeUrl('/')}>
            <Icon name='close' />
          </RightMenuBarButton>
        </RightMenuBarOptions>
      </RightMenuBar>

      <Divider />

      <Item.Group>
        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>1. Peer helper</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{asNeededSupportLog.peerHelper.firstName + ' ' + asNeededSupportLog.peerHelper.lastName}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>2. Date of Activity</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{new Date(asNeededSupportLog.date).getMonth() + 1 + '/' + (new Date(asNeededSupportLog.date).getDate()) + '/' + new Date(asNeededSupportLog.date).getFullYear()}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>3. Time of Activity</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{new Date(asNeededSupportLog.date).getHours() + ':' + new Date(asNeededSupportLog.date).getMinutes()}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>4. Gender of Student Helped</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{stringOperations.translate(genderOptions, asNeededSupportLog.gender)}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>5. Race of Student Helped</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{asNeededSupportLog.races.map(race => stringOperations.translate(raceOptions, race)).join(', ')}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>6. Grade of Student Helped</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{stringOperations.translate(gradeOptions, asNeededSupportLog.grade)}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>7. Topics shared during this conversation</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{asNeededSupportLog.experiences.map(experience => stringOperations.translate(lifeExperiencesOptions, experience)).join(', ')}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>8. How did you address this group?</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{stringOperations.translate(helpTypeOptions, asNeededSupportLog.help)}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>9. Where did you help this student</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{stringOperations.translate(placeOptions, asNeededSupportLog.location)}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>
      </Item.Group>
    </Segment>
  )
}

export default AtRiskReferralsLog
