import React from 'react'
import { Grid, Header, Icon, Popup, Segment, Table } from 'semantic-ui-react'
import { dashboard } from '../../../Theme/Styles'
import { NavLink } from 'react-router-dom'
import useGlobal from '../../../Store'
import { lifeExperiencesOptions } from '../../../Config/SelectOptions'
import useStringOperations from '../../../Utils/StringOperations'

const AsNeededSupportCoordinatorsStatistics = ({ asNeededSupport }) => {
  const stringOperations = useStringOperations()

  const [globalState] = useGlobal()
  const { loggedInUser } = globalState

  const _topicName = topic => {
    const keyword = lifeExperiencesOptions.find(option => option.key === topic.name)
    return keyword ? keyword.text : ''
  }

  return (
    <Segment style={dashboard.segment}>
      <Header as='h2' style={dashboard.header}>As-Needed Support</Header>
      <Grid rows={2}>
        <Grid.Row columns={2}>
          <Grid.Column computer={7} mobile={16} style={{ textAlign: 'center' }}>
            <Header style={dashboard.count}>
              {asNeededSupport.count}
              <Header.Subheader style={dashboard.countSubHeader}>Total Support Logs</Header.Subheader>
            </Header>
          </Grid.Column>

          <Grid.Column computer={9} mobile={16}>
            <Header as='h4' disabled style={dashboard.table.header}>Top topics</Header>
            <Table basic='very' style={dashboard.table.base}>
              <Table.Body>
                {asNeededSupport.topics.map((topic, index) => (
                  <Table.Row style={dashboard.list} key={index}>
                    {topic.percent ? (
                      <React.Fragment>
                        <Table.Cell style={dashboard.table.cell}>
                          {(index + 1) + '. ' + _topicName(topic)}
                          <Popup content={stringOperations.translateInfo(lifeExperiencesOptions, topic.name)}
                                 position="right center"
                                 trigger={<Icon style={{ marginLeft: 10, color: 'lightgray' }} name="info circle"/>}/>
                        </Table.Cell>
                        <Table.Cell style={dashboard.table.blueCell}>{topic.percent}%</Table.Cell>
                      </React.Fragment>
                    ) : (
                      <Table.Cell style={dashboard.table.cell} colSpan={2}>-</Table.Cell>
                    )}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={dashboard.boxButtonsRow}>
          <Grid.Column floated='right' style={dashboard.boxButtonsColumn}>
            {!['School Level', 'State Level', 'District Level'].includes(loggedInUser.role) && (
              <NavLink to={`/support-logs/0/edit`} style={dashboard.boxButtonsButton}>Add Log</NavLink>)}
            <NavLink to='/as-needed-support-report' style={dashboard.boxButtonsButton}>View</NavLink>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

export default AsNeededSupportCoordinatorsStatistics
