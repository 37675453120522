import AsNeededSupportTable from '../../Components/Dashboard/AsNeededSupportTable'
import ContextualRightComponent from '../../Components/ContextualRightComponent/index'
import PieChartComponent from '../../Components/PieChart'
import styled from 'styled-components'
import { Button, Grid, Header, Segment } from 'semantic-ui-react'
import React, { useEffect } from 'react'
import useGlobal from '../../Store'
import DemographicCard from '../../Components/Dashboard/DemographicCard'
import { NoPrint, Print } from 'react-easy-print'

const AsNeededSupportReport = (props) => {
  const [globalState, globalActions] = useGlobal()
  const { dashboardStatistics, currentState, currentDistrict, currentSchool, editedSchool } = globalState

  useEffect(() => {
    setTimeout(() => {
      globalActions.dashboards.getStatistics()
      if (currentSchool !== '') {
        globalActions.locations.getSchool(currentSchool)
      }
    }, 500)
  }, [currentState, currentDistrict, currentSchool])

  if (typeof dashboardStatistics.asNeededSupport === 'undefined') {
    return <div/>
  }

  return (
    <Print name='As-Needed Support Report'>
      <Grid>
        <Grid.Row style={{ paddingTop: 0 }}>
          <Grid.Column mobile={16} tablet={9} computer={11}>
            <Grid padded columns={2}>
              <Grid.Column style={{ paddingBottom: 0 }}>
                <StyledHeader as='h2'>As-Needed Support Report</StyledHeader>
              </Grid.Column>
              <Grid.Column style={{ paddingBottom: 0 }}>
                <NoPrint force>
                  <StyledButton icon='print' floated='right' basic onClick={() => window.print()}/>
                </NoPrint>
              </Grid.Column>
            </Grid>

            <Grid padded>
              <Grid.Column computer={16} mobile={16} style={{ paddingTop: 0 }}>
                <StyledCardContent>
                  <PieChartComponent title={editedSchool.name} columns={dashboardStatistics.asNeededSupport.topics}/>
                </StyledCardContent>
              </Grid.Column>
              <Grid.Column computer={16} mobile={16}>
                <AsNeededSupportTable/>
              </Grid.Column>
            </Grid>
          </Grid.Column>

          <Grid.Column mobile={16} tablet={7} computer={5}>
            {props.match.params.section === undefined ? (
              <DemographicCard data={dashboardStatistics.asNeededSupport}/>
            ) : (
              <ContextualRightComponent {...props} />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Print>
  )
}

const StyledHeader = styled(Header)`
  size: 24px !important;
  font-weight: lighter;
`

const StyledCardContent = styled(Segment)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 250px !important;
  align-items: stretch;
`

const StyledButton = styled(Button)`
  box-shadow: none !important;
  i {
    color: black !important;
  }
`

export default AsNeededSupportReport
