import React, { useEffect, useState } from 'react'
import { Header, Button, Segment, Form, Icon, Divider } from 'semantic-ui-react'
import { emotionalStateOptions, highRiskBehaviorsOptions, socialSkillsOptions } from '../../Config/SelectOptions'
import useGlobal from '../../Store'
import useValidation from '../../Utils/Validations'
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'
import InputAdornment from '@material-ui/core/InputAdornment'
import { NavLink } from 'react-router-dom'
import useUrlBuilder from '../../Utils/Links'
import _ from 'lodash'
import { RightMenuBar, RightMenuBarButton, RightMenuBarButtonIcon, RightMenuBarOptions, StyledTransparentButton } from '../../Theme/Components'
import { dashboard } from '../../Theme/Styles'
import moment from 'moment'

const defaultAssessement = {
  studentId: '',
  assessmentType: '',
  date: new Date(),
  time: new Date(),
  socialSkills: '',
  emotionalState: '',
  riskBehavior: '',
  referred: false
}

const AssessmentForm = (props) => {
  const studentId = props.match.params.id || '0'
  const assessmentType = props.match.params.action || 'initial'
  const [globalState, globalActions] = useGlobal()
  const [assessment, setAssessment] = useState(defaultAssessement)
  const requiredFields = ['socialSkills', 'emotionalState', 'riskBehavior']
  const [validForm, validationErrors, onFieldChangeValidation] = useValidation(requiredFields, assessment)
  const [makeUrl] = useUrlBuilder(props.match.url)

  useEffect(() => {
    if (globalState.students.length) {
      const student = _.find(globalState.students, student => student.id.toString() === studentId)
      if (student !== undefined && student[assessmentType].id) {
        const { date } = student[assessmentType]
        const dateObject = date !== '' ? new Date(date.split(' ').join('T')) : new Date()
        setAssessment({ ...student[assessmentType], date: dateObject, time: dateObject })
      } else setAssessment(defaultAssessement)
    }
  }, [globalState.students])

  const updateAssessmentState = e => {
    const { name, value } = typeof e.target !== 'undefined' ? e.target : e
    setAssessment({ ...assessment, [name]: value })
    onFieldChangeValidation(name, value)
  }

  const onDateChange = (date) => {
    setAssessment({ ...assessment, date })
  }

  const onTimeChange = (time) => {
    setAssessment({ ...assessment, time })
  }

  const save = () => {
    if (validForm) {
      globalActions.assessments.saveAssessment(studentId, {
        ...assessment,
        date: moment(assessment.date).format().slice(0, 19).split('T')[0] + ' ' + moment(assessment.time).format().slice(0, 19).split('T')[1]

      }, assessmentType)
        .then(() => {
          globalActions.notifications.showNotification({ show: true, header: 'Assessment saved!', subheader: '', type: 'success' })
          props.history.push(makeUrl('/'))
          globalActions.dashboards.getStatistics()
          globalActions.students.getStudents(0, 10000)
        })
    } else {
      globalActions.notifications.showNotification({ type: 'warning', header: 'Form validation error', subheader: 'Please complete all required fields!' })
    }
  }

  let pageTitle = ''
  switch (assessmentType) {
    case 'initialAssessment':
      pageTitle = 'Initial Assessment'
      break
    case 'midpointAssessment':
      pageTitle = 'Midpoint Assessment'
      break
    default:
      pageTitle = 'Final Assessment'
  }

  return (
    <Segment>
      <RightMenuBar>
        <Header as='h3' style={dashboard.myProgramHeader}>{pageTitle}</Header>
        <RightMenuBarOptions>
          <RightMenuBarButton as={NavLink} to={makeUrl('/')}>
            <RightMenuBarButtonIcon name='close' style={{ fontSize: 20 }} />
          </RightMenuBarButton>
        </RightMenuBarOptions>
      </RightMenuBar>

      <Divider />

      <Form size='large'>
        <Form.Field name='date' required>
          <label>1. What was the date of {pageTitle.split(' ')[0]} assigned student session?</label>
          <KeyboardDatePicker
            value={assessment.date}
            onChange={date => onDateChange(date)}
            animateYearScrolling
            format='MM/DD/YY'
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position='end'>
                  <Icon name='caret down' />
                </InputAdornment>
              )
            }} />
        </Form.Field>
        <Form.Field name='date' required>
          <label>2. What was the time of the {pageTitle.split(' ')[0]} assigned student session?</label>
          <KeyboardTimePicker
            ampm
            value={assessment.time}
            onChange={time => onTimeChange(time)}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position='end'>
                  <Icon name='caret down' />
                </InputAdornment>
              )
            }} />
        </Form.Field>
        <Form.Select label='3. Thinking about the SOCIAL SKILLS of this student, which group of words BEST describes them?' name='socialSkills'
          onChange={(e, data) => updateAssessmentState(data)} value={assessment.socialSkills} required
          error={validationErrors.socialSkills.error}
          options={socialSkillsOptions} placeholder='Choose...' />
        <Form.Select label='4. Thinking about the EMOTIONAL STATE of this student, which group of words BEST describes them?' name='emotionalState'
          onChange={(e, data) => updateAssessmentState(data)} value={assessment.emotionalState} required
          error={validationErrors.emotionalState.error}
          options={emotionalStateOptions} placeholder='Choose...' />
        <Form.Select
          label='5. Thinking about the HIGH RISK BEHAVIORS (consumes alcohol, uses illegal drugs, abuses prescription pills, self-harm, abusive behaviors or hears voices), which of the following BEST describes the student?'
          name='riskBehavior' onChange={(e, data) => updateAssessmentState(data)} value={assessment.riskBehavior}
          required
          error={validationErrors.riskBehavior.error}
          options={highRiskBehaviorsOptions} placeholder='Choose...' />
        <Form.Select label='6. Referred to a Program Coordinator / School Counselor:' name='referred' onChange={(e, data) => updateAssessmentState(data)}
          value={assessment.referred}
          required
          options={[{ key: 'Yes', text: 'Yes', value: true }, { key: 'No', text: 'No', value: false }]} placeholder='Choose...' />
        <div style={{ textAlign: 'right' }}>
          <Button.Group>
            <StyledTransparentButton onClick={save}>Save</StyledTransparentButton>
          </Button.Group>
        </div>
      </Form>
    </Segment>
  )
}

export default AssessmentForm
