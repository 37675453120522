import React, { useEffect } from 'react'
import { Grid } from 'semantic-ui-react'
import useGlobal from '../../Store'
import AsNeededSupportPeerHelperStatistics
  from '../../Components/Dashboard/Statistics/AsNeededSupportPeerHelperStatistics'
import GroupActivitiesPeerHelperStatistics
  from '../../Components/Dashboard/Statistics/GroupActivitiesPeerHelperStatistics'
import StudentsTable from '../../Components/Dashboard/StudentsTable'

const PeerHelperDashboard = (props) => {
  const [globalState, globalActions] = useGlobal()
  const { loggedInUser, asNeededLogs, groupActivityLogs } = globalState

  useEffect(() => {
    globalActions.asNeededLogs.getAsNeededLogs()
    globalActions.groupActivityLogs.getGroupActivityLogs()
  }, [])

  return (
    <Grid padded>
      <Grid.Column computer={8} mobile={16}>
        <AsNeededSupportPeerHelperStatistics asNeededSupport={asNeededLogs}/>
      </Grid.Column>
      <Grid.Column computer={8} mobile={16}>
        <GroupActivitiesPeerHelperStatistics groupActivities={groupActivityLogs}/>
      </Grid.Column>
      <Grid.Column computer={16} mobile={16}>
        <StudentsTable {...props} />
      </Grid.Column>
    </Grid>

  )
}

export default PeerHelperDashboard
