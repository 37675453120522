import React, { useEffect, useState } from 'react'
import { Header, Button, Segment, Modal, Divider, Form } from 'semantic-ui-react'
import useGlobal from '../../Store'
import { NavLink } from 'react-router-dom'
import { dashboard } from '../../Theme/Styles'
import {
  RightMenuBar,
  RightMenuBarButton,
  RightMenuBarButtonIcon,
  RightMenuBarOptions,
  RightMenuBarTrashButton
} from '../../Theme/Components'
import * as _ from 'lodash'
import { schoolLevels } from '../../Config/SelectOptions'
import useUrlBuilder from '../../Utils/Links'

const School = (props) => {
  const [makeUrl] = useUrlBuilder(props.match.url)
  const schoolId = props.match.params.id || '0'
  const [globalState, globalActions] = useGlobal()
  const [school, setSchool] = useState({ ...globalState.editedSchool })
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if (schoolId !== '0') {
      globalActions.locations.getSchool(schoolId)
    }
  }, [])

  useEffect(() => {
    setSchool({ ...globalState.editedSchool })
  }, [globalState.editedSchool])

  const level = _.find(schoolLevels, level => school.level === level.value)

  return (
    <Segment>
      <RightMenuBar>
        <Header as='h3' style={dashboard.myProgramHeader}>School</Header>
        <RightMenuBarOptions>
          <RightMenuBarButton as={NavLink} to={makeUrl(`/heirarchy-management/schools/${school.id}/edit`)}>
            <RightMenuBarButtonIcon name='edit' />
          </RightMenuBarButton>
          <RightMenuBarTrashButton as='p' onClick={() => setModalOpen(true)}>
            <RightMenuBarButtonIcon name='trash' />
          </RightMenuBarTrashButton>
          <RightMenuBarButton as={NavLink} to='/'>
            <RightMenuBarButtonIcon name='close' style={{ fontSize: 20 }} />
          </RightMenuBarButton>
        </RightMenuBarOptions>
      </RightMenuBar>

      <Divider />

      <Form>
        <Form.Group className='custom-form-group'>
          <label className='small-label'>Name</label>
          <p style={{ color: '#753CBE', fontSize: 16, paddingLeft: 5.5 }}>{school.name}</p>
        </Form.Group>

        <Form.Group className='custom-form-group'>
          <label className='small-label'>State</label>
          <p style={{ color: '#753CBE', fontSize: 16, paddingLeft: 5.5 }}>{school.state.name}</p>
        </Form.Group>

        <Form.Group className='custom-form-group'>
          <label className='small-label'>District</label>
          <p style={{ color: '#753CBE', fontSize: 16, paddingLeft: 5.5 }}>{school.district.name}</p>
        </Form.Group>

        <Form.Group className='custom-form-group'>
          <label className='small-label'>Level</label>
          <p style={{ color: '#753CBE', fontSize: 16, paddingLeft: 5.5 }}>{level ? level.text : ''}</p>
        </Form.Group>
      </Form>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)} closeIcon>
        <Modal.Header>Delete School</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete this school ?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setModalOpen(false)}>No</Button>
          <Button positive icon='checkmark' labelPosition='right' content='Yes' />
        </Modal.Actions>
      </Modal>
    </Segment>
  )
}

export default School
