import endpoints from '../Config/ApiEndpoints'
import axios from 'axios'
import { processRequestErrorNotification } from '../Utils/Error'
import { survey as defaultSurvey, surveyId as defaultSurveyId, surveysPagination as defaultSurveysPagination } from '../Config/DefaultState'

export const getSurveys = async (store, page = 0) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  let url = endpoints.surveys + '?page=' + page
  store.setState({ requestStatus: 'LOADING' })

  try {
    const response = await axios.get(url, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
    const { surveys } = response.data._embedded
    const surveysPagination = response.data.page

    store.setState({ requestStatus: 'SUCCESS', surveys, surveysPagination })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ surveys: [], requestStatus: 'FAILED', notification, surveysPagination: defaultSurveysPagination })
  }
}

export const getSurvey = async (store, id) => {
  store.setState({ requestStatus: 'LOADING' })

  try {
    const response = await axios.get(endpoints.surveys + `/${id}`)

    store.setState({ requestStatus: 'SUCCESS', survey: response.data })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ survey: defaultSurvey, requestStatus: 'FAILED', notification })
  }
}

export const saveSurvey = async (store, survey) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  try {
    const response = await axios.post(
      endpoints.surveys + (survey.id !== '0' ? `/${survey.id}` : ``),
      survey,
      { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } }
    )

    store.setState({ surveyId: response.data.id, requestStatus: 'SUCCESS' })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ surveyId: defaultSurveyId, requestStatus: 'FAILED', notification })
  }
}

export const saveSurveyAnswers = async (store, id, answers) => {
  try {
    const response = await axios.post(
      endpoints.surveys + `/${id}`,
      answers
    )

    store.setState({ surveyId: response.data.id, requestStatus: 'SUCCESS' })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ surveyId: defaultSurveyId, requestStatus: 'FAILED', notification })
  }
}

export const deleteSurvey = async (store, id) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  try {
    const response = await axios.delete(
      endpoints.surveys + `/${id}`,
      { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } }
    )

    store.setState({ surveyId: response.data.id, requestStatus: 'SUCCESS' })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ surveyId: defaultSurveyId, requestStatus: 'FAILED', notification })
  }
}
