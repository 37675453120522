import React, { useEffect } from 'react'
import { Header, Item, Table, Segment, Divider } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'
import useGlobal from '../../Store'
import { RightMenuBar, RightMenuBarButton, RightMenuBarButtonIcon, RightMenuBarOptions } from '../../Theme/Components'
import { dashboard } from '../../Theme/Styles'

const AsNeededSupportLogsList = () => {
  const [globalState, globalActions] = useGlobal()
  const { asNeededLogs } = globalState

  useEffect(() => {
    globalActions.asNeededLogs.getAsNeededLogs()
  }, [])

  return (
    <Segment>
      <RightMenuBar>
        <Header as='h3' style={dashboard.myProgramHeader}>As-Needed Support Logs</Header>
        <RightMenuBarOptions>
          <RightMenuBarButton as={NavLink} to={`/`}>
            <RightMenuBarButtonIcon name='close' style={{ fontSize: 20 }} />
          </RightMenuBarButton>
        </RightMenuBarOptions>
      </RightMenuBar>

      <Divider />

      <Table basic='very' striped size='small'>
        <Table.Body>
          {asNeededLogs.map((log, key) => (
            <Table.Row key={key}>
              <Table.Cell width={12}>
                {new Date(log.date.split(' ').join('T')).toLocaleDateString()}
              </Table.Cell>
              <Table.Cell width={4}>
                <Item as={NavLink} to={`/support-logs/${log.id}/view`} style={{ color: '#2076BC' }}> VIEW </Item>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Segment>
  )
}

export default AsNeededSupportLogsList
