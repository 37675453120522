import React from 'react'
import * as PropTypes from 'prop-types'
import { Button, Grid, Header, Icon, Transition } from 'semantic-ui-react'
import styled from 'styled-components'

const Notification = props => {
  return (
    <Transition visible={props.show} animation='scale' duration={0}>
      <Container show={props.show} type={props.type}>
        <Header as='h3' style={{float: 'left', marginBottom: 0}}>
          <Icon name={props.type === 'warning' ? 'warning sign' : 'check'} />
          <Header.Content>
            {props.header}
            <Header.Subheader>{props.subheader}</Header.Subheader>
          </Header.Content>
        </Header>
        <Button icon circular style={{ marginLeft: '3rem', marginTop: '-0.5rem', float: 'right' }} onClick={() => props.hideNotification()}>
          <Icon name='close' />
        </Button>

      </Container>
    </Transition>
  )
}

Notification.propTypes = {
  show: PropTypes.bool,
  type: PropTypes.oneOf(['success', 'warning']),
  header: PropTypes.string,
  subheader: PropTypes.string
}

Notification.defaultProps = {
  show: true,
  header: '',
  subheader: '',
  type: 'warning'
}

const Container = styled.section`
  display: ${props => props.show ? 'block' : 'none'};
  position: absolute;
  left: 20px;
  bottom: 20px;
  padding: 20px;
  color: white;
  background-color: ${props => props.type === 'warning' ? 'orange' : '#21ba45'};
`

export default Notification
