import React, { useEffect, useState } from 'react'
import { Button, Container, Form, Segment, Header, Divider, Icon } from 'semantic-ui-react'
import useGlobal from '../../Store'
import {
  academicStrengthsOptions,
  genderOptions,
  hobbiesOptions,
  lifeExperiencesOptions,
  raceOptions,
  gradeOptions, renderLabel
} from '../../Config/SelectOptions'
import useValidation from '../../Utils/Validations'
import { NavLink } from 'react-router-dom'
import { dashboard } from '../../Theme/Styles'
import { KeyboardDatePicker } from '@material-ui/pickers'
import InputAdornment from '@material-ui/core/InputAdornment'
import useUrlBuilder from '../../Utils/Links'
import {
  RightMenuBar,
  RightMenuBarButton,
  RightMenuBarButtonIcon,
  RightMenuBarOptions,
  StyledTransparentButton
} from '../../Theme/Components'
import moment from 'moment'

const defaultStudent = {

  firstName: '',
  lastInitial: '',
  assignedTo: '',
  gender: '',
  races: [],
  grade: '',
  academics: [],
  interests: [],
  experiences: [],
  endDate: '',
  schoolId: ''
}

const StudentForm = (props) => {
  const studentId = props.match.params.id || '0'
  const [globalState, globalActions] = useGlobal()
  const { usersList, loggedInUser, currentSchool } = globalState
  const [student, setStudent] = useState(defaultStudent)
  const requiredFields = loggedInUser.role === 'Peer Helper' ? ['firstName', 'lastInitial', 'gender', 'races', 'grade', 'academics', 'interests', 'experiences'] : ['firstName', 'lastInitial', 'assignedTo', 'gender', 'races', 'grade', 'academics', 'interests', 'experiences']
  const [validForm, validationErrors, onFieldChangeValidation] = useValidation(requiredFields, student)
  const [makeUrl] = useUrlBuilder(props.match.url)

  useEffect(() => {
    if (studentId !== '0') {
      globalActions.students.getStudent(studentId)
    } else setStudent(defaultStudent)
  }, [studentId])

  useEffect(() => {
    if (studentId !== '0') {
      setStudent({ ...globalState.editedStudent, assignedTo: globalState.editedStudent.assignedTo.id })
    }
  }, [globalState.editedStudent])

  useEffect(() => {
    globalActions.users.getUsers(0, { role: 'Peer Helper', schoolId: currentSchool, enabled: true }, 1000)
  }, [])

  const updateStudentState = e => {
    const { name, value } = typeof e.target !== 'undefined' ? e.target : e
    setStudent({ ...student, [name]: value })
    onFieldChangeValidation(name, value)
  }
  const onDateChange = (date, student) => {
    setStudent({ ...student, endDate: date.format('YYYY-MM-DD') + ' ' + '00:00:00' })
    onFieldChangeValidation('endDate', date.format('YYYY-MM-DD' + ' ' + '00:00:00'))
  }

  const assignedToOptions = usersList.map(user => {
    return { key: user.id, text: user.firstName + ' ' + user.lastName, value: user.id }
  })

  const saveStudent = () => {
    if (!currentSchool) {
      globalActions.notifications.showNotification({
        type: 'warning',
        header: 'Select School',
        subheader: 'Please select a school first!'
      })
    } else if (validForm) {
      globalActions.students.saveStudent({
        ...student,
        schoolId: currentSchool,
        assignedTo: loggedInUser.role === 'Peer Helper' ? loggedInUser.id : student.assignedTo,
        endDate: student.endDate === '' || student.endDate === null ? moment(new Date()).format().slice(0, 19).split('T').join(' ') : student.endDate
      })
        .then(() => {
          props.history.push(makeUrl('/'))
        })
    } else {
      const missingFields = []
      Object.keys(validationErrors).map(field => {
        if (validationErrors[field].error)
          missingFields.push(field.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase()))
      })

      let subheader = 'Please complete all required fields! Check "' + missingFields.join('", "') + '"'
      subheader = subheader.split('Races').join('Race')
        .split('Academics').join('Academic strengths')
        .split('Interests').join('Hobbies')
        .split('Experiences').join('Life experiences')

      globalActions.notifications.showNotification({
        type: 'warning',
        header: 'Invalid form',
        subheader
      })
    }
  }

  const academicStrengthsOrNeedsText = ['Coordinator', 'Peer Helper'].includes(loggedInUser.role) ? 'Academic Needs' : 'Academic Needs'
  return (
    <Container>
      <Segment>
        <RightMenuBar>
          <Header as='h3' style={dashboard.myProgramHeader}>{studentId !== '0' ? `Edit` : `Add`} Student</Header>
          <RightMenuBarOptions>
            <RightMenuBarButton as={NavLink} to={makeUrl('/')}>
              <RightMenuBarButtonIcon name='close' style={{ fontSize: 20 }}/>
            </RightMenuBarButton>
          </RightMenuBarOptions>
        </RightMenuBar>

        <Divider/>

        <Form>
          <Form.Input name='firstName' value={student.firstName} onChange={updateStudentState} required fluid
                      label='First name'
                      error={validationErrors.firstName.error}
                      placeholder='First name'/>
          <Form.Input name='lastInitial' value={student.lastInitial} onChange={updateStudentState} required fluid
                      label='Last initial'
                      error={validationErrors.lastInitial.error}
                      placeholder='Last initial'/>
          {loggedInUser.role === 'Peer Helper' ? (
            <Form.Input name='assignedTo' value={loggedInUser.firstName + ' ' + loggedInUser.lastName} disabled fluid
                        label='Assigned to'/>
          ) : (
            <Form.Select name='assignedTo' value={student.assignedTo} onChange={(e, data) => updateStudentState(data)}
                         options={assignedToOptions} required
                         fluid label='Assigned to' error={validationErrors.assignedTo.error} placeholder='Assigned to'/>
          )}
          <Form.Select name='gender' value={student.gender} onChange={(e, data) => updateStudentState(data)}
                       options={genderOptions} required fluid
                       label='Gender' error={validationErrors.gender.error}
                       placeholder='Gender'/>
          <Form.Dropdown name='races' value={student.races} onChange={(e, data) => updateStudentState(data)} required
                         fluid multiple selection label='Race'
                         error={validationErrors.races.error}
                         options={raceOptions} placeholder='Choose one or more'/>
          <Form.Select name='grade' value={student.grade} onChange={(e, data) => updateStudentState(data)}
                       options={gradeOptions} required fluid label='Grade'
                       error={validationErrors.grade.error}
                       placeholder='Grade'/>

          <Form.Dropdown name='academics' value={student.academics} onChange={(e, data) => updateStudentState(data)}
                         options={academicStrengthsOptions}
                         required fluid multiple selection label={academicStrengthsOrNeedsText}
                         error={validationErrors.academics.error}
                         placeholder={academicStrengthsOrNeedsText}/>

          <Form.Dropdown name='interests' value={student.interests} onChange={(e, data) => updateStudentState(data)}
                         options={hobbiesOptions}
                         required fluid multiple selection label='Hobbies/ Interests'
                         error={validationErrors.interests.error} placeholder='Hobbies/ Interests'/>
          <Form.Dropdown name='experiences' value={student.experiences} onChange={(e, data) => updateStudentState(data)}
                         options={lifeExperiencesOptions.filter(option => !option.groupOnly)}
                         renderLabel={renderLabel}
                         required fluid multiple selection label='Life Experiences'
                         error={validationErrors.experiences.error} placeholder='Life Experiences'/>
          <Form.Field name='endDate' required>
            <label>Expected Support Completion Date</label>
            <KeyboardDatePicker
              value={student.endDate || new Date()}
              onChange={date => onDateChange(date, student)}
              animateYearScrolling
              format='MM/DD/YY'
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position='end'>
                    <Icon name='caret down'/>
                  </InputAdornment>
                )
              }}/>
          </Form.Field>
          <div style={{ textAlign: 'right' }}>
            <Button.Group size='large'>
              <StyledTransparentButton color='blue' onClick={saveStudent}>Save</StyledTransparentButton>
            </Button.Group>
          </div>
        </Form>
      </Segment>
    </Container>
  )
}

export default StudentForm
