import React, { useState } from 'react'
import { useCookies } from 'react-cookie'
import useGlobal from '../../Store'
import { Icon, Menu, Sidebar } from 'semantic-ui-react'
import logoMobile from '../../Assets/Images/logo-mobile.jpg'
import _ from 'lodash'
import { NavLink } from 'react-router-dom'
import HierarchySelect from '../AppMenu/Select'

const NavBarMobile = ({ children, leftItems, loggedInUserId, role, currentSchool, currentDistrict, currentState }) => {
  const [visible, setVisible] = useState(false)
  const [, setCookie] = useCookies()
  const [, globalActions] = useGlobal()

  const handleSidebarShow = () => setVisible(true)
  const handleSidebarHide = () => setVisible(false)

  return (
    <Sidebar.Pushable as="div">
      {loggedInUserId !== '' && (
        <Sidebar as={Menu} animation="overlay" onHide={handleSidebarHide} icon="labeled" vertical visible={visible}
                 color="#753CBE" style={{ textAlign: 'left' }}>
          <Menu.Item>
            <img alt="logo" src={logoMobile} style={{ width: 340, height: 65 }}/>
          </Menu.Item>

          <Menu.Item as="div" key="dropdown">
            <HierarchySelect role={role} currentSchool={currentSchool} currentDistrict={currentDistrict}
                             currentState={currentState} setCookie={setCookie}
                             changeState={globalActions.locations.changeState}
                             changeDistrict={globalActions.locations.changeDistrict}
                             changeSchool={globalActions.locations.changeSchool}/>
          </Menu.Item>

          {_.map(leftItems, (item) =>

            (role === 'Peer Helper' && (item.key === 'users' || item.key === 'match' || item.key === 'campaigns')) || (['Peer Helper', 'State Level'].includes(role) && item.key === 'assists') || (role !== 'Coordinator' && role !== 'Super User' && item.key === 'match') || (role !== 'Super User' && item.key === 'archive') ? null : (
              <Menu.Item as="div" key={item.key} onClick={handleSidebarHide} style={{ textAlign: 'left' }}>
                <NavLink to={item.to}>
                  {item.icon && (
                    <Icon name={item.icon} style={{ fontSize: 30, marginRight: 0 }}/>
                  )}
                  {item.name}
                </NavLink>
              </Menu.Item>
            )
          )}

          <Menu.Item as="div" key="archive" onClick={handleSidebarHide} style={{ textAlign: 'left' }}>
            <NavLink to="/archive">Archive</NavLink>
          </Menu.Item>

          <Menu.Item as="div" key="profile" onClick={handleSidebarHide} style={{ textAlign: 'left' }}>
            <NavLink to="/profile">My Profile</NavLink>
          </Menu.Item>

          <Menu.Item as="div" key="guides" onClick={handleSidebarHide} style={{ textAlign: 'left' }}>
            <NavLink to="/help">Guides and Tutorials</NavLink>
          </Menu.Item>

          <Menu.Item as="div" key="logout" onClick={handleSidebarHide} style={{ textAlign: 'left' }}>
            <NavLink to="/logout">Logout</NavLink>
          </Menu.Item>
        </Sidebar>
      )}

      <Sidebar.Pusher dimmed={visible} style={{ minHeight: '100vh' }}>
        <Menu fixed="top">
          {loggedInUserId !== '' && (
            <Menu.Item onClick={handleSidebarShow}>
              <Icon name="sidebar"/>
            </Menu.Item>
          )}
        </Menu>

        {children}
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  )
}

export default NavBarMobile
