export const dashboard = {
  header: { fontWeight: 'regular', marginBottom: '2rem', color: 'rgba(0, 0, 0, 0.87)' },
  myProgramHeader: { fontWeight: 'normal', marginBottom: 0, color: '#753CBE', fontSize: 22 },
  myProgramLink: { fontSize: 14, color: 'rgba(0, 0, 0, 0.87)', display: 'flex', alignItems: 'center' },
  list: { fontSize: 13, fontWeight: 'light', lineHeight: 1.7, color: 'rgba(0, 0, 0, 0.64)' },
  count: { fontSize: 65, fontWeight: 'lighter', color: '#753CBE' },
  countSubHeader: { fontSize: 14, color: 'rgba(0,0,0,0.54)', fontWeight: 'regular' },
  segment: { height: '100%', padding: 22, paddingBottom: 15 },
  phCount: { fontSize: '6rem', fontWeight: 100, color: 'white' },
  phHeader: { fontWeight: 100, marginBottom: '1rem', paddingTop: '1rem', fontSize: 24 },
  boxButtonsRow: { paddingTop: 0 },
  boxButtonsRowLast: { padding: 0 },
  boxButtonsColumn: { textAlign: 'right' },
  boxButtonsButton: { paddingLeft: 14, fontSize: 13, textTransform: 'uppercase', color: '#2076BC' },
  table: {
    base: { marginTop: 0 },
    header: { marginBottom: '0.5rem', fontSize: 14, color: 'rgba(0,0,0,0.54)', fontWeight: 'lighter' },
    cell: { fontSize: 13, padding: 0, color: 'rgba(0,0,0,0.64)', fontWeight: 'light' },
    blueCell: { fontSize: 15, padding: 0, color: '#753CBE', textAlign: 'right', fontWeight: 'lighter' },
    lastCell: { textAlign: 'right' }
  }
}

export const pageContainer = {
  marginTop: '5.5rem',
  paddingBottom: '1rem'
}

export const viewport = {
  minHeight: '100vh'
}

export const rightForm = {
  marginRight: window.innerWidth > 1000 ? -5 : 0,
  paddingTop: 0
}
