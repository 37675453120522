import React, { useMemo } from 'react'
import useCampaignsService from '../../../Services/Campaigns'
import { useEffect, useState } from 'react'
import { Loader, Button, Checkbox, List, Modal } from 'semantic-ui-react'
import useGlobal from '../../../Store'

const SendSurveyUsers = (props) => {
  const [globalState, globalActions] = useGlobal()
  const { currentSchool, schools } = globalState
  const userWithoutGrade = [];
  const { open, survey, onClose, version } = props

  const { sendSurvey, getSchoolUsers } = useCampaignsService()

  const [users, setUsers] = useState([])

  const [checkedUsers, setCheckedUsers] = useState([])

  useEffect(() => {
    if (survey) {
      getSchoolUsers().then(users => setUsers(users))
    }
  }, [survey])

  const _onClose = () => {
    setCheckedUsers([])
    onClose()
  }

  const _sendSurvey = () => {
    if (!checkedUsers.length) {
      alert('Please select at least one user')
    } else{
      sendSurvey(survey, [], checkedUsers, version)
      globalActions.notifications.showNotification({
        show: true,
        header: 'Survey sent!',
        subheader: '',
        type: 'success'
      })
      _onClose()
    }
  }

  const _selectAll = () => {
    if (checkedUsers.length === users.length) {
      setCheckedUsers([])
    } else {
      setCheckedUsers(users.map(u => u.id))
    }
  }

  if (!survey) return null
  
  var validUsers = [];

  const userHasSchoolLevel = (user) => {
    
    const school = schools.find(school => school.id === currentSchool)
    if (school && school.schoolLevel !== 'HYBRID') 
      return true

    if (school.schoolLevel == 'HYBRID' && (!user.grades || user.grades.length === 0)){
      userWithoutGrade.push(user)
      return false
    }
    
    let acceptedGrades = []
    //if a hybrid school is selected than different users by level
    survey.schoolLevels.map(level => {
      if (level.name === 'ELEMENTARY')
        acceptedGrades = [...acceptedGrades, 'K', 'G1', 'G2', 'G3', 'G4', 'G5', 'G6']
      else if (level.name === 'MIDDLESCHOOL')
        acceptedGrades = [...acceptedGrades, 'G7', 'G8']
      else
        acceptedGrades = [...acceptedGrades, 'G9', 'G10', 'G11', 'G12']
    })

    return acceptedGrades.some(grade => user.grades?.includes(grade))
  }

  if(users){
    validUsers = users.filter(user => userHasSchoolLevel(user))
  }

  return (
    <Modal
      open={open}
      onClose={_onClose}
    >
      <Modal.Header>Send Survey</Modal.Header>
      <Modal.Content>
        {//check that their are users without grades to list and display the error
          userWithoutGrade.length > 0 &&
          <>
            <h3 className="red-text">
              Note: Peer Helper profiles <b>MUST</b> be completed for your Peer Helpers to display in the Survey list.</h3>
            <h4>
              Tip: To verify Peer Helpers have completed their profile go to Users and make sure they are displaying information for Gender, Grade, and Race.
            </h4> 
            <h4 className="red-text">   
              The following users are missing grade information: {userWithoutGrade.map(function(user, index){
              return (<span key={index}>{(index ? ', ': '') + user.firstName+ " " + user.lastName + (index==user.length ? '.': '')}</span>)
                })}
            </h4> 
          </>
        }
        
        {//check that their are valid users to display
          validUsers.length ? (
            <>
              
              <Button onClick={_selectAll}>Select All</Button>

              <List>
                {users.map((user, key) => {
                  if (userHasSchoolLevel(user))
                    return (
                      <List.Item key={key}>
                        <Checkbox label={user.firstName + ' ' + user.lastName} checked={checkedUsers.includes(user.id)}
                                  onChange={() => {
                                    setCheckedUsers(checkedUsers.includes(user.id) ? checkedUsers.filter(c => c !== user.id) : [...checkedUsers, user.id])
                                  }}/>
                      </List.Item>
                    )
                })}
              </List>
            </>
          ) : (
            <h3>No users available</h3>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={_onClose}>
          Cancel
        </Button>
        <Button
          content="Send"
          labelPosition='right'
          icon='checkmark'
          onClick={_sendSurvey}
          positive
        />
      </Modal.Actions>
    </Modal>
  )
}

export default SendSurveyUsers
