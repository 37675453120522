import React, { useEffect, useState } from 'react'
import 'survey-analytics/survey.analytics.css'
import useCampaignsService from '../../Services/Campaigns'
import BarGraphSegment from '../../Components/BarGraphSegment'
import { cloneDeep } from 'lodash'
import PieChartSurveyComponent from '../../Components/PieChartSurvey'
import { Select } from 'semantic-ui-react'
import StackBar from '../../Components/StackBar'

const SurveyAnalytics = ({ match }) => {
  const { getSurvey, getSurveyAllAnswers } = useCampaignsService()

  const [surveyId] = useState(match.params.id || '0')

  const [unfilteredData, setUnfilteredData] = useState()
  const [data, setData] = useState()
  const [survey, setSurvey] = useState({ id: '', name: '', body: '' })
  const [updatedSurvey, setUpdatedSurvey] = useState()
  const [previousPeerHelpers, setPreviousPeerHelpers] = useState('all')

  useEffect(() => {
    if (surveyId !== '0') {
      getSurvey(surveyId).then(survey => setSurvey({ ...survey, body: JSON.parse(survey.body) }))
    }
  }, [surveyId])

  useEffect(() => {
    if (survey.body) {
      getSurveyAllAnswers(surveyId).then(data => {
        try {
          if (data)
            setUnfilteredData(data.map(d => JSON.parse(d)))
        } catch (e) {
          console.log(e)
        }
      })
    }
  }, [survey])

  useEffect(() => {
    if (survey && data) {
      const newSurvey = cloneDeep(survey)

      // question1
      newSurvey.body.pages[0].elements[0].choices = survey.body.pages[0].elements[0].choices.map(choice => ({
        title: choice,
        points: data.filter(userAnswer => userAnswer.question1 === choice).length
      }))

      // question2
      newSurvey.body.pages[0].elements[1].choices = survey.body.pages[0].elements[1].rows.map(row => ({
        title: row,
        points: survey.body.pages[0].elements[1].columns.map(column => data.reduce((accumulator, currentValue) => {
          return accumulator + (currentValue.question2[row]?.trim() === column.trim() ? 1 : 0)
        }, 0))
      }))

      // question3
      newSurvey.body.pages[0].elements[2].choices = survey.body.pages[0].elements[2].rows.map(row => ({
        title: row,
        points: survey.body.pages[0].elements[2].columns.map(column => data.reduce((accumulator, currentValue) => {
          return accumulator + (currentValue.question3[row]?.trim() === column.trim() ? 1 : 0)
        }, 0))
      }))

      // question4
      newSurvey.body.pages[0].elements[3].choices = survey.body.pages[0].elements[3].choices.map(choice => ({
        title: choice,
        points: data.filter(userAnswer => userAnswer.question4.includes(choice)).length
      }))

      newSurvey.body.pages[0].elements[3].correctAnswers =
        data.filter(userAnswer => {
          return userAnswer.question4.every(answer => newSurvey.body.pages[0].elements[3].correctAnswer.includes(answer))
        }).length

      newSurvey.body.pages[0].elements[3].partialAnswers =
        data.filter(userAnswer => {
          return !userAnswer.question4.every(answer => {
            return newSurvey.body.pages[0].elements[3].correctAnswer.includes(answer)
          }) && userAnswer.question4.some(answer => newSurvey.body.pages[0].elements[3].correctAnswer.includes(answer))
        }).length

      newSurvey.body.pages[0].elements[3].wrongAnswers =
        data.filter(userAnswer => {
          return !userAnswer.question4.some(answer => newSurvey.body.pages[0].elements[3].correctAnswer.includes(answer))
        }).length

      // question5
      newSurvey.body.pages[0].elements[4].choices = survey.body.pages[0].elements[4].choices.map((choice, index) => {
        return {
          title: choice,
          points: (newSurvey.body.pages[0].elements[4].choices[index].points || 0)
            + data.reduce((accumulator, currentValue) => 5 - currentValue.question5.findIndex(answer => choice === answer) + accumulator, 0)
        }
      })

      newSurvey.body.pages[0].elements[4].totalPoints = data.length * 15

      setUpdatedSurvey(newSurvey)
    }
  }, [data, survey])

  useEffect(() => {
    if (unfilteredData) {
      if (previousPeerHelpers === 'all')
        setData(unfilteredData)
      else if (previousPeerHelpers === 'yes')
        setData(unfilteredData.filter(d => d.question1 === 'Yes'))
      else
        setData(unfilteredData.filter(d => d.question1 === 'No'))
    }
  }, [unfilteredData, previousPeerHelpers])

  if (!updatedSurvey) return null

  return (
    <>
      <div style={{
        margin: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center'
      }}>
        <h3 style={{ marginRight: 20, marginTop: 12 }}>Filter by:</h3>
        <Select placeholder="Show All"
                value={previousPeerHelpers}
                onChange={(e, data) => setPreviousPeerHelpers(data.value)}
                options={[{ key: 'all', value: 'all', text: 'Show All' }, {
                  key: 'yes',
                  value: 'yes',
                  text: 'Peer Helpers with previous experience'
                }, { key: 'no', value: 'no', text: 'New Peer Helpers' }]}/>
      </div>
      <BarGraphSegment
        title={`1. ${updatedSurvey.body.pages[0].elements[0].title}`}
        data={updatedSurvey.body.pages[0].elements[0].choices.map((column) => ({
          name: column.title,
          total: column.points || 0,
        }))}
        height={100}
        dataKey="total"/>

      <StackBar
        title={`2. ${updatedSurvey.body.pages[0].elements[1].title}`}
        data={updatedSurvey.body.pages[0].elements[1].choices.map(choice => ({
          name: choice.title,
          'Not sure at all': choice.points[0],
          'A little sure': choice.points[1],
          'Fairly sure': choice.points[2],
          'Sure': choice.points[3],
          'Very sure': choice.points[4],
        }))}
      />

      <StackBar
        title={`3. ${updatedSurvey.body.pages[0].elements[2].title}`}
        data={updatedSurvey.body.pages[0].elements[2].choices.map(choice => ({
          name: choice.title,
          'Not sure at all': choice.points[0],
          'A little sure': choice.points[1],
          'Fairly sure': choice.points[2],
          'Sure': choice.points[3],
          'Very sure': choice.points[4],
        }))}
      />

      <BarGraphSegment
        title={`4. ${updatedSurvey.body.pages[0].elements[3].title}`}
        data={updatedSurvey.body.pages[0].elements[3].choices.map((column) => ({
          name: column.title,
          total: column.points || 0,
        }))}
        yAxisWidth={450}
        height={200}
        dataKey="total"
      />

      <BarGraphSegment
        data={[{
          name: 'Correct Answers',
          total: updatedSurvey.body.pages[0].elements[3].correctAnswers,
          color: '#28c740'
        }, {
          name: 'Partially Correct Answers',
          total: updatedSurvey.body.pages[0].elements[3].partialAnswers,
          color: '#ebb434'
        }, {
          name: 'Wrong Answers',
          total: updatedSurvey.body.pages[0].elements[3].wrongAnswers,
          color: 'red'
        }]}
        yAxisWidth={200}
        height={120}
        dataKey="value"/>

      <PieChartSurveyComponent
        title={`5. ${updatedSurvey.body.pages[0].elements[4].title}`}
        label=""
        columns={updatedSurvey.body.pages[0].elements[4].choices.map((column) => ({
          name: column.title,
          percent: Math.round((column.points || 0) * 100 / updatedSurvey.body.pages[0].elements[4].totalPoints),
        }))}
      />
    </>
  )
  // return <div id="summaryContainer" />
}

export default SurveyAnalytics
