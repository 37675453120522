import React, { useEffect, useState } from 'react'
import {
  RightMenuBar, RightMenuBarButton, RightMenuBarButtonIcon,
  RightMenuBarOptions, StyledLogQuestionAnswer, StyledLogQuestionHeader,
  StyledTable,
  StyledTableHeader, StyledTransparentButton,
} from '../../Theme/Components'
import { Button, Divider, Grid, Header, Icon, Item, Responsive, Segment, Table } from 'semantic-ui-react'
import { dashboard } from '../../Theme/Styles'
import useCampaignsService from '../../Services/Campaigns'
import PieChartComponent from '../../Components/PieChart'
import _ from 'lodash'
import { NoPrint } from 'react-easy-print'
import styled from 'styled-components'
import * as Survey from 'survey-react'
import { NavLink } from 'react-router-dom'
import useGlobal from '../../Store'
import { Typography } from '@material-ui/core'

const CampaignAnalyticsPage = (props) => {
  const [globalState] = useGlobal()
  const { currentState, currentDistrict, currentSchool } = globalState
  const campaignId = props.match.params.campaignId || '0'

  const {
    getSurvey,
    getCampaign,
    getCampaignAnalyticsTable,
    getCampaignForecast,
    getCampaignSurveys
  } = useCampaignsService()

  const [campaign, setCampaign] = useState({})
  const [peerHelpers, setPeerHelpers] = useState([])
  const [questions, setQuestions] = useState([])
  const [survey, setSurvey] = useState({ id: 0, name: '', campaign: { id: '' } })
  const [notComplete, setNotComplete] = useState(0)

  const [showLog, setShowLog] = useState()
  useEffect(() => {
    if (campaignId !== '0') getCampaign(campaignId)
      .then(campaign => {
        if (campaign) setCampaign(campaign)
      })
  }, [])

  useEffect(() => {
    if (campaign?.id) {
      getCampaignAnalyticsTable(campaignId)
        .then(data => {
            setPeerHelpers(data)
        })

      getCampaignSurveys(campaignId)
        .then(surveys => setSurvey(surveys[0]))

      getCampaignForecast(campaignId)
        .then(forecast => setNotComplete(forecast))
    }
  }, [campaign, currentState, currentDistrict, currentSchool])

  useEffect(() => {
    if (showLog?.surveyId) {
      getSurvey(showLog.surveyId)
        .then((survey => {
          if (survey)
            setSurvey(survey)
        }))
    }
  }, [showLog])

  useEffect(() => {
    if (survey.id) {
      const model = new Survey.Model(JSON.parse(survey.body))
      setQuestions(model.getAllQuestions())
    }
  }, [survey])

  const getAnswerColor = (question, answers, answer = null) => {
    if (!question.correctAnswer) return 'black'

    if (_.isArray(answers)) {
      if (isOrderedQuestion(question))
        return answers.findIndex(a => a === answer) === question.correctAnswer.findIndex(a => a === answer) ? 'green' : 'red'
      else
        return question.correctAnswer.includes(answer) ? 'green' : 'red'
    } else if (_.isObject(answers)) {
      return answers[answer] === question.correctAnswer[answer] ? 'green' : 'red'
    } else {
      return answers === question.correctAnswer ? 'green' : 'red'
    }
  }

  const isOrderedQuestion = question =>
    _.isArray(question.choices) && _.isArray(question.correctAnswer) && question.choices.length === question.correctAnswer.length

  const showAnswers = (question) => {
    try {
      const answers = JSON.parse(showLog.body)[question.name]
      const questionSimpleObject = JSON.parse(JSON.stringify(question))

      if (!answers) return null

      if (_.isArray(answers)) {
        if (isOrderedQuestion(questionSimpleObject)) {
          return (
            <ol style={{ paddingLeft: 0 }}>
              {answers.map(answer => <li
                style={{ color: getAnswerColor(questionSimpleObject, answers, answer) }}>{answer}</li>)}
            </ol>
          )
        } else {
          return (
            <ul style={{ paddingLeft: 0 }}>
              {answers.map(answer => <li
                style={{ color: getAnswerColor(questionSimpleObject, answers, answer) }}>{answer}</li>)}
            </ul>
          )
        }
      } else if (_.isObject(answers)) {
        return (
          <ul style={{ paddingLeft: 0 }}>
            {Object.keys(answers).map((answer, index) => {
              return <li key={index}>{answer}<br/><span
                style={{ color: getAnswerColor(questionSimpleObject, answers, answer) }}>{answers[answer]}</span>
              </li>
            })}
          </ul>
        )
      } else {
        return (
          <p style={{ color: getAnswerColor(questionSimpleObject, answers) }}>
            {answers}
          </p>
        )
      }
    } catch (e) {
      console.log(e)
      return ''
    }
  }

  const renderPeerHelpersTable = () => (
    <StyledTable compact striped size="small">
      <Table.Header className="stickyTh">
        <Responsive as={Table.Row} minWidth={1000}>
          <Table.HeaderCell>
            Date
          </Table.HeaderCell>
          <Table.HeaderCell>
            Peer Helper
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center">
            Complete
          </Table.HeaderCell>
          <Table.HeaderCell/>
        </Responsive>
      </Table.Header>

      <Table.Body key={peerHelpers.length}>
        {_.map(peerHelpers, (log) => {
          const { peerHelper: { id, firstName, lastName }, body, createdAt, updatedAt } = log

          return (
            <Table.Row key={id}>
              <Table.Cell>
                <Responsive as="strong" maxWidth={767} style={{ marginRight: 5 }}>
                  Date
                </Responsive>
                {new Date(updatedAt || createdAt).toLocaleDateString('en', {
                  year: '2-digit',
                  month: 'numeric',
                  day: 'numeric'
                })}
              </Table.Cell>
              <Table.Cell>
                <Responsive as="strong" maxWidth={767} style={{ marginRight: 5 }}>
                  Peer Helper
                </Responsive>
                {(!!firstName && !!lastName) && firstName + ' ' + lastName}
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Responsive as="strong" maxWidth={767} style={{ marginRight: 5 }}>
                  Complete
                </Responsive>
                {!!body && <Icon name="check"/>}
              </Table.Cell>

              <Table.Cell textAlign="right">
                <NoPrint force>
                  {!!body && <Item as={Button} onClick={() => setShowLog(log)}
                                   style={{
                                     color: '#2076BC',
                                     backgroundColor: 'transparent',
                                     borderWidth: 0
                                   }}>
                    VIEW SURVEY
                  </Item>}
                </NoPrint>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </StyledTable>
  )

  const renderQuestionsTable = () => {
    if (!peerHelpers.length || !questions.length) return null

    const newQuestions = [...questions]
    peerHelpers.forEach(peerHelper => {
      const log = JSON.parse(peerHelper.body)

      const version = peerHelper.version

      Object.keys(log).forEach(questionName => {
        const questionIndex = newQuestions.findIndex(q => q.propertyHash.name === questionName)
        if (newQuestions[questionIndex].propertyHash.choices) {
          if (_.isArray(log[questionName])) {
            log[questionName].forEach(answer => {
              const choiceIndex = newQuestions[questionIndex].propertyHash.choices.findIndex(choice => choice.propertyHash.value === answer)

              if (choiceIndex > -1) {
                if (!newQuestions[questionIndex].propertyHash.choices[choiceIndex].propertyHash?.answersCount?.[version]) {
                  const newArray = []
                  newArray[version] = 1
                  newQuestions[questionIndex].propertyHash.choices[choiceIndex].propertyHash.answersCount = newArray
                } else
                  newQuestions[questionIndex].propertyHash.choices[choiceIndex].propertyHash.answersCount[version] += 1
              }
            })
          } else {
            const choiceIndex = newQuestions[questionIndex].propertyHash.choices.findIndex(choice => choice.propertyHash.value === log[questionName])

            if (choiceIndex > -1) {
              if (!newQuestions[questionIndex].propertyHash.choices[choiceIndex].propertyHash?.answersCount?.[version]) {
                const newArray = []
                newArray[version] = 1
                newQuestions[questionIndex].propertyHash.choices[choiceIndex].propertyHash.answersCount = newArray
              } else
                newQuestions[questionIndex].propertyHash.choices[choiceIndex].propertyHash.answersCount[version] += 1
            }
          }
        }

        if (newQuestions[questionIndex].propertyHash.rows && !newQuestions[questionIndex].propertyHash.columns) {
          Object.keys(log[questionName]).forEach((answerKey) => {
            const rowIndex = newQuestions[questionIndex].propertyHash.rows.findIndex(choice => choice.propertyHash.value === answerKey)

            if (rowIndex > -1) {
              if (!newQuestions[questionIndex].propertyHash.rows[rowIndex].propertyHash?.answersCount?.[version]) {
                const newArray = []
                newArray[version] = 1
                newQuestions[questionIndex].propertyHash.rows[rowIndex].propertyHash.answersCount = newArray
              } else
                newQuestions[questionIndex].propertyHash.rows[rowIndex].propertyHash.answersCount[version] += 1
            }
          })
        }

        if (newQuestions[questionIndex].propertyHash.rows && newQuestions[questionIndex].propertyHash.columns) {
          Object.keys(log[questionName]).forEach((answerKey) => {
            const rowIndex = newQuestions[questionIndex].propertyHash.rows.findIndex(choice => choice.propertyHash.value === answerKey)
            const columnIndex = newQuestions[questionIndex].propertyHash.columns.findIndex(column => column.propertyHash.value === log[questionName][answerKey])

            if (rowIndex > -1 && columnIndex > -1) {
              if (!newQuestions[questionIndex].propertyHash.rows[rowIndex].propertyHash.answersCount?.[columnIndex]) {
                const newArray = []
                newArray[columnIndex] = []
                newArray[columnIndex][version] = 1
                newQuestions[questionIndex].propertyHash.rows[rowIndex].propertyHash.answersCount = newArray
              } else
                newQuestions[questionIndex].propertyHash.rows[rowIndex].propertyHash.answersCount[columnIndex][version] += 1
            }
          })
        }
      })
    })

    return (
      <Segment>
        {newQuestions.map((question, index) => (
          <div key={index}>
            <Typography style={{ margin: '20px 0' }}>{question.fullTitle}</Typography>

            <StyledTable compact striped size="small">
              <Table.Header className="stickyTh">
                <Responsive as={Table.Row} minWidth={1000}>
                  <Table.HeaderCell style={{ width: '40%' }}>
                    Responses
                  </Table.HeaderCell>
                  {[...Array(survey.numberOfCompletions).keys()].map(version => (
                    <Table.HeaderCell>
                      {version === 0 ? 'Pre-Survey' : 'Post-Survey'}
                    </Table.HeaderCell>
                  ))}

                  <Table.HeaderCell style={{ width: '10%' }}>
                    % Change
                  </Table.HeaderCell>
                </Responsive>
              </Table.Header>

              <Table.Body>
                {question.propertyHash.choices?.map((choice, choiceIndex) => (
                  <Table.Row key={choiceIndex}>
                    <Table.Cell>{choice.propertyHash.value}</Table.Cell>
                    {[...Array(survey.numberOfCompletions).keys()].map(version => (
                      <Table.Cell>{choice.propertyHash?.answersCount?.[version] || '-'}</Table.Cell>
                    ))}
                    <Table.Cell>
                      {Math.round(choice.propertyHash?.answersCount?.[1] * 100 / choice.propertyHash?.answersCount?.[0]) || '-'}
                    </Table.Cell>
                  </Table.Row>
                ))}

                {question.propertyHash.rows && !question.propertyHash.columns && question.propertyHash.rows.map((row, rowIndex) => (
                  <Table.Row key={rowIndex}>
                    <Table.Cell>{row.propertyHash.value}</Table.Cell>
                    {[...Array(survey.numberOfCompletions).keys()].map(version => (
                      <Table.Cell>{row.propertyHash?.answersCount?.[version] || '-'}</Table.Cell>
                    ))}
                    <Table.Cell>
                      {Math.round(row.propertyHash?.answersCount?.[1] * 100 / row.propertyHash?.answersCount?.[0]) || '-'}
                    </Table.Cell>
                  </Table.Row>
                ))}

                {question.propertyHash.rows && question.propertyHash.columns && question.propertyHash.rows.map((row, rowIndex) => (
                  <Table.Row key={rowIndex}>
                    <Table.Cell>{row.propertyHash.value}</Table.Cell>
                    {[...Array(survey.numberOfCompletions).keys()].map(version => (
                      <Table.Cell style={{ verticalAlign: 'top' }}>
                        <div style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginRight: 40
                        }}>
                          {question.propertyHash.columns.map((column, columnIndex) => (
                            <div key={columnIndex} style={{ textAlign: 'center' }}>
                              {column.propertyHash.value}<br/><br/>{row.propertyHash?.answersCount?.[columnIndex]?.[version] || '-'}
                            </div>
                          ))}
                        </div>
                      </Table.Cell>
                    ))}
                    <Table.Cell> - </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </StyledTable>
          </div>
        ))}
      </Segment>
    )
  }

  return (
    <Grid>
      <Grid.Row style={{ paddingTop: 0 }}>
        <Grid.Column mobile={16} tablet={9} computer={campaign.type !== 'PREVENTION' ? 11 : 16}>
          <Grid padded columns={2}>
            <Grid.Column style={{ paddingBottom: 0 }}>
              <StyledHeader as="h2">Percentage of Surveys Complete</StyledHeader>
            </Grid.Column>
          </Grid>

          <Grid padded>
            <Grid.Column computer={16} mobile={16} style={{ paddingTop: 0 }}>
              <StyledCardContent>
                {campaign.type === 'PREVENTION' ? (
                  <PieChartComponent title={campaign.name} label="Survey Progress" columns={[{
                    name: 'COMPLETE',
                    percent: 100 - Math.round(notComplete / (notComplete + peerHelpers.filter(p => !!p.body).length) * 100) || 0
                  }, {
                    name: 'NOT COMPLETE',
                    percent: Math.round(notComplete / (notComplete + peerHelpers.filter(p => !!p.body).length) * 100) || 0
                  }]}/>
                ) : (
                  <PieChartComponent title={campaign.name} label="Survey Progress" columns={[{
                    name: 'COMPLETE',
                    percent: Math.round(peerHelpers.filter(p => !!p.body).length / peerHelpers.length * 100) || 0
                  }, {
                    name: 'NOT COMPLETE',
                    percent: 100 - Math.round(peerHelpers.filter(p => !!p.body).length / peerHelpers.length * 100) || 0
                  }]}/>
                )}
              </StyledCardContent>
            </Grid.Column>
            <Grid.Column computer={16} mobile={16}>
              <StyledTableHeader>
                <Header as="h2" style={dashboard.phHeader}>
                  {campaign.name}
                </Header>
                {campaign.type !== 'PREVENTION' && (
                  <StyledTransparentButton as={NavLink} to={`/campaigns/${campaignId}/report`}>
                    View Report
                  </StyledTransparentButton>
                )}
              </StyledTableHeader>

              {campaign.type !== 'PREVENTION' ? renderPeerHelpersTable() : renderQuestionsTable()}

            </Grid.Column>
          </Grid>
        </Grid.Column>

        <Grid.Column mobile={16} tablet={7} computer={5}>
          {!!showLog && (
            <Segment>
              <RightMenuBar>
                <Header as="h3">{campaign.name}</Header>
                <RightMenuBarOptions>
                  <RightMenuBarButton onClick={() => setShowLog(undefined)}>
                    <RightMenuBarButtonIcon name="close" style={{ fontSize: 20 }}/>
                  </RightMenuBarButton>
                </RightMenuBarOptions>
              </RightMenuBar>

              <Divider/>


              <Item.Group>
                {questions.map((question, key) => (
                  <Item key={key}>
                    <Item.Content>
                      <StyledLogQuestionHeader>{key + 1}. {question.fullTitle}</StyledLogQuestionHeader>
                      <StyledLogQuestionAnswer>{showAnswers(question)}</StyledLogQuestionAnswer>
                    </Item.Content>
                  </Item>
                ))}
              </Item.Group>
            </Segment>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

const StyledHeader = styled(Header)`
  size: 24px !important;
  font-weight: lighter;
`

const StyledCardContent = styled(Segment)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 250px !important;
  align-items: stretch;
`

export default CampaignAnalyticsPage
