import React, { useEffect, useState } from 'react'
import CoordinatorDashboard from './Coordinator'
import DistrictDashboard from './District'
import StateDashboard from './State'
import SuperUserDashboard from './SuperUser'
import PeerHelperDashboard from './PeerHelper'
import useGlobal from '../../Store'
import { Grid } from 'semantic-ui-react'
import ContextualRightComponent from '../../Components/ContextualRightComponent'
import * as qs from 'qs'
import { baseUrl } from '../../Config/ApiEndpoints'
import { pdfjs } from 'react-pdf'
import Pdf from '../../Components/Pdf/Pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const Dashboard = (props) => {
  const [globalState] = useGlobal()
  const { loggedInUser } = globalState
  const [currentSchool, setCurrentSchool] = useState('')

  const params = qs.parse(props.location.search.split('?').join(''))
  const pdfPath = params.pdfPath ? baseUrl + params.pdfPath : undefined

  useEffect(() => {
    setCurrentSchool(globalState.currentSchool)
  }, [globalState.currentSchool])

  const decideDashboard = () => {
    switch (loggedInUser.role) {
      case 'Super User':
        return SuperUserDashboard
      case 'State Level':
        return StateDashboard
      case 'District Level':
        return DistrictDashboard
      case 'Coordinator':
      case 'School Level':
        return CoordinatorDashboard
      default:
        return PeerHelperDashboard
    }
  }

  const Dashboard = loggedInUser.id !== '' ? decideDashboard() : 'div'

  return (
    <Grid>
      <Grid.Row style={{ paddingTop: 0 }}>
        <Grid.Column mobile={16} tablet={9} computer={11}>
          {pdfPath !== undefined ? (
            <Pdf pdfPath={pdfPath} history={props.history}/>
          ) : (
            <div key={currentSchool}>
              <Dashboard {...props} />
            </div>
          )}
        </Grid.Column>

          <Grid.Column mobile={16} tablet={7} computer={5}>
            <ContextualRightComponent {...props} width={16 / 5}/>
          </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default Dashboard
