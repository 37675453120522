import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Button, Divider, Form, Grid, Header, Icon, Pagination, Responsive, Segment, Table } from 'semantic-ui-react'
import {
  academicStrengthsOptions,
  genderOptions,
  gradeOptions,
  hobbiesOptions,
  lifeExperiencesOptions,
  raceOptions, renderLabel
} from '../../Config/SelectOptions'
import useGlobal from '../../Store'
import _ from 'lodash'
import { dashboard, rightForm } from '../../Theme/Styles'
import useStringOperations from '../../Utils/StringOperations'
import noSchoolPlaceholder from '../../Assets/Images/icons8-school-house-96.png'
import { StyledTable } from '../../Theme/Components'

export const defaultMatchToolFilers = {
  schoolId: '',
  gender: '',
  grades: '',
  races: [],
  academic: [],
  interests: [],
  experiences: []
}

const MatchTool = () => {
  const [globalState, globalActions] = useGlobal()
  const { matchResults, matchResultsPagination, currentSchool } = globalState
  const [filters, setFilters] = useState({ ...defaultMatchToolFilers, schoolId: currentSchool })
  const [searchPerformed, setSearchPerformed] = useState(false)
  const [page, setPage] = useState(matchResultsPagination.number)

  const [width, setWidth] = useState(0)

  useLayoutEffect(() => {
    function updateSize () {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  useEffect(() => {
    setFilters({ ...filters, schoolId: currentSchool })
  }, [currentSchool])

  const updateFilters = (e, data) => {
    setFilters({ ...filters, [data.name]: data.value })
  }

  const search = () => {
    setSearchPerformed(true)
    globalActions.matchTool.getMatches(filters, page)
  }

  const resetFilters = () => {
    setSearchPerformed(false)
    setFilters(defaultMatchToolFilers)
  }

  const onPageChange = (e, { activePage }) => {
    setPage(activePage - 1)
  }

  return (
    <Grid reversed='computer tablet'>
      <Grid.Column mobile={16} computer={5} style={rightForm}>
        <Responsive as='div' maxWidth={1000}>
          <Header as='h2'>Match Results</Header>
        </Responsive>
        <div style={width > 1000 ? { right: 0, top: 65, width: width / (16 / 5) } : {}}>
          <Segment>
            <Header as='h3' style={dashboard.myProgramHeader}>Match Tool</Header>
            <Divider/>
            {currentSchool !== '' ? (
              <Form size='large'>
                <Form.Group className='custom-form-group'>
                  <label className='small-label'>Gender</label>
                  <Form.Select name='gender' onChange={updateFilters} value={filters.gender} placeholder='Any'
                               options={[{ key: 'any', text: 'Any', value: '' }, ...genderOptions]}/>
                </Form.Group>

                <Form.Group className='custom-form-group'>
                  <label className='small-label'>Grade</label>
                  <Form.Select name='grades' onChange={updateFilters} value={filters.grades} placeholder='Any'
                               options={[{ key: 'any', text: 'Any', value: '' }, ...gradeOptions]}/>
                </Form.Group>

                <Form.Group className='custom-form-group'>
                  <label className='small-label'>Race</label>
                  <Form.Select name='races' multiple onChange={updateFilters} value={filters.races} placeholder='Any'
                               options={raceOptions}/>
                </Form.Group>

                <Form.Group className='custom-form-group'>
                  <label className='small-label'>Academic Needs</label>
                  <Form.Dropdown multiple fluid selection name='academic' onChange={updateFilters}
                                 value={filters.academic} placeholder='Any'
                                 options={academicStrengthsOptions}/>
                </Form.Group>

                <Form.Group className='custom-form-group'>
                  <label className='small-label'>Hobbies/Interests</label>
                  <Form.Dropdown multiple fluid selection name='interests' onChange={updateFilters}
                                 value={filters.interests} placeholder='Any'
                                 options={hobbiesOptions}/>
                </Form.Group>

                <Form.Group className='custom-form-group'>
                  <label className='small-label'>Life Experiences</label>
                  <Form.Dropdown multiple fluid selection name='experiences' onChange={updateFilters}
                                 value={filters.experiences} placeholder='Any'
                                 options={lifeExperiencesOptions.filter(option => !option.groupOnly)}
                                 renderLabel={renderLabel}
                  />
                </Form.Group>

                <div style={{ textAlign: 'right' }}>
                  <Button.Group>
                    <Button onClick={resetFilters}>Clear All</Button>
                    <Button.Or/>
                    <Button color='blue' onClick={search}>Search</Button>
                  </Button.Group>
                </div>
              </Form>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20%' }}>
                <img src={noSchoolPlaceholder} alt='No school selected'/>
                <p style={{ fontSize: 16, color: 'rgba(0, 0, 0, 0.38)', textAlign: 'center' }}>Select a school first</p>
              </div>
            )}
          </Segment>
        </div>
      </Grid.Column>

      <Grid.Column mobile={16} computer={11}>
        <Responsive {...Responsive.onlyComputer}>
          <Header as='h2' style={{ marginTop: 10 }}>Match Results</Header>
        </Responsive>
        {!searchPerformed ? (
          <Segment placeholder>
            <Header as='h2' icon color='grey'>
              PeerHelpers Match Tool
              <Icon name='user plus' style={{ marginTop: 20 }}/>
              <Header.Subheader>Perform a search to get started</Header.Subheader>
            </Header>
          </Segment>
        ) : (
          <div>
            {matchResults.length === 0 ? (
              <Segment placeholder>
                <Header as='h2' icon color='grey'>
                  PeerHelpers Match Tool
                  <Icon name='user x' style={{ marginTop: 20 }}/>
                  <Header.Subheader>No results found</Header.Subheader>
                </Header>
              </Segment>
            ) : (
              <React.Fragment>
                <UsersTable users={matchResults}/>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Pagination defaultActivePage={page + 1} totalPages={matchResultsPagination.totalPages}
                              onPageChange={onPageChange}/>
                </div>
              </React.Fragment>
            )}
          </div>
        )}
      </Grid.Column>
    </Grid>
  )
}

const UsersTable = ({ users }) => {
  const stringOperations = useStringOperations()
  return (
    <Table compact striped size='small'>
      <Table.Header>
        <Responsive as={Table.Row} minWidth={768}>
          <Table.HeaderCell>
            Student
          </Table.HeaderCell>
          <Table.HeaderCell>
            Gender
          </Table.HeaderCell>
          <Table.HeaderCell>
            Grade
          </Table.HeaderCell>
          <Table.HeaderCell>
            Race
          </Table.HeaderCell>
          <Table.HeaderCell>
            Academic Needs
          </Table.HeaderCell>
          <Table.HeaderCell>
            Hobbies/Interests
          </Table.HeaderCell>
          <Table.HeaderCell>
            Life Experiences
          </Table.HeaderCell>
        </Responsive>
      </Table.Header>

      <Table.Body>
        {_.map(users, ({ id, lastName, firstName, gender, grades, race, academic, interests, experiences }) => (
          <Table.Row key={firstName + lastName + id}>
            <Table.Cell>
              <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                Student:
              </Responsive>
              {firstName + ' ' + lastName}
            </Table.Cell>
            <Table.Cell>
              <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                Gender:
              </Responsive>
              {stringOperations.translate(genderOptions, gender)}
            </Table.Cell>
            <Table.Cell>
              <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                Grade:
              </Responsive>
              {stringOperations.translate(gradeOptions, grades)}
            </Table.Cell>
            <Table.Cell>
              <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                Race:
              </Responsive>
              {stringOperations.translate(raceOptions, race)}
            </Table.Cell>
            <Table.Cell>
              <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                Academic Needs:
              </Responsive>
              {stringOperations.translate(academicStrengthsOptions, academic)}
            </Table.Cell>
            <Table.Cell>
              <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                Hobbies/Interests:
              </Responsive>
              {stringOperations.translate(hobbiesOptions, interests)}
            </Table.Cell>
            <Table.Cell>
              <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                Life Experiences:
              </Responsive>
              {stringOperations.translate(lifeExperiencesOptions, experiences)}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}

export default MatchTool
