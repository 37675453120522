import React, { useEffect, useState } from 'react'
import { Button, Divider, Embed, Grid, Header, Image, List, Segment, Modal, Form } from 'semantic-ui-react'
import useGlobal from '../../Store'
import { NavLink } from 'react-router-dom'
import { StyledTransparentButton } from '../../Theme/Components'

const Guides = (props) => {
  const [globalState, globalActions] = useGlobal()
  const [modalOpen, setModalOpen] = useState(false)
  let { tutorials, guide, loggedInUser } = globalState
  const [contact, setContact] = useState({ subject: '', message: '' })
  const guideId = props.match.params.guideId || '0'

  useEffect(() => {
    globalActions.guidesAndTutorials.getGuidesAndTutorials()
    globalActions.guidesAndTutorials.getGuide(guideId)
  }, [guideId])

  const updateContactState = e => {
    const { name, value } = typeof e.target !== 'undefined' ? e.target : e
    setContact({ ...contact, [name]: value })
  }

  const saveContact = () => {
    globalActions.contact.saveContact(contact)
    setModalOpen(false)
    globalActions.notifications.showNotification({ show: true, header: 'Message sent!', subheader: '', type: 'success' })
  }

  if (guide !== undefined && !guide.id && tutorials.length) {
    guide = tutorials[0]
  }

  return (
    <Grid reversed='computer tablet'>
      <Grid.Row style={{ paddingTop: 0 }}>
        <Grid.Column mobile={16} computer={5}>
          <Segment style={{ height: '90vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div>
              <Header as='h2' color='blue'>Guides and Tutorials</Header>
              <Divider />
              <List relaxed>
                {tutorials.map((item, key) => (
                  <List.Item key={key}>
                    <Image avatar src='https://react.semantic-ui.com/images/avatar/small/rachel.png' />
                    <List.Content>
                      <List.Header as={NavLink} to={`/help/${item.id}`}>{item.title}</List.Header>
                    </List.Content>
                  </List.Item>
                ))}
              </List>
            </div>
            {loggedInUser.role !== 'Peer Helper' && (
              <StyledTransparentButton color='blue' style={{ textAlign: 'center', fontSize: 16 }} onClick={() => setModalOpen(true)}>
                Contact Support
              </StyledTransparentButton>
            )}
          </Segment>

          <Modal open={modalOpen} onClose={() => setModalOpen(false)} closeIcon>
            <Modal.Header>Contact Support</Modal.Header>
            <Modal.Content>
              <Form>
                <Form.Input name='subject' fluid label='Subject*' placeholder='Please enter the subject' onChange={updateContactState} />
                <Form.TextArea name='message' fluid label='Message' placeholder='...' onChange={updateContactState} />
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button.Group>
                <Button negative onClick={() => setModalOpen(false)}>Cancel</Button>
                <Button.Or />
                <Button positive icon='checkmark' labelPosition='right' content='Submit' onClick={saveContact} />
              </Button.Group>
            </Modal.Actions>
          </Modal>
        </Grid.Column>

        <Grid.Column mobile={16} computer={11}>
          <Header as='h1' style={{ marginTop: 10 }}>{guide.title}</Header>
          <Embed url={guide.url} active />
          <Segment style={{ marginTop: 0 }}>
            {guide.description}
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default Guides
