import React, { useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'
import { raceOptions, gradeOptions, genderOptions } from '../../Config/SelectOptions'
import useStringOperations from '../../Utils/StringOperations'

const StyledGridColumn = styled(Grid.Column)`
  margin-bottom: 2rem !important;
`

const DemographicCard = ({ data }) => {
  const stringOperations = useStringOperations()

  const [genderData, setGenderData] = useState([])
  const [raceData, setRaceData] = useState([])
  const [gradeData, setGradeData] = useState([])

  useEffect(() => {
    setGenderData(data && getSection(data.genders))
    setRaceData(data && getSection(data.races))
    setGradeData(data && getSection(data.grades))
  }, [data])

  const getSection = (section) => {
    return section ? Object.keys(section).map((key) =>
      <div key={key} style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingRight: '3rem',
        paddingLeft: '1rem',
        paddingTop: '0.5rem',
        borderBottom: '0.5px solid gray'
      }}
      >
        <p
          style={{ marginBottom: '2px' }}>{stringOperations.translate(genderOptions, key) || stringOperations.translate(gradeOptions, key) || stringOperations.translate(raceOptions, key)}</p>
        <p style={{ marginBottom: '2px', color: '#753CBE' }}>{section[key]}</p>
      </div>
    ) : null
  }

  return (
    <React.Fragment>
      <Grid>
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <div style={{
            marginTop: '2rem',
            paddingLeft: '1rem',
            paddingRight: '2rem',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            borderBottom: '2px solid black'
          }}>
            <p style={{ fontSize: '24px', marginBottom: '5px', color: 'rgba(77, 77, 77, 1)' }}>Demographic Totals</p>
            <p style={{ marginTop: '20px', fontSize: '9px', color: 'rgba(77, 77, 77, 1)' }}>TOTAL</p>
          </div>
        </Grid.Column>
      </Grid>
      <StyledGridColumn mobile={16} tablet={16} computer={16}>
        {genderData}
      </StyledGridColumn>
      <StyledGridColumn mobile={16} tablet={16} computer={16}>
        {raceData}
      </StyledGridColumn>
      <StyledGridColumn mobile={16} tablet={16} computer={16}>
        {gradeData}
      </StyledGridColumn>
    </React.Fragment>
  )
}

export default DemographicCard
