export const processRequestErrorNotification = error => {
  let subheader = ''

  if (typeof error.response !== 'undefined') {
    switch (error.response.status) {
      case 400:
        subheader = 'Bad request'
        break
      case 401:
        window.location.href = (window.location.host.indexOf('localhost') > -1 ? 'http://' : 'https://') + window.location.host + '/logout'
        subheader = 'Unauthorized'
        break
      case 403:
        subheader = 'Forbidden'
        break
      case 404:
        subheader = 'Resource not found'
        break
      case 500:
        subheader = 'Server error'
        break
      default:
        subheader = 'Unknown error'
    }
  }

  return { show: typeof error.response !== 'undefined' && error.response.status !== 404, header: 'Error', subheader, type: 'warning' }
}
