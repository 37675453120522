import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Document, Page } from 'react-pdf'
import printJS from 'print-js'
import { Button, Icon } from 'semantic-ui-react'

const Pdf = ({ history, pdfPath }) => {
  const [numPages, setNumPages] = useState(null)
  const [width, setWidth] = useState(0)
  const [zoom, setZoom] = useState(50)

  useLayoutEffect(() => {
    function updateSize () {
      setWidth(window.innerWidth * (zoom / 100))
    }

    updateSize()

    window.addEventListener('resize', updateSize)
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  useEffect(() => {
    setWidth(window.innerWidth * (zoom / 100))
  }, [zoom])

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  const changeZoom = (value) => {
    if (value >= 20 && value <= 100) {
      setZoom(value)
    }
  }

  const openTabAndClose = (pdfPath) => {
    const newTab = window.open(pdfPath)

    setTimeout(() => newTab.close(), 2000)
  }

  const saveToDisk = () => {
    let save = document.createElement('a')
    save.href = '#'
    save.download = pdfPath
    save.target = '_blank'
    save.onclick = () => openTabAndClose(pdfPath)
    document.body.appendChild(save)
    save.click()
    document.body.removeChild(save)
  }

  return (
    <div style={styles.container}>
      <Document file={pdfPath} onLoadSuccess={onDocumentLoadSuccess}>
        {[...Array(numPages).keys()].map(i => (
          <div style={{ marginTop: 20 }}>
            <Page width={width} pageNumber={i + 1}/>
          </div>
        ))}
      </Document>

      <div style={styles.optionsContainer}>
        <Button icon onClick={saveToDisk} style={styles.download}>
          <Icon name="cloud download"/>
        </Button>
        <Button icon onClick={() => printJS(pdfPath)} style={styles.print}>
          <Icon name="print"/>
        </Button>

        <Button icon onClick={() => changeZoom(zoom - 10)} disabled={zoom === 20} style={styles.zoomLeft}>
          <Icon name="zoom-out"/>
        </Button>

        <div style={styles.pageNumberOrZoom}>
          {zoom}%
        </div>

        <Button icon onClick={() => changeZoom(zoom + 10)} disabled={zoom === 100} style={styles.zoomRight}>
          <Icon name="zoom-in"/>
        </Button>

        <Button icon onClick={() => history.push('/')} style={styles.closeButton}>
          <Icon name="close"/>
        </Button>
      </div>
    </div>
  )
}

const styles = {
  container: { display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' },
  optionsContainer: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: 5,
    padding: '2px 10px'
  },
  pageNumberOrZoom: {
    width: 50,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 9,
    fontSize: 16
  },
  leftPage: { width: 35, height: 35, marginRight: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0 },
  rightPage: { width: 35, height: 35, marginRight: 50, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 },
  download: { width: 35, height: 35, marginRight: 5 },
  print: { width: 35, height: 35, marginRight: 50 },
  zoomLeft: { width: 35, height: 35, marginRight: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0 },
  zoomRight: { width: 35, height: 35, marginRight: 0, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 },
  closeButton: { width: 45, height: 45, marginRight: 0, marginLeft: 35 },
}

export default Pdf
