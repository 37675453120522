import React, { useEffect, useState } from 'react'
import { Dimmer, Grid, Loader } from 'semantic-ui-react'
import useGlobal from '../../Store'
import SupportTopicsStatistics from '../../Components/Dashboard/Statistics/SupportTopicsStatistics'
import AtRiskReferralsStatistics from '../../Components/Dashboard/Statistics/AtRiskReferralsStatistics'
import GroupActivitiesCoordinatorsStatistics
  from '../../Components/Dashboard/Statistics/GroupActivitiesCoordinatorsStatistics'
import AsNeededSupportCoordinatorsStatistics
  from '../../Components/Dashboard/Statistics/AsNeededSupportCoordinatorsStatistics'
import OngoingSupportStatistics from '../../Components/Dashboard/Statistics/OngoingSupportStatistics'
import ProgramsStatistics from '../../Components/Dashboard/Statistics/ProgramsStatistics'
import ContextualRightComponent from '../../Components/ContextualRightComponent'
import PeerHelpersStatistics from '../../Components/Dashboard/Statistics/PeerHelpersStatistics'

const StateDashboard = (props) => {
  const [globalState, globalActions] = useGlobal()
  const { dashboardRequestStatus, currentSchool, currentState, currentDistrict } = globalState
  const [dashboardStatistics, setDashboardStatistics] = useState(globalState.dashboardStatistics)

  useEffect(() => {
    if (props.location.pathname !== '/') {
      globalActions.dashboards.getStatistics()
    }
  }, [])

  useEffect(() => {
    if (props.location.pathname === '/') {
      setTimeout(() => {
        globalActions.dashboards.getStatistics()
      }, 500)
    }
  }, [props.location, currentSchool, currentDistrict, currentState])

  useEffect(() => {
    setDashboardStatistics({
      ...dashboardStatistics,
      programs: globalState.dashboardStatistics.programs,
      asNeededSupport: globalState.dashboardStatistics.asNeededSupport,
      onGoingSupport: globalState.dashboardStatistics.onGoingSupport,
      referrals: globalState.dashboardStatistics.referrals,
      groupActivities: globalState.dashboardStatistics.groupActivities,
      topics: globalState.dashboardStatistics.topics,
      peerHelpers: globalState.dashboardStatistics.peerHelpers
    })
  }, [globalState.dashboardStatistics])

  return (
    <Grid padded>
      <Grid.Column computer={8} mobile={16}>
        {currentSchool === '' ? (
          <ProgramsStatistics programs={dashboardStatistics.programs} level={'State Level'}/>
        ) : (
          <PeerHelpersStatistics peerHelpers={dashboardStatistics.peerHelpers}/>
        )}
      </Grid.Column>
      <Grid.Column computer={8} mobile={16}>
        <OngoingSupportStatistics data={dashboardStatistics.onGoingSupport}/>
      </Grid.Column>

      <Grid.Column computer={8} mobile={16}>
        <GroupActivitiesCoordinatorsStatistics groupActivities={dashboardStatistics.groupActivities}/>
      </Grid.Column>
      <Grid.Column computer={8} mobile={16}>
        <AsNeededSupportCoordinatorsStatistics asNeededSupport={dashboardStatistics.asNeededSupport}/>
      </Grid.Column>

      <Grid.Column computer={8} mobile={16}>
        <AtRiskReferralsStatistics referrals={dashboardStatistics.referrals}/>
      </Grid.Column>
      <Grid.Column computer={8} mobile={16}>
        <SupportTopicsStatistics topics={dashboardStatistics.topics}/>
      </Grid.Column>
    </Grid>
  )
}

export default StateDashboard
