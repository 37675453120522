import axios from 'axios'
import endpoints from '../Config/ApiEndpoints'
import { processRequestErrorNotification } from '../Utils/Error'
import { defaultMatchToolFilers } from '../Pages/MatchTool/'
import { getResourceFromArchive } from './Archive'

export const getMatches = async (store, filters = defaultMatchToolFilers, page = 0) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  let query = []
  const filtersWithSchool = { ...filters, schoolId: store.state.currentSchool }
  Object.keys(filtersWithSchool).forEach(key => {
    if (filtersWithSchool[key] !== undefined && ((typeof filtersWithSchool[key] === 'string' && filtersWithSchool[key] !== '') || filtersWithSchool[key].length > 0)) {
      if (typeof filtersWithSchool[key] !== 'string' && filtersWithSchool[key].length > 0) {
        filtersWithSchool[key].map(filter => {
          query.push(`${key}:${filter}`)
        })
      } else {
        query.push(`${key}:${filtersWithSchool[key]}`)
      }
    }
  })

  const url = endpoints.users.matchTool + `/search?search=${query.join(',')}&page=${page}`

  store.setState({ matchResultsRequestStatus: 'LOADING' })
  try {
    const response = await getResourceFromArchive(store, url, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
    const matchResults = response.data._embedded.users
    const matchResultsPagination = response.data.page

    store.setState({ matchResultsRequestStatus: 'SUCCESS', matchResults, matchResultsPagination })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ matchResults: [], matchResultsRequestStatus: 'FAILED', notification })
  }
}
