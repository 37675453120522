import axios from 'axios'
import endpoints from '../Config/ApiEndpoints'
import { processRequestErrorNotification } from '../Utils/Error'

export const showNotification = (store, notification) => {
  store.setState({ notification })
}

export const hideNotification = (store) => {
  const notification = { show: false }
  store.setState({ notification })
}

export const getUserNotifications = async (store) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  store.setState({ requestStatus: 'LOADING' })
  try {
    const response = await axios.get(endpoints.notifications.root, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
    const { notifications } = response.data._embedded

    store.setState({ requestStatus: 'SUCCESS', notifications })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ notifications: [], requestStatus: 'FAILED', notification })
  }
}

export const getNotificationCount = async (store) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  store.setState({ requestStatus: 'LOADING' })
  try {
    const response = await axios.get(endpoints.notifications.count, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
    const notificationCount = response.data

    store.setState({ requestStatus: 'SUCCESS', notificationCount })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ notificationCount: 0, requestStatus: 'FAILED', notification })
  }
}

export const readNotification = async (store, id) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  store.setState({ requestStatus: 'LOADING' })
  try {
    await axios.patch(endpoints.notifications.root + '/' + id, null, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })

    store.setState({ requestStatus: 'SUCCESS' })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ requestStatus: 'FAILED', notification })
  }
}
