import { useCookies } from 'react-cookie'
import useGlobal from '../../Store'
import React, { useEffect, useState } from 'react'
import { Select } from 'semantic-ui-react'

const isMobile = window.innerWidth < 1000

const DistrictSelect = (props) => {
  const [, setCookie] = useCookies()
  const [globalState, globalActions] = useGlobal()
  const [ districts, setDistricts ] = useState([])

  useEffect(() => {
    globalActions.locations.getDistricts(props.currentState)
  }, [props.currentState])

  useEffect(() => {
      setDistricts(globalState.districts || [])
  }, [globalState.districts])

  const districtOptions =
    typeof districts !== 'undefined'
      ? districts.map((district, key) => ({
        key,
        text: district.name,
        value: district.id
      }))
      : []

  return (
    <Select
      placeholder="Select a District"
      className="menu-select"
      options={districtOptions}
      value={globalState.currentDistrict || null}
      onChange={(e, data) =>
        globalActions.locations.changeDistrict(setCookie, data.value)
      }
      style={{ marginRight: 10, width: isMobile ? '80%' : '75%' }}
    />
  )
}

export default DistrictSelect
