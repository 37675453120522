import { useCookies } from 'react-cookie'
import useGlobal from '../../Store'
import React, { useEffect, useState } from 'react'
import { Select } from 'semantic-ui-react'

const isMobile = window.innerWidth < 1000

const SchoolSelect = () => {
  const [, setCookie] = useCookies()
  const [globalState, globalActions] = useGlobal()
  const [schools, setSchools] = useState([])

  useEffect(() => {
    globalActions.locations.getSchools()
  }, [])

  useEffect(() => {
    setSchools(globalState.schools)
    if (globalState.schools && globalState.schools.length === 1) {
      globalActions.locations.changeSchool(setCookie, globalState.schools[0].id)
    }
  }, [globalState.schools])

  const schoolOptions = schools.map((school, key) => ({
    key,
    text: school.name,
    value: school.id
  }))

  if (schoolOptions.length === 1) {
    return (
      <p style={{ color: 'rgba(0, 0, 0, 0.65)', display: 'inline-block', marginRight: 10 }}>{schoolOptions[0].text}</p>
    )
  } else {
    return (
      <Select
        placeholder="Select a School"
        className="menu-select"
        options={schoolOptions}
        value={globalState.currentSchool || null}
        onChange={(e, data) =>
          globalActions.locations.changeSchool(setCookie, data.value)
        }
        style={{ marginRight: 10, width: isMobile ? '80%' : '75%' }}
      />
    )
  }
}

export default SchoolSelect
