import { Accordion, Icon, Checkbox, Modal, Button, List } from 'semantic-ui-react'
import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import useCampaignsService from '../../../Services/Campaigns'
import useGlobal from '../../../Store'

const SendSurvey = (props) => {
  const [, globalActions] = useGlobal()
  const { open, survey, onClose, version } = props

  const { sendSurvey, getMySchools } = useCampaignsService()

  const [states, setStates] = useState([])
  const [districts, setDistricts] = useState([])
  const [schools, setSchools] = useState([])

  const [activeAccordion, setActiveAccordion] = useState('')
  const [checkedSchools, setCheckedSchools] = useState([])

  useEffect(() => {
    if (survey) {
      getMySchools(survey.schoolLevels.map(s => s.id))
        .then(response => {
          if (response.states.length) setStates(response.states)
          else if (response.districts.length) setDistricts(response.districts)
          else setSchools(response.schools)
        })
    }
  }, [survey])

  const _onClose = () => {
    setCheckedSchools([])
    onClose()
  }

  const _sendSurvey = () => {
    if(!checkedSchools.length){
      alert("Please select at least one school")
    }else {
      sendSurvey(survey, checkedSchools, [], version)
      globalActions.notifications.showNotification({
        show: true,
        header: 'Survey sent!',
        subheader: '',
        type: 'success'
      })
      _onClose()
    }
  }

  if (!survey) return null

  const districtIndeterminate = district => {
    return district.schools?.some(school => checkedSchools.includes(school.id)) && !district.schools?.every(school => checkedSchools.includes(school.id))
  }

  const districtAllSelected = district => {
    return district.schools?.every(school => checkedSchools.includes(school.id)) && district.schools?.length
  }

  const stateIndeterminate = state => {
    return state.schools?.some(school => checkedSchools.includes(school.id)) && !state.schools?.every(school => checkedSchools.includes(school.id))
  }

  const stateAllSelected = state => {
    return state.schools?.every(school => checkedSchools.includes(school.id)) && state.schools?.length
  }

  const checkSchools = (state, district) => {
    if (!state) {
      const allThere = district.schools?.every(school => checkedSchools.includes(school.id))
      const someThere = district.schools?.some(school => checkedSchools.includes(school.id))

      if (allThere) {
        setCheckedSchools(checkedSchools.filter(c => !district.schools?.map(s => s.id).includes(c)))
      } else if (someThere) {
        setCheckedSchools([...checkedSchools.filter(c => !district.schools?.map(s => s.id).includes(c)), ...district.schools?.map(s => s.id)])
      } else {
        setCheckedSchools([...checkedSchools, ...district.schools?.map(s => s.id)])
      }
    } else {
      const allThere = state.schools?.every(school => checkedSchools.includes(school.id))
      const someThere = state.schools?.some(school => checkedSchools.includes(school.id))

      if (allThere) {
        setCheckedSchools(checkedSchools.filter(c => !state.schools?.map(s => s.id).includes(c)))
      } else if (someThere) {
        setCheckedSchools([...checkedSchools.filter(c => !state.schools?.map(s => s.id).includes(c)), ...state.schools?.map(s => s.id)])
      } else {
        setCheckedSchools([...checkedSchools, ...state.schools?.map(s => s.id)])
      }
    }
  }

  const _selectAll = () => {
    let allSchools = []
    if (states.length) {
      states.forEach(s => {
        if (s?.districts)
          s.districts.forEach(d => {
            if (d.schools)
              d.schools.forEach(school => allSchools.push(school.id))
          })
      })
    } else if (districts.length) {
      districts.forEach(d => {
        if (d.schools)
          d.schools.forEach(school => allSchools.push(school.id))
      })
    } else {
      allSchools = schools.map(s => s.id)
    }

    if (allSchools.length === checkedSchools.length) {
      setCheckedSchools([])
    } else {
      setCheckedSchools(allSchools)
    }
  }

  const showSchools = schools => {
    return (
      <List style={{ marginLeft: 20, marginTop: 15, paddingTop: 0 }}>
        {schools.map((school, key2) => (
          <List.Item key={key2} style={{ marginBottom: 10 }}>
            <Checkbox label={school.name + (school.schoolLevel === 'HYBRID' ? ' - Hybrid' : '')}
                      checked={checkedSchools.includes(school.id)} onChange={() => {
              setCheckedSchools(checkedSchools.includes(school.id) ? checkedSchools.filter(c => c !== school.id) : [...checkedSchools, school.id])
            }}/>
          </List.Item>
        ))}
      </List>
    )
  }

  const showDistricts = districts => {
    return (
      <List style={{ marginLeft: 20, marginTop: 7, paddingTop: 0 }}>
        {districts.map((district, key1) => {
          if (!isEmpty(district.schools)) return (
            <List.Item key={key1}>
              <Accordion style={{ marginTop: 0 }}>
                <Accordion.Title
                  active={activeAccordion.includes(district.id)}
                  index={0}>
                  <Icon name="dropdown"
                        onClick={() => setActiveAccordion(activeAccordion.includes(district.id) ? activeAccordion.replace(district.id, '') : activeAccordion + district.id)}
                  />
                  <Checkbox label={district.name}
                            checked={districtAllSelected(district)}
                            indeterminate={districtIndeterminate(district)}
                            onChange={() => checkSchools(null, district)}
                  />
                </Accordion.Title>

                <Accordion.Content active={activeAccordion.includes(district.id)} style={{ paddingTop: 0 }}>
                  {!isEmpty(district.schools) ? (
                    <>
                      {showSchools(district.schools)}
                    </>
                  ) : (
                    <i style={{ marginLeft: 20, marginTop: 10 }}>No schools</i>
                  )}
                </Accordion.Content>
              </Accordion>
            </List.Item>
          )
        })}
      </List>
    )
  }

  const showStates = states => {
    return (
      <List>
        {states.map((state, key) => {
          if (!isEmpty(state.districts)) return (
            <List.Item key={key}>
              <Accordion style={{ marginTop: 0 }}>
                <Accordion.Title
                  active={activeAccordion.includes(state.id)}
                  index={0}
                >
                  <Icon name="dropdown"
                        onClick={() => setActiveAccordion(activeAccordion.includes(state.id) ? '' : state.id)}/>
                  <Checkbox label={state.name}
                            checked={stateAllSelected(state)}
                            indeterminate={stateIndeterminate(state)}
                            onChange={() => checkSchools(state, null)}/>
                </Accordion.Title>

                <Accordion.Content active={activeAccordion.includes(state.id)} style={{ paddingTop: 0 }}>
                  {!isEmpty(state.districts) ? (
                    <>
                      {showDistricts(state.districts)}
                    </>
                  ) : (
                    <i style={{ marginLeft: 20, marginTop: 10 }}>No districts</i>
                  )}
                </Accordion.Content>
              </Accordion>
            </List.Item>
          )
        })}
      </List>
    )
  }

  return (
    <Modal
      open={open}
      onClose={_onClose}
    >
      <Modal.Header>Send Survey</Modal.Header>
      <Modal.Content>
        <Button onClick={_selectAll}>Select All</Button>
        {states.length ?
          showStates(states)
          : districts.length ?
            showDistricts(districts)
            : showSchools(schools)
        }
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={_onClose}>
          Cancel
        </Button>
        <Button
          content="Send"
          labelPosition="right"
          icon="checkmark"
          onClick={_sendSurvey}
          positive
        />
      </Modal.Actions>
    </Modal>
  )
}

export default SendSurvey
