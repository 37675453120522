import React from 'react'
import {
  Tooltip, PieChart, Pie, Cell, ResponsiveContainer
} from 'recharts'
import styled from 'styled-components'
import { Segment, Header } from 'semantic-ui-react'
import { lifeExperiencesOptions } from '../Config/SelectOptions'
import { dashboard } from '../Theme/Styles'

const PieChartSurveyComponent = (props) => {
  const COLORS = ['#753CBE', '#0088FE', '#3CBEB9', '#F58127', '#BE3CA5']

  const data = props.columns.map(topic => {
    return {
      name: topic.name,
      value: topic.percent
    }
  })

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const RADIAN = Math.PI / 180
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN) - 10
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text x={x} y={y} fill="white" textAnchor="top">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    )
  }

  const columns = props.columns.map((value, index) => {
    return (
      <StyledProperty key={index}>
        <StyledText>{index + 1}. {lifeExperiencesOptions.find(option => value.name === option.value)?.text || value.name} </StyledText>
        <StyledCount>{value.percent}%</StyledCount>
      </StyledProperty>
    )
  })

  return (
    <React.Fragment>
      <Header as="h2" style={{ ...dashboard.phHeader, marginBottom: 0, paddingBottom: 0 }}>{props.title}</Header>

      <StyledCardContent>
        <StyledResponsiveContainer width='25%' height={300}>
          <PieChart style={{ marginTop: 20 }}>
            <Pie label={renderCustomizedLabel} data={data} cx='50%' cy='65%' labelLine={false} outerRadius='100%'
                 fill='#753CBE' dataKey='value'>
              {data.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} key={index}/>)}
            </Pie>
            <Tooltip/>
          </PieChart>
        </StyledResponsiveContainer>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {columns}
        </div>
      </StyledCardContent>
    </React.Fragment>
  )
}

const StyledCardContent = styled(Segment)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  align-items: center;
`

const StyledResponsiveContainer = styled(ResponsiveContainer)`
  .recharts-surface {
      margin-top: -4.5rem !important;
  }
`

const StyledProperty = styled.div`
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  width: 300px;
  border-bottom: 2px solid lightgray !important;
  height: 22px !important;
`

const StyledCount = styled.p`
color: #753CBE !important;
font-family: Roboto !important;
`

const StyledText = styled.p`
  width: 10rem !important;
`

export default PieChartSurveyComponent
