import React from 'react'
import { Button, Card, Grid, Header, Icon, Image, List } from 'semantic-ui-react'
import {
  academicStrengthsOptions,
  genderOptions,
  hobbiesOptions,
  lifeExperiencesOptions,
  raceOptions
} from '../../Config/SelectOptions'
import useStringOperations from '../../Utils/StringOperations'
import * as _ from 'lodash'
import styled from 'styled-components'
import { StyledTransparentButton } from '../../Theme/Components'

const ProfileCard = ({ user, close }) => {
  const stringOperations = useStringOperations()

  return (
    <Card fluid>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        paddingTop: '1rem',
        paddingBottom: '1rem',
        background: 'rgba(0,0,0,0.05)'
      }}>
        {(user.avatar) ? (
          <Image src={user.avatar} avatar centered ui={false} style={{ marginLeft: 15 }}/>
        ) : (
          <Icon name="user" style={{ marginLeft: 15, fontSize: 30, marginTop: 15 }}/>
        )}

        <Card.Content style={{ marginLeft: 15 }}>
          <Card.Header as='h2' style={{ margin: 0 }}>
            {user.firstName + ' ' + user.lastName}
          </Card.Header>
          <Card.Description>
            {user.roles[0].name}
          </Card.Description>
        </Card.Content>
      </div>

      <StyledTransparentButton icon circular size='mini' onClick={close}
                               style={{ position: 'absolute', top: 10, right: 20, width: 25 }}>
        <Icon name='close' style={{ fontSize: 20, color: 'black' }}/>
      </StyledTransparentButton>

      <Card.Content extra style={{ paddingTop: '1rem' }}>
        <Grid columns={2} divided>
          <Grid.Row>
            <Grid.Column>
              <Header as='h3'>
                Phone
                <Header.Subheader>
                  {user.phone}
                </Header.Subheader>
              </Header>
            </Grid.Column>
            <Grid.Column>
              <Header as='h3'>
                Email
                <Header.Subheader>
                  {user.email}
                </Header.Subheader>
              </Header>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <List style={{ padding: 15, paddingTop: 0 }}>
              <List.Item>
                <List.Header>Username</List.Header>{user.username}
              </List.Item>
              <List.Item>
                <List.Header>Gender</List.Header>{stringOperations.translate(genderOptions, user.gender)}
              </List.Item>
              <List.Item>
                <List.Header>Race</List.Header>{stringOperations.translate(raceOptions, user.race)}
              </List.Item>
              <List.Item>
                <List.Header>Life
                  Experiences</List.Header>{stringOperations.translate(lifeExperiencesOptions, user.experiences) || '-'}
              </List.Item>
              <List.Item>
                <List.Header>Hobbies/Interests</List.Header>{stringOperations.translate(hobbiesOptions, user.interests) || '-'}
              </List.Item>
              <List.Item>
                <List.Header>Academic
                  Strengths</List.Header>{stringOperations.translate(academicStrengthsOptions, user.academic) || '-'}
              </List.Item>
            </List>
          </Grid.Row>
        </Grid>
      </Card.Content>

      {user.roles[0].name !== 'Super User' && (
        <div style={{ background: 'rgba(0, 0, 0, 0.05', padding: 15 }}>
          <StyledSchoolsHeader as='h2'>Schools</StyledSchoolsHeader>
          {['Super User', 'State Level', 'District Level'].includes(user.roles[0].name) ? (
            <>
              {user.roles[0].name === 'State Level' ? (
                <Card.Header as='h4' style={{ margin: 0 }}>{user.state.name}</Card.Header>
              ) : (
                <Card.Header as='h4' style={{ margin: 0 }}>{user.district.name}</Card.Header>
              )}
            </>
          ) : (
            <>
              {_.map(user.schools, school => (
                <Card.Content extra style={{ marginBottom: 10 }}>
                  <Card.Header as='h4' style={{ margin: 0 }}>{school.name}</Card.Header>
                  <Card.Description>{user.district.name}</Card.Description>
                  <Card.Description>{user.state.name}</Card.Description>
                </Card.Content>
              ))}
            </>
          )}
        </div>
      )}
    </Card>
  )
}

const StyledSchoolsHeader = styled(Header)`
  padding-left: 0px !important;
`

export default ProfileCard
