const useUrlBuilder = (currentLocation) => {
  const pages = ['ongoing-overview', 'atrisk-referrals-report', 'group-activities-report', 'as-needed-support-report', 'schools', 'users', 'assists']
  const section = currentLocation.split('/')[1]

  const makeUrl = (url) => {
    return pages.includes(section) ? `/${section}${url}` : `${url}`
  }

  return [makeUrl]
}

export default useUrlBuilder
