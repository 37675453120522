import React, { useEffect, useState } from 'react'
import { Header, Pagination, Responsive, Table } from 'semantic-ui-react'
import { dashboard } from '../../Theme/Styles'
import _ from 'lodash'
import useGlobal from '../../Store'
import { StyledTable } from '../../Theme/Components'

const UsageReportTable = (props) => {
  const [globalState, globalActions] = useGlobal()
  const { currentSchool, currentDistrict, currentState } = globalState
  const [users, setUsers] = useState(globalState.users)

  const getUsers = () => {
    setTimeout(() => {
      globalActions.dashboards.getUsageReport(0, 100000)
    }, 500)
  }

  useEffect(() => {
    setUsers(globalState.users)
  }, [globalState.users])

  useEffect(getUsers, [])

  useEffect(getUsers, [props.location, currentSchool, currentDistrict, currentState])

  return (
    <React.Fragment>
      <Header as='h2' style={dashboard.phHeader}>Usage Report</Header>
      <Table compact striped size='small'>
        <Table.Header className="stickyTh">
          <Responsive as={Table.Row} minWidth={1000}>
            <Table.HeaderCell textAlign='center'>
            Peer Helpers
            </Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>
              #Assigned Students
            </Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>
              #As-Needed Logs
            </Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>
              #Group Activities
            </Table.HeaderCell>
          </Responsive>
        </Table.Header>

        <Table.Body>
          {_.map(users, ({ name, students, asNeededLogs, groupLogs }, index) => (
            <Table.Row key={index}>
              <Table.Cell textAlign='center'>
                <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                  Peer Helper:
                </Responsive>
                {name}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                  #Assigned Students
                </Responsive>
                {students}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                  #As-Needed Logs
                </Responsive>
                {asNeededLogs}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                  #Group Activities
                </Responsive>
                {groupLogs}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </React.Fragment>
  )
}

export default UsageReportTable
