import React, { useEffect, useState } from 'react'
import { Dropdown, Item, Responsive, Table } from 'semantic-ui-react'
import _ from 'lodash'
import { NavLink } from 'react-router-dom'
import useGlobal from '../../Store'
import {
  addressingTypeOptions,
  helpTypeOptions,
  numberOfStudentsOptions,
  typeOfActivityOptions
} from '../../Config/SelectOptions'
import useStringOperations from '../../Utils/StringOperations'
import { NoPrint } from 'react-easy-print'
import { StyledTable } from '../../Theme/Components'

const GroupActivitiesTable = () => {
  const stringOperations = useStringOperations()
  const [globalState, globalActions] = useGlobal()
  const { currentState, currentDistrict, currentSchool } = globalState
  const [groupActivityLogs, setGroupActivityLogs] = useState(globalState.groupActivityLogs)
  const [selectedType, setSelectedType] = useState({ text: 'All Types', value: '' })

  useEffect(() => {
    setGroupActivityLogs(globalState.groupActivityLogs)
  }, [globalState.groupActivityLogs])

  const getLogs = () => {
    setTimeout(() => {
      globalActions.groupActivityLogs.getGroupActivityLogs(0, 100000, selectedType.value)
    }, 500)
  }

  useEffect(getLogs, [selectedType, currentState, currentDistrict, currentSchool])

  const onChange = (e, field) => setSelectedType({ text: field.text, value: field.value })

  return (
    <React.Fragment>
      <Table compact striped size='small'>
        <Table.Header className="stickyTh">
          <Responsive as={Table.Row} minWidth={1000}>
            <Table.HeaderCell>
              Title
            </Table.HeaderCell>
            <Table.HeaderCell>
              Date
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Dropdown text={<p style={{ fontSize: 12.6, color: 'black' }}>{selectedType.text}</p>}>
                <Dropdown.Menu>
                  <Dropdown.Item text='All Types' value='' onClick={onChange}/>
                  <Dropdown.Divider/>
                  <Dropdown.Item text='Prevention and Awareness' value='PREVENTION' onClick={onChange}/>
                  <Dropdown.Item text='Support' value='SUPPORT' onClick={onChange}/>
                </Dropdown.Menu>
              </Dropdown>
            </Table.HeaderCell>
            <Table.HeaderCell>
              Logged By
            </Table.HeaderCell>
            <Table.HeaderCell>
              # Peer Helpers
            </Table.HeaderCell>
            <Table.HeaderCell>
              Delivery
            </Table.HeaderCell>
            <Table.HeaderCell>
            </Table.HeaderCell>
          </Responsive>
        </Table.Header>

        <Table.Body>
          {_.map(groupActivityLogs, ({ id, title, date, type, creator, peerHelpers, help }) => (
            <Table.Row key={id}>
              <Table.Cell>
                <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                  Title
                </Responsive>
                {title}
              </Table.Cell>
              <Table.Cell>
                <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                  Date
                </Responsive>
                {new Date(date.split(' ').join('T')).toLocaleDateString('en', {
                  year: '2-digit',
                  month: 'numeric',
                  day: 'numeric'
                })}
              </Table.Cell>
              <Table.Cell>
                <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                  Log Type
                </Responsive>
                {stringOperations.translate(typeOfActivityOptions, type)}
              </Table.Cell>
              <Table.Cell>
                <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                  Logged By
                </Responsive>
                {creator ? creator.firstName + ' ' + creator.lastName : ''}
              </Table.Cell>
              <Table.Cell>
                <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                  # Peer Helpers
                </Responsive>
                {peerHelpers.length}
              </Table.Cell>
              <Table.Cell>
                <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                  Delivery
                </Responsive>
                {stringOperations.translate(addressingTypeOptions, help)}
              </Table.Cell>
              <Table.Cell>
                <NoPrint force>
                  <Item as={NavLink} to={`/group-activities-report/group-logs/${id}/view`}
                        style={{ color: '#2076BC' }}> VIEW LOG </Item>
                </NoPrint>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </React.Fragment>
  )
}

export default GroupActivitiesTable
