import React, { useEffect, useState } from 'react'
import { Container, Segment, Divider, Item } from 'semantic-ui-react'
import useGlobal from '../../Store'
import {
  academicStrengthsOptions,
  genderOptions,
  hobbiesOptions,
  lifeExperiencesOptions,
  raceOptions,
  gradeOptions
} from '../../Config/SelectOptions'
import { NavLink } from 'react-router-dom'
import useStringOperations from '../../Utils/StringOperations'

import useUrlBuilder from '../../Utils/Links'
import {
  RightMenuBar,
  RightMenuBarButton,
  RightMenuBarButtonIcon,
  RightMenuBarOptions,
  StyledLogQuestionAnswer,
  StyledLogQuestionHeader
} from '../../Theme/Components'

const defaultStudent = {
  firstName: '',
  lastInitial: '',
  assignedTo: '',
  gender: '',
  races: [],
  grade: '',
  academics: [],
  interests: [],
  experiences: [],
  endDate: '',
  schoolId: ''
}

const StudentView = (props) => {
  const studentId = props.match.params.id || '0'
  const [globalState, globalActions] = useGlobal()
  const { loggedInUser } = globalState
  const [student, setStudent] = useState(defaultStudent)
  const [makeUrl] = useUrlBuilder(props.match.url)
  const stringOperations = useStringOperations()

  useEffect(() => {
    if (studentId !== '0') {
      globalActions.students.getStudent(studentId)
    } else setStudent(defaultStudent)
  }, [studentId])

  useEffect(() => {
    setStudent({ ...globalState.editedStudent, assignedTo: globalState.editedStudent.assignedTo.firstName + ' ' + globalState.editedStudent.assignedTo.lastName })
  }, [globalState.editedStudent])

  const academicStrengthsOrNeedsText = ['Coordinator', 'Peer Helper'].includes(loggedInUser.role) ? 'Academic Needs' : 'Academic Needs'

  return (
    <Container>
      <Segment>
        <RightMenuBar>
          <RightMenuBarOptions>
            <RightMenuBarButton as={NavLink} to={makeUrl('/')}>
              <RightMenuBarButtonIcon name='close' style={{ fontSize: 20 }} />
            </RightMenuBarButton>
          </RightMenuBarOptions>
        </RightMenuBar>

        <Divider />

        <Item.Group>
          <Item>
            <Item.Content>
              <StyledLogQuestionHeader>Student Name</StyledLogQuestionHeader>
              <StyledLogQuestionAnswer>{student.firstName + ' ' + student.lastInitial}</StyledLogQuestionAnswer>
            </Item.Content>
          </Item>

          <Item>
            <Item.Content>
              <StyledLogQuestionHeader>Assigned to</StyledLogQuestionHeader>
              <StyledLogQuestionAnswer>{student.assignedTo}</StyledLogQuestionAnswer>
            </Item.Content>
          </Item>

          <Item>
            <Item.Content>
              <StyledLogQuestionHeader>Gender</StyledLogQuestionHeader>
              <StyledLogQuestionAnswer>{stringOperations.translate(genderOptions, student.gender)}</StyledLogQuestionAnswer>
            </Item.Content>
          </Item>

          <Item>
            <Item.Content>
              <StyledLogQuestionHeader>Race</StyledLogQuestionHeader>
              <StyledLogQuestionAnswer>{student.races.map(race => stringOperations.translate(raceOptions, race)).join(', ')}</StyledLogQuestionAnswer>
            </Item.Content>
          </Item>

          <Item>
            <Item.Content>
              <StyledLogQuestionHeader>Grade</StyledLogQuestionHeader>
              <StyledLogQuestionAnswer>{stringOperations.translate(gradeOptions, student.grade)}</StyledLogQuestionAnswer>
            </Item.Content>
          </Item>

          <Item>
            <Item.Content>
              <StyledLogQuestionHeader>{academicStrengthsOrNeedsText}</StyledLogQuestionHeader>
              <StyledLogQuestionAnswer>{student.academics.map(academic => stringOperations.translate(academicStrengthsOptions, academic)).join(', ')}</StyledLogQuestionAnswer>
            </Item.Content>
          </Item>

          <Item>
            <Item.Content>
              <StyledLogQuestionHeader>Hobbies/ Interests</StyledLogQuestionHeader>
              <StyledLogQuestionAnswer>{student.interests.map(interest => stringOperations.translate(hobbiesOptions, interest)).join(', ')}</StyledLogQuestionAnswer>

            </Item.Content>
          </Item>

          <Item>
            <Item.Content>
              <StyledLogQuestionHeader>Life Experiences</StyledLogQuestionHeader>
              <StyledLogQuestionAnswer>{student.experiences.map(experience => stringOperations.translate(lifeExperiencesOptions, experience)).join(', ')}</StyledLogQuestionAnswer>

            </Item.Content>
          </Item>

          {/*<Item>*/}
          {/*  <Item.Content>*/}
          {/*    <StyledLogQuestionHeader>Expected Support Competion Date</StyledLogQuestionHeader>*/}
          {/*    <StyledLogQuestionAnswer>{student.endDate}</StyledLogQuestionAnswer>*/}
          {/*  </Item.Content>*/}
          {/*</Item>*/}
        </Item.Group>
      </Segment>
    </Container>
  )
}

export default StudentView
