import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Button, Divider, Form, Header, Modal, Segment } from 'semantic-ui-react'
import useGlobal from '../../Store'
import useValidation from '../../Utils/Validations'
import {
  RightMenuBar,
  RightMenuBarButton,
  RightMenuBarButtonIcon,
  RightMenuBarOptions,
  StyledTransparentButton
} from '../../Theme/Components'
import { gradeOptions } from '../../Config/SelectOptions'
import { dashboard } from '../../Theme/Styles'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import { editedSchool as defaultEditedSchool } from '../../Config/DefaultState'

const AddForm = (props) => {
  const [globalState, globalActions] = useGlobal()

  const schoolId = props.match.params.id || '0'
  const isFilter = !props.match.params.id && !props.match.params.action

  const [isEdit, setIsEdit] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [gradeLevel, setGradeLevel] = useState(Object.assign([], gradeOptions))
  const { districts, states, schoolLevel } = globalState
  const [school, setSchool] = useState({ ...defaultEditedSchool })
  const requiredFields = ['stateId', 'districtId', 'name', 'level']
  const [validForm, validationErrors, onFieldChangeValidation] = useValidation(requiredFields, school)

  const districtOptions = districts ? districts.map((district, key) => ({
    key,
    text: district.name,
    value: district.id
  })) : []

  const stateOptions = states.map((state, key) => ({ key, text: state.name, value: state.id }))

  const levelOptions = typeof schoolLevel !== 'undefined' ? schoolLevel.map((i, key) => ({
    key,
    text: i.name,
    value: i.id
  })) : []

  useEffect(() => {
    setSchool({ ...school, stateId: globalState.currentState, districtId: globalState.currentDistrict })
    globalActions.locations.getSchoolListing(globalState.currentState, globalState.currentDistrict)
  }, [globalState.currentState, globalState.currentDistrict])

  useEffect(() => {
    globalActions.locations.getState()
    globalActions.locations.getSchoolLevel()

    setGradeLevel(Object.assign([], gradeOptions))

    if (schoolId === '0') {
      setIsEdit(true)
      setSchool({
        id: '',
        name: '',
        stateId: globalState.currentState,
        districtId: globalState.currentDistrict,
        level: ''
      })
    } else {
      setIsEdit(false)
      globalActions.locations.getSchool(schoolId)
    }
  }, [schoolId])

  useEffect(() => {
    if (school.stateId) {
      globalActions.locations.getDistricts(school.stateId)
    }
  }, [school.stateId])

  useEffect(() => {
    if (school.districtId) {
      globalActions.locations.getDistrictsSchools(school.districtId)
    }
  }, [school.districtId])

  useEffect(() => {
    if (schoolId !== '0')
      setSchool({ ...globalState.editedSchool, level: globalState.editedSchool.schoollevel_id })

    if (globalState.editedSchool) {
      const schoolGrades = globalState.editedSchool.grades ? globalState.editedSchool.grades.split(',') : []
      setGradeLevel(gradeLevel.map(level => ({ ...level, ischecked: schoolGrades.indexOf(level.key) > -1 })))
    }
  }, [globalState.editedSchool])

  const updateSchoolState = async (e) => {
    const { name, value } = typeof e.target !== 'undefined' ? e.target : e
    await setSchool({ ...school, [name]: value })
    onFieldChangeValidation(name, value)

    if (name === 'stateId' && isFilter) {
      await globalActions.locations.getDistrictList(value)
      await globalActions.locations.getSchoolListing(value, school.districtId ? school.districtId : '')
    } else if (name === 'districtId' && isFilter) {
      await globalActions.locations.getSchoolListing(school.stateId ? school.stateId : '', value)
    }
  }

  const handleCloseSchoolModal = async () => {
    await globalActions.locations.handleSelectSchool(null, 'deselect')
    props.history.push('/heirarchy-management/schools')
  }

  const handleGradeLevel = (idx) => {
    let grade = [...gradeLevel]
    grade[idx].ischecked = !grade[idx].ischecked
    setGradeLevel(grade)
  }

  const handleSchoolOperation = async (from = null) => {
    let response
    if (from === 'delete') {
      response = await globalActions.locations.handleSchoolAPI(
        { ...school, grade: gradeLevel },
        'delete'
      )
      setModalOpen(false)
    } else if (validForm && school && school.name.trim()) {
      if (schoolId === '0') {
        setButtonDisabled(true)
        response = await globalActions.locations.handleSchoolAPI(
          { ...school, grade: gradeLevel },
          'add'
        )
        setButtonDisabled(false)
      } else {
        response = await globalActions.locations.handleSchoolAPI(
          { ...school, grade: gradeLevel },
          'edit'
        )
      }
    } else {
      globalActions.notifications.showNotification({
        type: 'warning',
        header: 'Form validation error',
        subheader: 'Please complete all required fields!'
      })
    }

    if (from === 'delete' || validForm) {
      response && props.history.push('/heirarchy-management/schools')

      setTimeout(() => {
        globalActions.locations.getSuperUserSchools()
      }, 500)

      setSchool({ ...school, level: '', name: '', stateId: '', districtId: '' })
      setGradeLevel(gradeLevel.map(level => ({ ...level, ischecked: false })))
    }
  }

  return (
    <Segment>
      <RightMenuBar>
        <Header as="h3" style={dashboard.myProgramHeader}>
          {!isFilter && schoolId !== '0'
            ? 'Edit'
            : !isFilter && schoolId === '0'
              ? 'Add New'
              : ''}
          &nbsp;School
        </Header>
        <RightMenuBarOptions>
          {schoolId !== '0' && (
            <>
              <RightMenuBarButton onClick={() => setIsEdit(true)}>
                <RightMenuBarButtonIcon
                  name="pencil"
                  style={{ fontSize: 15 }}
                />
              </RightMenuBarButton>

              <RightMenuBarButton onClick={() => setModalOpen(true)}>
                <RightMenuBarButtonIcon name="trash" style={{ fontSize: 15 }}/>
              </RightMenuBarButton>
            </>
          )}

          {!isFilter && (
            <RightMenuBarButton onClick={() => handleCloseSchoolModal()}>
              <RightMenuBarButtonIcon
                name="close"
                style={{ fontSize: schoolId !== '0' ? 15 : 20 }}
              />
            </RightMenuBarButton>
          )}
        </RightMenuBarOptions>
      </RightMenuBar>

      <Divider/>
      <Form>
        <Form.Group className="custom-form-group">
          <label className="small-label">State</label>
          <Form.Select
            name="stateId"
            value={school.stateId}
            onChange={(e, data) => updateSchoolState(data)}
            error={validationErrors.stateId.error}
            placeholder="Select a State"
            required
            fluid
            options={stateOptions}
            disabled={schoolId !== '0' && !isEdit}
          />
        </Form.Group>

        <Form.Group className="custom-form-group">
          <label className="small-label">District</label>
          <Form.Select
            name="districtId"
            value={school.districtId}
            onChange={(e, data) => updateSchoolState(data)}
            error={validationErrors.districtId.error}
            placeholder="Select a District"
            required
            fluid
            options={districtOptions}
            disabled={schoolId !== '0' && !isEdit}
          />
        </Form.Group>

        {isFilter && (
          <div style={{ textAlign: 'right' }}>
            <Button onClick={() => {
              setSchool({ ...school, stateId: '', districtId: '' })
              globalActions.locations.getSchoolListing('', '')
            }}>Clear</Button>
          </div>
        )}

        {!isFilter && (
          <>
            <Form.Group className="custom-form-group">
              <label className="small-label">Name</label>
              <Form.Input
                name="name"
                value={school.name}
                onChange={(e, data) => updateSchoolState(data)}
                required
                fluid
                error={validationErrors.name.error}
                placeholder="Enter school name"
                disabled={schoolId !== '0' && !isEdit}
              />
            </Form.Group>

            <Form.Group className="custom-form-group">
              <label className="small-label">Level</label>
              <Form.Select
                name="level"
                value={school.level}
                onChange={(e, data) => updateSchoolState(data)}
                error={validationErrors.level.error}
                placeholder="Select a Level"
                required
                fluid
                options={levelOptions}
                disabled={schoolId !== '0' && !isEdit}
              />
            </Form.Group>
            <Form.Group
              className="custom-form-group"
              style={{ flex: 1, flexDirection: 'row' }}
            >
              <FormLabel component="legend">Grade Levels</FormLabel>

              {gradeLevel.length > 0 && (
                gradeLevel.map((i, idx) => (
                    <FormControlLabel
                      key={i.key}
                      value={i.value}
                      control={
                        <Checkbox
                          color="secondary"
                          checked={i.ischecked}
                          onClick={() => handleGradeLevel(idx)}
                        />
                      }
                      label={i.text}
                      labelPlacement="start"
                      disabled={schoolId !== '0' && !isEdit}
                    />
                  )
                ))}
            </Form.Group>
            <div style={{ textAlign: 'right' }}>
              <Button.Group>
                <StyledTransparentButton
                  onClick={() => handleSchoolOperation()}
                  disabled={(schoolId !== '0' && !isEdit) || buttonDisabled}
                >
                  {schoolId !== '0' ? 'Edit' : 'Add'} School
                </StyledTransparentButton>
              </Button.Group>
            </div>
          </>
        )}
      </Form>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)} closeIcon>
        <Modal.Header>Delete School</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete this School ?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setModalOpen(false)}>
            No
          </Button>
          <Button
            positive
            icon="checkmark"
            labelPosition="right"
            content="Yes"
            onClick={() => handleSchoolOperation('delete')}
          />
        </Modal.Actions>
      </Modal>
    </Segment>
  )
}

export default AddForm
