import { useCookies } from 'react-cookie'
import useGlobal from '../../Store'
import React, { useEffect } from 'react'
import { Select } from 'semantic-ui-react'

const isMobile = window.innerWidth < 1000

const StateSelect = () => {
  const [, setCookie] = useCookies()
  const [globalState, globalActions] = useGlobal()
  const { states } = globalState

  useEffect(() => {
    globalActions.locations.getState()
  }, [])

  const stateOptions = states.map((state, key) => ({
    key,
    text: state.name,
    value: state.id
  }))

  return (
    <Select
      placeholder="Select a State"
      className="menu-select"
      options={stateOptions}
      value={globalState.currentState || null}
      onChange={(e, data) =>
        globalActions.locations.changeState(setCookie, data.value)
      }
      style={{ marginRight: 10, width: isMobile ? '80%' : '75%' }}
    />
  )
}

export default StateSelect
