import React, { useEffect, useState } from 'react'
import { Button, Divider, Form, Header, Segment } from 'semantic-ui-react'
import { dashboard } from '../../Theme/Styles'
import _ from 'lodash'
import useGlobal from '../../Store'

const UserSearch = ({ filters, setFilters, resetFilters, searchUser, loggedInUser, roles, userManagementStates, userManagementDistricts, userManagementSchools }) => {
  const [, globalActions] = useGlobal()

  const [stateOptions, setStateOptions] = useState([])
  const [districtOptions, setDistrictOptions] = useState([])
  const [schoolOptions, setSchoolOptions] = useState([])
  const [roleOptions, setRoleOptions] = useState([])

  useEffect(() => {
    const sto = userManagementStates ? userManagementStates.map((state, key) => ({
      key,
      text: state.name,
      value: state.id
    })) : []

    setStateOptions([{ key: 'all', text: 'All', value: '' }, ...sto])

    const dto = userManagementDistricts ? userManagementDistricts.map((district, key) => ({
      key,
      text: district.name,
      value: district.id
    })) : []
    setDistrictOptions([{ key: 'all', text: 'All', value: '' }, ...dto])

    const scto = userManagementSchools ? userManagementSchools.map((school, key) => ({
      key,
      text: school.name,
      value: school.id
    })) : []
    setSchoolOptions([{ key: 'all', text: 'All', value: '' }, ...scto])

    let rolesOption
    switch (loggedInUser.role) {
      case 'Coordinator':
        rolesOption = roles.filter((role) => role.text === 'Peer Helper')
        break
      case 'School Level':
        rolesOption = roles.filter((role) => ['Peer Helper', 'Coordinator'].includes(role.text))
        break
      case 'District Level':
        rolesOption = roles.filter((role) => ['Peer Helper', 'Coordinator', 'School Level'].includes(role.text))
        break
      case 'State Level':
        rolesOption = roles.filter((role) => ['Peer Helper', 'Coordinator', 'School Level', 'District Level'].includes(role.text))
        break
      case 'Super User':
        rolesOption = roles.filter((role) => ['Peer Helper', 'Coordinator', 'School Level', 'District Level', 'State Level'].includes(role.text))
    }
    setRoleOptions(rolesOption)
  }, [roles, userManagementStates, userManagementDistricts, userManagementSchools])

  return (
    <Segment>
      <Header as='h3' style={dashboard.myProgramHeader}>User Search</Header>

      <Divider/>

      <Form size='large'>
        {(loggedInUser.role === 'Super User') && (
          <Form.Group className='custom-form-group'>
            <label className='small-label'>State</label>
            <Form.Select placeholder='Select a State' options={stateOptions} value={filters.stateId}
                         onChange={(e, data) => {
                           setFilters({ ...filters, stateId: data.value })
                           setDistrictOptions([])
                           setSchoolOptions([])
                           globalActions.locations.getDistricts(data.value, true)
                         }}/>
          </Form.Group>
        )}

        {(loggedInUser.role !== 'Coordinator' && loggedInUser.role !== 'School Level' && loggedInUser.role !== 'District Level' && districtOptions.length > 0) && (
          <Form.Group className='custom-form-group'>
            <label className='small-label'>District</label>
            <Form.Select placeholder='Select a District' options={districtOptions} value={filters.districtId}
                         onChange={(e, data) => {
                           setFilters({ ...filters, districtId: data.value })
                           setSchoolOptions([])
                           globalActions.locations.getDistrictsSchools(data.value, true)
                         }}/>
          </Form.Group>
        )}

        {(((loggedInUser.role !== 'Coordinator') || (loggedInUser.role === 'Coordinator' && schoolOptions.length > 1)) && schoolOptions.length > 0) && (
          <Form.Group className='custom-form-group'>
            <label className='small-label'>School</label>
            <Form.Select placeholder='Select a School' options={schoolOptions} value={filters.schoolId}
                         onChange={(e, data) => setFilters({ ...filters, schoolId: data.value })}/>
          </Form.Group>
        )}

        {(loggedInUser.role !== 'Peer Helper') && (
          <Form.Group className='custom-form-group'>
            <label className='small-label'>Role</label>
            <Form.Select placeholder='Select a Role' options={roleOptions}
                         value={filters.role !== '' ? _.find(roles, role => filters.role === role.text).value : ''}
                         onChange={(e, data) => setFilters({
                           ...filters,
                           role: _.find(roles, role => data.value === role.value).text
                         })}/>
          </Form.Group>
        )}

        <Form.Group className='custom-form-group no-margin-bottom'>
          <label className='small-label'>Enabled/Disabled</label>
        </Form.Group>

        <Form.Group inline widths='equal'>
          <Form.Radio label='Enabled' name='enabledOrDisabled' checked={filters.enabled === 'true'}
                      onChange={() => setFilters({ ...filters, enabled: 'true' })}/>
          <Form.Radio label='Disabled' name='enabledOrDisabled' checked={filters.enabled === 'false'}
                      onChange={() => setFilters({ ...filters, enabled: 'false' })}/>
          <Form.Radio label='Both' name='enabledOrDisabled' checked={filters.enabled === undefined}
                      onChange={() => setFilters({ ...filters, enabled: undefined })}/>
        </Form.Group>
        <Form.Group className='custom-form-group'>
          <label className='small-label'>Search by name</label>
          <Form.Input placeholder='Enter a name' value={filters.name}
                      onChange={e => setFilters({ ...filters, name: e.target.value })}
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          e.preventDefault()
                          searchUser()
                        }
                      }}
          />
        </Form.Group>
        <div style={{ textAlign: 'right', marginTop: 10 }}>
          <Button.Group>
            <Button onClick={resetFilters}>Clear All</Button>
            <Button.Or/>
            <Button color='blue' onClick={searchUser}>Search</Button>
          </Button.Group>
        </div>
      </Form>
    </Segment>
  )
}

export default UserSearch
