import React, { useEffect, useLayoutEffect, useState } from 'react'
import axios from 'axios'
import queryString from 'query-string'

const ProgramsPage = () => {
  const [height, setHeight] = useState(window.innerHeight)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    axios.post(window.location.host.indexOf('peerhelpersportal.com') > -1 ? 'https://dropbox.peerhelpersportal.com/login' : 'https://dropbox.peerhelper.live/login', {
      user: 'sjobs',
      password: 'Aa11223300'
    }, { withCredentials: true })
      .then(() => setLoaded(true))
      .catch(() => setLoaded(true))

    document.getElementsByClassName('container')[0].style.marginTop = '65px'
    document.getElementsByTagName('body')[0].style.overflow = 'hidden'

    return () => {
      document.getElementsByTagName('body')[0].style.overflow = 'scroll'
      document.getElementsByTagName('body')[0].style.marginTop = '5.5rem'
    }
  }, [])

  useLayoutEffect(() => {
    function updateSize () {
      setHeight(window.innerHeight - 65)
    }

    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  if (loaded)
    return (
      <iframe
        src={window.location.host.indexOf('peerhelpersportal.com') > -1 ? 'https://dropbox.peerhelpersportal.com' : 'https://dropbox.peerhelper.live'}
        style={{ width: '100%', height }}/>
    )
  else
    return <></>
}

export default ProgramsPage
