import axios from 'axios'
import endpoints from '../Config/ApiEndpoints'
import { processRequestErrorNotification } from '../Utils/Error'
import { demographicData as defaultDemographicData } from '../Config/DefaultState'
import { getResourceFromArchive } from './Archive'

export const getDemographicData = async (store) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  const { currentSchool } = store.state
  const url = endpoints.users.students + `/search?search=${currentSchool !== '' ? `schoolId:${currentSchool}` : ''}`

  store.setState({ requestStatus: 'LOADING' })
  try {
    const response = await getResourceFromArchive(store, url, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
    const demographicData = response.data

    store.setState({ requestStatus: 'SUCCESS', demographicData })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ demographicData: defaultDemographicData, requestStatus: 'FAILED', notification })
  }
}
