import { Button, Divider, Header, Item, Modal, Segment } from 'semantic-ui-react'
import {
  RightMenuBar,
  RightMenuBarButton,
  RightMenuBarButtonIcon,
  RightMenuBarOptions, StyledLogQuestionAnswer, StyledLogQuestionHeader
} from '../../../../Theme/Components'
import { dashboard } from '../../../../Theme/Styles'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import {
  assistHelpOptions,
  genderOptions,
  gradeOptions,
  initiationOptions,
  lifeExperiencesOptions,
  raceOptions
} from '../../../../Config/SelectOptions'
import useGlobal from '../../../../Store'
import useStringOperations from '../../../../Utils/StringOperations'

const AssistView = (props) => {
  const assistId = props.match.params.id || '0'

  const [globalState, globalActions] = useGlobal()
  const [assist, setAssist] = useState({ ...globalState.editedAssist })
  const stringOperations = useStringOperations()
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if (assistId !== '0') {
      globalActions.assists.getAssist(assistId)
    }
  }, [assistId])

  useEffect(() => {
    setAssist({ ...globalState.editedAssist })
  }, [globalState.editedAssist])

  const deleteAssist = () => {
    globalActions.assists.deleteAssist(assistId).then(props.history.push('/assists'))
    globalActions.assists.getAssists(0)
  }

  return (
    <Segment>
      <RightMenuBar>
        <Header as='h3' style={dashboard.myProgramHeader}>Assist Log</Header>
        <RightMenuBarOptions>
          <React.Fragment>
            <RightMenuBarButton as={NavLink} to={`/assists/edit/${assistId}`}>
              <RightMenuBarButtonIcon name='edit'/>
            </RightMenuBarButton>

            <RightMenuBarButton onClick={() => setModalOpen(true)}>
              <RightMenuBarButtonIcon name='trash'/>
            </RightMenuBarButton>

            <Modal open={modalOpen} onClose={() => setModalOpen(false)} closeIcon>
              <Modal.Header>Delete Your Assist</Modal.Header>
              <Modal.Content>
                <p>Are you sure you want to delete this Assist?</p>
              </Modal.Content>
              <Modal.Actions>
                <Button negative onClick={() => setModalOpen(false)}>No</Button>
                <Button positive icon='checkmark' labelPosition='right' content='Yes' onClick={deleteAssist}/>
              </Modal.Actions>
            </Modal>
          </React.Fragment>

          <RightMenuBarButton as={NavLink} to={'/assists'}>
            <RightMenuBarButtonIcon name='close' style={{ fontSize: 20 }}/>
          </RightMenuBarButton>
        </RightMenuBarOptions>
      </RightMenuBar>

      <Divider/>

      <Item.Group>
        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>1. Date of Activity</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{new Date(assist.date.split(' ').join('T')).toLocaleDateString('en', {
              year: '2-digit',
              month: 'numeric',
              day: 'numeric'
            })}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>2. Time of Activity</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{new Date(assist.date.split(' ').join('T')).toLocaleTimeString('en-US', {
              hour: '2-digit',
              minute: '2-digit'
            })}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>3. Student Name</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{assist.name}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>4. Gender of Student Helped</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{stringOperations.translate(genderOptions, assist.gender)}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>5. Race of Student Helped</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{assist.races.map(race => stringOperations.translate(raceOptions, race)).join(', ')}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>6. Grade of Student Helped</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{stringOperations.translate(gradeOptions, assist.grade)}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>7. Initiation of Assist</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{stringOperations.translate(initiationOptions, assist.assist)}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>8. Nature of issue</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{assist.experiences.map(experience => stringOperations.translate(lifeExperiencesOptions, experience)).join(', ')}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>

        <Item>
          <Item.Content>
            <StyledLogQuestionHeader>9. How did you help this student?</StyledLogQuestionHeader>
            <StyledLogQuestionAnswer>{stringOperations.translate(assistHelpOptions, assist.help)}</StyledLogQuestionAnswer>
          </Item.Content>
        </Item>
      </Item.Group>
    </Segment>
  )
}

export default AssistView
