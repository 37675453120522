import {
  RightMenuBar,
  RightMenuBarButton,
  RightMenuBarButtonIcon,
  RightMenuBarOptions, StyledTransparentButton
} from '../../../../Theme/Components'
import { Button, Divider, Form, Header, Icon, Segment } from 'semantic-ui-react'
import { dashboard } from '../../../../Theme/Styles'
import { NavLink } from 'react-router-dom'
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'
import InputAdornment from '@material-ui/core/InputAdornment'
import {
  assistHelpOptions,
  genderOptions,
  gradeOptions, initiationOptions,
  lifeExperiencesOptions,
  raceOptions,
  renderLabel
} from '../../../../Config/SelectOptions'
import React, { useEffect, useState } from 'react'
import { editedAssist as defaultEditedAssist } from '../../../../Config/DefaultState'
import useValidation from '../../../../Utils/Validations'
import moment from 'moment'
import useGlobal from '../../../../Store'

const AssistForm = (props) => {
  const logId = props.match.params.id || '0'

  const [globalState, globalActions] = useGlobal()
  const { currentSchool } = globalState
  const [assist, setAssist] = useState(defaultEditedAssist)
  const requiredFields = ['date', 'time', 'races', 'gender', 'grade', 'assist', 'experiences', 'help']
  const [validForm, validationErrors, onFieldChangeValidation] = useValidation(requiredFields, assist)

  useEffect(() => {
    globalActions.assists.getAssist(logId)
  }, [logId])

  useEffect(() => {
    const { date } = globalState.editedAssist
    const dateObject = date !== '' ? new Date(date.split(' ').join('T')) : new Date()

    setAssist({ ...globalState.editedAssist, date: dateObject, time: dateObject })
  }, [globalState.editedAssist])

  const updateAssistLog = e => {
    const { name, value } = typeof e.target !== 'undefined' ? e.target : e
    setAssist({ ...assist, [name]: value })
    onFieldChangeValidation(name, value)
  }

  const onDateChange = (date) => {
    setAssist({ ...assist, date })
  }

  const onTimeChange = (time) => {
    setAssist({ ...assist, time })
  }

  const save = () => {
    if (!currentSchool) {
      globalActions.notifications.showNotification({
        type: 'warning',
        header: 'Select School',
        subheader: 'Please select a school first!'
      })
    } else if (validForm) {
      globalActions.assists.saveAssist({
        ...assist,
        schoolId: currentSchool,
        date: moment(assist.date).format().slice(0, 19).split('T')[0] + ' ' + moment(assist.time).format().slice(0, 19).split('T')[1]
      })
        .then(() => {
          props.history.push('/assists')
        })
    } else {
      const missingFields = []
      Object.keys(validationErrors).map(field => {
        if (validationErrors[field].error)
          missingFields.push(field.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase()))
      })

      let subheader = 'Please complete all required fields! Check "' + missingFields.join('", "') + '"'
      subheader = subheader
        .split('Experiences').join('Issues')
        .split('Races').join('Races')

      globalActions.notifications.showNotification({ type: 'warning', header: 'Form validation error', subheader })
    }
  }

  return (
    <Segment>
      <RightMenuBar>
        <Header as='h3' style={dashboard.myProgramHeader}>{logId !== '0' ? 'Edit' : 'New'} Assist Log</Header>
        <RightMenuBarOptions>
          <RightMenuBarButton as={NavLink} to={'/assists'}>
            <RightMenuBarButtonIcon name='close' style={{ fontSize: 20 }}/>
          </RightMenuBarButton>
        </RightMenuBarOptions>
      </RightMenuBar>

      <Divider/>

      <Form size='large'>
        <Form.Field name='date' required>
          <label>1. Date of Activity</label>
          <KeyboardDatePicker
            value={assist.date}
            onChange={date => onDateChange(date)}
            animateYearScrolling
            format='MM/DD/YY'
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position='end'>
                  <Icon name='calendar outline'/>
                </InputAdornment>
              )
            }}/>
        </Form.Field>
        <Form.Field name='date' required>
          <label>2. Time of Activity</label>
          <KeyboardTimePicker
            ampm
            value={assist.time}
            onChange={time => onTimeChange(time)}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position='end'>
                  <Icon name='clock outline'/>
                </InputAdornment>
              )
            }}/>
        </Form.Field>
        <Form.Input label='3. Student Name' name='name'
                    onChange={(e, data) => updateAssistLog(data)}
                    value={assist.name}
                    placeholder='Name'/>
        <Form.Select label='4. Gender of Student Helped' name='gender'
                     onChange={(e, data) => updateAssistLog(data)}
                     value={assist.gender} required
                     error={validationErrors.gender.error}
                     options={genderOptions} placeholder='Gender'/>
        <Form.Dropdown label='5. Race of Student Helped' name='races' value={assist.races}
                       onChange={(e, data) => updateAssistLog(data)} required fluid
                       multiple selection error={validationErrors.races.error}
                       options={raceOptions} placeholder='Choose one or more'/>
        <Form.Select label='6. Grade of Student Helped' name='grade'
                     onChange={(e, data) => updateAssistLog(data)}
                     value={assist.grade} required
                     error={validationErrors.grade.error}
                     options={gradeOptions} placeholder='Grade'/>
        <Form.Select label='7. Initiation of Assist' name='assist'
                     onChange={(e, data) => updateAssistLog(data)}
                     value={assist.assist} required
                     error={validationErrors.assist.error}
                     options={initiationOptions} placeholder='Choose...'/>
        <Form.Dropdown label='8. Nature of issue' name='experiences'
                       onChange={(e, data) => updateAssistLog(data)}
                       value={assist.experiences} fluid multiple selection required
                       error={validationErrors.experiences.error}
                       options={lifeExperiencesOptions.filter(option => !option.groupOnly)}
                       renderLabel={renderLabel}
                       placeholder='Choose one or more'/>
        <Form.Select label='9. How did you help this student?' name='help'
                     onChange={(e, data) => updateAssistLog(data)}
                     value={assist.help}
                     required
                     error={validationErrors.help.error}
                     options={assistHelpOptions} placeholder='Choose...'/>

        <div style={{ textAlign: 'right' }}>
          <Button.Group>
            <StyledTransparentButton onClick={save}>Save</StyledTransparentButton>
          </Button.Group>
        </div>
      </Form>
    </Segment>
  )
}

export default AssistForm
