import { Grid } from 'semantic-ui-react'
import React from 'react'
import ContextualRightComponent from '../../Components/ContextualRightComponent'
import AssistsTable from './parts/AssistsTable'

const Assists = (props) => {
  return (
    <Grid reversed="mobile vertically">
      <Grid.Column mobile={16} computer={11}>
        <AssistsTable {...props} />
      </Grid.Column>

      <Grid.Column mobile={16} computer={5}>
        <ContextualRightComponent {...props}/>
      </Grid.Column>
    </Grid>
  )
}

export default Assists
