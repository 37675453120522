import { Button, Icon } from 'semantic-ui-react'
import React from 'react'

const MyProgramsCategoryPicker = ({ category, setCategory, subcategory, setSubcategory, programs }) => {
  if (!programs.categories || !programs.subCategories)
    return <></>

  if (category === '')
    return (
      <>
        {programs.categories.map((category, index) => (
          <Button size="mini" key={index} onClick={() => {
            setCategory(category)
            const subcategories = programs.subCategories[category]

            if (subcategories && subcategories.length)
              setSubcategory(subcategories[0])
          }}>
            {category}
          </Button>
        ))}
      </>
    )
  else {
    const subcategories = programs.subCategories[category]
    return (
      <>
        {subcategories && subcategories.length && subcategories.map(sub => (
          <Button size="mini" style={{marginBottom: 5}} onClick={() => setSubcategory(sub)} positive={sub === subcategory}>
            {sub}
          </Button>
        ))}
      </>
    )
  }
}

export default MyProgramsCategoryPicker
