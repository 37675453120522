import React from 'react'
import { Button, Grid, Header, Segment } from 'semantic-ui-react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { NoPrint } from 'react-easy-print'

const OverviewCard = props => {
  const columns = props.columns.map((value, index) => {
    return (
      <StyledProperty key={index}>
        <StyledCount>{value.propertyCount}
          {value.isPercentage && (<StyledPercentage>%</StyledPercentage>)}</StyledCount>
        <StyledText>{value.property}</StyledText>
      </StyledProperty>
    )
  })

  return (
    <React.Fragment>
      <Grid columns={2}>
        <Grid.Column style={{ paddingBottom: 0 }}>
          <StyledHeader as='h2'>{props.title}</StyledHeader>
        </Grid.Column>
        <Grid.Column style={{ paddingBottom: 0 }}>
          <NoPrint force>
            <StyledButton icon='print' floated='right' basic onClick={() => window.print()} />
          </NoPrint>
        </Grid.Column>
      </Grid>
      <StyledSegment>
        {columns}
      </StyledSegment>
    </React.Fragment>
  )
}

OverviewCard.propTypes = {
  title: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    property: PropTypes.string,
    propertyCount: PropTypes.number,
    isPercentage: PropTypes.bool
  }))
}

const StyledHeader = styled(Header)`
  size: 24px !important;
  font-weight: lighter;
`

const StyledSegment = styled(Segment)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: stretch;
`

const StyledButton = styled(Button)`
  box-shadow: none !important;
  i {
    color: black !important;
  }
`

const StyledProperty = styled.div`
  display: flex;
  flex: 1 !important;
  flex-direction: column;
`

const StyledCount = styled.p`
  font-size: 76px;
  color: #753CBE;
  display: flex;
  justify-content: center !important;
  margin-bottom: -15px !important;
  font-weight: lighter;
`

const StyledPercentage = styled.p`
  font-size: 24px;
  color: #753CBE;
  display: flex;
  font-family: Roboto !important;
  float: right !important;
  margin-bottom: 15px !important;
  position: relative;
  top: 4rem;
`

const StyledText = styled.p`
  text-align: center; 
  vertical-align: middle; 
  font-size: 14px;
  color: #4D4D4D;
`

export default OverviewCard
