import React, { useEffect, useLayoutEffect, useState } from "react";
import _ from "lodash";
import useGlobal from "../../../Store";
import {
  Button,
  Checkbox,
  Grid,
  Header,
  Icon,
  List,
  Modal,
  Pagination,
  Radio,
  Responsive,
  Segment,
  Table,
} from "semantic-ui-react";
import { rightForm } from "../../../Theme/Styles";
import {
  genderOptions,
  gradeOptions,
  raceOptions,
} from "../../../Config/SelectOptions";
import useStringOperations from "../../../Utils/StringOperations";
import {
  StyledTableHeader,
  StyledTransparentButton,
} from "../../../Theme/Components";
import ProfileCard from "../../../Components/ProfileCard/ProfileCard";
import UserForm from "../../../Components/UserForm/UserForm";
import UserSearch from "../../../Components/UserSearch/UserSearch";

export const defaultUserFilters = {
  stateId: "",
  districtId: "",
  schoolId: "",
  role: "",
  enabled: "true",
  name: "",
};

const UsersPage = (props) => {
  const [globalState, globalActions] = useGlobal();
  const {
    loggedInUser,
    roles,
    usersPagination,
    userManagementSchools,
    userManagementDistricts,
    userManagementStates,
  } = globalState;

  const [filters, setFilters] = useState({
    ...defaultUserFilters,
    stateId: globalState.currentState,
    districtId: globalState.currentDistrict,
    schoolId: globalState.currentSchool,
  });

  const [page, setPage] = useState(usersPagination.number);
  const [editUser, setEditUser] = useState(false);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [detailedUser, setDetailedUser] = useState({});
  const [editedUserId, setEditedUserId] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedCoordinators, setSelectedCoordinators] = useState([]);
  const [selectedPH, setSelectedPH] = useState([]);
  const [deleteStudents, setDeleteStudents] = useState(false);
  const [deleteLogs, setDeleteLogs] = useState(false);
  const [deleteAssignedStudents, setDeleteAssignedStudents] = useState(false);
  const [deleteAssignedLogs, setDeleteAssignedLogs] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [usersList, setUsersList] = useState(globalState.usersList);

  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [schools, setSchools] = useState([]);

  const [width, setWidth] = useState(0);

  useEffect(() => {
    setFilters({
      ...filters,
      stateId: globalState.currentState,
      districtId: globalState.currentDistrict,
      schoolId: globalState.currentSchool,
    });
    if (globalState.currentSchool) {
      globalActions.users.getUsers(page, {
        ...filters,
        stateId: globalState.currentState,
        districtId: globalState.currentDistrict,
        schoolId: globalState.currentSchool,
      });
    }
  }, [
    globalState.currentState,
    globalState.currentDistrict,
    globalState.currentSchool,
    page,
  ]);

  useLayoutEffect(() => {
    setFilters(defaultUserFilters);
    setEditedUserId("");
  }, []);

  useLayoutEffect(() => {
    function updateSize() {
      setWidth(window.innerWidth / (16 / 5));
    }

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [props.location.pathname]);

  useEffect(() => {
    globalActions.roles.getRoles();

    globalActions.locations.getStates(true);
  }, []);

  useEffect(() => {
    if (filters.stateId) {
      globalActions.locations.getDistricts(filters.stateId, true);
    }
  }, [filters.stateId]);

  useEffect(() => {
    if (filters.districtId) {
      globalActions.locations.getDistrictsSchools(filters.districtId, true);
    }
  }, [filters.districtId]);

  useEffect(() => {
    setStates(userManagementStates);
    if (userManagementStates && userManagementStates.length === 1) {
      globalActions.locations.getDistricts(userManagementStates[0].id, true);
      setFilters({ ...filters, stateId: userManagementStates[0].id });
    }
  }, [userManagementStates]);

  useEffect(() => {
    setDistricts(userManagementDistricts);
    if (userManagementDistricts && userManagementDistricts.length === 1) {
      globalActions.locations.getDistrictsSchools(
        userManagementDistricts[0].id,
        true
      );
      setFilters({ ...filters, districtId: userManagementDistricts[0].id });
    }
  }, [userManagementDistricts]);

  useEffect(() => {
    setSchools(userManagementSchools);
    if (userManagementSchools && userManagementSchools.length === 1) {
      setFilters({ ...filters, schoolId: userManagementSchools[0].id });
    }
  }, [userManagementSchools]);

  useEffect(() => {
    if (globalState.usersList) {
      setUsersList(globalState.usersList);
    }
  }, [globalState.usersList]);

  const _confirmDeleteUsers = () => {
    if (selectedUsers.length === 0)
      globalActions.notifications.showNotification({
        show: true,
        header: "Please select at least one user.",
        subheader: "",
        type: "warning",
      });
    else setModalOpen(true);
  };

  const _deleteUsers = () => {
    globalActions.users
      .deleteUsers(
        selectedUsers,
        deleteStudents,
        deleteLogs,
        deleteAssignedStudents,
        deleteAssignedLogs
      )
      .then(() => {
        setTimeout(() => {
          setModalOpen(false);
          setFilters(defaultUserFilters);
          globalActions.users.getUsers(page, filters);
        }, 2000);
      });
  };

  const searchUser = () => {
    globalActions.users.getUsers(0, filters);
  };

  const onPageChange = (e, { activePage }) => {
    setPage(activePage - 1);
  };

  const resetFilters = (e) => {
    e.preventDefault();
    setFilters({
      ...filters,
      role: defaultUserFilters.role,
      enabled: defaultUserFilters.enabled,
      name: defaultUserFilters.name,
    });
    if (globalState.currentSchool) {
      globalActions.users.getUsers(0, {
        ...filters,
        role: defaultUserFilters.role,
        enabled: defaultUserFilters.enabled,
        name: defaultUserFilters.name,
      });
    }
    setPage(0);
  };

  const handleEditUser = (userId) => {
    setEditedUserId(userId);
    setEditUser(true);
    setShowUserDetails(false);
  };

  const finishEditingUser = () => {
    setEditedUserId("");
    setEditUser(false);
    setTimeout(() => {
      globalActions.users.getUsers(page, filters);
    }, 1500);
  };

  const cancelEdit = () => {
    setEditedUserId("");
    setEditUser(false);
    setDistricts([]);
    setSchools([]);
  };

  return (
    <>
      <Grid reversed="computer tablet">
        <Grid.Column mobile={16} computer={5} style={rightForm}>
          <Responsive as="div" maxWidth={1000}>
            <StyledTableHeader>
              <Header as="h2">Users</Header>
            </StyledTableHeader>
          </Responsive>

          {editUser || showUserDetails ? (
            <React.Fragment>
              {editUser ? (
                <UserForm
                  {...props}
                  userId={editedUserId}
                  refresh={finishEditingUser}
                  cancelEdit={cancelEdit}
                />
              ) : (
                <ProfileCard
                  user={detailedUser}
                  close={() => setShowUserDetails(false)}
                />
              )}
            </React.Fragment>
          ) : (
            <div style={{ position: "fixed", top: 65, right: 0, width }}>
              <UserSearch
                filters={filters}
                setFilters={setFilters}
                resetFilters={resetFilters}
                searchUser={searchUser}
                loggedInUser={loggedInUser}
                roles={roles}
                userManagementStates={states}
                userManagementDistricts={districts}
                userManagementSchools={schools}
              />
            </div>
          )}
        </Grid.Column>

        <Grid.Column mobile={16} computer={11}>
          <Responsive as="div" minWidth={1000}>
            <StyledTableHeader>
              <Header as="h2" style={{ marginTop: 10 }}>
                Users
              </Header>
              <div>
                {loggedInUser.role === "Super User" && (
                  <>
                    <StyledTransparentButton
                      style={{ padding: 5 }}
                      onClick={() => _confirmDeleteUsers()}
                    >
                      <Icon name="trash" />
                    </StyledTransparentButton>

                    <StyledTransparentButton
                      style={{ padding: 5 }}
                      onClick={() => handleEditUser(0)}
                    >
                      <Icon name="plus" />
                    </StyledTransparentButton>
                  </>
                )}
              </div>
            </StyledTableHeader>
          </Responsive>

          {usersList.length === 0 ? (
            <Segment placeholder>
              <Header as="h2" icon color="grey">
                Searching results
                <Icon name="user x" style={{ marginTop: 20 }} />
                <Header.Subheader>No results found</Header.Subheader>
              </Header>
            </Segment>
          ) : (
            <React.Fragment>
              <UsersTable
                handleEditUser={handleEditUser}
                users={usersList}
                refresh={finishEditingUser}
                showUserDetails={(user) => {
                  setEditUser(false);
                  setShowUserDetails(true);
                  setDetailedUser(user);
                }}
                updateSelectedUsers={setSelectedUsers}
                updateSelectedCoordinators={setSelectedCoordinators}
                updateSelectedPH={setSelectedPH}
              />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Pagination
                  activePage={page + 1}
                  totalPages={usersPagination.totalPages}
                  onPageChange={onPageChange}
                />
              </div>
            </React.Fragment>
          )}
        </Grid.Column>
      </Grid>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)} closeIcon>
        <Modal.Header>Delete Users</Modal.Header>
        <Modal.Content>
          <p style={{ fontSize: 20 }}>
            Are you sure you want to delete{" "}
            {selectedUsers.length === 1
              ? "this user"
              : `these ${selectedUsers.length} users`}
            ?
          </p>

          {(selectedCoordinators.length > 0 || selectedPH.length > 0) && (
            <>
              <p style={{ fontSize: 15, marginTop: 50 }}>
                You have selected&nbsp;
                {selectedCoordinators.length > 0 &&
                  `${selectedCoordinators.length} coordinators`}
                {selectedCoordinators.length > 0 &&
                  selectedPH.length > 0 &&
                  " and "}
                {selectedPH.length > 0 && `${selectedPH.length} peer helpers`},
                do you want to:
              </p>

              <List>
                <List.Item>
                  <Checkbox
                    label={"Delete created logs"}
                    value={deleteLogs}
                    onChange={() => setDeleteLogs(!deleteLogs)}
                  />
                </List.Item>
                <List.Item>
                  <Checkbox
                    label={"Delete created students"}
                    value={deleteStudents}
                    onChange={() => setDeleteStudents(!deleteStudents)}
                  />
                </List.Item>
                {selectedPH.length > 0 && (
                  <>
                    <List.Item>
                      <Checkbox
                        label={"Delete assigned logs"}
                        value={deleteAssignedLogs}
                        onChange={() =>
                          setDeleteAssignedLogs(!deleteAssignedLogs)
                        }
                      />
                    </List.Item>
                    <List.Item>
                      <Checkbox
                        label={"Delete assigned students"}
                        value={deleteAssignedStudents}
                        onChange={() =>
                          setDeleteAssignedStudents(!deleteAssignedStudents)
                        }
                      />
                    </List.Item>
                  </>
                )}
              </List>
            </>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setModalOpen(false)}>
            No
          </Button>
          <Button
            positive
            icon="checkmark"
            labelPosition="right"
            content="Yes"
            onClick={_deleteUsers}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

const UsersTable = ({
  users,
  handleEditUser,
  refresh,
  showUserDetails,
  updateSelectedUsers,
  updateSelectedCoordinators,
  updateSelectedPH,
}) => {
  const stringOperations = useStringOperations();
  const isMobile = window.innerWidth < 1000;
  const [globalState, globalActions] = useGlobal();
  const { loggedInUser } = globalState;

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedCoordinators, setSelectedCoordinators] = useState([]);
  const [selectedPH, setSelectedPH] = useState([]);

  const onComplete = (id) => {
    const user = users.find((user) => user.id === id);
    globalActions.users
      .saveUser({ id: user.id, enabled: user.enabled ? "false" : "true" })
      .then(() => refresh());
  };

  const _selectUser = (userId, roles) => {
    const role = roles[0].name;
    setSelectedUsers(
      selectedUsers.indexOf(userId) > -1
        ? selectedUsers.filter((user) => user !== userId)
        : [...selectedUsers, userId]
    );
    if (role === "Peer Helper")
      setSelectedPH(
        selectedPH.indexOf(userId) > -1
          ? selectedPH.filter((user) => user !== userId)
          : [...selectedPH, userId]
      );

    if (role === "Coordinator")
      setSelectedCoordinators(
        selectedCoordinators.indexOf(userId) > -1
          ? selectedCoordinators.filter((user) => user !== userId)
          : [...selectedCoordinators, userId]
      );
  };

  const _selectAllUsers = () => {
    if (selectedUsers.length === users.length) {
      setSelectedUsers([]);
      setSelectedCoordinators([]);
      setSelectedPH([]);
    } else {
      setSelectedUsers(users.map((user) => user.id));
      setSelectedCoordinators(
        users
          .filter((user) => user.roles[0].name === "Coordinator")
          .map((user) => user.id)
      );
      setSelectedPH(
        users
          .filter((user) => user.roles[0].name === "Peer Helper")
          .map((user) => user.id)
      );
    }
  };

  useEffect(() => {
    updateSelectedUsers(selectedUsers);
    updateSelectedCoordinators(selectedCoordinators);
    updateSelectedPH(selectedPH);
  }, [selectedUsers]);

  return (
    <Table compact striped size="small">
      <Responsive as={Table.Header} minWidth={1000}>
        <Table.Row className="stickyTh">
          {loggedInUser.role === "Super User" && (
            <Table.HeaderCell>
              <Checkbox
                checked={selectedUsers.length === users.length}
                onChange={() => _selectAllUsers()}
              />
            </Table.HeaderCell>
          )}
          <Table.HeaderCell>First Name</Table.HeaderCell>
          <Table.HeaderCell>Last Name</Table.HeaderCell>
          <Table.HeaderCell>Username</Table.HeaderCell>
          <Table.HeaderCell>Gender</Table.HeaderCell>
          <Table.HeaderCell>Grade</Table.HeaderCell>
          <Table.HeaderCell>Race</Table.HeaderCell>
          {["Coordinator", "Super User"].includes(loggedInUser.role) && (
            <Table.HeaderCell>Edit</Table.HeaderCell>
          )}
          <Table.HeaderCell>Enabled</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Responsive>

      <Table.Body>
        {_.map(users, (user) => {
          const {
            id,
            firstName,
            lastName,
            username,
            gender,
            grades,
            race,
            enabled,
            roles,
          } = user;

          return (
            <Table.Row key={firstName + lastName + id}>
              {loggedInUser.role === "Super User" && (
                <Table.Cell>
                  <Checkbox
                    checked={selectedUsers.includes(id)}
                    onChange={() => _selectUser(id, roles)}
                  />
                </Table.Cell>
              )}
              <Table.Cell>
                <Responsive
                  as="strong"
                  maxWidth={767}
                  style={{ marginRight: 5 }}
                >
                  First Name:
                </Responsive>
                {firstName}
              </Table.Cell>
              <Table.Cell>
                <Responsive
                  as="strong"
                  maxWidth={767}
                  style={{ marginRight: 5 }}
                >
                  Last Name:
                </Responsive>
                {lastName}
              </Table.Cell>
              <Table.Cell>
                <Responsive
                  as="strong"
                  maxWidth={767}
                  style={{ marginRight: 5 }}
                >
                  Username:
                </Responsive>
                {username.substring(0, 30)}
              </Table.Cell>
              <Table.Cell>
                <Responsive
                  as="strong"
                  maxWidth={767}
                  style={{ marginRight: 5 }}
                >
                  Gender:
                </Responsive>
                {stringOperations.translate(genderOptions, gender)}
              </Table.Cell>
              <Table.Cell>
                <Responsive
                  as="strong"
                  maxWidth={767}
                  style={{ marginRight: 5 }}
                >
                  Grade:
                </Responsive>
                {grades !== null
                  ? grades
                      .map((grade) =>
                        stringOperations.translate(gradeOptions, grade)
                      )
                      .join(", ")
                  : ""}
              </Table.Cell>
              <Table.Cell>
                <Responsive
                  as="strong"
                  maxWidth={767}
                  style={{ marginRight: 5 }}
                >
                  Race:
                </Responsive>
                {stringOperations.translate(raceOptions, race)}
              </Table.Cell>

              {["Coordinator", "Super User"].includes(loggedInUser.role) && (
                <Table.Cell>
                  <Button
                    icon
                    color="blue"
                    circular
                    size="mini"
                    labelPosition={isMobile ? "right" : undefined}
                    onClick={() => handleEditUser(id)}
                    style={isMobile ? { marginTop: "1rem" } : {}}
                  >
                    <Responsive as="span" {...Responsive.onlyMobile}>
                      Edit
                    </Responsive>{" "}
                    <Icon name="pencil alternate" />
                  </Button>
                </Table.Cell>
              )}

              <Table.Cell>
                <Radio
                  toggle
                  checked={enabled}
                  label={isMobile ? (enabled ? "Enabled" : "Disabled") : ""}
                  style={isMobile ? { marginTop: "1rem" } : {}}
                  onChange={() => onComplete(id)}
                />
              </Table.Cell>

              <Table.Cell>
                <Button
                  icon
                  color="green"
                  circular
                  size="mini"
                  labelPosition={isMobile ? "right" : undefined}
                  onClick={() => showUserDetails(user)}
                  style={isMobile ? { marginTop: "1rem" } : {}}
                >
                  <Responsive as="span" {...Responsive.onlyMobile}>
                    Edit
                  </Responsive>{" "}
                  <Icon name="eye" />
                </Button>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

export default UsersPage;
