import React, { useEffect, useState } from 'react'
import { Button, Grid, Image, Modal, Popup } from 'semantic-ui-react'
import { StyledProfileModalImages } from '../../Theme/Components'
import styled from 'styled-components'

const imgGirl1 = 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/160/apple/155/woman_emoji-modifier-fitzpatrick-type-1-2_1f469-1f3fb_1f3fb.png'
const imgGirl2 = 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/198/woman_emoji-modifier-fitzpatrick-type-3_1f469-1f3fc_1f3fc.png'
const imgGirl3 = 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/198/woman_emoji-modifier-fitzpatrick-type-4_1f469-1f3fd_1f3fd.png'
const imgGirl4 = 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/198/woman_emoji-modifier-fitzpatrick-type-5_1f469-1f3fe_1f3fe.png'
const imgBoy1 = 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/198/boy_emoji-modifier-fitzpatrick-type-1-2_1f466-1f3fb_1f3fb.png'
const imgBoy2 = 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/198/boy_emoji-modifier-fitzpatrick-type-3_1f466-1f3fc_1f3fc.png'
const imgBoy3 = 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/198/boy_emoji-modifier-fitzpatrick-type-4_1f466-1f3fd_1f3fd.png'
const imgBoy4 = 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/198/boy_emoji-modifier-fitzpatrick-type-5_1f466-1f3fe_1f3fe.png'

const ProfilePictureModal = ({ avatar, modalOpen, setModalOpen, saveProfilePicture, updateUserState }) => {
  const [profilePicture, setProfilePicture] = useState({ src: avatar || '' })
  const imageGirlList = [imgGirl1, imgGirl2, imgGirl3, imgGirl4]
  const imageBoyList = [imgBoy1, imgBoy2, imgBoy3, imgBoy4]
  const [imageGirl, setImageGirl] = useState({ src: imgGirl2 })
  const [imageBoy, setImageBoy] = useState({ src: imgBoy2 })

  useEffect(() => {
    updateUserState({ target: { name: 'avatar', value: profilePicture.src } })
  }, [profilePicture])

  useEffect(() => {
    setProfilePicture({ src: avatar })
  }, [avatar])

  const closeModal = () => {
    setModalOpen(false)
  }

  const chooseGirlImage = (image) => {
    setImageGirl({ src: image })
    setProfilePicture({ src: image })
  }

  const chooseBoyImage = (image) => {
    setImageBoy({ src: image })
    setProfilePicture({ src: image })
  }

  return (
    <Modal open={modalOpen} onClose={closeModal} closeIcon>
      <Modal.Header>
        Select Profile Picture <p style={{ fontSize: 'medium', opacity: '0.7', float: 'right', marginRight: '7rem' }}>Picture selected:</p>
      </Modal.Header>

      <Modal.Content style={{ display: 'flex', flexDirection: 'column', padding: '2rem 2rem 2rem 3rem' }}>
        <StyledProfileModalImages>
          <Grid columns={3}>
            <Grid.Column>
              <Popup flowing hoverable trigger={
                <Button icon style={{ backgroundColor: 'white', padding: '0', margin: '0' }}>
                  <Image wrapped src={imageGirl.src} size='tiny' />
                </Button>
              }>
                <Grid style={{ padding: '1rem' }}>
                  {imageGirlList.map(girlImage => (
                    <StyledChooseAvatarButton icon onClick={() => chooseGirlImage(girlImage)}>
                      <Image wrapped src={girlImage} size='tiny' />
                    </StyledChooseAvatarButton>
                  ))}
                </Grid>
              </Popup>
            </Grid.Column>

            <Grid.Column textAlign='left'>
              <Popup flowing hoverable trigger={
                <Button icon style={{ backgroundColor: 'white', padding: '0', margin: '0' }}>
                  <Image wrapped src={imageBoy.src} size='tiny' />
                </Button>
              }>
                <Grid style={{ padding: '1rem' }}>
                  {imageBoyList.map(boyImage => (
                    <StyledChooseAvatarButton icon onClick={() => chooseBoyImage(boyImage)}>
                      <Image wrapped src={boyImage} size='tiny' />
                    </StyledChooseAvatarButton>
                  ))}
                </Grid>
              </Popup>
            </Grid.Column>

            <Grid.Column textAlign='right'>
              <Image wrapped src={profilePicture.src} size='tiny' style={{ marginRight: '7rem' }} />
            </Grid.Column>
          </Grid>
        </StyledProfileModalImages>

        <Button black basic style={{ fontSize: 'medium' }} floated="right" onClick={saveProfilePicture}>Save</Button>
      </Modal.Content>
    </Modal>
  )
}

const StyledChooseAvatarButton = styled(Button)`
  background-color: white;
  padding: 0.5rem;
  margin: 0;
`

export default ProfilePictureModal
