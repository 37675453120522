import _ from 'lodash'

const useStringOperations = () => {
  const translate = (options, string) => {
    if (typeof string === 'undefined') {
      return ''
    } else if (typeof string === 'object') {
      const texts = _.map(string, item => {
        return _.find(options, option => option.key === item)
      })
      return _.map(texts, text => typeof text !== 'undefined' ? text.text : '').join(', ')
    } else {
      const neededOption = _.find(options, option => option.key === string)
      return typeof neededOption !== 'undefined' ? neededOption.text.replace("_", " ") : ''
    }
  }

  const translateInfo = (options, string) => {
    if (typeof string === 'undefined') {
      return ''
    } else if (typeof string === 'object') {
      const texts = _.map(string, item => {
        return _.find(options, option => option.key === item)
      })
      return _.map(texts, text => typeof text !== 'undefined' ? text.subtext : '').join(', ')
    } else {
      const neededOption = _.find(options, option => option.key === string)
      return typeof neededOption !== 'undefined' ? neededOption.subtext : ''
    }
  }

  return { translate, translateInfo }
}

export default useStringOperations
