import React, { useLayoutEffect, useState } from 'react'
import useGlobal from '../../Store'
import { Button, Grid, Header, Icon, List, Responsive, Segment, Table } from 'semantic-ui-react'
import _ from 'lodash'
import ContextualRightComponent from '../../Components/ContextualRightComponent'
import { NavLink } from 'react-router-dom'
import { StyledTable, StyledTransparentButton } from '../../Theme/Components'
import { Link } from '../Import/Import'

const isMobile = window.innerWidth < 1000

const SchoolsPage = (props) => {
  const [globalState] = useGlobal()
  const { superUserSchools } = globalState

  const [leftWidth, setLeftWidth] = useState(0)

  useLayoutEffect(() => {
    function updateSize () {
      setLeftWidth(window.innerWidth / (16 / 3))
    }

    window.addEventListener('resize', updateSize)
    updateSize()

    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return (
    <Grid>
      <Grid.Column mobile={16} computer={3}>
        <div style={window.innerWidth > 1000 ? {
          position: 'fixed',
          left: 0,
          top: 65,
          width: leftWidth
        } : {}}>
          <Segment>
            <List>
              <Link path="/heirarchy-management/state" currentPath={props.location.pathname}/>
              <Link path="/heirarchy-management/district" currentPath={props.location.pathname}/>
              <Link path="/heirarchy-management/schools" currentPath={props.location.pathname}/>
            </List>
          </Segment>
        </div>
      </Grid.Column>

      <Grid.Column mobile={16} tablet={9} computer={8} style={{ paddingTop: 0 }}>
        {(!superUserSchools || superUserSchools.length === 0) ? (
          <Segment placeholder style={{ marginTop: 0 }}>
            <StyledTransparentButton as={NavLink} to={`/heirarchy-management/schools/0/add`}
                                     style={{ position: 'absolute', top: '10px', right: '10px' }}>
              <Icon name="plus"/>
            </StyledTransparentButton>

            <Header as="h2" icon color="grey">
              Schools
              <Header.Subheader>No schools found</Header.Subheader>
            </Header>
          </Segment>) : <SchoolsTable schools={superUserSchools} history={props.history}/>}
      </Grid.Column>

      <Grid.Column mobile={16} tablet={7} computer={5}>
        <ContextualRightComponent {...props} width={16 / 5}/>
      </Grid.Column>
    </Grid>
  )
}

const SchoolsTable = ({ schools, history }) => {
  return (
    <StyledTable compact striped size='small'>
      <Table.Header className="stickyTh">
        <Responsive as={Table.Row} minWidth={1000}>
          <Table.HeaderCell>
            School
          </Table.HeaderCell>
          <Table.HeaderCell>
            State
          </Table.HeaderCell>
          <Table.HeaderCell>
            District
          </Table.HeaderCell>
          <Table.HeaderCell>
            Coordinator Code
          </Table.HeaderCell>
          <Table.HeaderCell>
            Peer Helper Code
          </Table.HeaderCell>
          <Table.HeaderCell style={{ textAlign: 'right', paddingRight: 14 }}>
            <StyledTransparentButton as={NavLink} to={`/heirarchy-management/schools/0/edit`}>
              <Icon name='plus'/>
            </StyledTransparentButton>
          </Table.HeaderCell>
        </Responsive>
      </Table.Header>
      <Table.Body>
        {_.map(schools, ({ id, name, stateName, districtName, coordinatorCode, peerHelperCode }) => (
          <Table.Row key={id + name + coordinatorCode + peerHelperCode}>
            <Table.Cell>
              <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                School:
              </Responsive>
              {name}
            </Table.Cell>
            <Table.Cell>
              <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                State:
              </Responsive>
              {stateName}
            </Table.Cell>
            <Table.Cell>
              <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                District:
              </Responsive>
              {districtName}
            </Table.Cell>
            <Table.Cell style={{ color: 'purple' }}>
              <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                Coordinator Code:
              </Responsive>
              {coordinatorCode !== null ? coordinatorCode : ''}
            </Table.Cell>
            <Table.Cell style={{ color: 'purple' }}>
              <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                Peer Helper Code:
              </Responsive>
              {peerHelperCode !== null ? peerHelperCode : ''}
            </Table.Cell>

            <Table.Cell style={{ textAlign: 'right' }}>
              <Button icon color='blue' circular size='mini' labelPosition={isMobile ? 'right' : undefined}
                      onClick={() => history.push(`/heirarchy-management/schools/${id}/edit`)}
                      style={isMobile ? { marginTop: '1rem' } : {}}>
                <Responsive as='span' {...Responsive.onlyMobile}>Edit</Responsive> <Icon name='pencil alternate'/>
              </Button>
            </Table.Cell>
          </Table.Row>
        ))}

      </Table.Body>
    </StyledTable>
  )
}

export default SchoolsPage
