let host = window.location.host.split('iam.').join('').split('api').join('')

if (host.indexOf('localhost') > -1) host = 'peerhelpersportal.com'

//if (host.indexOf('localhost') > -1) host = 'peerhelper.live'
// host = 'peerhelpersportal.com'

export const baseUrl =
  window.location.host.indexOf('localhost') > -1
    ? 'http://localhost:8080'
    : 'https://api.' + host
export const keycloakUrl = 'https://iam.' + host

const realm = 'PeerHelper'
export const redirectUrl =
  (window.location.host.indexOf('localhost') > -1 ? 'http://' : 'https://') +
  window.location.host +
  '/login'
export const keycloack = `${keycloakUrl}/auth/realms/${realm}/protocol/openid-connect/auth?scope=openid&response_type=code&client_id=api&redirect_uri=${redirectUrl}`
export const keycloackCodeLogin = `${keycloakUrl}/auth/realms/${realm}/protocol/openid-connect/token`
export const keycloakToken =
  keycloakUrl.indexOf('peerhelper.live') > -1
    ? 'YXBpOjBkNTYwODk3LTI3MzYtNDhjMy04ZGIyLWUxZWQ1MWQ1YjgyNg=='
    : `YXBpOjQ0NzdlYTg3LWE5OGEtNGY3My04MDM1LWZkNzBmNWQwMjAxYg==`

const users = {
  root: baseUrl + '/users',
  students: baseUrl + '/students',
  matchTool: baseUrl + '/matchTools',
}

const guides = {
  root: baseUrl + '/tutorials',
}

const contact = {
  root: baseUrl + '/contact',
}

const notifications = {
  root: baseUrl + '/notifications',
  count: baseUrl + '/notifications/count',
}

const archive = {
  hierarchy: baseUrl + '/import',
  users: baseUrl + '/users/import',
  archive: baseUrl + '/archives',
}

const surveys = baseUrl + '/surveys'

const locations = {
  root: baseUrl + '/hierarchy',
  myHierarchy: baseUrl + '/me/hierarchy',
  myGrades: baseUrl + '/me/grades',
  schools: baseUrl + '/me/schools',
  superUserSchools: baseUrl + '/schools',
  schoolLevel: '/schoollevel',
  districts: baseUrl + '/districts',
  states: baseUrl + '/states',
  levels: baseUrl + '/levels',
  roles: baseUrl + '/roles',
  labelDistrict: '/district',
  labelSchool: '/schools',
}

const adminState = {
  // states: localUrl + '/states',
}
const logs = {
  root: baseUrl + '/logs',
  asNeededLogs: baseUrl + '/asNeededSupport',
  groupActivityLogs: baseUrl + '/groupActivity',
  onGoingSupportLogs: baseUrl + '/ongoingSupport',
  assists: `${baseUrl}/educatorLog`
}
const assessments = {
  root: baseUrl + '/assessments',
}
const dashboardStatistics = {
  reports: baseUrl + '/reports',
  programs: baseUrl + '/programs',
  programsOrder: baseUrl + '/programsOrder',
  documents: baseUrl + '/documents',
  coordinatorsStatistics: baseUrl + '/coordinator/statistics',
  districtStatistics: baseUrl + '/district/statistics',
  phStatistics: baseUrl + '/peerHelper/statistics',
  stateLandingStatistics: baseUrl + '/state/statistics',
  superUserStatistics: baseUrl + '/superUser/statistics',
  topics: baseUrl + '/reports/supportTopics',
  atRiskReferral: baseUrl + '/reports/atRiskReferral/logs',
  usageReport: baseUrl + '/reports/usageReports',
}

export default {
  users,
  guides,
  dashboardStatistics,
  notifications,
  locations,
  adminState,
  logs,
  assessments,
  contact,
  archive,
  surveys,
}
