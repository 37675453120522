import axios from 'axios'
import endpoints from '../Config/ApiEndpoints'
import { processRequestErrorNotification } from '../Utils/Error'
import { editedOnGoingSupportLog as defaultEditedOnGoingSupportLog } from '../Config/DefaultState'
import { getResourceFromArchive } from './Archive'

export const getOnGoingSupportLog = async (store, logId) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  store.setState({ requestStatus: 'LOADING' })

  try {
    const response = await getResourceFromArchive(store, endpoints.logs.root + '/' + logId, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
    const editedOnGoingSupportLog = response.data

    store.setState({ requestStatus: 'SUCCESS', editedOnGoingSupportLog })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ editedOnGoingSupportLog: defaultEditedOnGoingSupportLog, requestStatus: 'FAILED', notification })
  }
}

export const saveOnGoingSupportLog = async (store, log, studentId) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  let url = endpoints.users.students + '/' + studentId + '/logs'
  store.setState({ requestStatus: 'LOADING' })

  try {
    if (log.id === '') {
      await axios.post(url, log, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
    } else {
      await axios.patch(url + '/' + log.id, log, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
    }
    const notification = { type: 'success', header: `Log ${log.id === '' ? 'added' : 'updated'}` }
    store.setState({ requestStatus: 'SUCCESS', notification })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ requestStatus: 'FAILED', notification })
  }
}

export const deleteOnGoingSupportLog = async (store, logId) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  store.setState({ requestStatus: 'LOADING' })

  try {
    // await getResourceFromArchive(store, endpoints.logs.onGoingSupportLogs + '/' + logId, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })

    await axios.delete(endpoints.logs.onGoingSupportLogs + '/' + logId, {
      headers: {
        Authorization:
          `Bearer ${store.state.loggedInUser.accessToken}`
      }
    })

    store.setState({ requestStatus: 'SUCCESS' })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ requestStatus: 'FAILED', notification })
  }
}
