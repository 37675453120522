import React, { useEffect, useState } from 'react'
import { Header, Button, Segment, Form, Icon, Divider } from 'semantic-ui-react'
import {
  lifeExperiencesOptions,
  placeOptions,
  typeOfActivityOptions,
  numberOfStudentsOptions,
  addressingTypeOptions, renderLabel
} from '../../Config/SelectOptions'
import useGlobal from '../../Store'
import useValidation from '../../Utils/Validations'
import { NavLink } from 'react-router-dom'
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'
import InputAdornment from '@material-ui/core/InputAdornment'
import useUrlBuilder from '../../Utils/Links'
import { dashboard } from '../../Theme/Styles'
import {
  RightMenuBar,
  RightMenuBarButton,
  RightMenuBarButtonIcon,
  RightMenuBarOptions,
  StyledTransparentButton
} from '../../Theme/Components'
import { editedGroupActivityLog as defaultEditedGroupActivityLog } from '../../Config/DefaultState'
import moment from 'moment'

const GroupActivityLogForm = (props) => {
  const groupActivityLogId = props.match.params.id || '0'
  const [globalState, globalActions] = useGlobal()
  const { usersList, loggedInUser, currentSchool } = globalState
  const [groupActivityLog, setGroupActivityLog] = useState(defaultEditedGroupActivityLog)
  const requiredFields = ['title', 'peerHelpers', 'type', 'experiences', 'help', 'location']
  const [validForm, validationErrors, onFieldChangeValidation] = useValidation(requiredFields, groupActivityLog)
  const [makeUrl] = useUrlBuilder(props.match.url)
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    globalActions.users.getUsers(0, { role: 'Peer Helper', schoolId: currentSchool, enabled: true }, 10000)
    globalActions.groupActivityLogs.getGroupActivityLog(groupActivityLogId)
  }, [])

  useEffect(() => {
    globalActions.groupActivityLogs.getGroupActivityLog(groupActivityLogId)
  }, [groupActivityLogId])

  useEffect(() => {
    const { date } = globalState.editedGroupActivityLog
    const dateObject = date !== '' ? new Date(date.split(' ').join('T')) : new Date()

    setGroupActivityLog({
      ...globalState.editedGroupActivityLog,
      peerHelpers: groupActivityLog.peerHelpersId || new Array(loggedInUser.id),
      date: dateObject,
      time: dateObject,
      size: globalState.editedGroupActivityLog.sizeReferrals
    })
  }, [globalState.editedGroupActivityLog])

  const updateGroupActivityLogState = e => {
    const { name, value } = typeof e.target !== 'undefined' ? e.target : e
    setGroupActivityLog({ ...groupActivityLog, [name]: value })
    onFieldChangeValidation(name, value)
  }

  const onDateChange = (date) => {
    setGroupActivityLog({ ...groupActivityLog, date })
  }

  const onTimeChange = (time) => {
    setGroupActivityLog({ ...groupActivityLog, time })
  }

  const save = () => {
    console.log(groupActivityLog.size)
    if (!currentSchool)
      globalActions.notifications.showNotification({
        type: 'warning',
        header: 'Select School',
        subheader: 'Please select a school first!'
      })
    else if(groupActivityLog.referred && (!groupActivityLog.size||groupActivityLog.size<1)){
      globalActions.notifications.showNotification({
        type: 'warning',
        header: 'Number of Students',
        subheader: 'Please select the number of students'
      })
    }
    else if (validForm) {
      setDisabled(true)
      globalActions.groupActivityLogs.saveGroupActivityLog({
        ...groupActivityLog,
        schoolId: currentSchool,
        size: groupActivityLog.referred ? groupActivityLog.size : 0,
        date: moment(groupActivityLog.date).format().slice(0, 19).split('T')[0] + ' ' + moment(groupActivityLog.time).format().slice(0, 19).split('T')[1]
      })
        .then(() => {
          props.history.push(makeUrl('/'))
        })
        .catch(e => console.log(e))
        .then(() => setDisabled(false))
    } else {
      const missingFields = []
      Object.keys(validationErrors).map(field => {
        if (validationErrors[field].error)
          missingFields.push(field.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase()))
      })

      let subheader = 'Please complete all required fields! Check "' + missingFields.join('", "') + '"'
      subheader = subheader.split('Peer Helpers Id').join('Peer Helpers')
        .split('Experiences').join('Topics')
        .split('Type').join('Activity type')
        .split('Size').join('Number or students')
        .split('Help').join('How did you address')

      globalActions.notifications.showNotification({
        type: 'warning',
        header: 'Form validation error',
        subheader
      })
    }
  }

  const peerHelperOptions = usersList.map(user => {
    return { key: user.id, text: user.firstName + ' ' + user.lastName, value: user.id }
  })

  useEffect(() => {
    const user = usersList.find(option => option.id === loggedInUser.id)

    if (user && groupActivityLog.peerHelpers.length === 0) {
      setGroupActivityLog({ ...groupActivityLog, peerHelpers: [user.id] })
    }
  }, [usersList])

  return (
    <Segment>
      <RightMenuBar>
        <Header as='h3' style={dashboard.myProgramHeader}>{groupActivityLogId !== '0' ? 'Edit' : 'New'} Group Activity
          Log</Header>
        <RightMenuBarOptions>
          <RightMenuBarButton as={NavLink} to={makeUrl('/')}>
            <RightMenuBarButtonIcon name='close' style={{ fontSize: 20 }}/>
          </RightMenuBarButton>
        </RightMenuBarOptions>
      </RightMenuBar>

      <Divider/>

      <Form size='large'>
        <Form.Input label='1. Title of Activity' name='title' onChange={updateGroupActivityLogState}
                    value={groupActivityLog.title} required
                    error={validationErrors.title.error} placeholder='Title'/>
        <Form.Field name='date' required>
          <label>2. Date of Activity</label>
          <KeyboardDatePicker
            value={groupActivityLog.date || new Date()}
            onChange={date => onDateChange(date)}
            animateYearScrolling
            format='MM/DD/YY'
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position='end'>
                  <Icon name='caret down'/>
                </InputAdornment>
              )
            }}/>
        </Form.Field>
        <Form.Field name='date' required>
          <label>3. Time of Activity</label>
          <KeyboardTimePicker
            ampm
            value={groupActivityLog.time}
            onChange={time => onTimeChange(time)}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position='end'>
                  <Icon name='caret down'/>
                </InputAdornment>
              )
            }}/>
        </Form.Field>

        <Form.Dropdown name='peerHelpers' value={groupActivityLog.peerHelpers}
                       onChange={(e, data) => updateGroupActivityLogState(data)}
                       options={peerHelperOptions} required fluid multiple selection
                       label='4. Participating Peer Helpers'
                       error={validationErrors.peerHelpers.error}
                       placeholder='Choose one or more'/>
        <Form.Select label='5. What type of activity was this?' name='type'
                     onChange={(e, data) => updateGroupActivityLogState(data)}
                     value={groupActivityLog.type} required error={validationErrors.type.error}
                     options={typeOfActivityOptions} placeholder='Choose...'/>
        <Form.Select label='6. How did you address this group?' name='help'
                     onChange={(e, data) => updateGroupActivityLogState(data)}
                     value={groupActivityLog.help} required
                     error={validationErrors.help.error}
                     options={addressingTypeOptions} placeholder='Choose...'/>
        <Form.Dropdown label='7. Topics shared during this conversation' name='experiences'
                       onChange={(e, data) => updateGroupActivityLogState(data)}
                       value={groupActivityLog.experiences} fluid multiple selection required
                       error={validationErrors.experiences.error}
                       options={lifeExperiencesOptions}
                       renderLabel={renderLabel}
                       placeholder='Choose one or more'/>
        <Form.Select label='8. Where did you help this student?' name='location'
                     onChange={(e, data) => updateGroupActivityLogState(data)}
                     value={groupActivityLog.location}
                     required
                     error={validationErrors.location.error}
                     options={placeOptions} placeholder='Choose...'/>
        <Form.Select label='9. Were any students referred to a Program Coordinator / School Counselor:' name='referred'
                     onChange={(e, data) => updateGroupActivityLogState(data)}
                     value={groupActivityLog.referred}
                     required
                     options={[{ key: 'Yes', text: 'Yes', value: true }, { key: 'No', text: 'No', value: false }]}
                     placeholder='Choose...'/>
        {!!groupActivityLog.referred && (
          <Form.Input label='10. How many students'
                      name='size'
                      onChange={(e, data) => {
                        if (!isNaN(data.value))
                          updateGroupActivityLogState(data)
                      }}
                      value={groupActivityLog.size}
                      error={validationErrors.location.error}/>
        )}
        <div style={{ textAlign: 'right' }}>
          <Button.Group>
            <StyledTransparentButton onClick={save} disabled={disabled}>Save</StyledTransparentButton>
          </Button.Group>
        </div>
      </Form>
    </Segment>
  )
}

export default GroupActivityLogForm
