import React, { useEffect, useState } from 'react'
import { Header, Button, Segment, Form, Icon, Divider } from 'semantic-ui-react'
import {
  genderOptions,
  gradeOptions,
  helpTypeOptions,
  lifeExperiencesOptions,
  placeOptions,
  raceOptions, renderLabel
} from '../../Config/SelectOptions'
import useGlobal from '../../Store'
import useValidation from '../../Utils/Validations'
import { NavLink } from 'react-router-dom'
import InputAdornment from '@material-ui/core/InputAdornment'
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'
import useUrlBuilder from '../../Utils/Links'
import {
  RightMenuBar,
  RightMenuBarButton,
  RightMenuBarButtonIcon,
  RightMenuBarOptions,
  StyledTransparentButton
} from '../../Theme/Components'
import { dashboard } from '../../Theme/Styles'
import { editedAsNeededLog as defaultEditedAsNeededLog } from '../../Config/DefaultState'
import moment from 'moment'

const AsNeededSupportLogForm = (props) => {
  const asNeededLogId = props.match.params.id || '0'
  const [globalState, globalActions] = useGlobal()
  const { usersList, loggedInUser, currentSchool } = globalState
  const [asNeededSupportLog, setAsNeededSupportLog] = useState(defaultEditedAsNeededLog)
  const requiredFields = loggedInUser.role === 'Peer Helper' ? ['gender', 'races', 'grade', 'experiences', 'help', 'location'] : ['userId', 'gender', 'races', 'grade', 'experiences', 'help', 'location']
  const [validForm, validationErrors, onFieldChangeValidation] = useValidation(requiredFields, asNeededSupportLog)
  const [makeUrl] = useUrlBuilder(props.match.url)
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    globalActions.users.getUsers(0, { role: 'Peer Helper', schoolId: currentSchool, enabled: true }, 1000)
    globalActions.asNeededLogs.getAsNeededLog(asNeededLogId)
  }, [])

  useEffect(() => {
    globalActions.asNeededLogs.getAsNeededLog(asNeededLogId)
  }, [asNeededLogId])

  useEffect(() => {
    const { date } = globalState.editedAsNeededLog
    const dateObject = date !== '' ? new Date(date.split(' ').join('T')) : new Date()

    setAsNeededSupportLog({ ...globalState.editedAsNeededLog, date: dateObject, time: dateObject })
  }, [globalState.editedAsNeededLog])

  const updateAsNeededSupportLogState = e => {
    const { name, value } = typeof e.target !== 'undefined' ? e.target : e
    setAsNeededSupportLog({ ...asNeededSupportLog, [name]: value })
    onFieldChangeValidation(name, value)
  }

  const onDateChange = (date) => {
    setAsNeededSupportLog({ ...asNeededSupportLog, date })
  }

  const onTimeChange = (time) => {
    setAsNeededSupportLog({ ...asNeededSupportLog, time })
  }

  const save = () => {
    if (!currentSchool) {
      globalActions.notifications.showNotification({
        type: 'warning',
        header: 'Select School',
        subheader: 'Please select a school first!'
      })
    } else if (validForm) {
      setDisabled(true)
      globalActions.asNeededLogs.saveAsNeededLog({
        ...asNeededSupportLog,
        schoolId: currentSchool,
        userId: loggedInUser.role === 'Peer Helper' ? loggedInUser.id : asNeededSupportLog.userId,
        date: moment(asNeededSupportLog.date).format().slice(0, 19).split('T')[0] + ' ' + moment(asNeededSupportLog.time).format().slice(0, 19).split('T')[1]
      })
        .then(() => {
          props.history.push(makeUrl('/'))
        })
        .catch(e => console.log(e))
        .then(() => setDisabled(false))
    } else {
      const missingFields = []
      Object.keys(validationErrors).map(field => {
        if (validationErrors[field].error)
          missingFields.push(field.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase()))
      })

      let subheader = 'Please complete all required fields! Check "' + missingFields.join('", "') + '"'
      subheader = subheader.split('Experiences').join('Topics')
        .split('\"Help\"').join('\"How did you address\"')
        .split('User Id').join('Peer Helper')
        .split('Races').join('Races')

      globalActions.notifications.showNotification({ type: 'warning', header: 'Form validation error', subheader })
    }
  }

  const peerHelperOptions = usersList.map(user => {
    return { key: user.id, text: user.firstName + ' ' + user.lastName, value: user.id }
  })

  return (
    <Segment>
      <RightMenuBar>
        <Header as='h3' style={dashboard.myProgramHeader}>{asNeededLogId !== '0' ? 'Edit' : 'New'} Support Log</Header>
        <RightMenuBarOptions>
          <RightMenuBarButton as={NavLink} to={makeUrl('/')}>
            <RightMenuBarButtonIcon name='close' style={{ fontSize: 20 }}/>
          </RightMenuBarButton>
        </RightMenuBarOptions>
      </RightMenuBar>

      <Divider/>

      <Form size='large'>
        {loggedInUser.role === 'Peer Helper' ? (
          <Form.Input name='userId' value={loggedInUser.firstName + ' ' + loggedInUser.lastName} required disabled fluid
                      label='1. Peer helper'/>
        ) : (
          <Form.Select name='userId' value={asNeededSupportLog.peerHelper.id}
                       onChange={(e, data) => updateAsNeededSupportLogState(data)}
                       options={peerHelperOptions}
                       required
                       fluid label='1. Peer helper' error={validationErrors.userId.error}
                       placeholder='Select a Peer helper'/>
        )}
        <Form.Field name='date' required>
          <label>2. Date of Activity</label>
          <KeyboardDatePicker
            value={asNeededSupportLog.date}
            onChange={date => onDateChange(date)}
            animateYearScrolling
            format='MM/DD/YY'
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position='end'>
                  <Icon name='calendar outline'/>
                </InputAdornment>
              )
            }}/>
        </Form.Field>
        <Form.Field name='date' required>
          <label>3. Time of Activity</label>
          <KeyboardTimePicker
            ampm
            value={asNeededSupportLog.time}
            onChange={time => onTimeChange(time)}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position='end'>
                  <Icon name='clock outline'/>
                </InputAdornment>
              )
            }}/>
        </Form.Field>
        <Form.Select label='4. Gender of Student Helped' name='gender'
                     onChange={(e, data) => updateAsNeededSupportLogState(data)}
                     value={asNeededSupportLog.gender} required
                     error={validationErrors.gender.error}
                     options={genderOptions} placeholder='Gender'/>
        <Form.Dropdown label='5. Race of Student Helped' name='races' value={asNeededSupportLog.races}
                       onChange={(e, data) => updateAsNeededSupportLogState(data)} required fluid
                       multiple selection error={validationErrors.races.error}
                       options={raceOptions} placeholder='Choose one or more'/>
        <Form.Select label='6. Grade of Student Helped' name='grade'
                     onChange={(e, data) => updateAsNeededSupportLogState(data)}
                     value={asNeededSupportLog.grade} required
                     error={validationErrors.grade.error}
                     options={gradeOptions} placeholder='Grade'/>
        <Form.Dropdown label='7. Topics shared during this conversation' name='experiences'
                       onChange={(e, data) => updateAsNeededSupportLogState(data)}
                       value={asNeededSupportLog.experiences} fluid multiple selection required
                       error={validationErrors.experiences.error}
                       options={lifeExperiencesOptions.filter(option => !option.groupOnly)}
                       renderLabel={renderLabel}
                       placeholder='Choose one or more'/>
        <Form.Select label='8. How did you help this student?' name='help'
                     onChange={(e, data) => updateAsNeededSupportLogState(data)}
                     value={asNeededSupportLog.help}
                     required
                     error={validationErrors.help.error}
                     options={helpTypeOptions} placeholder='Choose...'/>
        <Form.Select label='9. Where did you help this student?' name='location'
                     onChange={(e, data) => updateAsNeededSupportLogState(data)}
                     value={asNeededSupportLog.location}
                     required
                     error={validationErrors.location.error}
                     options={placeOptions} placeholder='Choose...'/>
        <div style={{ textAlign: 'right' }}>
          <Button.Group>
            <StyledTransparentButton onClick={save} disabled={disabled}>Save</StyledTransparentButton>
          </Button.Group>
        </div>
      </Form>
    </Segment>
  )
}

export default AsNeededSupportLogForm
