import React, { useEffect, useState } from 'react'
import { Button, Divider, Grid, Header, Input, List, Modal, Segment } from 'semantic-ui-react'
import useGlobal from '../../Store'
import styled from 'styled-components'
import { RightMenuBarButton, RightMenuBarButtonIcon, StyledIconButton } from '../../Theme/Components'
import _ from 'lodash'
import { useCookies } from 'react-cookie'
import { NavLink } from 'react-router-dom'

const ArchivePage = (props) => {
  const [globalState, globalActions] = useGlobal()
  const [, setCookie] = useCookies()
  const [showArchivesModal, setShowArchivesModal] = useState()
  const { archives } = globalState
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    globalActions.archive.getArchives()
  }, [])

  return (
    <React.Fragment>
      <Grid reversed='mobile vertically' centered>
        <Grid.Column computer={12} table={10} mobile={16} reversed='mobile vertically'>
          <Header as='h2'>Admin Folder</Header>

          <Segment>
            <Grid style={{ marginLeft: window.innerWidth > 1000 ? '5rem' : 0 }}>
              <Grid.Row>
                <StyledColumn width={16}>
                  <Header as='h3'>Imports</Header>
                  <Divider />
                </StyledColumn>

                <StyledColumn computer={5} tablet={8} mobile={16} style={{height: 40}}>
                  <StyledFakeInput style={{ marginTop: 0 }} onClick={() => props.history.push('/import')}>
                    Import users
                  </StyledFakeInput>
                </StyledColumn>

                <StyledColumn computer={5} tablet={8} mobile={16}>
                  <StyledFakeInput style={{ marginTop: 0 }} onClick={() => props.history.push('/import-hierarchy')}>
                    Import hierarchy
                  </StyledFakeInput>
                </StyledColumn>

                <StyledColumn computer={5} tablet={8} mobile={16}>
                  {/* <StyledHiddenInput  /> */}
                  <StyledFakeInput style={{ marginTop: 0 }} onClick={() => props.history.push('/heirarchy-management/state')}>
                    Hierarchy Management
                    <StyledIconButton icon='users' style={{ position: 'absolute', right: 10, top: '50%', transform: 'translateY(-50%)' }} />
                  </StyledFakeInput>
                </StyledColumn>
              </Grid.Row>

              <Grid.Row>
                <StyledColumn width={16}>
                  <Header as='h3'>Programs</Header>
                  <Divider />
                </StyledColumn>

                <StyledColumn computer={5} tablet={8} mobile={16}>
                  <StyledReportsButton onClick={() => props.history.push('/dropbox')}>
                    Manage Programs
                    <StyledIconButton style={{ position: 'absolute', right: 10, top: '50%', transform: 'translateY(-50%)' }} />
                  </StyledReportsButton>
                </StyledColumn>
              </Grid.Row>

              <Grid.Row>
                <StyledColumn width={16}>
                  <Header as='h3'>Reports</Header>
                  <Divider />
                </StyledColumn>

                <StyledColumn computer={5} tablet={8} mobile={16}>
                  <StyledReportsButton onClick={() => props.history.push('/users-report')}>
                    Users report
                    <StyledIconButton style={{ position: 'absolute', right: 10, top: '50%', transform: 'translateY(-50%)' }} />
                  </StyledReportsButton>
                </StyledColumn>

                <StyledColumn computer={5} tablet={8} mobile={16}>
                  <StyledReportsButton onClick={() => props.history.push('/usage-report')}>
                    Usage report
                    <StyledIconButton style={{ position: 'absolute', right: 10, top: '50%', transform: 'translateY(-50%)' }} />
                  </StyledReportsButton>
                </StyledColumn>
              </Grid.Row>

              {window.location.host.indexOf('http://peerhelpersportal.com/') === -1 && (
                <React.Fragment>
                  <Grid.Row>
                    <StyledColumn width={16}>
                      <Header as='h3'>Surveys</Header>
                      <Divider />
                    </StyledColumn>

                    <StyledColumn computer={5} tablet={8} mobile={16}>
                      <StyledReportsButton onClick={() => props.history.push('/surveys')}>
                        Surveys list
                        <StyledIconButton style={{ position: 'absolute', right: 10, top: '50%', transform: 'translateY(-50%)' }} />
                      </StyledReportsButton>
                    </StyledColumn>
                  </Grid.Row>

                  <Grid.Row>
                    <StyledColumn width={16}>
                      <Header as='h3'>Archives</Header>
                      <Divider />
                    </StyledColumn>

                    <StyledColumn computer={5} tablet={8} mobile={16}>
                      <StyledReportsButton onClick={() => setModalOpen(true)}>
                        Archive now
                        <StyledIconButton style={{ position: 'absolute', right: 10, top: '50%', transform: 'translateY(-50%)' }} />
                      </StyledReportsButton>
                      <Modal open={modalOpen} onClose={() => setModalOpen(false)} closeIcon>
                        <Modal.Header>Archive now</Modal.Header>
                        <Modal.Content>
                          <p>Are you sure you want to archive now ?</p>
                        </Modal.Content>
                        <Modal.Actions>
                          <Button negative onClick={() => setModalOpen(false)}>No</Button>
                          <Button positive icon='checkmark' labelPosition='right' content='Yes' onClick={() => {
                            globalActions.archive.archiveNow()
                            setModalOpen(false)

                            setTimeout(() => {
                              globalActions.archive.getArchives()
                            }, 1000)
                          }} />
                        </Modal.Actions>
                      </Modal>
                    </StyledColumn>

                    <StyledColumn computer={5} tablet={8} mobile={16}>
                      <StyledReportsButton onClick={() => setShowArchivesModal(true)}>
                        View archives
                        <StyledIconButton style={{ position: 'absolute', right: 10, top: '50%', transform: 'translateY(-50%)' }} />
                      </StyledReportsButton>
                    </StyledColumn>

                    <StyledColumn computer={5} tablet={8} mobile={16}>
                      <StyledReportsButton onClick={() => globalActions.archive.selectArchive(setCookie, '')}>
                        Return to current data
                        <StyledIconButton style={{ position: 'absolute', right: 10, top: '50%', transform: 'translateY(-50%)' }} />
                      </StyledReportsButton>
                    </StyledColumn>
                  </Grid.Row>
                </React.Fragment>
              )}
            </Grid>
          </Segment>
        </Grid.Column>
      </Grid>

      <Modal open={showArchivesModal} closeIcon onClose={() => setShowArchivesModal(false)}>
        <Header content='Select an archive' />
        <Modal.Content>
          <List divided verticalAlign='middle'>
            {_.map(archives, archive => (
              <List.Item>
                <List.Content>
                  <List.Header as='a' href='javascript:void(0)' onClick={() => {
                    globalActions.archive.selectArchive(setCookie, archive.name)
                    setShowArchivesModal(false)
                  }}>
                    {new Date(archive.name.toUpperCase().split('T')[0].replace(/_/g, '-') + 'T' + archive.name.toUpperCase().split('T')[1].replace(/_/g, ':')).toLocaleString('en-US')}
                  </List.Header>
                </List.Content>
              </List.Item>
            ))}
          </List>
        </Modal.Content>
      </Modal>
    </React.Fragment>
  )
}

const StyledColumn = styled(Grid.Column)`
  position: relative;
  width: 50% !important;
`

const StyledHiddenInput = styled(Input)`
  opacity: 0;
  z-index: 2;
  height: 47px;
`

const StyledFakeInput = styled.div`
  cursor: pointer !important;
  position: absolute;
  top: 0;
  margin-top: 1rem;
  width: 260px;
  background-color: rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  height: 47px;
  color: black;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 16px;
  padding-right: 30px;
  padding-left: 20px;
  word-wrap: break-word;
`
const StyledButton = styled(Button)`
  margin-top: 0 !important;
  width: 260px !important;
  background-color: rgba(0, 0, 0, 0.16) !important;
  border-radius: 20px !important;
  height: 47px !important;
  color: black !important;
  z-index: 1 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
  font-size: 16px !important;
  font-weight: normal !important;
  padding-right: 30px !important;
  padding-left: 20px !important;
  word-wrap: break-word !important;
`

const StyledReportsButton = styled(StyledButton)`
  background: rgb(117, 60, 190) !important;
  color: white !important;
`

export default ArchivePage
