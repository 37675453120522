import { Button, Icon, Table, Item } from 'semantic-ui-react'
import styled from 'styled-components'

export const StyledIconButton = styled(Button)`
  padding: 0 !important;
  background-color: transparent !important;
  border-width: 0 !important;
  color: black;
  z-index: 3;
`

export const StyledTransparentButton = styled(Button)`
  font-weight: normal !important;
  font-size: 14px;
  text-transform: uppercase !important;
  background-color: transparent !important;
  border-width: 0 !important;
  color: #2076BC !important;
  position: relative;
`

export const StyledLogQuestionHeader = styled(Item.Header)`
  font-size: 12px !important;
  color: rgba(0, 0, 0, 0.34) !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
`

export const StyledLogQuestionAnswer = styled(Item.Description)`
  color: #753CBE !important;
  font-size: 14px !important;
  margin-left: 16px !important;
`

export const RightMenuBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between
`

export const RightMenuBarOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end
`

export const RightMenuBarButton = styled(StyledIconButton)`
  margin-right: 10px;
`
// height: 16px
export const RightMenuBarButtonIcon = styled(Icon)`
  font-size: 15px;
  color: #4D4D4D
`

export const RightMenuBarTrashButton = styled(StyledIconButton)`
  margin-right: 10px;
  height: 16px;
  cursor: pointer
`

export const StyledTableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center
`

export const StyledTable = styled(Table)`
  margin-top: 0 !important;
`

export const StyledHeaderCell = styled(Table.HeaderCell)`
  font-size: 14px !important;
  color: rgba(0,0,0,0.40) !important;
  padding-bottom: 0 !important;
  font-weight: normal !important;
`

export const StyledProfileModalImages = styled.div`
  flex-shrink: 1;
  padding: 1rem 0 2rem 0;
`
