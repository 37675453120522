import axios from 'axios/index'
import endpoints, { baseUrl } from '../Config/ApiEndpoints'
import { processRequestErrorNotification } from '../Utils/Error'

export const getResourceFromArchive = async (store, url, headers) => {
  const currentArchive = store.state.currentArchive
  let formattedUrl = url
  if (currentArchive !== undefined && currentArchive !== '') {
    if (url.indexOf('?search=') > -1) {
      formattedUrl = formattedUrl.replace('?search=', '?search=archive:' + currentArchive + ',')
    } else {
      formattedUrl = url.replace(baseUrl, baseUrl + '/archives/' + currentArchive)
    }
  }

  // remove extra comma
  formattedUrl = formattedUrl.replace(/,\s*$/, '').replace(/,&/, '&')
  // remove search if empty
  formattedUrl = formattedUrl.replace(/\/search\?search=$/, '').replace(/\/search\?search=&/, '?')

  return await axios.get(formattedUrl, headers)
}

export const selectArchive = async (store, setCookie, currentArchive) => {
  const notification = {
    type: 'success',
    header: currentArchive !== '' ? 'Archived selected' : 'Successfully restored to current data'
  }

  await setCookie('currentArchive', currentArchive)
  store.setState({ currentArchive, notification })
}

export const setArchive = (store, currentArchive) => {
  store.setState({ currentArchive })
}

export const getArchives = async (store) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  store.setState({ requestStatus: 'LOADING' })
  try {
    const response = await axios.get(endpoints.archive.archive, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
    const { archives } = response.data._embedded

    store.setState({ requestStatus: 'SUCCESS', archives })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ archives: [], requestStatus: 'FAILED', notification })
  }
}

export const archiveNow = async (store) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  store.setState({ requestStatus: 'LOADING' })
  try {
    await axios.post(endpoints.archive.archive, { name: new Date().toISOString().split('.')[0].replace(/[^0-9a-zA-Z]/g, '_') }, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
    const notification = { type: 'success', header: 'Data archived!' }

    setTimeout(() => {
      store.setState({ requestStatus: 'SUCCESS', notification })
    }, 500)
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ requestStatus: 'FAILED', notification })
  }
}

export const saveHierarchy = async (store, file) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  store.setState({ requestStatus: 'LOADING' })
  try {
    await axios.post(endpoints.archive.hierarchy, file, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
    const notification = { type: 'success', header: 'Hierarchy imported' }

    setTimeout(() => {
      store.setState({ requestStatus: 'SUCCESS', notification })
    }, 500)
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ requestStatus: 'FAILED', notification })
  }
}

export const saveUsers = async (store, file) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  store.setState({ requestStatus: 'LOADING' })
  try {
    await axios.post(endpoints.archive.users, file, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })

    setTimeout(() => {
      store.setState({ requestStatus: 'SUCCESS' })
    }, 500)
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ requestStatus: 'FAILED', notification })
  }
}
