import axios from 'axios'
import endpoints from '../Config/ApiEndpoints'
import { processRequestErrorNotification } from '../Utils/Error'
import { editedGroupActivityLog as defaultEditedGroupActivityLog } from '../Config/DefaultState'
import { getResourceFromArchive } from './Archive'

export const getGroupActivityLogs = async (store, page = 0, size = 1000, type = '') => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  let query = []
  const filtersWithSchool = {
    type,
    stateId: store.state.currentState,
    districtId: store.state.currentDistrict,
    schoolId: store.state.currentSchool
  }
  Object.keys(filtersWithSchool).forEach(key => {
    if (filtersWithSchool[key] !== undefined && ((typeof filtersWithSchool[key] === 'string' && filtersWithSchool[key] !== '') || filtersWithSchool[key].length > 0)) {
      if (typeof filtersWithSchool[key] !== 'string' && filtersWithSchool[key].length > 0) {
        filtersWithSchool[key].map(filter => {
          query.push(`${key}:${filter}`)
        })
      } else {
        query.push(`${key}:${filtersWithSchool[key]}`)
      }
    }
  })

  const url = endpoints.logs.groupActivityLogs + `/search?search=${query.join(',')}&page=${page}&size=${size}`

  store.setState({ requestStatus: 'LOADING' })
  try {
    const response = await getResourceFromArchive(store, url, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
    const { logs } = response.data._embedded
    const groupActivityLogsPagination = response.data.page

    store.setState({ requestStatus: 'SUCCESS', groupActivityLogs: logs, groupActivityLogsPagination })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ groupActivityLogs: [], requestStatus: 'FAILED', notification })
  }
}

export const getGroupActivityLog = async (store, groupActivityLogId) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  store.setState({ requestStatus: 'LOADING' })

  try {
    const response = await getResourceFromArchive(store, endpoints.logs.root + '/' + groupActivityLogId, {
      headers: {
        Authorization:
          `Bearer ${store.state.loggedInUser.accessToken}`
      }
    })
    const editedGroupActivityLog = response.data
    editedGroupActivityLog.peerHelpersId = editedGroupActivityLog.peerHelpers.map(peerHelper => peerHelper.id)

    store.setState({ requestStatus: 'SUCCESS', editedGroupActivityLog })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ editedGroupActivityLog: defaultEditedGroupActivityLog, requestStatus: 'FAILED', notification })
  }
}

export const saveGroupActivityLog = async (store, log) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  let url = endpoints.logs.groupActivityLogs
  store.setState({ requestStatus: 'LOADING' })

  try {
    if (log.id === '') {
      await axios.post(url, { ...log, schoolId: store.state.currentSchool }, {
        headers: {
          Authorization:
            `Bearer ${store.state.loggedInUser.accessToken}`
        }
      })
    } else {
      await axios.patch(url + '/' + log.id, log, {
        headers: {
          Authorization:
            `Bearer ${store.state.loggedInUser.accessToken}`
        }
      })
    }
    const notification = {
      type: 'success', header:
        `Log ${log.id === '' ? 'added' : 'updated'}`
    }
    setTimeout(() => {
      store.setState({ requestStatus: 'SUCCESS', notification })
    }, 500)
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ requestStatus: 'FAILED', notification })
  }
}

export const deleteGroupActivityLog = async (store, groupActivityLogId) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  store.setState({ requestStatus: 'LOADING' })

  try {
    await axios.delete(endpoints.logs.groupActivityLogs + '/' + groupActivityLogId, {
      headers: {
        Authorization:
          `Bearer ${store.state.loggedInUser.accessToken}`
      }
    })

    store.setState({ requestStatus: 'SUCCESS' })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ requestStatus: 'FAILED', notification })
  }
}
