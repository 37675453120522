export const requestStatus = 'INITIAL'
export const dashboardRequestStatus = 'LOADING'

export const roles = []

export const loggedInUser = {
  id: '',
  firstName: '',
  lastName: '',
  username: '',
  gender: '',
  race: [],
  grade: '',
  email: '',
  phone: '',
  role: '',
  schools: [],
}

export const hierarchy = []

export const usersRequestStatus = 'INITIAL'
export const schoolLevel = []
export const usersList = []
export const usersPagination = {
  size: 20,
  totalElements: 0,
  totalPages: 0,
  number: 0,
}

export const archives = []
export const currentArchive = ''

export const surveyId = ''
export const survey = { id: '0', body: '[]' }
export const surveys = []
export const surveysPagination = {
  size: 20,
  totalElements: 0,
  totalPages: 0,
  number: 0,
}

export const defaultMeUser = {
  id: '',
  avatar: '',
  firstName: '',
  lastName: '',
  username: '',
  gender: '',
  grades: [],
  race: [],
  academic: [],
  interests: [],
  experiences: [],
}

export const editedUser = {
  firstName: '',
  lastName: '',
  username: '',
  email: '',
  password: '',
  roles: [],
  state: '',
  district: '',
  schools: [],
}

export const editedSchool = {
  id: '',
  name: '',
  districtId: '',
  stateId: '',
  level: '',
  grades: ''
}
export const editState = {
  id: '',
  name: '',
}
export const editDistrictLable = 'District'
export const editDistrict = {
  id: '',
  name: '',
  state: '',
}

export const matchResultsRequestStatus = 'INITIAL'
export const matchResults = []
export const matchResultsPagination = {
  size: 20,
  totalElements: 0,
  totalPages: 0,
  number: 0,
}

export const tutorials = []
export const guide = {
  id: '',
  title: '',
  url: '',
  description: '',
}
export const notifications = []
export const notification = {
  show: false,
  header: '',
  subheader: '',
  type: 'warning',
}

export const notificationCount = 0

export const dashboardStatistics = {
  topics: { support: ['Topic 1'], prevention: [] },
  referrals: { count: '', supportPercentage: '', preventionPercentage: '' },
  groupActivities: {
    count: '',
    supportPercentage: '',
    preventionPercentage: '',
    meditationPercentage: '',
    support: '',
    prevention: '',
    meditation: '',
  },
  asNeededSupport: { count: '', topics: [] },
  onGoingSupport: { count: '', initial: '', midpoint: '', final: '' },
  peerHelpers: { count: '', onGoingStudents: '', logsCompleted: '' },
  programs: { schools: '', peerHelpers: '' },
  assignedStudents: { count: '' },
}
export const topics = {}

export const schools = []
export const userManagementSchools = []
export const currentSchool = ''

export const superUserSchools = []
export const superUserSchoolsPagination = {
  size: 20,
  totalElements: 0,
  totalPages: 0,
  number: 0,
}

export const districtsSchools = []

export const districts = []
export const userManagementDistricts = []
export const currentDistrict = ''

export const states = []
export const userManagementStates = []
export const currentState = ''

export const levels = []
export const currentLevel = ''

export const myProgram = []
export const program = {}

export const programs = []

export const myGrades = []

export const editedStudent = {
  id: '',
  firstName: '',
  lastInitial: '',
  assignedTo: '',
  gender: '',
  races: [],
  grade: '',
  academics: [],
  interests: [],
  experiences: [],
  endDate: '',
  schoolId: '',
  initialAssessment: { id: 0 },
  midpointAssessment: { id: 0 },
  finalAssessment: { id: 0 },
}

export const students = []
export const studentsPagination = {
  size: 20,
  totalElements: 0,
  totalPages: 0,
  number: 0,
}

export const editedAsNeededLog = {
  id: '',
  userId: '',
  peerHelper: {},
  date: '',
  gender: '',
  races: [],
  grade: '',
  experiences: [],
  help: '',
  location: '',
  schoolId: '',
}
export const editedAssist = {
  id: '',
  date: '',
  time: '',
  name: '',
  gender: '',
  races: [],
  grade: '',
  assist: '',
  experiences: [],
  help: '',
}
export const assists = []
export const assistsPagination = {
  size: 20,
  totalElements: 0,
  totalPages: 1,
  number: 0,
}

export const asNeededLogs = []
export const asNeededSupportLogsPagination = {
  size: 20,
  totalElements: 0,
  totalPages: 0,
  number: 0,
}

export const groupActivityLogs = []
export const groupActivityLogsPagination = {
  size: 20,
  totalElements: 0,
  totalPages: 0,
  number: 0,
}

export const editedGroupActivityLog = {
  id: '',
  title: '',
  date: '',
  peerHelpers: [],
  peerHelpersId: [],
  type: '',
  size: 1,
  experiences: [],
  help: '',
  location: '',
  referred: false,
}

export const editedAssessment = {
  id: '',
  studentId: '',
  assessmentType: '',
  date: '',
  socialSkills: '',
  emotionalState: '',
  riskBehavior: '',
  referred: false,
}

export const assessments = []

export const usageReport = []

export const usageReportPagination = {
  size: 20,
  totalElements: 0,
  totalPages: 0,
  number: 0,
}

export const logsPagination = {
  size: 20,
  totalElements: 0,
  totalPages: 0,
  number: 0,
}

export const demographicData = {
  genders: [],
  grades: [],
  races: [],
}

export const editedStudentReport = {
  id: '',
  points: [],
  firstName: '',
  lastInitial: '',
  initialTargetDate: '',
  midpointTargetDate: '',
  finalTargetDate: '',
  logs: [],
}

export const editedOnGoingSupportLog = {
  id: '',
  date: '',
  location: '',
  studentActivity: '',
  classWorkType: '',
  experiences: [],
  referred: false,
}

export const onGoingSupportLogs = []

export const preventionTopicsReport = []

export const supportTopicsReport = []
