import axios from 'axios'
import endpoints from '../Config/ApiEndpoints'
import { processRequestErrorNotification } from '../Utils/Error'
import { editedAsNeededLog as defaultEditedAsNeededLog } from '../Config/DefaultState'
import { getResourceFromArchive } from './Archive'

export const getAsNeededLogs = async (store, page = 0, size = 1000) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  const { currentState, currentDistrict, currentSchool } = store.state

  let search = `/search?search=`
  if (currentSchool !== '') {
    search += `schoolId:${currentSchool}`
  } else if (currentDistrict !== '') {
    search += `districtId:${currentDistrict}`
  } else if (currentState !== '') {
    search += `stateId:${currentState}`
  }
  const url = endpoints.logs.asNeededLogs + search + `&page=${page}&size=${size}`

  store.setState({ requestStatus: 'LOADING' })
  try {
    const response = await getResourceFromArchive(store, url, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
    const { logs } = response.data._embedded
    const asNeededSupportLogsPagination = response.data.page

    store.setState({ requestStatus: 'SUCCESS', asNeededLogs: logs, asNeededSupportLogsPagination })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ asNeededLogs: [], requestStatus: 'FAILED', notification })
  }
}

export const getAsNeededLog = async (store, asNeededLogId) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  store.setState({ requestStatus: 'LOADING' })
  try {
    const response = await getResourceFromArchive(store, endpoints.logs.root + '/' + asNeededLogId, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
    const editedAsNeededLog = response.data
    editedAsNeededLog.userId = response.data.peerHelper.id

    store.setState({ requestStatus: 'SUCCESS', editedAsNeededLog })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ editedAsNeededLog: defaultEditedAsNeededLog, requestStatus: 'FAILED', notification })
  }
}

export const saveAsNeededLog = async (store, log) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  let url = endpoints.logs.asNeededLogs
  store.setState({ requestStatus: 'LOADING' })

  try {
    if (log.id === '') {
      await axios.post(url, {
        ...log,
        schoolId: store.state.currentSchool
      }, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
    } else {
      await axios.patch(url + '/' + log.id, log, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
    }
    const notification = { type: 'success', header: `Log ${log.id === '' ? 'added' : 'updated'}` }
    setTimeout(() => {
      store.setState({ requestStatus: 'SUCCESS', notification })
    }, 500)
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ requestStatus: 'FAILED', notification })
  }
}

export const deleteAsNeededLog = async (store, asNeededLogId) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  store.setState({ requestStatus: 'LOADING' })

  try {
    await axios.delete(endpoints.logs.asNeededLogs + '/' + asNeededLogId, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })

    store.setState({ requestStatus: 'SUCCESS' })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ requestStatus: 'FAILED', notification })
  }
}
