import { Icon } from 'semantic-ui-react'
import React from 'react'

const MyProgramNoPrograms = () => {
  return (
    <div>
      <p style={{ textAlign: 'center', marginTop: '1rem' }}><Icon name='book' size='big' disabled /></p>
      <p style={{ color: 'rgba(0, 0, 0, 0.38)', textAlign: 'center' }}> No programs </p>
    </div>
  )
}

export default MyProgramNoPrograms
