import React, { useEffect, useState } from 'react'
import { Header, Icon, Item, Pagination, Popup, Responsive, Table } from 'semantic-ui-react'
import { dashboard } from '../../Theme/Styles'
import _ from 'lodash'
import { NavLink } from 'react-router-dom'
import useGlobal from '../../Store'
import useUrlBuilder from '../../Utils/Links'
import { StyledTable, StyledTableHeader, StyledTransparentButton } from '../../Theme/Components'
import { NoPrint } from 'react-easy-print'

const StudentsProgressionTable = (props) => {
  const [globalState, globalActions] = useGlobal()
  const { currentState, currentDistrict, currentSchool, loggedInUser } = globalState
  const [students, setStudents] = useState(globalState.students)
  const [makeUrl] = useUrlBuilder(props.match.url)

  const calculateSocialSkills = (socialSkills) => {
    let response
    switch (socialSkills) {
      case 'HIGH':
        response = 5
        break
      case 'AVEREGE':
        response = 4
        break
      case 'OCCASIONALLY':
        response = 3
        break
      case 'OFTEN':
        response = 2
        break
      case 'NEVER':
        response = 1
        break
      default:
        response = null
    }

    return response
  }

  const calculateEmotionalState = (emotionalState) => {
    let response
    switch (emotionalState) {
      case 'JOY':
        response = 5
        break
      case 'HOPE':
        response = 4
        break
      case 'NEUTRAL':
        response = 3
        break
      case 'IMPATIENT':
        response = 2
        break
      case 'GUILT':
        response = 1
        break
      default:
        response = null
    }

    return response
  }

  const calculateRiskBehaviour = (riskBehavior) => {
    let response
    switch (riskBehavior) {
      case 'ACTIVELY':
        response = 5
        break
      case 'OCCASIONALLY':
        response = 4
        break
      case 'THOUGHTS':
        response = 3
        break
      case 'EXPOSED':
        response = 2
        break
      case 'NO':
        response = 1
        break
      default:
        response = null
    }

    return response
  }

  const checkMidpointProgression = (initialAssessment, midpointAssessment) => {
    let ok = true
    if (!midpointAssessment.id) return ok
    if (calculateSocialSkills(initialAssessment.socialSkills) > calculateSocialSkills(midpointAssessment.socialSkills)) ok = false
    if (calculateEmotionalState(initialAssessment.emotionalState) > calculateEmotionalState(midpointAssessment.emotionalState)) ok = false
    if (calculateRiskBehaviour(initialAssessment.riskBehavior) < calculateRiskBehaviour(midpointAssessment.riskBehavior)) ok = false
    return ok
  }

  const checkFinalProgression = (midpointAssessment, finalAssessment) => {
    let ok = true
    if (!finalAssessment.id) return ok
    if (calculateSocialSkills(midpointAssessment.socialSkills) > calculateSocialSkills(finalAssessment.socialSkills)) ok = false
    if (calculateEmotionalState(midpointAssessment.emotionalState) > calculateEmotionalState(finalAssessment.emotionalState)) ok = false
    if (calculateRiskBehaviour(midpointAssessment.riskBehavior) < calculateRiskBehaviour(finalAssessment.riskBehavior)) ok = false
    return ok
  }

  useEffect(() => {
    setStudents(globalState.students)
  }, [globalState.students])

  const getStudents = () => {
    setTimeout(() => {
      globalActions.students.getStudents(0, 10000)
    }, 500)
  }

  useEffect(getStudents, [])

  useEffect(getStudents, [props.location, currentState, currentDistrict, currentSchool])

  return (
    <React.Fragment>
      <StyledTableHeader>
        <Header as='h2' style={dashboard.phHeader}>
          <strong>Ongoing Support</strong> Students and Progression
        </Header>
        {!['School Level', 'State Level', 'District Level'].includes(loggedInUser.role) && (
          <StyledTransparentButton as={NavLink} to={makeUrl(`/students/0/edit`)}>
            <Icon name='plus'/>
          </StyledTransparentButton>
        )}
      </StyledTableHeader>

      <StyledTable compact striped size='small'>
        <Table.Header className="stickyTh">
          <Responsive as={Table.Row} minWidth={768}>
            <Table.HeaderCell textAlign='center'>
              Student
            </Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>
              Peer Helper
            </Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>
              #Logs
            </Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>
              Initial
            </Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>
              Midpoint
            </Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>
              Final
            </Table.HeaderCell>
            {!['School Level', 'State Level', 'District Level'].includes(loggedInUser.role) && (
              <Table.HeaderCell/>
            )}
            <Table.HeaderCell/>
          </Responsive>
        </Table.Header>

        <Table.Body>
          {_.map(students, ({ id, firstName, lastInitial, assignedTo, logs, initialAssessment, midpointAssessment, finalAssessment, supportEnded }) => (
            <Table.Row key={firstName + id}>
              <Table.Cell textAlign='center'>
                <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                  Student:
                </Responsive>
                {['State Level', 'District Level', 'School Level'].includes(loggedInUser.role) ? (
                  <NavLink to={makeUrl(`/students/${id}/view`)}
                           style={{ color: initialAssessment.id ? ((checkMidpointProgression(initialAssessment, midpointAssessment) && checkFinalProgression(midpointAssessment, finalAssessment)) ? 'green' : 'red') : 'black' }}>
                    {firstName + ' ' + lastInitial + '.'}
                  </NavLink>
                ) : (
                  <NavLink to={makeUrl(`/students/${id}/edit`)}
                           style={{ color: initialAssessment.id ? ((checkMidpointProgression(initialAssessment, midpointAssessment) && checkFinalProgression(midpointAssessment, finalAssessment)) ? 'green' : 'red') : 'black' }}>
                    {firstName + ' ' + lastInitial + '.'}
                  </NavLink>
                )}
              </Table.Cell>

              <Table.Cell textAlign='center'>
                <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                  Peer Helper:
                </Responsive>
                {assignedTo ? assignedTo.firstName + ' ' + assignedTo.lastName : ''}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                  Logs:
                </Responsive>
                {logs ? logs.length : '0'}
              </Table.Cell>

              <Table.Cell textAlign='center'>
                <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                  Initial:
                </Responsive>
                {initialAssessment.id ? (
                  <Item as={NavLink} to={makeUrl(`/assessments/${id}/view/initialAssessment`)}>
                    <Icon name='check' color='green'/>
                  </Item>
                ) : (!['Peer Helper', 'Coordinator', 'Super User'].includes(loggedInUser.role) || supportEnded) ? (
                  <Item style={{ color: 'rgba(0, 0, 0, 0.38)', fontSize: '24px' }}> + </Item>
                ) : (
                  <Item as={NavLink} to={makeUrl(`/assessments/${id}/initialAssessment`)}
                        style={{ color: '#2076BC', fontSize: '24px' }}> + </Item>)}
              </Table.Cell>

              <Table.Cell textAlign='center'>
                <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                  Midpoint:
                </Responsive>
                {midpointAssessment.id ? (
                  <Item as={NavLink} to={makeUrl(`/assessments/${id}/view/midpointAssessment`)}>
                    <Icon name='check'
                          color={checkMidpointProgression(initialAssessment, midpointAssessment) ? 'green' : 'red'}/>
                  </Item>
                ) : (!['Peer Helper', 'Coordinator', 'Super User'].includes(loggedInUser.role) || supportEnded) ? (
                  <Item style={{ color: 'rgba(0, 0, 0, 0.38)', fontSize: '24px' }}> + </Item>
                ) : (initialAssessment.id ? (
                  <Item as={NavLink} to={makeUrl(`/assessments/${id}/midpointAssessment`)}
                        style={{ color: '#2076BC', fontSize: '24px' }}> + </Item>
                ) : (
                  <Popup content='Add initial assessment first'
                         trigger={<Item style={{ color: '#2076BC', fontSize: '24px' }}> + </Item>}/>
                ))}
              </Table.Cell>

              <Table.Cell textAlign='center'>
                <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                  Final:
                </Responsive>
                {finalAssessment.id ?
                  (
                    <Item as={NavLink} to={makeUrl(`/assessments/${id}/view/finalAssessment`)}>
                      <Icon name='check'
                            color={checkFinalProgression(midpointAssessment, finalAssessment) ? 'green' : 'red'}/>
                    </Item>
                  ) : (!['Peer Helper', 'Coordinator', 'Super User'].includes(loggedInUser.role) || supportEnded) ? (
                    <Item style={{ color: 'rgba(0, 0, 0, 0.38)', fontSize: '24px' }}> + </Item>
                  ) : (midpointAssessment.id ? (
                    <Item as={NavLink} to={makeUrl(`/assessments/${id}/finalAssessment`)}
                          style={{ color: '#2076BC', fontSize: '24px' }}> + </Item>
                  ) : (
                    <Popup content='Add midpoint assessment first'
                           trigger={<Item style={{ color: '#2076BC', fontSize: '24px' }}> + </Item>}/>
                  ))}
              </Table.Cell>

              <NoPrint force>
                {!['School Level', 'State Level', 'District Level'].includes(loggedInUser.role) && (
                  <Table.Cell textAlign='center'>
                    {supportEnded ? (
                      <Item style={{ color: 'rgba(0,0,0, 0.38)' }}>
                        ADD LOG 
                      </Item>
                    ) : (
                      <Item as={NavLink} to={makeUrl(`/continual-support/0/edit/${id}`)} style={{ color: '#2076BC' }}>
                        ADD LOG
                      </Item>
                    )}
                  </Table.Cell>
                )}

                <Table.Cell textAlign='center'>
                  <Item as={NavLink} to={makeUrl(`/students/${id}/report`)} style={{ color: '#2076BC' }}>
                    VIEW REPORT
                  </Item>
                </Table.Cell>
              </NoPrint>
            </Table.Row>
          ))}
        </Table.Body>
      </StyledTable>
    </React.Fragment>
  )
}

export default StudentsProgressionTable
