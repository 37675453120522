import axios from 'axios'
import endpoints from '../Config/ApiEndpoints'
import { processRequestErrorNotification } from '../Utils/Error'
import {
  editedStudent as defaultEditedStudent,
  editedStudentReport as defaultEditedStudentReport
} from '../Config/DefaultState'
import { getResourceFromArchive } from './Archive'

export const getStudents = async (store, page = 0, size = 20) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  const { currentState, currentDistrict, currentSchool } = store.state

  let search = `/search?search=`
  if (currentSchool !== '') {
    search += `schoolId:${currentSchool}`
  } else if (currentDistrict !== '') {
    search += `districtId:${currentDistrict}`
  } else if (currentState !== '') {
    search += `stateId:${currentState}`
  }

  let url = endpoints.users.students + search + `&page=${page}&size=${size}`
  store.setState({ requestStatus: 'LOADING' })
  try {
    const response = await getResourceFromArchive(store, url, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
    const { students } = response.data._embedded
    const studentsPagination = response.data.page

    store.setState({ requestStatus: 'SUCCESS', students, studentsPagination })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ students: [], requestStatus: 'FAILED', notification })
  }
}

export const getStudent = async (store, studentId) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  let url = endpoints.users.students
  store.setState({ requestStatus: 'LOADING' })

  try {
    const response = await getResourceFromArchive(store, url + '/' + studentId, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
    const editedStudent = response.data

    store.setState({ requestStatus: 'SUCCESS', editedStudent })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ editedStudent: defaultEditedStudent, requestStatus: 'FAILED', notification })
  }
}

export const saveStudent = async (store, student) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  let url = endpoints.users.students
  store.setState({ requestStatus: 'LOADING' })
  try {
    const newStudent = student.id === undefined || student.id === null || student.id === ''
    if (newStudent) {
      const { currentSchool } = store.state
      await axios.post(url, {
        ...student,
        schoolId: currentSchool
      }, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
    } else {
      await axios.patch(url + '/' + student.id, student, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
    }
    const notification = { type: 'success', header: `Student ${newStudent ? 'added' : 'updated'}` }
    setTimeout(() => {
      store.setState({ requestStatus: 'SUCCESS', notification })
    }, 500)
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ requestStatus: 'FAILED', notification })
  }
}

export const studentSupportEnded = async (store, studentId, ended = true) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  let url = endpoints.users.students
  store.setState({ requestStatus: 'LOADING' })

  try {
    await axios.patch(url + '/' + studentId + '/support', { 'supportEnded': ended }, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })

    store.setState({ requestStatus: 'SUCCESS' })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ editedStudent: defaultEditedStudent, requestStatus: 'FAILED', notification })
  }
}

export const getStudentReport = async (store, studentReportId) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  let url = endpoints.users.students + '/' + studentReportId
  store.setState({ requestStatus: 'LOADING' })

  try {
    const response = await getResourceFromArchive(store, url, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
    const editedStudentReport = response.data

    store.setState({ requestStatus: 'SUCCESS', editedStudentReport })
  } catch (error) {
    const notification = processRequestErrorNotification(error)
    store.setState({ editedStudentReport: defaultEditedStudentReport, requestStatus: 'FAILED', notification })
  }
}
