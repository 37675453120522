import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import React from 'react'
import { Header, Segment } from 'semantic-ui-react'
import { dashboard } from '../Theme/Styles'
import styled from 'styled-components'

const COLORS = ['#753CBE', '#0088FE', '#3CBEB9', '#F58127', '#BE3CA5']

const StackBar = (props) => {
  console.log(props.data)
  return (
    <React.Fragment>
      <Header as="h2" style={{ ...dashboard.phHeader, marginBottom: 0, paddingBottom: 0 }}>{props.title}</Header>

      <StyledCardContent>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            layout="vertical"
            data={props.data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis   allowDecimals={false} type="number" />
            <YAxis width={450} dataKey="name" type="category"/>
            <Tooltip/>
            <Legend/>
            <Bar dataKey="Not sure at all" stackId="a" fill={COLORS[0]}/>
            <Bar dataKey="A little sure" stackId="a" fill={COLORS[1]}/>
            <Bar dataKey="Fairly sure" stackId="a" fill={COLORS[2]} />
            <br />
            <Bar dataKey="Sure" stackId="a" fill={COLORS[3]} />
            <Bar dataKey="Very sure" stackId="a" fill={COLORS[4]} />
          </BarChart>
        </ResponsiveContainer>
      </StyledCardContent>
    </React.Fragment>
  )
}

const StyledCardContent = styled(Segment)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  align-items: center;
`

export default StackBar
