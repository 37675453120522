import React, { useEffect, useState } from 'react'
import { Header, Responsive, Table, Item, Icon, Pagination } from 'semantic-ui-react'
import { dashboard } from '../../Theme/Styles'
import { NavLink } from 'react-router-dom'
import _ from 'lodash'
import useGlobal from '../../Store'
import { StyledTable, StyledTableHeader, StyledTransparentButton } from '../../Theme/Components'
import { NoPrint } from 'react-easy-print'

const AsNeededSupportTable = () => {
  const [globalState, globalActions] = useGlobal()
  const { currentState, currentDistrict, currentSchool, asNeededSupportLogsPagination, loggedInUser } = globalState
  const [asNeededLogs, setAsNeededLogs] = useState(globalState.asNeededLogs)

  useEffect(() => {
    setAsNeededLogs(globalState.asNeededLogs)
  }, [globalState.asNeededLogs])

  const getLogs = () => {
    setTimeout(() => {
      globalActions.asNeededLogs.getAsNeededLogs(0, 100000)
    }, 500)
  }

  useEffect(getLogs, [])
  useEffect(getLogs, [currentState, currentDistrict, currentSchool])

  return (
    <React.Fragment>
      <StyledTableHeader>
        <Header as='h2' style={dashboard.phHeader}>As-Needed Support Logs</Header>
        {['Peer Helper', 'Coordinator'].includes(loggedInUser.role) &&
        <StyledTransparentButton as={NavLink} to={`/as-needed-support-report/support-logs/0/edit`}>
          <Icon name='plus'/>
        </StyledTransparentButton>
        }
      </StyledTableHeader>

      <StyledTable compact striped size='small'>
        <Table.Header className="stickyTh">
          <Responsive as={Table.Row} minWidth={1000}>
            <Table.HeaderCell>
              Date
            </Table.HeaderCell>
            <Table.HeaderCell>
              Peer Helper
            </Table.HeaderCell>
            <Table.HeaderCell/>
          </Responsive>
        </Table.Header>

        <Table.Body>
          {_.map(asNeededLogs, ({ id, date, peerHelper }) => {
            if (peerHelper !== null && peerHelper !== undefined) {
              return (
                <Table.Row key={id}>
                  <Table.Cell>
                    <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                      Date
                    </Responsive>
                    {new Date(date.split(' ').join('T')).toLocaleDateString('en', {
                      year: '2-digit',
                      month: 'numeric',
                      day: 'numeric'
                    })}
                  </Table.Cell>
                  <Table.Cell>
                    <Responsive as='strong' maxWidth={767} style={{ marginRight: 5 }}>
                      Peer Helper
                    </Responsive>
                    {peerHelper.firstName + ' ' + peerHelper.lastName}
                  </Table.Cell>
                  <Table.Cell>
                    <NoPrint force>
                      <Item as={NavLink} to={`/as-needed-support-report/support-logs/${id}/view`}
                            style={{ color: '#2076BC' }}> VIEW LOG </Item>
                    </NoPrint>
                  </Table.Cell>
                </Table.Row>
              )
            }
          })}
        </Table.Body>
      </StyledTable>
    </React.Fragment>
  )
}

export default AsNeededSupportTable
