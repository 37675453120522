import React, { useEffect, useState } from 'react'
import { Header, Segment } from 'semantic-ui-react'
import * as Survey from 'survey-react'
import 'survey-react/survey.css'
import useCampaignsService from '../../Services/Campaigns'
import useGlobal from '../../Store'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

const SurveyTake = ({ match }) => {
  const [globalState, globalActions] = useGlobal()
  const { getSurvey, saveSurveyAnswers, profileIsComplete } = useCampaignsService()
  const { loggedInUser } = globalState
  const history = useHistory()

  const logId = match.params.logId || '0'
  const version = match.params.version || '0'
  const schoolId = match.params.schoolId || '0'

  const [surveyId] = useState(match.params.id || '0')

  const [survey, setSurvey] = useState({ id: '', name: '', body: '' })

  useEffect(() => {
    if (surveyId !== '0') {
      getSurvey(surveyId).then(survey => setSurvey(survey))
    }
  }, [surveyId, logId, schoolId])

  useEffect(() => {
    if (loggedInUser.role === 'Peer Helper') {
      profileIsComplete()
        .then(isComplete => {
          if (!isComplete) {
            history.push('/profile')
            globalActions.notifications.showNotification({
              show: true,
              header: 'You must complete your profile first!',
              subheader: '',
              type: 'warning'
            })
          }
        })
    }
  }, [loggedInUser])

  const onComplete = (data) => {
    saveSurveyAnswers(surveyId, JSON.stringify(data.valuesHash), logId, version, schoolId)
      .then(() => {
        window.scrollTo({ top: 0 })
      })
  }

  if (!survey.body) return null

  const surveyObj = JSON.parse(survey.body)

  surveyObj.title = survey.campaign.type === 'PEERHELPER'
    ? (survey.name + " - " + (survey.schoolLevels[0].name === 'ELEMENTARY' ? ' Elementary School' : ' Middle/High School'))
    : (version === '0' ? 'Pre-' : 'Post-') + survey.name.replace(" Survey", "").replace("PATH", "PATH Survey")

  const model = new Survey.Model(surveyObj)

  return (
    <>
      <Segment>
        <Survey.Survey
          model={model}
          onComplete={onComplete}
        />
      </Segment>
    </>
  )
}
const StyledHeader = styled(Header)`
  size: 24px !important;
  font-weight: lighter;
  margin-left: 15px;
`
export default SurveyTake
