import endpoints from '../Config/ApiEndpoints'
import axios from 'axios'

export const saveContact = async (store, contact) => {
  if (typeof store.state.loggedInUser.accessToken === 'undefined') {
    return false
  }

  let url = endpoints.contact.root
  store.setState({ requestStatus: 'LOADING' })
  try {
    await axios.post(url, contact, { headers: { Authorization: `Bearer ${store.state.loggedInUser.accessToken}` } })
    const notification = { type: 'success', header: 'Message sent' }

    setTimeout(() => {
      store.setState({ requestStatus: 'SUCCESS', notification })
    }, 500)
  } catch (error) {
    const notification = { type: 'success', header: 'Message could not be sent' }
    store.setState({ requestStatus: 'FAILED', notification })
  }
}
