import React, { useEffect, useState } from 'react'
import useGlobal from '../../Store'
import useValidation from '../../Utils/Validations'
import { Button, Divider, Form, Header, Icon, Segment } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'
import {
  lifeExperiencesOptions,
  placeOptions,
  onGoingSupportActivityTypeOptions,
  classWorkOptions, renderLabel
} from '../../Config/SelectOptions'
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'
import InputAdornment from '@material-ui/core/InputAdornment'
import useUrlBuilder from '../../Utils/Links'
import { dashboard } from '../../Theme/Styles'
import {
  RightMenuBar,
  RightMenuBarButton,
  RightMenuBarButtonIcon,
  RightMenuBarOptions,
  StyledTransparentButton
} from '../../Theme/Components'
import { editedOnGoingSupportLog as defaultEditedOnGoingSupportLog } from '../../Config/DefaultState'
import moment from 'moment'

const OnGoingSupportLogForm = (props) => {
  const onGoingSupportLogId = props.match.params.id || '0'
  const studentId = props.match.params.extraId
  const [globalState, globalActions] = useGlobal()
  const [onGoingSupportLog, setOnGoingSupportLog] = useState(defaultEditedOnGoingSupportLog)
  const requiredFields = ['studentActivity', 'experiences', 'location']
  const [validForm, validationErrors, onFieldChangeValidation] = useValidation(requiredFields, onGoingSupportLog)
  const [makeUrl] = useUrlBuilder(props.match.url)
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    globalActions.onGoingSupportLogs.getOnGoingSupportLog(onGoingSupportLogId)
  }, [])

  useEffect(() => {
    globalActions.onGoingSupportLogs.getOnGoingSupportLog(onGoingSupportLogId)
  }, [onGoingSupportLogId])

  useEffect(() => {
    const { date } = globalState.editedOnGoingSupportLog
    const dateObject = date !== '' ? new Date(date.split(' ').join('T')) : new Date()

    setOnGoingSupportLog({
      ...globalState.editedOnGoingSupportLog,
      classWorkType: globalState.editedOnGoingSupportLog.studentActivity,
      date: dateObject,
      time: dateObject
    })
  }, [globalState.editedOnGoingSupportLog])

  const updateOnGoingSupportLogState = e => {
    const { name, value } = typeof e.target !== 'undefined' ? e.target : e
    setOnGoingSupportLog({ ...onGoingSupportLog, [name]: value })
    onFieldChangeValidation(name, value)
  }

  const onDateChange = (date) => {
    setOnGoingSupportLog({ ...onGoingSupportLog, date })
  }

  const onTimeChange = (time) => {
    setOnGoingSupportLog({ ...onGoingSupportLog, time })
  }

  const save = () => {
    if (validForm) {
      setDisabled(true)
      globalActions.onGoingSupportLogs.saveOnGoingSupportLog({
        ...onGoingSupportLog,
        date: moment(onGoingSupportLog.date).format().slice(0, 19).split('T')[0] + ' ' + moment(onGoingSupportLog.time).format().slice(0, 19).split('T')[1]
      }, studentId)
        .then(() => {
          props.history.push(makeUrl('/'))
        })
        .catch(e => console.log(e))
        .then(() => setDisabled(false))
    } else {
      globalActions.notifications.showNotification({
        type: 'warning',
        header: 'Form validation error',
        subheader: 'Please complete all required fields!'
      })
    }
  }

  return (
    <Segment>
      <RightMenuBar>
        <Header as='h3' style={dashboard.myProgramHeader}>{onGoingSupportLogId !== '0' ? 'Edit' : 'New'} Ongoing Support
          Log</Header>
        <RightMenuBarOptions>
          <RightMenuBarButton as={NavLink} to={makeUrl(`/`)}>
            <RightMenuBarButtonIcon name='close' style={{ fontSize: 20 }}/>
          </RightMenuBarButton>
        </RightMenuBarOptions>
      </RightMenuBar>

      <Divider/>

      <Form size='large'>
        <Form.Field name='dateOfActivity' required>
          <label>1. Date of Activity</label>
          <KeyboardDatePicker
            value={onGoingSupportLog.date}
            onChange={date => onDateChange(date)}
            animateYearScrolling
            format='MM/DD/YY'
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position='end'>
                  <Icon name='calendar alternate outline'/>
                </InputAdornment>
              )
            }}/>
        </Form.Field>
        <Form.Field name='timeOfActivity' required>
          <label>2. Time of Activity</label>
          <KeyboardTimePicker
            ampm
            value={onGoingSupportLog.time}
            onChange={time => onTimeChange(time)}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position='end'>
                  <Icon name='clock outline'/>
                </InputAdornment>
              )
            }}/>
        </Form.Field>
        <Form.Select label='3. Where did you help this student?' name='location'
                     onChange={(e, data) => updateOnGoingSupportLogState(data)}
                     value={onGoingSupportLog.location} required
                     error={validationErrors.location.error}
                     options={placeOptions} placeholder='Choose...'/>
        <Form.Select label='4. What did you do together?' name='studentActivity'
                     onChange={(e, data) => updateOnGoingSupportLogState(data)}
                     value={classWorkOptions.findIndex(option => option.value === onGoingSupportLog.studentActivity) > -1 ? 'WORK' : onGoingSupportLog.studentActivity}
                     required
                     error={validationErrors.studentActivity.error}
                     options={onGoingSupportActivityTypeOptions} placeholder='Choose...'/>

        {(onGoingSupportLog.studentActivity === 'WORK') && (
          <Form.Select label='Choose class work:' name='classWorkType'
                       multiple
                       onChange={(e, data) => updateOnGoingSupportLogState(data)}
                       value={onGoingSupportLog.classWorkType} required
                       error={typeof validationErrors.classWorkType !== 'undefined' ? validationErrors.classWorkType.error : false}
                       options={classWorkOptions} placeholder='Choose...'/>)
        }
        <Form.Dropdown label='5. Topics shared during this conversation'
                       name='experiences'
                       onChange={(e, data) => updateOnGoingSupportLogState(data)}
                       value={onGoingSupportLog.experiences} fluid multiple selection required
                       error={validationErrors.experiences.error}
                       options={lifeExperiencesOptions.filter(option => !option.groupOnly)}
                       renderLabel={renderLabel}

                       placeholder='Choose one or more'/>
        <Form.Select label='6. Referred to a Program Coordinator / School Counselor:' name='referred'
                     onChange={(e, data) => updateOnGoingSupportLogState(data)}
                     value={onGoingSupportLog.referred}
                     options={[{ key: 'Yes', text: 'Yes', value: true }, { key: 'No', text: 'No', value: false }]}
                     placeholder='Choose...'/>
        <div style={{ textAlign: 'right' }}>
          <Button.Group>
            <StyledTransparentButton onClick={save} disabled={disabled}>Save</StyledTransparentButton>
          </Button.Group>
        </div>
      </Form>
    </Segment>
  )
}

export default OnGoingSupportLogForm
