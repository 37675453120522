import React from 'react'
import { Grid, Header, Segment, Table } from 'semantic-ui-react'
import { dashboard } from '../../../Theme/Styles'
import { NavLink } from 'react-router-dom'

const AtRiskReferralsStatistics = ({ referrals }) => {
  return (
    <Segment style={dashboard.segment}>
      <Header as='h2' style={dashboard.header}>At-Risk Referrals</Header>
      <Grid rows={2}>
        <Grid.Row columns={2}>
          <Grid.Column computer={7} mobile={16} style={{ textAlign: 'center' }}>
            <Header style={dashboard.count}>
              {referrals.count}
              <Header.Subheader style={dashboard.countSubHeader}>Referrals</Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column computer={9} mobile={16}>
            <Header as='h4' disabled style={dashboard.table.header}>Type</Header>
            <Table basic='very' style={dashboard.table.base}>
              <Table.Body>
                <Table.Row style={dashboard.list}>
                  <Table.Cell style={dashboard.table.cell}>Support</Table.Cell>
                  <Table.Cell style={dashboard.table.blueCell}>{referrals.supportPercentage}%</Table.Cell>
                </Table.Row>
                <Table.Row style={dashboard.list}>
                  <Table.Cell style={dashboard.table.cell}>Prevention</Table.Cell>
                  <Table.Cell style={dashboard.table.blueCell}>{referrals.preventionPercentage}%</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={dashboard.boxButtonsRow}>
          <Grid.Column floated='right' style={dashboard.boxButtonsColumn}>
            <NavLink to='/atrisk-referrals-report' style={dashboard.boxButtonsButton}>View</NavLink>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

export default AtRiskReferralsStatistics
