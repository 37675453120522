import _ from 'lodash'
import { Grid, Header, Responsive, Segment, Table } from 'semantic-ui-react'
import { Typography } from '@material-ui/core'
import { StyledTable } from '../../Theme/Components'
import React, { useEffect, useState } from 'react'
import useGlobal from '../../Store'
import * as Survey from 'survey-react'
import useCampaignsService from '../../Services/Campaigns'
import styled from 'styled-components'
import PieChartComponent from '../../Components/PieChart'
import BarGraphSegment from '../../Components/BarGraphSegment'

const SurveyComparationPage = (props) => {
  const [globalState] = useGlobal()
  const { currentState, currentDistrict, currentSchool } = globalState

  const { getSurvey, getSurveyPeerHelpers } = useCampaignsService()

  const surveyId = props.match.params.id || '0'

  const [survey, setSurvey] = useState({ id: 0, name: '', campaign: { id: '' } })
  const [questions, setQuestions] = useState([])
  const [peerHelpers, setPeerHelpers] = useState([])

  useEffect(() => {
    if (surveyId !== '0')
      getSurvey(surveyId).then(survey => setSurvey(survey))
  }, [])

  useEffect(() => {
    (async () => {
      if (survey.id) {
        const ph = []
        for (let i = 0; i < survey.numberOfCompletions; i++) {
          const data = await getSurveyPeerHelpers(survey.id, i)
          ph[i] = data
        }
        setPeerHelpers(ph)

        const model = new Survey.Model(JSON.parse(survey.body))
        setQuestions(model.getAllQuestions())
      }
    })()
  }, [survey, currentState, currentDistrict, currentSchool])

  const getVersionName = (numberOfCompletions, version) => {
    if (numberOfCompletions > 2) {
      return `Log ${parseInt(version) + 1}`
    } else {
      return version === 0 ? 'Pre-PATH' : 'Post-PATH'
    }
  }

  const renderQuestionsTable = () => {
    if (!peerHelpers.length) return null

    const newQuestions = [...questions]
    peerHelpers.forEach((phs, version) => {
      phs.forEach((peerHelper) => {
        const log = JSON.parse(peerHelper.body)

        Object.keys(log).forEach(questionName => {
          const questionIndex = newQuestions.findIndex(q => q.propertyHash.name === questionName)

          if (questionIndex > -1) {
            if (newQuestions[questionIndex].propertyHash.choices) {
              if (questionName === 'question2') {
                if (newQuestions[questionIndex].propertyHash.choices.length === 6) {
                  newQuestions[questionIndex].propertyHash.choices.push({ propertyHash: { value: 'Multiracial' } })
                }

                if (log.question2.length > 1) {
                  log.question2 = ['Multiracial']
                }
              }

              if (['question4', 'question5', 'question6', 'question7'].includes(questionName)) {
                if (!newQuestions[questionIndex].propertyHash.correctAnswers)
                  newQuestions[questionIndex].propertyHash.correctAnswers = []
                if (!newQuestions[questionIndex].propertyHash.wrongAnswers)
                  newQuestions[questionIndex].propertyHash.wrongAnswers = []
                if (!newQuestions[questionIndex].propertyHash.partialAnswers)
                  newQuestions[questionIndex].propertyHash.partialAnswers = []
                if (!newQuestions[questionIndex].propertyHash.totalAnswers)
                  newQuestions[questionIndex].propertyHash.totalAnswers = []

                if (!newQuestions[questionIndex].propertyHash.correctAnswers[version])
                  newQuestions[questionIndex].propertyHash.correctAnswers[version] = 0
                if (!newQuestions[questionIndex].propertyHash.wrongAnswers[version])
                  newQuestions[questionIndex].propertyHash.wrongAnswers[version] = 0
                if (!newQuestions[questionIndex].propertyHash.partialAnswers[version])
                  newQuestions[questionIndex].propertyHash.partialAnswers[version] = 0
                if (!newQuestions[questionIndex].propertyHash.totalAnswers[version])
                  newQuestions[questionIndex].propertyHash.totalAnswers[version] = 0

                if (questionName === 'question4') {
                  newQuestions[questionIndex].propertyHash.totalAnswers[version] += 1

                  if (log.question4 === newQuestions[questionIndex].propertyHash.correctAnswer || log.question4.includes(newQuestions[questionIndex].propertyHash.correctAnswer)) {
                    newQuestions[questionIndex].propertyHash.correctAnswers[version] += 1
                  } else {
                    newQuestions[questionIndex].propertyHash.wrongAnswers[version] += 1
                  }
                } else {
                  newQuestions[questionIndex].propertyHash.totalAnswers[version] += 1
                  if (newQuestions[questionIndex].propertyHash.correctAnswer.every(answer => log[questionName].includes(answer))) {
                    newQuestions[questionIndex].propertyHash.correctAnswers[version] += 1
                  } else if (newQuestions[questionIndex].propertyHash.correctAnswer.some(answer => log[questionName].includes(answer))) {
                    newQuestions[questionIndex].propertyHash.partialAnswers[version] += 1
                  } else {
                    newQuestions[questionIndex].propertyHash.wrongAnswers[version] += 1
                  }
                }
              }

              if (_.isArray(log[questionName])) {
                log[questionName].forEach(answer => {
                  const choiceIndex = newQuestions[questionIndex].propertyHash.choices.findIndex(choice => choice.propertyHash.value === answer)

                  if (choiceIndex > -1) {
                    if (!newQuestions[questionIndex].propertyHash.choices[choiceIndex].propertyHash?.answersCount?.[version]) {
                      const newArray = newQuestions[questionIndex].propertyHash.choices[choiceIndex].propertyHash?.answersCount ? newQuestions[questionIndex].propertyHash.choices[choiceIndex].propertyHash?.answersCount : []
                      newArray[version] = 1
                      newQuestions[questionIndex].propertyHash.choices[choiceIndex].propertyHash.answersCount = newArray
                    } else
                      newQuestions[questionIndex].propertyHash.choices[choiceIndex].propertyHash.answersCount[version] += 1
                  }
                })
              } else {
                const choiceIndex = newQuestions[questionIndex].propertyHash.choices.findIndex(choice => choice.propertyHash.value === log[questionName])
                if (choiceIndex > -1) {
                  if (!newQuestions[questionIndex].propertyHash.choices[choiceIndex].propertyHash?.answersCount?.[version]) {
                    const newArray = newQuestions[questionIndex].propertyHash.choices[choiceIndex].propertyHash?.answersCount ? newQuestions[questionIndex].propertyHash.choices[choiceIndex].propertyHash?.answersCount : []
                    newArray[version] = 1
                    newQuestions[questionIndex].propertyHash.choices[choiceIndex].propertyHash.answersCount = newArray
                  } else
                    newQuestions[questionIndex].propertyHash.choices[choiceIndex].propertyHash.answersCount[version] += 1
                }
              }
            }

            if (newQuestions[questionIndex].propertyHash.rows && !newQuestions[questionIndex].propertyHash.columns) {
              Object.keys(log[questionName]).forEach((answerKey) => {
                const rowIndex = newQuestions[questionIndex].propertyHash.rows.findIndex(choice => choice.propertyHash.value === answerKey)

                if (rowIndex > -1) {
                  if (!newQuestions[questionIndex].propertyHash.rows[rowIndex].propertyHash?.answersCount?.[version]) {
                    const newArray = newQuestions[questionIndex].propertyHash.rows[rowIndex].propertyHash?.answersCount ? newQuestions[questionIndex].propertyHash.rows[rowIndex].propertyHash?.answersCount : []
                    newArray[version] = 1
                    newQuestions[questionIndex].propertyHash.rows[rowIndex].propertyHash.answersCount = newArray
                  } else
                    newQuestions[questionIndex].propertyHash.rows[rowIndex].propertyHash.answersCount[version] += 1
                }
              })
            }

            if (newQuestions[questionIndex].propertyHash.rows && newQuestions[questionIndex].propertyHash.columns) {
              Object.keys(log[questionName]).forEach((answerKey) => {
                const rowIndex = newQuestions[questionIndex].propertyHash.rows.findIndex(choice => choice.propertyHash.value === answerKey)
                const columnIndex = newQuestions[questionIndex].propertyHash.columns.findIndex(column => column.propertyHash.value === log[questionName][answerKey])

                if (rowIndex > -1 && columnIndex > -1) {
                  if (!newQuestions[questionIndex].propertyHash.rows[rowIndex].propertyHash.answersCount) {
                    newQuestions[questionIndex].propertyHash.rows[rowIndex].propertyHash.answersCount = []
                  }
                  if (!newQuestions[questionIndex].propertyHash.rows[rowIndex].propertyHash.answersCount[columnIndex]) {
                    newQuestions[questionIndex].propertyHash.rows[rowIndex].propertyHash.answersCount[columnIndex] = []
                  }

                  if (!newQuestions[questionIndex].propertyHash.rows[rowIndex].propertyHash.answersCount?.[columnIndex][version]) {
                    newQuestions[questionIndex].propertyHash.rows[rowIndex].propertyHash.answersCount[columnIndex][version] = 1
                  } else
                    newQuestions[questionIndex].propertyHash.rows[rowIndex].propertyHash.answersCount[columnIndex][version] += 1
                }
              })
            }
          }
        })
      })
    })

    return (
      <Segment>
        {newQuestions.map((question, index) => (
          <div key={index}>
            <Typography style={{
              margin: '20px 0',
              fontWeight: 'bold',
              fontSize: 18
            }}>{`${index + 1}. ${question.fullTitle}`}</Typography>

            <StyledTable compact striped size="small">
              <Table.Header className="stickyTh">
                <Responsive as={Table.Row} minWidth={1000}>
                  <Table.HeaderCell style={{ width: '40%' }}>
                    Responses
                  </Table.HeaderCell>

                  {[...Array(survey.numberOfCompletions).keys()].map(version => (
                    <Table.HeaderCell style={{ width: `${50 / survey.numberOfCompletions}%` }}>
                      {getVersionName(survey.numberOfCompletions, version)}
                    </Table.HeaderCell>
                  ))}

                  <Table.HeaderCell style={{ width: '10%' }}>
                    % Change
                  </Table.HeaderCell>

                </Responsive>
              </Table.Header>

              <Table.Body>
                {question.propertyHash.choices?.map((choice, choiceIndex) => (
                  <Table.Row key={choiceIndex}>
                    <Table.Cell>
                      {!![3, 4, 5, 6].includes(index) ? (
                        <>
                          {!!((question.propertyHash.correctAnswer === choice.propertyHash.value) || (question.propertyHash.correctAnswer.includes(choice.propertyHash.value))) ? (
                            <p style={{ fontSize: 15, fontWeight: '700' }}>
                              {choice.propertyHash.value}
                            </p>
                          ) : (
                            choice.propertyHash.value
                          )}
                        </>
                      ) : (
                        choice.propertyHash.value
                      )}
                    </Table.Cell>
                    {[...Array(survey.numberOfCompletions).keys()].map(version => {
                      return (
                        <Table.Cell>{choice.propertyHash?.answersCount?.[version] || '-'}</Table.Cell>
                      )
                    })}

                    <Table.Cell>
                      {((choice.propertyHash?.answersCount?.[1] || 0) === 0 && (choice.propertyHash?.answersCount?.[0] || 0) === 0) ? '-' : `${(choice.propertyHash?.answersCount?.[1] || 0) >= (choice.propertyHash?.answersCount?.[0] || 0) ? '+' : '-'}${Math.abs(100 - Math.round((choice.propertyHash?.answersCount?.[1] || 0) * 100 / choice.propertyHash?.answersCount?.[0] || 0)) || '0'}%`}
                    </Table.Cell>
                  </Table.Row>
                ))}

                {question.propertyHash.rows && !question.propertyHash.columns && question.propertyHash.rows.map((row, rowIndex) => (
                  <Table.Row key={rowIndex}>
                    <Table.Cell>{row.propertyHash.value}</Table.Cell>
                    {[...Array(survey.numberOfCompletions).keys()].map(version => (
                      <Table.Cell>{row.propertyHash?.answersCount?.[version] || '-'}</Table.Cell>
                    ))}

                    <Table.Cell>
                      {((row.propertyHash?.answersCount?.[1] || 0) === 0 && (row.propertyHash?.answersCount?.[0] || 0) === 0) ? '-' : `${(row.propertyHash?.answersCount?.[1] || 0) >= (row.propertyHash?.answersCount?.[0] || 0) ? '+' : '-'}${Math.abs(100 - Math.round((row.propertyHash?.answersCount?.[1] || 0) * 100 / row.propertyHash?.answersCount?.[0] || 0)) || '0'}%`}
                    </Table.Cell>
                  </Table.Row>
                ))}

                {question.propertyHash.rows && question.propertyHash.columns && question.propertyHash.rows.map((row, rowIndex) => (
                  <>
                    <Table.Row key={rowIndex}>
                      <Table.Cell>{row.propertyHash.value}</Table.Cell>
                      {[...Array(survey.numberOfCompletions).keys()].map(version => (
                        <Table.Cell style={{ verticalAlign: 'top' }}>
                          <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginRight: 40
                          }}>
                            {question.propertyHash.columns.map((column, columnIndex) => (
                              <div key={columnIndex} style={{ textAlign: 'center' }}>
                                {column.propertyHash.value}<br/><br/>{row.propertyHash?.answersCount?.[columnIndex]?.[version] || '-'}
                              </div>
                            ))}
                          </div>
                        </Table.Cell>
                      ))}
                      <Table.Cell> - </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell colSpan={4} style={{ paddingTop: 20 }}>
                        {[...Array(survey.numberOfCompletions).keys()].map(version => (
                          <BarGraphSegment
                            title={version ? 'Post-PATH' : 'Pre-PATH'}
                            data={question.propertyHash.columns.map((column, columnIndex) => ({
                              name: column.propertyHash.value,
                              total: row.propertyHash?.answersCount?.[columnIndex]?.[version] || 0
                            }))}
                            height={200}
                            dataKey="value"/>
                        ))}
                      </Table.Cell>
                    </Table.Row>
                  </>
                ))}
              </Table.Body>
            </StyledTable>
            {!![3, 4, 5, 6].includes(index) && (
              <>
                {[...Array(survey.numberOfCompletions).keys()].map(version => (
                  //how to exclude if total answers is undefined
                  <PieChartComponent
                    title={version ? 'Post-PATH' : 'Pre-PATH'}
                    label="Correct / Incorrect answers"
                    columns={[{
                      name: 'CORRECT',
                      percent: question.propertyHash.totalAnswers?.[version]?(100 - Math.round((question.propertyHash.partialAnswers?.[version] || 0 + question.propertyHash.wrongAnswers?.[version] || 0) * 100 / question.propertyHash.totalAnswers?.[version] || 0) || 0):0
                    }, {
                      name: 'PARTIALLY',
                      percent: Math.round((question.propertyHash.partialAnswers?.[version] || 0) * 100 / question.propertyHash.totalAnswers?.[version] || 0) || 0
                    }, {
                      name: 'INCORRECT',
                      percent: Math.round((question.propertyHash.wrongAnswers?.[version] || 0) * 100 / question.propertyHash.totalAnswers?.[version] || 0) || 0
                    }]}/>
                ))}
              </>
              
            )}
          </div>
        ))}
      </Segment>
    )
  }

  return (
    <Grid>
      <Grid.Column mobile={16} tablet={16} computer={16}>
        <Grid.Row style={{ paddingTop: 0 }}>
          <StyledHeader
            as="h2">{`PATH Survey - ${survey.name.includes('Middle') ? 'Middle/High School' : 'Elementary School'} - Comparison Report`}</StyledHeader>
        </Grid.Row>

        <Grid.Row>
          {renderQuestionsTable()}
        </Grid.Row>
      </Grid.Column>
    </Grid>
  )
}

export default SurveyComparationPage

const StyledHeader = styled(Header)`
                    size: 24px !important;
                    font-weight: lighter;
                    margin:20px;
                    `
